<template>
  <div class='wrapper' :class='{"is-dark-mode": isDarkMode}'>
    <div class='search-wrapper'>
      <SearchField :modelValue='search' @update:modelValue='searchChange'/>
    </div>
    <div class='list' v-if='options && options.length > 0 && !isLoading'>
      <ListItem v-for='(option, index) in options'
                :key='index'
                :class='{"is-active": isSelected(option), "list-item": true}'
                @click='change(option)'>
        <div class='list-item-checkbox'>
          <CheckIcon/>
        </div>
        <p class='text'>{{option.label}}</p>
      </ListItem>
    </div>
    <div class='placeholder' v-else-if='isLoading'>Loading...</div>
    <div class='placeholder' v-else>List is empty.</div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  import CheckIcon from '@/components/icons/CheckIcon'
  import {SearchField} from '@/components/ui/text-fields'
  import ListItem from '@/components/ui/list/ListItem'

  export default {
    props: {
      modelValue: [Object, Array],
      isMultiple: {
        type: Boolean,
        default: false
      },
      onChange: Function,
      requestOptions: Function
    },
    components: {
      CheckIcon,
      SearchField,
      ListItem
    },
    data: () => ({
      options: [],
      search: '',
      isLoading: false
    }),
    computed: {
      ...mapGetters('theme', ['isDarkMode']),
    },
    methods: {
      isSelected(option) {
        if (!this.isMultiple) {
          return this.modelValue?.value === option?.value
        } else {
          return this.modelValue.some(item => item?.value === option?.value)
        }
      },
      change(option) {
        let val = this.modelValue

        if (!this.isMultiple) {
          val = val === option?.value ? null : option
        } else {
          if (!val.find(item => item?.value === option?.value)) {
            val.push(option)
          } else {
            const foundIndex = val.findIndex(item => item?.value === option?.value)

            val.splice(foundIndex, 1)
          }
        }

        this.$emit('update:modelValue', val)
      },
      async setOptions() {
        this.isLoading = true

        try {
          const res = await this?.requestOptions(this.search)

          this.options = res || []
        } finally {
          this.isLoading = false
        }
      },
      async searchChange(event) {
        this.search = event.target.value

        await this.setOptions()
      }
    },
    emits: ['update:modelValue'],
    async created() {
      await this.setOptions()
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    width: 100%;
    height: 100%;

    &.is-dark-mode {
      .list {
        & .list-item {
          &.is-active {
            & .list-item-checkbox {
              background-color: $violet;
            }
          }
        }
      }
    }
  }

  .placeholder {
    width: 100%;
    text-align: center;
  }

  .search-wrapper {
    margin-bottom: 10px;
  }

  .list {
    height: calc(100% - 50px);
    overflow-y: auto;

    & .list-item {
      padding: 5px 10px 5px 5px;

      .text {
        display: inline-block;
        width: calc(100% - 40px);
        color: var(--text-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.is-active {
        & .list-item-checkbox {
          background-color: $black;

          svg {
            opacity: 1;
          }
        }
      }

      & .list-item-checkbox {
        background-color: #7e7e7e;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .3s ease;
        margin-right: 10px;

        svg {
          transition: .3s ease;
          opacity: 0;
          color: $white;
        }
      }
    }
  }
</style>