<template>
  <Ethereum :callback="initialize" />
  <div class="farms-table">
    <div class="farms-header">
      <div class="farm-header-text" v-for="index in 5" :key="index">
        <TooltipLabel :tooltip="columns[index - 1].tooltip">
          <template v-slot:default>
            <p>{{ columns[index - 1].title }}</p>
          </template>
        </TooltipLabel>
      </div>
      <div class="farm-header-text tvl">
        <TooltipLabel :tooltip="columns[5].tooltip">
          <template v-slot:default>
            <p>{{ columns[5].title }}</p>
          </template>
        </TooltipLabel>
      </div>
    </div>
    <div class="farm-row" v-if="getFarms.length === 0">
      <FarmsRowSkeleton v-for="index in 4" :key="index" />
    </div>
    <div v-else class="farm-row" v-for="(farm, index) in getFarms" :key="index">
      <FarmsRow :farm="farm" />
    </div>
  </div>
</template>

<script>
'use strict';

// imports
import { mapState } from 'vuex';
import store from '/src/store';
import { formatNumber } from '/src/utility';
import axios from 'axios';
import { ethers } from 'ethers';

// Component Imports
import Ethereum from '../../common/Ethereum';
import FarmsRow from './FarmsRow';
import TooltipLabel from '/src/components/ui/TooltipLabel';
import FarmsRowSkeleton from './FarmsRowSkeleton.vue';

// Icon Imports
import LoadingSpinnerIcon from '/src/components/icons/LoadingSpinnerIcon';

// Set up the default component.
export default {
  components: {
    Ethereum,
    FarmsRow,
    TooltipLabel,
    FarmsRowSkeleton
  },

  data() {
    return {
      ethers,
      formatNumber,

      refreshInterval: null,
      hasInitialized: false,

      ethPriceUsd: 0,
      tokenPrices: {}
    };
  },

  computed: {
    ...mapState({
      ethereum: (state) => state.ethers,
      farms: (state) => state.farms
    }),

    localCanSign() {
      return this.ethereum.canSign;
    },

    columns() {
      return [
        {
          title: 'Farm'
        },
        {
          title: 'Staked',
          tooltip: 'Total amount currently staked in the associated farm.'
        },
        {
          title: 'Earned',
          tooltip: 'Total amount of tokens/points available to claim or spend.'
        },
        {
          title: 'Estimated Yield per $1000',
          tooltip: 'Estimated yield per $1000 (in tokens) in the associated farm.'
        },
        {
          title: 'APY',
          tooltip: 'Current estimated APY. Fluctuations in returns may occur depending on pool conditions.'
        },
        {
          title: 'TVL',
          tooltip: 'Total value locked in the associated farm.'
        }
      ];
    },

    // Dynamically gets all farms.
    getFarms() {
      const farms = [];
      for (const farmData of this.farms.farms) {
        let farm = farmData.data;
        let amountEarned = ethers.BigNumber.from(0);
        let amountStaked = ethers.BigNumber.from(0);
        let userBalance = ethers.BigNumber.from(0);
        let userTokenBalance = ethers.BigNumber.from(0);
        let userAllowance = ethers.BigNumber.from(0);
        let tokenYield = ethers.BigNumber.from(0);
        let pointYield = ethers.BigNumber.from(0);
        let totalTokenStrength = farm.totalTokenStrength;
        let totalPointStrength = farm.totalPointStrength;
        let totalValueLocked = ethers.BigNumber.from(0);

        for (const poolIndex of Object.keys(farm.poolTokens)) {
          const poolToken = farm.poolTokens[poolIndex].poolToken;
          const pool = farm.poolTokens[poolIndex];
          if (farmData.availableUserPoints) amountEarned = farmData.availableUserPoints;
          if (farmData.poolData[poolIndex].userAmount) amountStaked = farmData.poolData[poolIndex].userAmount;
          if (farmData.poolData[poolIndex].userWalletBalance) userBalance = farmData.poolData[poolIndex].userWalletBalance;
          if (farmData.poolData[poolIndex].pendingRewards) userTokenBalance = farmData.poolData[poolIndex].pendingRewards;
          if (farmData.poolData[poolIndex].userAllowance) userAllowance = farmData.poolData[poolIndex].userAllowance;

          // Calculate rewards.
          const tokenPriceUsd = this.tokenPrices[farm.rewardToken.token];
          if (tokenPriceUsd && tokenPriceUsd > 0) {
            let tokenPerThousandDollars = ethers.BigNumber.from(Math.floor(1000 / tokenPriceUsd)).mul(ethers.utils.parseEther('1'));
            let totalTokenStaked = pool.poolTotalSupply;
            let totalAfterDeposit = totalTokenStaked.add(tokenPerThousandDollars);
            let poolShare = tokenPerThousandDollars.mul(1000000).div(totalAfterDeposit);
            pointYield = this.calculateReturns(farm.currentPointRate.mul(pool.pointStrength).div(totalPointStrength), poolShare);
            totalValueLocked = `$${formatNumber(ethers.utils.formatUnits(totalTokenStaked.toString(), 18) * tokenPriceUsd, 0)}`;
          }

          // Calculate token rewards if LP pool
          if (farmData.poolData[poolIndex].isLp && this.ethPriceUsd > 0) {
            let etherPerLp = farmData.poolData[poolIndex].lpTokenValue / 1000000;
            let lpPrice = etherPerLp * this.ethPriceUsd;
            let lpPerThousandDollars = lpPrice > 0 ? ethers.BigNumber.from(Math.floor(1000000.0 / lpPrice)).mul(ethers.utils.parseEther('1')) : ethers.BigNumber.from(0);
            let totalLpStaked = farmData.poolData[poolIndex].poolTotalSupply;
            let totalAfterDeposit = totalLpStaked.mul(1000).add(lpPerThousandDollars);
            totalValueLocked = `$${formatNumber(ethers.utils.formatUnits(totalLpStaked.toString(), 18) * lpPrice, 0)}`;

            let poolShare = totalAfterDeposit > 0 ? lpPerThousandDollars.mul(1000000).div(totalAfterDeposit) : 0;

            // Calculate the token rate.
            pointYield = this.calculateReturns(farm.currentPointRate.mul(pool.pointStrength).div(totalTokenStrength), poolShare);
            tokenYield = this.calculateReturns(farm.currentTokenRate.mul(pool.tokenStrength).div(totalPointStrength), poolShare);
          }

          // Update farms with formatted data.
          farms.push({
            address: farm.stakerContract,
            token: {
              address: poolToken,
              symbol: farm.rewardToken.symbol
            },
            pair: farmData.poolData[poolIndex].pair,
            pairImgs: farmData.poolData[poolIndex].pairImgs,
            getLink: farmData.poolData[poolIndex].getLink,
            contractLink: farmData.poolData[poolIndex].contractLink,
            pairInfoLink: farmData.poolData[poolIndex].pairInfoLink,
            isLp: farmData.poolData[poolIndex].isLp,
            point: farmData.point,
            amountStaked: amountStaked,
            amountEarned: amountEarned,
            userBalance: userBalance,
            userTokenBalance: userTokenBalance,
            userAllowance: userAllowance,
            pointYield: pointYield,
            tokenYield: tokenYield,
            tokenPrice: tokenPriceUsd,
            totalValueLocked: totalValueLocked
          });
        }
      }
      return farms;
    }
  },

  methods: {
    async initialize() {
      let userAddress = ethers.constants.AddressZero;

      if (this.ethereum.canSign) {
        userAddress = this.ethereum.address;
      }

      await store.dispatch('farms/retrieveFullFarmsInformation', userAddress, {
        root: true
      });

      // Retrieve the price of Ether.

      try {
        let priceResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
        this.ethPriceUsd = priceResponse ? ethers.utils.parseEther(`${priceResponse.data.ethereum.usd}`).div(ethers.constants.WeiPerEther) : 3300;
      } catch (error) {
        console.error(error);
      }

      // Only retrieve personal user data if the provider is a signer.
      if (this.ethereum.canSign) {
        await this.startPolling();
      }

      // Retrieve the price of all farm tokens.
      await this.getTokenPrices();
      this.hasInitialized = true;
    },

    async startPolling() {
      if (!this.refreshInterval) {
        const pageUpdate = async () => {
          let userAddress = ethers.constants.AddressZero;
          if (this.ethereum.canSign) {
            userAddress = this.ethereum.address;
          }
          await store.dispatch('farms/retrieveFullFarmsInformation', userAddress, { root: true });
        };
        await pageUpdate();
        this.refreshInterval = setInterval(pageUpdate, 20000);
      }
    },

    async stopPolling() {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    },

    // Get all farm token prices from coingecko.
    async getTokenPrices() {
      for (const farm of this.farms.farms) {
        const tokenAddress = farm.rewardToken.token;
        if (!this.tokenPrices[tokenAddress]) {
          try {
            const tokenPriceResponse = await axios.get(`https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokenAddress}&vs_currencies=eth%2Cusd`);

            this.tokenPrices[tokenAddress] = tokenPriceResponse.data[tokenAddress.toLowerCase()] ? tokenPriceResponse.data[tokenAddress.toLowerCase()].usd : 1;
          } catch (error) {
            console.error(error);
          }
        }
      }
    },

    calculateReturns(currentRate, poolShare) {
      return {
        day: currentRate
          .mul(6450)
          .mul(poolShare)
          .div(1000000),
        week: currentRate
          .mul(6450)
          .mul(7)
          .mul(poolShare)
          .div(1000000),
        month: currentRate
          .mul(6450)
          .mul(30)
          .mul(poolShare)
          .div(1000000),
        year: currentRate
          .mul(6450)
          .mul(365)
          .mul(poolShare)
          .div(1000000)
      };
    }
  },

  // Watch the Ethereum store for an address change to detect a wallet connection.
  watch: {
    localCanSign: {
      deep: true,
      handler: async function(newValue) {
        if (newValue) {
          await this.startPolling();
        } else {
          await this.stopPolling();
        }
      }
    }
  }
};
</script>

<style scoped>
.farms-table {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-bottom: none;
  width: 100%;
}

.farms-header {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.farm-header-text {
  display: flex;
  padding: 20px;
  align-items: center;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  /* cursor: pointer; */
}

.farm-header-text:hover {
  /* background-color: rgba(var(--text-color-rgb), 0.1); */
}

.farm-header-text:active {
  /* background-color: rgba(var(--text-color-rgb), 0.2); */
}

.farm-header-text > p {
  margin-right: 10px;
}

.svg {
  display: flex;
}

@media (max-width: 971px) {
  .farms-header {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 730px) {
  .tvl {
    display: none;
  }
}

@media (max-width: 520px) {
  .farms-header {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .farm-header-text {
    font-size: 12px;
    padding: 20px 10px;
  }
}
</style>
