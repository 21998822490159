'use strict';

// Imports.
import axios from 'axios';

// Specify actions that can be performed by this module.
export default {
  async updateEthPrice({ commit }) {
    try {
      let priceResponse = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      );
      let ethPrice = priceResponse.data.ethereum.usd;
      commit('updateEthPrice', ethPrice);
    } catch (error) {
      console.error(error);
    }
  }
};
