<template>
  <Select :options='options'
          :modelValue='modelValue'
          :label='label'
          :isClearable='isClearable'
          :isSelectWithOptionIcon='isSelectWithOptionIcon'
          contentWidth='120px'
          @update:modelValue='handleChange'/>
</template>

<script>
  import {Select} from '@/components/ui/select'

  import {currencyOptions} from './data'

  export default {
    components: {Select},
    props: {
      modelValue: {
        type: Object,
        required: false,
        default: null
      },
      label: {
        type: String,
        required: false
      },
      isSelectWithOptionIcon: {
        type: Boolean,
        required: false
      },
      isClearable: {
        isClearable: {
          type: Boolean,
          default: false,
          required: false
        }
      }
    },
    data: () => ({options: currencyOptions}),
    methods: {
      handleChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>
