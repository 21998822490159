<template>
  <transition name="fade" appear>
    <div class="modal-background" @click="exitModal" v-if="isOpen"></div>
  </transition>
  <transition name="slide" appear>
    <div class="modal-wrapper" v-if="isOpen">
      <div class="modal-container">
        <div class="modal-header-wrapper">
          <div class="modal-header">ROI</div>
          <div>
            <CurrencyDropdown class="dropdown" :currencies="currencies" @currencySelected="currencySelected" />
          </div>
          <div class="close-icon" @click="exitModal">
            <CloseIcon />
          </div>
        </div>
        <div class="modal-content-wrapper">
          <div class="roi-grid">
            <!-- labels -->
            <div class="column">
              <div class="roi-column-name">Timeframe</div>
              <div>1 day</div>
              <div>1 week</div>
              <div>1 month</div>
              <div>1 year (APY)</div>
            </div>

            <div class="column" v-if="modalData.apr">
              <div class="roi-column-name">ROI ({{ modalData.farm.token.symbol }})</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.apr.day), 2) }}%</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.apr.week), 2) }}%</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.apr.month), 2) }}%</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.apr.year), 2) }}%</div>
            </div>

            <div class="column" v-if="modalData.tokenYield && currentCurrency === 'USD'">
              <div class="roi-column-name">{{ modalData.farm.token.symbol }} per $1000</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.day), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.week), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.month), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.year), 2) }}</div>
            </div>

            <div class="column" v-if="modalData.tokenYield && currentCurrency !== 'USD'">
              <div class="roi-column-name">{{ modalData.farm.token.symbol }} per 1000 {{ modalData.farm.token.symbol }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.day) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.week) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.month) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.tokenYield.year) * modalData.farm.tokenPrice, 2) }}</div>
            </div>

            <div class="column" v-if="currentCurrency === 'USD'">
              <div class="roi-column-name">{{ modalData.farm.point.symbol }} per $1000</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.day), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.week), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.month), 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.year), 2) }}</div>
            </div>

            <div class="column" v-else>
              <div class="roi-column-name">{{ modalData.farm.point.symbol }} per 1000 {{ modalData.farm.token.symbol }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.day) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.week) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.month) * modalData.farm.tokenPrice, 2) }}</div>
              <div>{{ formatNumber(ethers.utils.formatEther(modalData.yield.year) * modalData.farm.tokenPrice, 2) }}</div>
            </div>
          </div>
          <div class="roi-disclaimer">
            Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.
            Day calculations are based off a 6450 blocks day.
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Imports.
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component Imports.
import CurrencyDropdown from '/src/components/pages/my-items/my-items-modal/CurrencyDropdown';

// Icon imports.
import CloseIcon from '../../icons/CloseIcon';

export default {
  components: {
    CurrencyDropdown,
    // Icons
    CloseIcon
  },

  props: {
    isOpen: Boolean,
    modalData: Object
  },

  emits: ['exit'],

  data() {
    return {
      ethers,
      formatNumber,
      currentCurrency: 'USD'
    };
  },

  computed: {
    currencies() {
      return ['USD', this.modalData.farm.token.symbol];
    }
  },

  methods: {
    exitModal() {
      this.$emit('exit');
      this.currentCurrency = 'USD';
    },

    currencySelected(item) {
      this.currentCurrency = item;
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  z-index: 999;
  cursor: default;
}

.modal-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
  z-index: 998;
}

.modal-container {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  min-width: 400px;
  width: fit-content;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  background-color: var(--background-color-nft);
}

.modal-header-wrapper {
  position: relative;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.modal-header {
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 700;
}

.close-icon {
  position: absolute;
  right: 13px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.close-icon:hover {
  cursor: pointer;
  background-color: rgba(var(--text-color-rgb), 0.1);
}

.close-icon:active {
  background-color: rgba(var(--text-color-rgb), 0.2);
}

.modal-content-wrapper {
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
}

.roi-grid {
  display: flex;
  justify-content: space-evenly;
  /* display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto; */
}

.column {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
}

.column > * {
  margin-bottom: 5px;
}

.roi-column-name {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.roi-disclaimer {
  max-width: fit-content;
  margin-top: 20px;
  opacity: 0.5;
  font-size: 12px;
}

.dropdown {
  position: absolute;
  /* left: 20px; */
  right: 70px;
  top: 13px;
}

/* Animations */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(40px);
}
</style>
