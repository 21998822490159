<template>
  <div class="link-field-component link-website-component">
    <div v-if="show" class='link-website-container'>
      <label class="link-field__label" for="link">Website Address</label>
      <div class="link-field__input-wrap">
        <Field
            name="website"
            class="link-field__input"
            type="text"
            id="link"
            v-model="website"
        />
        <ErrorMessage class="link-field__error" name="website" />
        <Button class='link-field__close-button' is-text @click='closeInput'>
          <CloseIcon/>
        </Button>
      </div>
    </div>

    <Button v-else
            is-secondary
            class="link-field__open-button"
            @click="openInput">
      <i class='fas fa-plus'/>
      Add a website
    </Button>
  </div>
</template>

<script>
import {Field, ErrorMessage} from 'vee-validate';

import Button from "@/components/ui/Button";
import CloseIcon from '@/components/icons/CloseIcon'

export default {
  components: {
    Field,
    Button,
    CloseIcon,
    ErrorMessage
  },
  data() {
    return {
      website: "",
      show: false
    }
  },
  methods: {
    openInput() {
      this.show = true;
    },
    closeInput() {
      this.show = false;
    }
  }
}
</script>

<style lang="scss" src="./LinkField.scss" scoped></style>