<template>
  <div id="toggle"
       class="toggle"
       :class='{"is-dark-mode": isActive}'/>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  --size: 1.5rem;
  appearance: none;
  outline: none;
  cursor: pointer;

  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: hsl(0, 100%, 0%);

  transition: all 600ms;
  transition: transform 200ms ease-in;

  &.is-dark-mode {
    --ray-size: calc(var(--size) * -0.4);
    --offset-orthogonal: calc(var(--size) * 0.65);
    --offset-diagonal: calc(var(--size) * 0.45);

    transform: scale(0.75);
    color: hsl(240, 100%, 95%);
    box-shadow: inset 0 0 0 var(--size),
      calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
      var(--offset-orthogonal) 0 0 var(--ray-size),
      0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
      0 var(--offset-orthogonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
      var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
      var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
  }
}

.toggle {
  z-index: 1;
  &:checked {
    & ~ .background {
      --bg: white;
    }
  }
}
</style>
