'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Specify state for this module.
const state = {
  isOpen: false,
  item: null,
  bannerImage: null,
  avatarImage: null,
  creatorHandle: null,
  isDisabled: null,
  startBlock: null,
  activeTab: 'Details',
  itemType: '',
  drop: null
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
