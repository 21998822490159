export default {
    setNotifications(state, payload) {
        state.notifications = payload
    },
    addNotifications(state, payload) {
        state.notifications = [...state.notifications, ...payload]
    },
    setShortNotificationsList(state, payload) {
        state.latestNotifications = payload
    },
    setCursor(state, payload) {
        state.cursor = payload
    },
    setFilter(state, payload) {
        state.filters.statuses = payload
    },
    setLoading(state, payload) {
        state.isLoading = payload
    }
}
