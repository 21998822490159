const items = {
  nfts: [
    {
      id: 1,
      isPinned: true,
      price: "0.15 ETH",
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb935738e434e6050ead7_nft-2.jpg',
        state: 'onSale',
        likes: 1332
      }
    },
    {
      id: 2,
      isPinned: false,
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb938dbb61df64aa0a583_nft.jpg',
        state: 'notListed',
        likes: 133
      }
    },
    {
      id: 3,
      isPinned: false,
      price: "1 ETH",
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a0d743a3b854604a2_nft.png',
        state: 'onAuction',
        likes: 2500
      }
    },
    {
      id: 4,
      isPinned: false,
      price: '0.1 ETH',
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb9362793fe221848f607_nft-1.jpg',
        state: 'onSale',
        likes: 3
      }
    },
    {
      id: 5,
      isPinned: false,
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a64f683fa80daa6fa_nft-1.png',
        state: 'notListed',
        likes: 13322
      }
    },
    {
      id: 6,
      isPinned: false,
      metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcfe183a2f741ea9f1f_nft-3.png',
        state: 'notListed',
        likes: 1900
      }
    }
  ],
  users: [
    {
      id: 1,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
    {
      id: 2,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
    {
      id: 3,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
    {
      id: 4,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
    {
      id: 5,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
    {
      id: 6,
      username: 'username',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner-p-800.jpeg'
    },
  ],
  collections: [
    {
      id: 1,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'
    },
    {
      id: 2,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'    },
    {
      id: 3,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'    },
    {
      id: 4,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'    },
    {
      id: 5,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'    },
    {
      id: 6,
      username: 'Collection Name',
      description: 'Victor Mosquera is a Vancouver, B.C. based artist creating works influenced by psychedelia and the ethereal intersection…',
      image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6891a4f9ec4760f7bec_download.gif',
      bgImage: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6ddded6c05cfb09d0b7_unnamed%20(1).png'    },
  ]
} 

export default {
  async getItems({commit}, type) {
    commit('setLoading', {
      bool: true,
      type: type
    })
    commit('setType', type)
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          commit('setItems', items[type])
          resolve()
        }, 2000);
      })
      commit('setLoading', {bool: false})
    } catch {
      return
    }
  }
}
