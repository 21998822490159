<template>
  <div>
    <div class="wallet-container" v-if="items && items[0] === 'loading'">
      <SkeletonCard v-for="index in 9" :key="index" />
    </div>
    <div v-else-if="items && items.length > 0" class="wallet-container" :class="walletContainerStyles">
      <div v-for="item in items" :key="item">
        <BaseNFTCard :item="item" @click="openModal(item)" @onPin="onPin" :is-show-dropdown-toggle="isShowDropdownToggle">
          <template v-slot:info>
            <BaseNFTCardInfo :item="item" />
          </template>
          <template v-slot:price>
            <BaseNFTCardPrice :state="cardState" :prices="item.prices" :item="item" />
          </template>
        </BaseNFTCard>
        <!-- Skeleton card(s) here. -->
      </div>
    </div>
    <div v-else-if="items && items.length === 0" class="noItems">
      <p>No items to display.</p>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import router from '../../../router';
import { mapActions } from 'vuex';

// Component imports.
import BaseNFTCard from './nft-card/BaseNFTCard';
import BaseNFTCardInfo from './nft-card/BaseNFTCardInfo';
import BaseNFTCardPrice from './nft-card/BaseNFTCardPrice';
// import LoadingSpinnerIcon from '/src/components/icons/LoadingSpinnerIcon';
import SkeletonCard from '/src/components/pages/my-items/nft-card/SkeletonCard.vue';

// Set up the default component.
export default {
  components: {
    BaseNFTCard,
    BaseNFTCardInfo,
    BaseNFTCardPrice,
    // LoadingSpinnerIcon,
    SkeletonCard
  },

  props: {
    items: {
      type: Array,
      required: false
    },
    cardState: {
      type: String,
      required: true
    },
    isShowDropdownToggle: {
      type: Boolean,
      required: false
    },
    onPin: {
      type: Function,
      required: false
    }
  },

  inject: ['drop'],

  computed: {
    walletContainerStyles() {
      if (this.cardState === 'onDrops') {
        return 'drops-container';
      } else {
        return '';
      }
    }
  },

  methods: {
    ...mapActions('itemModal', ['open']),
    openModal: function(item) {
      let isDisabled = false;
      let drop = null;
      if (this.drop) {
        drop = this.drop.value;
      }

      this.open({
        isOpen: true,
        item: item,
        bannerImage: null,
        avatarImage: null,
        creatorHandle: null,
        isDisabled: isDisabled,
        startBlock: null,
        isDropItem: true, // TODO: replace this with a modal mode selector.
        itemType: this.cardState,
        drop: drop
      });
    },

    openPermalink(item) {
      let formattedName = item.metadata.name.toLowerCase().split(' ');
      formattedName[formattedName.length - 1] = formattedName[formattedName.length - 1].replace('#', '');
      formattedName = formattedName.join('-');
      router.replace({
        name: 'items',
        params: { id: formattedName, isModal: true }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.spinner {
  height: 100px;
  width: 100px;
}
.wallet-container {
  margin-top: 30px;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: auto;
  justify-content: center;

  @include media-max-width($smallScreen) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

.noItems {
  padding: 20px;
}

.drops-container {
  grid-template-columns: repeat(auto-fit, minmax(400px, min-content));
}

@media (max-width: 450px) {
  .drops-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
