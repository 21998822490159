<template>
  <div class="quantityRemaining" v-if="isLive">{{ formatNumber(remaining, 0) }} / {{ formatNumber(total, 0) }} remaining</div>
  <div class="priceWrapper">
    <div>
      <div class="card-secondary">Price</div>
      <div class="price-value"><EthIcon class="eth-icon" /> {{ formatNumber(ethers.utils.formatEther(price), 2) }}</div>
    </div>
    <div v-if="isLive">
      <div v-if="isSoldOut" class="soldOutTag">SOLD OUT</div>
      <div v-else>
        <Button v-if="!hasSufficientFunds" :isDisabled="true" :isSecondary="true" @click.stop="">
          Insufficient Funds
        </Button>
        <Button v-else :isSecondary="true" @click.stop="purchaseItem">
          Buy Now
        </Button>
      </div>
    </div>
    <!-- <div v-else-if="isEnded" class="soldOutTag">SOLD OUT</div> -->
    <div v-else-if="isEnded" class="availableTag">Drop Ended</div>
    <div v-else class="availableTag">Available {{ timeRemaining }}</div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ref, computed, inject } from 'vue';
import { formatNumber } from '/src/utility/format';
import { ethers } from 'ethers';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useStore } from 'vuex';

// Component Imports.
import Button from '/src/components/ui/Button';
import EthIcon from '/src/components/icons/EthIcon';

TimeAgo.addLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

export default {
  components: {
    Button,
    EthIcon
  },

  props: {
    item: {
      type: Object
    }
  },

  data() {
    return {
      ethers,
      formatNumber
    };
  },

  setup (props) {
    const store = useStore();
    const drop = inject('drop');

    const isLive = computed(() => {
      return drop.value.startDate < new Date() && drop.value.endDate > new Date();
    });

    const isEnded = computed(() => {
      return drop.value.endDate < new Date();
    });

    const isSoldOut = computed(() => {
      return props.item.cap.sub(props.item.minted).eq(0);
    });

    const remaining = computed(() => {
      return props.item.cap.sub(props.item.minted);
    });

    const total = computed(() => {
      return props.item.cap;
    });

    const price = computed(() => {
      return props.item.prices[0].price;
    });

    const timeRemaining = computed(() => {
      return timeAgo.format(drop.value.startDate);
    });

    const hasSufficientFunds = computed(() => {
      return !store.state.ethers.balance.lt(price.value);
    });

    const purchaseItem = () => {
      let shopAddress = props.item.shop;
      let poolId = 0; // TODO: pool selection
      let groupId = props.item.groupId;
      let assetId = 0; // TODO: assetId selection
      let amount = 1; // TODO: eventual quantity input
      let value = props.item.prices[0].price; // pass an optional Ether transaction value
      store.dispatch('items/purchaseLaunchpadItem', { shopAddress, poolId, groupId, assetId, amount, value });
    };

    return {
      total,
      isLive,
      isEnded,
      isSoldOut,
      remaining,
      price,
      drop,
      timeRemaining,
      hasSufficientFunds,
      purchaseItem
    };
  }
};
</script>
<style scoped lang="scss">
.quantityRemaining {
  margin-bottom: 10px;
}
.priceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .price-value {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 16px;

    .eth-icon {
      margin-right: 5px;
      height: 15px;
    }
  }

  .soldOutTag {
    padding: 5px 10px;
    border-radius: 24px;
    background-color: #f12848;
    color: #fff;
    text-transform: uppercase;
  }

  .availableTag {
    padding: 5px 10px;
    border-radius: 24px;
    background-color: var(--nft-pill);
    color: white;
  }
}
</style>
