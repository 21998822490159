<template>
  <section class="common-section-component">
    <div class="common-container common-section__container">
      <h2 class="common-section__title">Upcoming Pools</h2>
      <ul v-if="isLoading" class="common-section__list">
        <li class="common-section__item" v-for="count in 3" :key="count">
          <SkeletorTemplateUpcoming />
        </li>
      </ul>
      <ul v-else class="common-section__list">
        <li class="common-section__item" v-for="pool in pools" :key="pool.id">
          <router-link
            class="common-section__link"
            :to="{ name: 'PoolDetails', params: { id: pool.id } }"
          >
            <Card :pool="pool">
              <template v-slot:footer-metadata>
                <p class="item__metadata">
                  <span class="item__metadata-title">Max Allocation</span>
                  <span class="item__metadata-value">
                    {{ pool.max_allocation }}
                  </span>
                </p>
                <p class="item__metadata">
                  <span class="item__metadata-title">Min Allocation</span>
                  <span class="item__metadata-value">
                    {{ pool.min_allocation }}
                  </span>
                </p>
              </template>
            </Card>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import SkeletorTemplateUpcoming from "../skeletor-template/SkeletorTemplateUpcoming";
import Card from "../card/Card";

export default {
  components: {
    SkeletorTemplateUpcoming,
    Card
  },
  props: {
    pools: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style src="../CommonSection.scss" lang="scss" scoped></style>
