import {map} from 'lodash'

import {socialService} from '@/services'

const retrieveNotifications = async data => await socialService.Notifications.list({
    ...data,
    limit: data?.hasOwnProperty('limit') ? data.limit : 20
})

export default {
    async fetchNotifications({commit, state}, isInitial = true) {
        if (isInitial && state.notifications.length !== 0) {
            commit('setNotifications', [])
        }

        commit('setLoading', true)

        try {
            const response = await retrieveNotifications({
                types: map(state.filters.statuses, 'value'),
                cursor: state.cursor === null ? undefined : state.cursor
            })

            if (response && response?.notifications) {
                commit(isInitial ? 'setNotifications' : 'addNotifications', response.notifications)
                commit('setCursor', response.cursorNext)
            }
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchLatestNotifications({commit}) {
        try {
            const response = await retrieveNotifications({limit: 10})

            if (response && response?.notifications) {
                commit('setShortNotificationsList', response.notifications)
            }
        } catch {
            commit('setShortNotificationsList', [])
        }
    },
    async changeFilter({commit, dispatch}, payload) {
        commit('setCursor', null)
        commit('setFilter', payload)

        await dispatch('fetchNotifications')
    }
}
