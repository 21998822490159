<template>
  <div class="cardModalItemButtons__container">
    <div>{{ formatNumber(views, 0) }} views</div>
    <div class="cardModalItemButtons__item" @click="likeItem">
      <HeartIcon :filled="liked" class="like-button" />
      <div class="cardModalItemButtons__likeValue">
        {{ abbreviateNumber(likes) }}
      </div>
    </div>
    <div class="cardModalItemButtons__item" @click="watchItem">
      <WatchIcon :filled="watched" class="watch-button" />
      <div class="cardModalItemButtons__watchValue">
        {{ formatNumber(watchers, 0) }} watchers
      </div>
    </div>
  </div>
</template>

<script>
// Imports
import { formatNumber, abbreviateNumber } from '/src/utility/format';

// Icon Imports
import HeartIcon from '/src/components/icons/HeartIcon';
import WatchIcon from '/src/components/icons/WatchIcon';

export default {
  components: {
    HeartIcon,
    WatchIcon
  },

  props: ['views', 'likes', 'watchers'],

  data() {
    return {
      formatNumber,
      abbreviateNumber,
      liked: false,
      watched: false
    };
  },

  methods: {
    likeItem() {
      this.liked = !this.liked;
    },

    watchItem() {
      this.watched = !this.watched;
    }
  }
};
</script>

<style>
.cardModalItemButtons__container {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.cardModalItemButtons__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 1s ease;
}

.cardModalItemButtons__likeValue,
.cardModalItemButtons__watchValue {
  transition: all 0.2s ease;
  margin-left: 2px;
}

.cardModalItemButtons__item:hover .cardModalItemButtons__likeValue {
  color: #e0245e;
}

.cardModalItemButtons__item:hover .like-button {
  background-color: rgba(224, 36, 94, 0.1);
  color: #e0245e;
}

.cardModalItemButtons__item:active .like-button {
  background-color: rgba(224, 36, 94, 0.2);
}

.cardModalItemButtons__item:hover .cardModalItemButtons__watchValue {
  color: #1ec91e;
}

.cardModalItemButtons__item:hover .watch-button {
  background-color: rgba(74, 224, 36, 0.1);
  color: #1ec91e;
}

.cardModalItemButtons__item:active .watch-button {
  background-color: rgba(74, 224, 36, 0.2);
}
</style>
