<template>
  <slot v-if="exactFlag === true "/>
</template>

<script>
import withFlags from "@/utility/mixins/withFlags"
export default {
  props: {
    requiredFlag: {
      type: String,
      required: true
    }
  },
  mixins: [withFlags()]
}
</script>

<style>
</style>