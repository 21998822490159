import requests from './requests.service';
import localStorage from './local-storage.service';

const basePath = process.env.VUE_APP_AUTH_BASE_URL; 

const collectionsPath = `${basePath}/v1/auth`;

const Auth = {
  login: async address => {
    const response = await requests.post(`${collectionsPath}/login/ethereum`, { address });
    const { otp, sessionId } = response;

    return { otp, sessionId }
  },
  verifyOTP: async (session, otp) => {
    try {
      const response = await requests.post(`${collectionsPath}/otp/verify`, { sessionId: session, code: otp });
      const { accessToken, refreshToken } = response;
  
      return { accessToken, refreshToken };

    } catch(err) {
      console.error(err);
    }
  },
  refreshToken: async token => {
    const response = await requests.post(`${collectionsPath}/token/refresh`, { token });
    const { accessToken, refreshToken } = response;

    return { accessToken, refreshToken };
  }
};

const authService = {
  Auth
};

export { authService };
