<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
    <defs></defs>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.2673 10.0311L.4269 12.8715l.707.7071 3.1619-3.1618.903.3386 4.5488-1.2867V4.9649l1.2997-1.2997v7.0902h2.5994V3.6071h-2.5413L14.0009.7117l-.7071-.7072-3.6026 3.6026H8.4675l-4.7179 1.761c-.299.117-.5004.403-.5004.7214 0 .4224.3444.7668.7668.7668h1.8326l.9581-.3647-1.0145 1.0146H1.2997C.5784 7.5062.0065 8.091 0 8.8059l3.2673 1.2252z"
      clip-rule="evenodd"
    ></path>
  </svg>
</template>
