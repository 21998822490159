'use strict';

// Imports.
import { farmService } from '../../services';

// Specify actions that can be performed by this module.
export default {
  // Use the farm service to deploy a farm.
  async deploy(
    { dispatch, commit },
    { name, tokenAddress, tokenEmissionSchedule, pointEmissionSchedule, tokenMintAmount, tokenTransferAmount, initialPools }
  ) {
    commit('deployRequest', { name, tokenAddress });
    try {
      let response = await farmService.deployLocally(
        name,
        tokenAddress,
        tokenEmissionSchedule,
        pointEmissionSchedule,
        tokenMintAmount,
        tokenTransferAmount,
        initialPools
      );
      let farmData = response.farm;
      let farmAddress = farmData.address;

      // If the deploy is successful, store farm information.
      if (farmAddress) {
        commit('deploySuccess', farmData);
        await dispatch('alert/success', `Farm successfully deployed to ${farmAddress}.`, { root: true });
        await dispatch('getAllFarms', { root: true });
      } else {
        commit('deployFailure', null);
        await dispatch('alert/error', `Farm '${name}' deployed with no return address!`, { root: true });
      }

      // Catch deployment errors.
    } catch (error) {
      commit('deployFailure', error);
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the farm service to get a list of all a user's farms.
  async getAllFarms({ dispatch, commit }) {
    try {
      let response = await farmService.getAllFarms();
      commit('updateFarmsList', response);

      // Catch farm retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async retrieveFullFarmsInformation({ commit }, address) {
    let farms = await farmService.retrieveFullFarmsInformation(address);
    commit('updateFarmsInformation', farms);
  },

  // Approve a specific token spend for a farm.
  async approve({ dispatch, commit }, { farmAddress, tokenAddress }) {
    try {
      let amount = await farmService.approve(farmAddress, tokenAddress);
      commit('updateTokenApproval', { tokenAddress, amount });

      // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Deposit a specific type of token into a farm.
  async deposit({ dispatch, commit }, { farmAddress, tokenAddress, amount }) {
    try {
      await farmService.deposit(farmAddress, tokenAddress, amount);

      // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Withdraw a specific type of token from a farm.
  async withdraw({ dispatch, commit }, { farmAddress, tokenAddress, amount }) {
    try {
      await farmService.withdraw(farmAddress, tokenAddress, amount);

      // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Add a new token staking pool to a farm.
  async addPool({ dispatch, commit }, { farmAddress, poolToken, tokenStrength, pointStrength }) {
    await farmService.addPool(farmAddress, poolToken, tokenStrength, pointStrength);
    await dispatch('retrieveFullFarmInformation', farmAddress);
  }
};
