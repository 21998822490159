"use strict";

// Set the page visibile per our probabilistic checks.
let probablyHidden = false;
let timeWithoutAction = 0;
const setVisible = async function () {
  let performDispatch = false;
  if (probablyHidden) {
    performDispatch = true;
  }
  probablyHidden = false;
  timeWithoutAction = 0;
  if (performDispatch) {
    await dispatchVisibilityEvent();
  }
};

// Initialize the probabilistic page visibility detector; if the window is
// not in focus, begin checking for it to gain focus.
let hasFocusLocal = true;
let lastActionTime;
let timeoutHandler;
const initializeVisibilityDetection = function () {
  if (!hasFocusLocal) {
    lastActionTime = new Date().getTime();
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    timeoutHandler = setTimeout(checkPageVisibility, threshold);
  }
};

// Determine probable visibility of the page by seeing how long ago the last
// user action was.
let threshold = 15 * 60 * 1000;
const checkPageVisibility = async function () {
  if (hasFocusLocal || !lastActionTime) {
    timeWithoutAction = 0;
  } else {
    let currentTime = new Date().getTime();
    timeWithoutAction = Math.floor(currentTime - lastActionTime);
  }

  // The page is probably hidden if there hasn't been any action in a while.
  if (timeWithoutAction >= threshold && !probablyHidden) {
    probablyHidden = true;
    await dispatchVisibilityEvent();
  }
};

// Dispatch an event for a probable change in page visibility.
let hidden = false;
const dispatchVisibilityEvent = async function () {
  await dispatch(
    "visibility/update",
    {
      hidden: hidden,
      probablyHidden: probablyHidden,
      timeWithoutAction: timeWithoutAction
    },
    { root: true }
  );
};

// An event listener for detecting Page Visibility per the browser API.
const visibilityChangeListener = async function (event) {
  if (!timeoutHandler) {
    hidden = false;
  } else {
    hidden = document.hidden;
  }
  await dispatchVisibilityEvent();
};

// An event listener for detecting mouse movement.
const mouseMoveListener = async function (event) {
  lastActionTime = new Date().getTime();
  if (hidden) {
    hidden = false;
    await dispatchVisibilityEvent();
  }
};

// An event listener for detecting page mouse overs.
const mouseOverListener = async function (event) {
  await setVisible();
};

// An event listener for detecting page mouse out events.
const mouseOutListener = function (event) {
  initializeVisibilityDetection();
};

// An event listener for detecting pages losing focus through blur.
const blurListener = function (event) {
  hasFocusLocal = false;
  initializeVisibilityDetection();
};

// An event listener for detecting pages gaining focus.
const focusListener = async function (event) {
  hasFocusLocal = true;
  await setVisible();
};

// Set up a page visibility monitoring service. This function takes a reference
// to the VueX dispatcher.
let dispatch;
const setup = async function (_dispatch, timeoutThreshold) {
  dispatch = _dispatch;
  threshold = timeoutThreshold;

  // Register a visibility check using the Page Visibility API.
  document.addEventListener("visibilitychange", visibilityChangeListener);

  // Attach events for tracking user action timings.
  probablyHidden = false;
  timeWithoutAction = 0;
  document.addEventListener("mousemove", mouseMoveListener);
  document.addEventListener("mouseover", mouseOverListener);
  document.addEventListener("mouseout", mouseOutListener);
  window.addEventListener("blur", blurListener);
  window.addEventListener("focus", focusListener);
  await setVisible();
};

// Clean up the page by removing event listeners.
const cleanup = async function () {
  document.removeEventListener("visibilitychange", visibilityChangeListener);
  document.removeEventListener("mousemove", mouseMoveListener);
  document.removeEventListener("mouseover", mouseOverListener);
  document.removeEventListener("mouseout", mouseOutListener);
  window.removeEventListener("blur", blurListener);
  window.removeEventListener("focus", focusListener);
};

// Export the page visibility service functions.
export const visibilityService = {
  setup,
  cleanup
};
