const globalActivities = [
  {
    id: 1,
    type: "auction",
    user: "username",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1s",
    price: "0.1 ETH",
  },
  {
    id: 2,
    type: "offer_made",
    user: "username",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1m 20s",
    price: "0.1 ETH",
  },
  {
    id: 3,
    type: "listing",
    user: "username",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1h",
    price: "0.1 ETH",
  },
  {
    id: 4,
    type: "transfer",
    user: "username",
    item: "0xc4F3...A8ae",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "2h",
  },
  {
    id: 5,
    type: "transfer",
    user: "other user",
    item: "0xc4F3...A8ae",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "2h",
  },
  {
    id: 6,
    type: "sale",
    seller: "User A",
    buyer: "User B",
    price: "0.2 ETH",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "2h",
  },
];

const globalFilteredActivities = [
  {
    id: 3,
    type: "listing",
    user: "username",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1h",
    price: "0.1 ETH",
  },
];

const myActivities = [
  {
    id: 12,
    type: "auction",
    user: "you",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1s",
    price: "0.1 ETH",
  },
  {
    id: 13,
    type: "offer_made",
    user: "you",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1m 20s",
    price: "0.1 ETH",
  },
  {
    id: 14,
    type: "listing",
    user: "you",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "1h",
    price: "0.1 ETH",
  },
  {
    id: 15,
    type: "transfer",
    user: "you",
    item: "0xc4F3...A8ae",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "2h",
  },
  {
    id: 16,
    type: "transfer",
    user: "you",
    item: "0xc4F3...A8ae",
    nft: "Super puper item",
    collection: "Super duper collection",
    time: "2h",
  },
];

export default {
  async fetchActivities({ commit, getters, dispatch, state }, payload) {
    try {
      let { type, filters } = payload;
      commit("clearActivityList");
      commit("setIsLoading", payload);
      const activities = await new Promise((resolve, reject) => {
        const hashmap = {
          global: () => resolve(globalActivities),
          following: () => resolve(globalActivities),
          myactivity: () => resolve(myActivities),
          watchlist: () => resolve(myActivities),
        };

        setTimeout(() => {
          hashmap[type]();
        }, 2000);
      });
      if (JSON.stringify(state.isLoading) === JSON.stringify(payload)) {
        commit("setActivityList", activities);
        commit("setIsLoading", false);
      }
    } catch (err) {
      await dispatch("alert/error", err, { root: true });
    }
  },

  setActivitiesFilter({ commit, state, dispatch }, payload) {
    const { tab, filter } = payload;
    if (state.filters[tab].includes(filter)) {
      commit("deleteActivityTabFilter", { tab, filter });
      dispatch("fetchActivities", { type: tab, filters: state.filters[tab] });
    } else {
      commit("setActivityTabFIlter", { tab, filter });
      dispatch("fetchActivities", { type: tab, filters: state.filters[tab] });
    }
  },

  clearActivitiesFilters({commit, state, dispatch}, payload) {
    const { tab } = payload;
    commit("deleteAllTabFilters", { tab });
    dispatch("fetchActivities", { type: tab, filters: state.filters[tab] });
  }
};
