export default {
  setItems(state, items) {
    state.items = items
  },
  clearItems(state) {
    state.items = []
  },
  setLoading(state, loading) {
    state.loading.bool = loading.bool
    if (loading.type) {
      state.loading.type = loading.type
    }
  },
  setType(state, type) {
    state.type = type
  }
}