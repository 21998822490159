'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import { authHeader } from '../utility';

// Messages for future localization.
const ERRORS = {
  NOT_WHITELISTED: {
    originalMessage: 'execution reverted: You are not whitelisted on this pool.',
    friendlyMessage: 'You are not on the whitelist to purchase this item.'
  },
  SALE_PURCHASE_LIMIT: {
    originalMessage: 'execution reverted: You may not purchase any more items from this sale.',
    friendlyMessage: 'You cannot purchase any more items from this sale.'
  }
};

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Upload an item's image to persist in the backend.
const uploadImage = async function(imageFile) {
  if (!config) {
    config = await initializeConfig();
  }
  const formData = new FormData();
  formData.append('imageFile', imageFile, imageFile.name);
  return axios.post(`${config.apiUrl}/upload-image`, formData, {
    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
  });
};

// Upload an item's metadata to persist in the backend.
const uploadMetadata = async function(metadata) {
  if (!config) {
    config = await initializeConfig();
  }
  return axios.post(`${config.apiUrl}/upload-metadata`, {
    metadata
  });
};

// Deploy the item using the local Ethers provider.
const deployLocally = async function(metadataUri, amount, shopAddress, prices, royalty, shouldList, listAmount) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  console.log(provider);

  let wallet = await ethersService.getWallet();
  let walletAddress = await ethersService.getWalletAddress();
  let recordContractAddress = config.itemRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(recordContractAddress, config.itemRecordABI, wallet);

  // Generate initial minting array data.
  let initialSupply = [];
  let maximumSupply = [];
  let initialRecipients = [];
  let itemIds = [];
  for (let i = 0; i < amount; i++) {
    initialSupply.push(1);
    maximumSupply.push(1);
    initialRecipients.push(walletAddress);
    itemIds.push(i + 1);
  }

  // Generate listing data.
  let listingIds = [];
  let listingSupply = [];
  for (let i = 0; i < listAmount; i++) {
    listingIds.push(i + 1);
    listingSupply.push(1);
  }

  // Create a new item via the farm records contract.
  console.log(`Creating item in: ${recordContractAddress} ...`);
  let createItemTransaction = await recordContract.createItem(metadataUri, royalty, initialSupply, maximumSupply, initialRecipients, []);
  let itemCreatedReceipt = await createItemTransaction.wait();
  let itemCreatedEvent = itemCreatedReceipt.events[itemCreatedReceipt.events.length - 1];
  let newItemAddress = itemCreatedEvent.args[0];
  console.log(`... item created in transaction: ${createItemTransaction.hash}.`);

  // If listing, grant the shop permission to transfer the items on the owner's behalf.
  if (shouldList) {
    let itemContract = ethersService.connectToContract(newItemAddress, config.itemABI, wallet);
    let isApproved = await itemContract.isApprovedForAll(walletAddress, shopAddress);
    if (!isApproved) {
      let approvalTransaction = await itemContract.setApprovalForAll(shopAddress, true);
      await approvalTransaction.wait();
    }

    // List the newly-created item as an option to spend points on in its shop.
    let shopContract = ethersService.connectToContract(shopAddress, config.shopABI, wallet);
    let pricePairsInput = [];
    for (let pricePair of prices) {
      let priceEntry = {
        assetType: pricePair.assetType,
        asset: pricePair.address || pricePair.primary,
        price: ethersService.parseEther('1').mul(pricePair.price)
      };
      pricePairsInput.push(priceEntry);
    }
    let listingTransaction = await shopContract.listItems(pricePairsInput, [newItemAddress], [listingIds], [listingSupply]);
    await listingTransaction.wait();
  }

  // Return the same format that we do for the remote server-created contracts.
  return {
    status: 'ok',
    item: {
      address: newItemAddress
    }
  };
};

// Fetch all items created by a particular user.
const getAllItems = async function() {
  if (!config) {
    config = await initializeConfig();
  }
  let wallet = await ethersService.getWallet();
  let walletAddress = await ethersService.getWalletAddress();
  let recordContractAddress = config.itemRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(recordContractAddress, config.itemRecordABI, wallet);

  let itemCount = await recordContract.getItemCount(walletAddress);
  let items = [];
  for (let i = 0; i < itemCount; i++) {
    let item = await recordContract.itemRecords(walletAddress, i);
    let itemContract = ethersService.connectToContract(item, config.itemABI, wallet);
    let metadata = await itemContract.uri(0);
    items.push({
      address: item,
      metadata: metadata
    });
  }
  return items;
};

// Parse the items owned by a given address.
const processItems = async function(contractAddress, userAddress, provider) {
  if (!config) {
    config = await initializeConfig();
  }
  let itemContract = new ethers.Contract(contractAddress, config.itemABI, provider);

  // Check relative item transfer events to determine this user's current
  // inventory.
  let ownershipData = {};
  let batchTransfers = await itemContract.queryFilter('TransferBatch');
  console.log(`Processing ${batchTransfers.length} batch transfer events ...`);
  for (let i = 0; i < batchTransfers.length; i++) {
    let batchTransfer = batchTransfers[i];
    let blockNumber = batchTransfer.blockNumber;
    let itemIds = batchTransfer.args.ids;
    let to = batchTransfer.args.to;
    let amounts = batchTransfer.args[4];
    for (let j = 0; j < itemIds.length; j++) {
      let itemId = itemIds[j];
      let itemAmount = amounts[j];
      if (ownershipData[itemId.toString()]) {
        if (ownershipData[itemId.toString()].block <= blockNumber) {
          ownershipData[itemId.toString()] = {
            itemId: itemId,
            amount: itemAmount,
            owner: to,
            block: blockNumber
          };
        }
      } else {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          amount: itemAmount, // TODO: likely wrong
          owner: to,
          block: blockNumber
        };
      }
    }
  }

  // Single transfer events.
  let singleTransfers = await itemContract.queryFilter('TransferSingle');
  console.log(`Processing ${singleTransfers.length} single transfer events ...`);
  for (let i = 0; i < singleTransfers.length; i++) {
    let singleTransfer = singleTransfers[i];
    let blockNumber = singleTransfer.blockNumber;
    let itemId = singleTransfer.args.id;
    let to = singleTransfer.args.to;
    let amount = singleTransfer.args.value;
    if (ownershipData[itemId.toString()]) {
      if (ownershipData[itemId.toString()].block <= blockNumber) {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          amount: amount,
          owner: to,
          block: blockNumber
        };
      }
    } else {
      ownershipData[itemId.toString()] = {
        itemId: itemId,
        amount: amount,
        owner: to,
        block: blockNumber
      };
    }
  }

  // Process the item event history to find owned items.
  let metadataUri = await itemContract.metadataUri({ gasLimit: ethers.BigNumber.from('14500000') });
  let ownedItems = [];
  for (const itemId in ownershipData) {
    let itemOwnershipData = ownershipData[itemId];
    if (itemOwnershipData.owner === userAddress) {
      let metadataId = itemOwnershipData.itemId._hex.substring(2).padStart(64, '0');
      let itemMetadataUri = metadataUri.replace('{id}', metadataId);
      let metadataResponse = await axios.get(itemMetadataUri);
      let metadata = metadataResponse.data;
      ownedItems.push({
        id: metadataId,
        metadataUri: itemMetadataUri,
        metadata: metadata,
        balance: itemOwnershipData.amount,
        cap: ethers.BigNumber.from(metadata.groupSize)
      });
    }
  }
  return ownedItems;
};

// Fetch all items owned by a particular address.
const getOwnedItems = async function(address) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let ownedItems = [];

  let itemCollections = config.itemCollections[networkId];
  for (let itemCollectionAddress of itemCollections) {
    let isValid = await ethers.utils.isAddress(itemCollectionAddress);
    if (isValid) {
      ownedItems = ownedItems.concat(await processItems(itemCollectionAddress, address, provider));
    }
  }
  return ownedItems;
};

// A helper function to parse pool output data.
const parsePool = async function(provider, pool) {
  let poolItems = [];
  for (let i = 0; i < pool.items.length; i++) {
    let item = pool.items[i];
    let itemPrices = [];
    for (let j = 0; j < item.prices.length; j++) {
      let pricePair = item.prices[j];
      itemPrices.push({
        assetType: pricePair.assetType,
        asset: pricePair.asset,
        price: pricePair.price
      });
    }
    let metadataId = item.groupId
      .shl(128)
      ._hex.substring(2)
      .padStart(64, '0');
    let itemMetadataUri = pool.itemMetadataUri.replace('{id}', metadataId);
    let metadataResponse = await axios.get(itemMetadataUri);
    let metadata = metadataResponse.data;
    poolItems.push({
      metadataUri: itemMetadataUri,
      metadata: metadata,
      groupId: item.groupId,
      cap: item.cap,
      minted: item.minted,
      prices: itemPrices
    });
  }
  return {
    name: pool.name,
    startBlock: pool.startBlock,
    endBlock: pool.endBlock,
    requirement: {
      requiredType: pool.requirement.requiredType,
      requiredAsset: pool.requirement.requiredAsset,
      requiredAmount: pool.requirement.requiredAmount
    },
    items: poolItems
  };
};

// TODO: create a function to get a specific launchpad item from its ID.
// we will convert a name such as `pool-shark-rare-20` from a URL into an ID.

// Fetch all available launchpad items.
const getLaunchpadItems = async function(shopAddresses) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();

  // Grab the first pool from each specified shop.
  let pools = [];
  for (let shopAddress of shopAddresses) {
    let isValid = await ethers.utils.isAddress(shopAddress);
    if (!isValid) {
      return {};
    }

    // Try the contract as both an old and a new mint shop.
    try {
      let launchpadContract = new ethers.Contract(shopAddress, config.launchpadABI, provider);
      let staker;
      try {
        staker = await launchpadContract.stakers(0);
      } catch (error) {
      }
      let outputPools = await launchpadContract.getPools([ 0 ]);
      let poolA = await parsePool(provider, outputPools[0]);
      poolA.id = 0;
      poolA.staker = staker;
      pools.push(poolA);
    } catch (error) {
      let mintShopContract = new ethers.Contract(shopAddress, config.upgradedMintShopABI, provider);
      let outputPools = await mintShopContract.getPools([ 0 ]);
      let poolA = await parsePool(provider, outputPools[0]);
      poolA.id = 0;
      pools.push(poolA);
    }
  }

  return {
    name: 'SuperFarm Sale',
    description: 'A launchpad for the first SuperFarm item sale.',
    pools: pools
  };
};

// Purchase an item from the launchpad.
const purchaseLaunchpadItem = async function(shopAddress, poolId, groupId, assetId, amount, value) {
  console.log(shopAddress)
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }
  // let launchpadTokenAddress = config.launchpadTokenAddress[networkId];
  // isValid = await ethers.utils.isAddress(launchpadTokenAddress);
  // if (!isValid) {
  //   return; // TODO: throw useful error.
  // }

  // Check for token spend approval.
  let signer = await provider.getSigner(); // TODO: make sure provider can sign
  // let tokenContract = new ethers.Contract(
  //   launchpadTokenAddress,
  //   config.tokenABI,
  //   signer
  // );
  let address = await signer.getAddress();
  // let allowance = await tokenContract.allowance(
  //   address,
  //   launchpadContractAddress
  // );
  // if (!allowance.gt(0)) {
  //   // TODO: make a more robust check for the exact required allowance amount
  //   let approvalTransaction = await tokenContract.approve(
  //     launchpadContractAddress,
  //     ethers.constants.MaxUint256
  //   ); // TODO: prompt the user for a configurable allowance input
  //   await approvalTransaction.wait();
  // }

  // Purchase the item.
  let launchpadContract = new ethers.Contract(shopAddress, config.launchpadABI, signer);
  try {

    // Attach a transaction value if Ether purchase.
    if (value) {
      let purchaseTransaction = await launchpadContract.mintFromPool(poolId, groupId, assetId, amount, { value: value });
      await purchaseTransaction.wait();
    } else {
      let purchaseTransaction = await launchpadContract.mintFromPool(poolId, groupId, assetId, amount);
      await purchaseTransaction.wait();
    }

    // Return a user-friendly error for the purchase failure.
  } catch (error) {
    let errorMessage = error.error.data.originalError.message;
    for (let potentialErrorKey of Object.keys(ERRORS)) {
      let errorClass = ERRORS[potentialErrorKey];
      if (errorMessage === errorClass.originalMessage) {
        throw new Error(errorClass.friendlyMessage);
      }
    }
    throw error;
  }
};

// Fetch the status of item shop approval.
const getApproval = async function(itemAddress, walletAddress, shopAddress) {
  if (!config) {
    config = await initializeConfig();
  }
  let wallet = await ethersService.getWallet();
  let itemContract = ethersService.connectToContract(itemAddress, config.itemABI, wallet);
  return itemContract.isApprovedForAll(walletAddress, shopAddress);
};

// Export the shop service functions.
export const itemService = {
  uploadImage,
  uploadMetadata,
  deployLocally,
  getAllItems,
  getOwnedItems,
  getLaunchpadItems,
  purchaseLaunchpadItem,
  getApproval
};
