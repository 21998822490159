<template>
  <div class='info-container'>
    <div class='info-container-avatar'><slot name='avatar'/></div>
    <div class='info-container-name'><slot name='name'/></div>
    <div class='info-container-address'><slot name='address'/></div>
    <div class='info-container-actions'><slot name='actions'/></div>
    <div class='info-container-stats'><slot name='stats'/></div>
    <div class='info-container-description'><slot name='description'/></div>
    <div class='info-container-website'><slot name='website'/></div>
    <div class='info-container-social'><slot name='social'/></div>
  </div>
</template>

<style scoped lang='scss'>
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    &-avatar {
      margin-top: -100px;
    }

    &-name {
      margin: 10px 0 5px 0;

      @include media-max-width($smallScreen) {
        font-size: 20px;
      }
    }

    &-actions, &-address {
      margin-bottom: 15px;
    }

    &-stats {
      margin: 0 -10px 15px;
    }

    &-website {
      margin-top: 10px;
    }

    &-social {
      margin-top: 10px;
    }
  }
</style>