"use strict";

const upcoming_pools = [
  {
    id: 0,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 1",
    time: "in 17 hours",
    condition: null,
    token: null,
    progress_percent: null,
    progress_min: null,
    ratio_per: null,
    total_raise: "10k",
    max_allocation: 1,
    min_allocation: 2,
    participants: 3,
    maximum: 4,
    access: "Private"
  },
  {
    id: 1,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 2",
    time: "in 17 hours",
    condition: null,
    token: null,
    progress_percent: null,
    progress_min: null,
    ratio_per: null,
    total_raise: "20k",
    max_allocation: 11,
    min_allocation: 12,
    participants: 13,
    maximum: 14,
    access: "Public"
  },
  {
    id: 2,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 3",
    time: "in 17 hours",
    condition: null,
    token: null,
    progress_percent: null,
    progress_min: null,
    ratio_per: null,
    total_raise: "30k",
    max_allocation: 21,
    min_allocation: 22,
    participants: 23,
    maximum: 24,
    access: "Private"
  },
  {
    id: 3,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 4",
    time: "in 17 hours",
    condition: null,
    token: null,
    progress_percent: null,
    progress_min: null,
    ratio_per: null,
    total_raise: "40k",
    max_allocation: 31,
    min_allocation: 32,
    participants: 33,
    maximum: 34,
    access: "Public"
  }
];
const featured_pools = [
  {
    id: 0,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 5",
    time: "in 17 hours",
    condition: "live",
    token: "eht",
    progress_percent: 50,
    progress_min: 80,
    ratio_per: 1,
    total_raise: "50k",
    max_allocation: 0,
    min_allocation: 0,
    participants: 1,
    maximum: 2,
    access: "Private"
  },
  {
    id: 1,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 6",
    time: "in 17 hours",
    condition: "live",
    token: "eht",
    progress_percent: 70,
    progress_min: 81,
    ratio_per: 1,
    total_raise: "60k",
    max_allocation: 0,
    min_allocation: 0,
    participants: 11,
    maximum: 12,
    access: "Public"
  },
  {
    id: 2,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 7",
    time: "in 17 hours",
    condition: "filled",
    token: "eht",
    progress_percent: 100,
    progress_min: 83,
    ratio_per: 1,
    total_raise: "70k",
    max_allocation: 0,
    min_allocation: 0,
    participants: 21,
    maximum: 22,
    access: "Private"
  },
  {
    id: 3,
    avatar: "ava_400x400.jpg",
    name: "Pool Name 8",
    time: "in 17 hours",
    condition: "closed",
    token: "eht",
    progress_percent: 50,
    progress_min: 84,
    ratio_per: 1,
    total_raise: "80k",
    max_allocation: 0,
    min_allocation: 0,
    participants: 31,
    maximum: 32,
    access: "Public"
  }
];
const single_pool = {
  id: 0,
  status: "filled",
  avatar: "ava_400x400.jpg",
  pool_name: "Pool Name Goes Here",
  pool_hash: "0xf13F96f7F95517ae54A0B3F9494E58607eDfCa1D",
  published: 13,
  common_info: {
    swap_amount: "10638.3 TOKEN = 1 ETH",
    token: 600000,
    eth: 56.4,
    progress_percent: 89,
    progress_min: 82,
    current_value: 492000,
    total_value: 600000
  },
  pool_information: {
    token_distribution: "March 30th 2021, 3:30 PM UTC",
    min_allocation: "No minimum ETH",
    max_allocation: "0.166 ETH",
    min_swap_level: "29 ETH",
    access_type: "Private"
  },
  token_information: {
    name: "Token Name",
    address: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
    total_supply: "100,000,000",
    holders: 1,
    transfers: 1
  },
  social_information: {
    website: "https://superfarm.com/#/",
    twitter: "https://superfarm.com/#/",
    whitepaper: "https://superfarm.com/#/"
  }
};

// Specify actions that can be performed by this module.
export default {
  async fetchUpcomingPools({ dispatch, commit }) {
    try {
      const pools = await new Promise(resolve => {
        setTimeout(() => {
          resolve(upcoming_pools);
        }, 1000);
      });
      commit("setUpcomingPools", pools);
    } catch (error) {
      await dispatch("alert/error", error, { root: true });
    } finally {
      commit("setIsLoadingUpcoming", false);
    }
  },

  async fetchFeaturedPools({ dispatch, commit }) {
    try {
      const pools = await new Promise(resolve => {
        setTimeout(() => {
          resolve(featured_pools);
        }, 2000);
      });
      commit("setFeaturedPools", pools);
    } catch (error) {
      await dispatch("alert/error", error, { root: true });
    } finally {
      commit("setIsLoadingFeatured", false);
    }
  },

  async fetchSinglePools({ dispatch, commit }) {
    try {
      commit("setIsLoadingSingle", true);
      const pool = await new Promise(resolve => {
        setTimeout(() => {
          resolve(single_pool);
        }, 1000);
      });
      commit("setSinglePool", pool);
    } catch (error) {
      await dispatch("alert/error", error, { root: true });
    } finally {
      commit("setIsLoadingSingle", false);
    }
  }
};
