import {StorageService} from './local-storage.service'

class TokenStorageService extends StorageService {
    accessTokenKey = 'accessToken'
    refreshTokenKey = 'refreshToken'

    setAccessToken = token => this.set(this.accessTokenKey, token)

    getAccessToken = () => this.get(this.accessTokenKey)

    removeAccessToken = () => this.remove(this.accessTokenKey)

    setRefreshToken = token => this.set(this.refreshTokenKey, token)

    getRefreshToken = () => this.get(this.refreshTokenKey)

    removeRefreshToken = () => this.remove(this.refreshTokenKey)
}

const TokenStorage = new TokenStorageService()

export default TokenStorage