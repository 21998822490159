<template>
  <button :class="{
    'select-button': true,
    'active': isActive,
    'left': iconPosition === 'left',
    'right': iconPosition === 'right',
    'with-icon': hasIcon,
    'is-dark-mode': isDarkMode,
    'hoverable': hoverable
  }">
    <div v-if='hasIcon' class='icon-wrapper'>
      <slot name='icon'/>
    </div>
    <div v-if='hasContent' class='content-wrapper'>
      <slot/>
    </div>
  </button>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: {
      isActive: {
        type: Boolean,
        required: false
      },
      hoverable: {
        type: Boolean,
        default: false,
        required: false
      },
      iconPosition: {
        type: String,
        default: 'left',
        required: false
      }
    },
    computed: {
      ...mapGetters('theme', ['isDarkMode']),
      hasIcon() {
        return !!this.$slots.icon
      },
      hasContent() {
        return !!this.$slots.default
      }
    }
  }
</script>

<style scoped lang='scss'>
  .select-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    height: 40px;
    outline: none;
    border: 1px solid rgb(var(--text-color-rgb));
    font-weight: 500;
    text-align: center;
    transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);
    border-radius: 24px;
    background-color: $white;

    .content-wrapper {
      width: 100%;
    }

    &.with-icon {
      .content-wrapper {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.left {
      .icon-wrapper {
        margin-right: 10px;
      }
    }

    &.right {
      flex-direction: row-reverse;

      .icon-wrapper {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &.is-dark-mode {
      background-color: transparent;

      &.active {
        background-color: $violet;
      }
    }

    &.active {
      color: $white;
      background-color: $black;
    }

    &:not(.active) {
      &.hoverable:hover {
        background-color: rgba(var(--background-color-rgb-reverse), .1);
      }
    }
  }
</style>
