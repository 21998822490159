<template>
  <ul class='wrapper'>
    <slot/>
  </ul>
</template>

<script>
  export default {
    data: () => ({
      Accordion: {
        count: 0,
        active: null
      }
    }),
    provide() {
      return {Accordion: this.Accordion}
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }
</style>