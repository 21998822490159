<template>
  <Field name='socials' v-model='socials'>
    <div class='link-field-component' v-for='(social, index) in socials' :key='social.id'>
      <div class='link-field__input-wrap link-field-collection'>
        <div>
          <label class='link-field__label' :for='`link_${index}`'>Social Link</label>
          <Field :name='`socials[${index}].link`'
                 v-model='socials[index].link'
                 class='link-field__input'
                 type='text'
                 :id='`link_${index}`'/>

          <ErrorMessage class='link-field__error' :name='`socials[${index}].link`' ref='select'/>
        </div>

        <div>
          <Select :value='socials[index].type'
                  :name='`socials[${index}].type`'
                  :socials='socials'
                  :options='selectOptions'/>

          <Button class='link-field__close-button' is-text @click='removeLink(index)'>
            <CloseIcon/>
          </Button>
        </div>
      </div>
    </div>
  </Field>
  <Button v-if='isShowAddLinkButton'
          class='link-field__open-button'
          @click='addLink'
          is-secondary>
    <i class='fas fa-plus'/>
    Add a social link
  </Button>
</template>

<script>
import {shallowRef} from 'vue'
import {map, omit, sortBy} from 'lodash'
import {Field, ErrorMessage} from 'vee-validate'

import {CloseIcon, getSocialIconByKey} from '@/components/icons'
import Button from '@/components/ui/Button'

import Select from './Select'

export default {
  components: {
    Field, Button,
    ErrorMessage,
    Select, CloseIcon
  },
  data() {
    return {
      socials: [],
      selectOptions: [
        {label: 'Twitter', value: 'twitter'},
        {label: 'Instagram', value: 'instagram'},
        {label: 'Facebook', value: 'facebook'},
        {label: 'YouTube', value: 'youtube'},
        {label: 'LinkedIn', value: 'linkedin'},
        {label: 'Email', value: 'email'},
        {label: 'Twitch', value: 'twitch'},
        {label: 'Telegram', value: 'telegram'},
        {label: 'Medium', value: 'medium'},
        {label: 'Discord', value: 'discord'},
        {label: 'SnapChat', value: 'snapchat'}
      ].map(item => ({...item, icon: shallowRef(getSocialIconByKey(item.value))}))
    }
  },
  computed: {
    isShowAddLinkButton() {
      return this.socials.length < this.selectOptions.length * 2
    }
  },
  methods: {
    addLink() {
      const availableTypes = {}

      this.selectOptions.forEach(type => {
        availableTypes[type.value] = {...type, count: 0}
      })

      sortBy(map(this.socials, 'type.value')).forEach(type => {
        availableTypes[type].count += 1

        if (availableTypes[type].count >= 2) {
          delete availableTypes[type]
        }
      })

      const option = availableTypes[Object.keys(availableTypes)[0]]

      if (!option) return

      this.socials.push({
        id: Date.now(),
        link: '',
        type: omit(option, ['icon', 'count'])
      })
    },
    removeLink(index) {
      this.socials.splice(index, 1)
    }
  }
}
</script>

<style lang='scss' src='./LinkField.scss' scoped/>