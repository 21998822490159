<template>
  <div class="button-container" :class="{ disabled: isDisabled }">
    {{ displayText }}
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      required: false
    },
    disabledText: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  computed: {
    displayText() {
      if (this.isDisabled) {
        return this.disabledText;
      } else {
        return this.buttonText;
      }
    }
  }
};
</script>

<style scoped>
.button-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 60px;
  height: fit-content;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #1d1d1f;
  border-radius: 20px 1px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#9a00ff),
    to(#7500ff)
  );
  background-image: linear-gradient(180deg, #9a00ff, #7500ff);
  box-shadow: inset 0 0 0 0 #1d1d1f, 0 2px 0 0 #1d1d1f;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease,
    -webkit-transform 200ms ease;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
}

.disabled {
  background-image: linear-gradient(180deg, #dddddd, #dddddd);
  pointer-events: none;
  color: black;
}

.button-container:hover {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #1d1d1f, 0 4px 0 0 #1d1d1f;
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.button-container:active {
  box-shadow: inset 0 4px 0 0 #1d1d1f, 0 0 0 0 #1d1d1f;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.button-text {
}
</style>
