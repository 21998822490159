<template>
  <div class='badge-wrapper'>
    <div class='badge' v-if='!!value'>{{badgeCountValue}}</div>
    <slot/>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        required: true
      },
      maxCount: {
        type: Number,
        required: false,
        default: 999
      }
    },
    computed: {
      badgeCountValue() {
        return (this.maxCount && this.value > this.maxCount) ? `+${this.maxCount}` : this.value
      }
    }
  }
</script>

<style scoped lang='scss'>
  .badge-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .badge {
    top: -15px;
    left: auto;
    right: auto;
    padding: 3px 5px;
    position: absolute;
    border-radius: 24px;
    background-color: $amaranthRed;
    color: $white;
    font-size: 12px;
    line-height: 1;
    min-width: 18px;
    min-height: 18px;
  }
</style>