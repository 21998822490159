'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethers } from 'ethers';
import axios from 'axios';
import { authHeader } from '../utility';
import { ethersService } from './index';

const ERRORS = {
  NO_LP:{
    originalMessage: '',
    friendlyMessage: ''
  }
};

let config;
(async () => {
	config = await initializeConfig();
})();

const getLPStakingContract = async function () {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();


  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];

  let stakingContract = new ethers.Contract(lpStakingAddress, config.lpStakingABI, provider);
  return stakingContract;
}

const registerListener = async function (event, listener) {
  let contract = await getLPStakingContract();
  contract.on(event, listener);
};

const approveTokens = async function () {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];
  let lpTokenAddress = config.lpTokenAddress[networkId];
  let lpTokenContract = new ethers.Contract(lpTokenAddress, config.lpTokenABI, signer);
  let maxApproval = ethers.constants.MaxUint256;
  let approvalTransaction = await lpTokenContract.approve(lpStakingAddress, maxApproval);
  await approvalTransaction.wait();
  return maxApproval;
};

const getTotalStakedBalance = async function () {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();

  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpTokenAddress = config.lpTokenAddress[networkId];
  let lpTokenContract = new ethers.Contract(lpTokenAddress, config.lpTokenABI, provider);
  let totalStakedBalance = await lpTokenContract.balanceOf(config.lpStakingAddress[networkId]);

  return totalStakedBalance;
}

const getStakedBalance = async function () {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();
  let lpsContract = await getLPStakingContract();
  let userStakedBalance = await lpsContract.balanceOf(walletAddress);
  return userStakedBalance;
}

const getEarnedBalance = async function () {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();
  let lpsContract = await getLPStakingContract();
  let userStakedBalance = await lpsContract.earned(walletAddress);

  return userStakedBalance;
}

const getPeriodFinish = async function () {
  let lpsContract = await getLPStakingContract();
  let periodFinish = await lpsContract.periodFinish();

  return periodFinish;
}

const getRewardRate = async function () {
  let lpsContract = await getLPStakingContract();
  let rewardRate = await lpsContract.rewardRate();

  return rewardRate;
}

const getPoolValueEth = async function () {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();

  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];
  let lpTokenAddress = config.lpTokenAddress[networkId];
  let wethAddress = config.weth[networkId];
  let wethContract = new ethers.Contract(wethAddress, config.tokenABI, provider);
  let wethBalance = await wethContract.balanceOf(lpTokenAddress);
  let poolValueEth = wethBalance.mul(2);  //total value of ALL lp

  let lpTokenContract = new ethers.Contract(lpTokenAddress, config.lpTokenABI, provider);
  let stakingLpBalance = await lpTokenContract.balanceOf(lpStakingAddress);
  let totalLpSupply = await lpTokenContract.totalSupply();

  let stakedLPRatio = stakingLpBalance.div(totalLpSupply);

  return poolValueEth.mul(stakingLpBalance).div(totalLpSupply); //value of entire pool in eth * amount of lp staked / total lp supply
}

const stakeTokens = async function (amount) {
  let provider = await ethersService.getProvider();

  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];

  let stakingContract = new ethers.Contract(lpStakingAddress, config.lpStakingABI, signer);
  let stakeTx = await stakingContract.stake(amount);
  await stakeTx.wait();
}

const unstakeTokens = async function (amount) {
  let provider = await ethersService.getProvider();

  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];

  let stakingContract = new ethers.Contract(lpStakingAddress, config.lpStakingABI, signer);
  let unstakeTx = await stakingContract.withdraw(amount);
  //await unstakeTx.wait();
}

const claimReward = async function () {
  let provider = await ethersService.getProvider();

  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];

  let stakingContract = new ethers.Contract(lpStakingAddress, config.lpStakingABI, signer);
  let unstakeTx = await stakingContract.getReward();
  await unstakeTx.wait();
}

const checkAllowances = async function () {
  let provider = await ethersService.getProvider();

  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpStakingAddress = config.lpStakingAddress[networkId];
  let lpTokenAddress = config.lpTokenAddress[networkId];
  let lpTokenContract = new ethers.Contract(lpTokenAddress, config.lpTokenABI, signer);
  let allowance = await lpTokenContract.allowance(await signer.getAddress(), lpStakingAddress);
  return allowance;
}

const getLPBalance = async function () {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let lpTokenAddress = config.lpTokenAddress[networkId];
  let lpTokenContract = new ethers.Contract(lpTokenAddress, config.lpTokenABI, provider);
  let lpBalance = await lpTokenContract.balanceOf(await signer.getAddress());
  return lpBalance;
}


export const poolService = {
  registerListener,
  approveTokens,

  //read
  getTotalStakedBalance,
  getStakedBalance, //balanceOf
  getEarnedBalance,
  getPeriodFinish,
  getPoolValueEth,
  getRewardRate,
  checkAllowances,
  getLPBalance,

  stakeTokens,
  unstakeTokens,
  claimReward
  /*


  rewardPerToken,
  rewardRate,
  rewardsDuration,
  totalGlobalDeposited,

  //write
  stakeTokens,
  claimReward,//getReward
  withdraw
  */
};
