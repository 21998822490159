<template>
  <div className="item-border">
    <router-link v-if="!isLoading" :to="`/items/${item.id}`">
      <img v-if="item.cardImage" :src="item.cardImage" className="item-image" />
      <img v-else :src="item.image" className="item-image" />
    </router-link>

    <div v-else className="skeleton-item-image">
      <Skeletor circle size="250" />
    </div>

    <div v-if="!isLoading" className="item-issue-container">
      <div v-if="collection" className="collection-rectangle">
        <!-- <img src="../images/verified-stamp.png" className="item-verified-stamp"> -->
        <text v-if="!isLoading" className="item-collection-text">{{ item.collection + ' Collection' }}</text>
      </div>
      <div v-if="home" className="home-rectangle">
        <!-- <img src="../images/verified-stamp.png" className="item-verified-stamp"> -->
        <text v-if="!isLoading" className="item-collection-text">{{ item.collection + ' Collection' }}</text>
      </div>
      <div className="nft-rectangle">
        <text v-if="!isLoading" className="item-issue-number">{{ '#' + item.issueNumber + '/' + item.metadata.groupSize }}</text>
      </div>
    </div>

    <router-link v-if="!isLoading" :to="`/items/${item.id}`">
      <div className="item-avatar-wrapper">
        <div className="item-avatar-1"></div>
        <div className="item-avatar-2"></div>
        <div className="item-avatar-3"></div>

        <img src="../images/dots.png" className="dots-icon" />
      </div>

      <!-- <div className="item-availability-wrapper">
				<text className="item-availability">{{ `${item.itemGroupSize - item.itemGroupCirculating} Still Available` }}</text>
			</div> -->

      <div className="item-name-wrapper">
        <text className="item-name">{{ item.name }}</text>
        <img src="../images/heart-icon.png" className="item-heart-icon" />
      </div>

      <div className="item-price-wrapper">
        <text className="eth-superscript">ETH</text>
        <text className="item-price">{{ ethers.utils.formatEther(item.price) }}</text>
        <button className="item-buy-btn">BUY</button>
      </div>
    </router-link>

    <div v-else>
      <div className="skeleton-item-title">
        <Skeletor width="250" height="50" pill />
      </div>
      <div className="skeleton-item-title">
        <Skeletor width="250" height="50" pill />
      </div>
      <div className="skeleton-item-bottom">
        <div className="skeleton-item-price">
          <Skeletor width="100" height="50" pill />
        </div>
        <div className="skeleton-item-button">
          <Skeletor width="135" height="75" pill />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import { ethers } from 'ethers';

// Component imports.
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

// Set up the default item component.
export default {
  name: 'Item',
  components: {
    Skeletor
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false
    },
    home: {
      type: Boolean,
      required: false
    },
    collection: {
      type: Boolean,
      required: false
    },
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      ethers
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      ethereum: state => state.ethers
    })
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    ...mapActions('ethers', ['connectWallet'])
  },
  watch: {
    $route(to, from) {
      this.clearAlert();
    }
  }
};
</script>

<style scoped>
.item-border {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 50px 75px -15px rgba(96, 124, 172, 0.15);
  border-radius: 20px;
  width: 270px;
  height: 498px;
  text-align: center;
  margin: auto;
  transition: box-shadow 0.2s;
  font-family: Hammersmith One;
}

.item-border:hover {
  box-shadow: 0px 0px 75px 0px rgba(96, 124, 172, 0.5);
  padding-bottom: 5px;
}

.item-border a {
  text-decoration: none;
}

.item-image {
  border-radius: 20px 20px 0px 0px;
  width: 270px;
  height: 270px;
  object-fit: contain;
}

.skeleton-item-image {
  padding: 10px;
  margin-bottom: 15px;
}

.item-issue-container {
  display: flex;
  margin-top: -10px;
}

.collection-rectangle {
  position: relative;
  display: flex;
  justify-content: center;
  background: #538ce6;
  box-shadow: 0px 15px 20px -10px rgba(85, 43, 240, 0.398712);
  border-radius: 12.5px;
  width: 160px;
  height: 20px;
  z-index: 1;
  margin-left: 20px;
}

.home-rectangle {
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(225deg, #53dae7 1.33%, #5504e4 100%);
  box-shadow: 0px 15px 20px -10px rgba(85, 43, 240, 0.398712);
  border-radius: 12.5px;
  width: 160px;
  height: 20px;
  z-index: 1;
  margin-left: 20px;
}

.nft-rectangle {
  position: relative;
  display: flex;
  justify-content: center;
  background: #7e8da5;
  border-radius: 12.5px;
  width: 60px;
  height: 20px;
  padding-top: 4px;
  margin-left: 10px;
}

.item-collection-text {
  padding-top: 3px;
  font-style: normal;
  text-align: right;
  font-size: 10px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1000;
}

.item-verified-stamp {
  position: absolute;
  z-index: 1001;
}

.item-issue-number {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1001;
}

.item-avatar-wrapper {
  height: 64px;
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dee5ef;
}

.item-avatar-1 {
  position: absolute;
  background-image: url('../images/stock-profile.png');
  width: 35px;
  height: 35px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  z-index: 3;
}

.item-avatar-2 {
  position: absolute;
  margin-left: 25px;
  background-image: url('../images/stock-profile.png');
  width: 35px;
  height: 35px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  z-index: 2;
}

.item-avatar-3 {
  position: absolute;
  margin-left: 50px;
  background-image: url('../images/stock-profile.png');
  width: 35px;
  height: 35px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  z-index: 1;
}

.dots-icon {
  position: absolute;
  margin-top: 15px;
  margin-left: 210px;
}

.item-name-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 65px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dee5ef;
}

.item-name {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #424b5f;
}

.item-heart-icon {
  position: absolute;
  right: 0;
  width: 16px;
  height: 15px;
}

.item-availability-wrapper {
  height: 65px;
  padding-top: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dee5ef;
}

.item-availability {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #424b5f;
}

.item-price-wrapper {
  position: relative;
  padding-top: 20px;
}

.eth-superscript {
  position: absolute;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #424b5f;
  margin-left: -10px;
  margin-top: 10px;
}

.item-price {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #424b5f;
  margin-right: 55px;
  margin-left: 20px;
}

.item-buy-btn {
  background: linear-gradient(225deg, #53dae7 1.33%, #5504e4 100%);
  box-shadow: 0px 10px 15px -10px rgba(85, 43, 240, 0.398712);
  border-radius: 16px;
  border: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  width: 90px;
  height: 48px;
  color: #ffffff;
  transition: box-shadow 0.2s;
}

.item-buy-btn:hover {
  box-shadow: 0px 10px 15px -10px rgba(85, 43, 240, 0.8);
}

.skeleton-item-title {
  padding-left: 10px;
  margin-bottom: 5px;
}

.skeleton-item-bottom {
  padding-left: 10px;
  display: flex;
}

.skeleton-item-price {
  margin-top: 25px;
  margin-right: 10px;
}

.skeleton-item-button {
  margin-top: 10px;
}
</style>
