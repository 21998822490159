<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      d="M4.5 12.5C7 12.5 9 10.4 9 7.9 9 5.4 7 3.4 4.5 3.4 2 3.4 0 5.4 0 7.9 0 10.4 2 12.5 4.5 12.5Z"
      class="a"
    ></path>
    <path
      d="M11.7 12.2C13 12.2 14 10.3 14 7.9 14 5.6 13 3.7 11.7 3.7 10.5 3.7 9.5 5.6 9.5 7.9 9.5 10.3 10.5 12.2 11.7 12.2Z"
      class="a"
    ></path>
    <path
      d="M15.2 11.8C15.6 11.8 16 10.1 16 8 16 5.8 15.6 4.1 15.2 4.1 14.8 4.1 14.4 5.8 14.4 8 14.4 10.1 14.8 11.8 15.2 11.8Z"
      class="a"
    ></path>
  </svg>
</template>
