import {shallowRef} from 'vue'

import {EthSmallIcon, HammerIcon, HandIcon, SuperIcon, TagIcon} from '@/components/icons'

export const sortByOptions = [
    {value: 'most_recent', label: 'Most Recent'},
    {value: 'most_popular', label: 'Most Popular'},
    {value: 'trending', label: 'Trending'},
    {value: 'price_to_low', label: 'Price High to Low'},
    {value: 'price_to_high', label: 'Price Low to High'}
]

export const getExactSortOption = key => sortByOptions.find(item => item.value === key)

export const statusOptions = [
    {label: 'On Sale', value: 'sale', icon: shallowRef(TagIcon)},
    {label: 'On Auction', value: 'auction', icon: shallowRef(HammerIcon)},
    {label: 'Has Offers', value: 'offers', icon: shallowRef(HandIcon)}
]

export const currencyOptions = [
    {label: 'ETH', value: 'eth', icon: shallowRef(EthSmallIcon)},
    {label: 'SUPER', value: 'super', icon: shallowRef(SuperIcon)}
]