<template>
  <ReportModal title='Report NFT'
               :onSubmit='submitReport'
               :isLoading='isReportSubmitting'/>
  <div class='wrapper'>
    <div class='container'>
      <Filters/>
      <List/>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'

  import {ReportModal} from '@/components/common/modal'

  import Filters from './components/Filters'
  import List from './components/List'

  export default  {
    components: {
      Filters,
      List,
      ReportModal
    },
    data() {
      return {
        isReportSubmitting: false
      }
    },
    methods: {
      ...mapActions('following', ['getItems']),
      async submitReport(message, close) {
        // TODO report request.

        close()
      }
    },
    async created() {
      document.title = 'Following - SuperFarm NFT Platform'

      await this.getItems()
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
  }

  .container {
    max-width: 1600px;
    margin: 0 auto;
  }
</style>
