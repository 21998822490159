<template>
  <SelectList isMultiple
              :options='options'
              :modelValue='modelValue'
              @update:modelValue='onChange'>
    <template v-slot='{options, isActive, handleChange}'>
      <div class='list'>
        <SelectButton v-for='(option, index) in options'
                      :key='index'
                      class='item'
                      @click='handleChange(option)'
                      :isActive='isActive(option)'>
          <template v-if='!!option?.icon' #icon>
            <component :is='option.icon' :class='{"eth-icon": option.value === "user_purchased_your_item"}'/>
          </template>
          {{option.label}}
        </SelectButton>
      </div>
    </template>
  </SelectList>
</template>

<script>
  import {shallowRef} from 'vue'

  import {SelectButton, SelectList} from '@/components/ui/select'
  import {
    CheckIcon, HammerIcon, EthIcon,
    TransferredIcon, HeartFilledIcon, HandIcon
  } from '@/components/icons'

  export default {
    components: {SelectButton, SelectList},
    props: {
      modelValue: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      options: [
        {
          value: 'user_followed_you',
          label: 'Follows',
          icon: shallowRef(CheckIcon)
        },
        {
          value: 'user_bid_on_your_item',
          label: 'Bids',
          icon: shallowRef(HammerIcon)
        },
        {
          value: 'user_purchased_your_item',
          label: 'Purchases',
          icon: shallowRef(EthIcon)
        },
        {
          value: 'user_transferred_item_to_you',
          label: 'Transfers',
          icon: shallowRef(TransferredIcon)
        },
        {
          value: 'user_liked_your_item',
          label: 'Likes',
          icon: shallowRef(HeartFilledIcon)
        },
        {
          value: 'user_offered_for_your_item',
          label: 'Offers',
          icon: shallowRef(HandIcon)
        }
      ]
    }),
    methods: {
      onChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>

<style scoped lang='scss'>
  .eth-icon {
    fill: currentColor;
    width: 12px;
    height: 12px;
  }
</style>
