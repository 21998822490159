<template>
  <div>
    <Filters name='favorites-section'/>
  </div>
</template>

<script>
  import {provide, reactive, watch} from 'vue'

  import {sortByOptions} from '@/components/common/filters'

  import Filters from '../components/Filters'

  export default {
    components: {Filters},
    setup() {
      const stateData = reactive({
        filters: {
          search: '',
          status: [],
          price: {
            from: '',
            to: ''
          },
          currency: null,
          collections: [],
          sort_by: sortByOptions[0]
        },
        page: 1
      })

      const setExactFilter = data => stateData.filters[data.type] = data.value

      const setAllFilters = values => {
        Object.keys(values).forEach(key => {
          if (values.hasOwnProperty(key)) {
            setExactFilter({type: key, value: values[key]})
          }
        })
      }

      provide('favorites-section', {
        filters: stateData.filters,
        setExactFilter,
        setAllFilters
      })

      watch(stateData.filters, (newValue, oldValue) => {
        // Todo api request
      })
    }
  }
</script>
