<template>
  <div class="like-button">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      :fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0515 3.0443L15.0517 3.04468C15.8821 4.74524 15.5823 6.71032 13.9414 8.9672L13.9405 8.96846C12.6399 10.769 10.7611 12.5805 8.00327 14.7223L8.00279 14.7226L8.0024 14.7228L8.00202 14.7226L8.00163 14.7223C5.24062 12.577 3.36502 10.7496 2.06296 8.96663C0.417354 6.70995 0.11776 4.74507 0.948081 3.04467L0.948264 3.0443C1.50938 1.89276 3.18362 0.880922 5.20625 1.46051C6.17029 1.7391 7.01138 2.33632 7.59215 3.15466L7.9999 3.7292L8.40765 3.15466C8.98853 2.33618 9.82981 1.73889 10.7941 1.46037L10.7953 1.46C12.8099 0.872243 14.4895 1.89089 15.0515 3.0443Z"
        :stroke="stroke"
        stroke-width="1"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['filled'],

  computed: {
    fill() {
      return this.filled ? '#e0245e' : 'none';
    },

    stroke() {
      return this.filled ? 'none' : 'currentColor';
    }
  }
};
</script>

<style scoped>
.like-button {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  transition: background-color 200ms ease;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.like-button:hover {
  background-color: rgba(224, 36, 94, 0.1);
  color: #e0245e;
}

.like-button:active {
  background-color: rgba(224, 36, 94, 0.2);
}
</style>
