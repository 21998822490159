<template>
  <div class='tabs-menu' :style='{top: stickyOffset}'>
    <slot/>
  </div>
</template>

<script>
  import {provide, computed, ref} from 'vue'

  export default {
    name: 'Tabs',
    props: {
      modelValue: {
        type: [String, Number]
      },
      stickyOffset: {
        type: [String, Number],
        required: false,
        default: 0
      }
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {
      const active = computed(() => props.modelValue)
      const tabs = ref([])

      const selectTab = tab => {
        emit('update:modelValue', tab)
      }

      provide('tabsState', {
        active,
        tabs,
        selectTab
      })
    }
  }
</script>

<style scoped lang='scss'>
  .tabs-menu {
    display: flex;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
    top: 0;
    position: sticky;
    background-color: var(--background-color);
    z-index: 1;
    overflow-x: auto;
  }
</style>