<template>
  <div id="drops" class="container">
    <div class="header">
      <img :src="image_source" />
      <h1>{{ series }}</h1>
      <p class="author">
        by <span>{{ author }}</span>
      </p>
      <div class="description" v-html="description"></div>
    </div>
    <div
      v-if="!isMobile()"
      :class="{
        'drop-days-container': !isMobile(),
        'drop-days-container-mobile': isMobile()
      }"
    >
      <!-- TODO digusting componentize this -->
      <div class="days">
        <div class="day-1-and-2">
          <div class="sold-out-day">
            <div class="day-title">Day 1</div>
            <div class="day-image-wrapper">
              <img
                src="../../../images/dropday/hype_lord.jpg"
                loading="lazy"
                class="day-image"
              />
              <img
                src="../../../images/dropday/sold-out.png"
                loading="lazy"
                alt=""
                class="image"
              />
            </div>
          </div>
          <div class="sold-out-day last">
            <div class="day-title">Day 2</div>
            <div class="day-image-wrapper">
              <img
                src="../../../images/dropday/yield_master.jpg"
                loading="lazy"
                class="day-image"
              />
              <img
                src="../../../images/dropday/sold-out.png"
                loading="lazy"
                alt=""
                class="image"
              />
            </div>
          </div>
        </div>
        <div
          id="w-node-ce80d9c7-5b1f-19ef-ff86-9eca65f311cd-05f60205"
          class="whitelist-days-wrapper"
        >
          <div class="whitelist-sale-date">
            Whitelist Sale on Monday, April 5th
          </div>
          <div class="whitelist-drops">
            <div class="day">
              <img
                src="../../../images/dropday/pool_shark.jpg"
                loading="lazy"
                class="day-image"
              />
              <div class="amount-available">80 available</div>
            </div>
            <div class="day">
              <img
                src="../../../images/dropday/lunar_prophet.jpg"
                loading="lazy"
                class="day-image"
              />
              <div class="amount-available">80 available</div>
            </div>
            <div class="day last">
              <img
                src="../../../images/dropday/cyber_chef.jpg"
                loading="lazy"
                class="day-image"
              />
              <div class="amount-available">80 available</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <DropDayInfoCard
        v-for="drop in dropDays"
        :key="drop.day"
        :day="drop.day"
        :blockNumber="drop.blockNumber"
        :date="drop.date"
        :img="drop.img"
        :num_avail="drop.num_avail"
        :isSoldOut="drop.isSoldOut"
        :isOnSale="drop.isOnSale"
      /> -->
    </div>

    <div class="cards">
      <div
        class="card"
        v-for="(pool, index) in items.launchpadItemList.pools"
        :key="index"
      >
        <!-- <p>
          {{ pool }}
        </p> -->
        <DropCard :pool="pool" />
        <!-- <FakeDropCard
          :poolName="pool.name"
          :poolItems="pool.items"
          :startBlock="pool.startBlock"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import initializeConfig from '../../../initialize-config';
import { useTask } from 'vue-concurrency';
import store from '../../../store';
import { ethers } from 'ethers';
import { formatNumber } from '../../../utility';

// Component imports.
import DropCard from './DropCard';

// Set up the component.
export default {
  components: {
    DropCard
  },
  data() {
    return {
      ethers,
      formatNumber,

      getLaunchpadItemsTask: null,

      // Launchpad setup.
      image_source: 'logo-512.png',
      series: 'SuperFarm Genesis Series',
      author: '@SuperFarm',
      description:
        '<p class="about-drop">With a cyberpunk aesthetic set in the dystopian future city of Cryptopolis, the first NFTs on SuperFarm are aptly named the Genesis Series. These NFTs are packed with utility across the SUPERVERSE, and will entitle their holders to unique privileges to unlock new experiences. Holders of these NFTs will enjoy key platform utilities such as priority whitelist access, farming multipliers, access to in-game items and more.<br><br>There are five unique characters that will be released during the Genesis Series Drop. If you <b>collect all five designs you will receive a free NFT collector’s trophy</b>, airdropped to your account at a later date. To get this NFT collector award you must be holding all five characters in the same wallet on 12:01AM PST April 20th, 2021. <br><b>Holders of these NFTs are also eligible for SUPER airdrops that vary based on rarity</b>.</p><br><br><p><h2><a href="https://link.medium.com/dclv1MVA8eb">Whitelisted Lottery Announcement</a></h2>',

      // TIM: these are the drop days metadata
      dropDays: [
        {
          day: 'Day 1',
          blockNumber: false,
          img: 'hype_lord.jpg',
          num_avail: 80,
          isSoldOut: true,
          isOnSale: false
        },
        {
          day: 'Day 2',
          blockNumber: false,
          img: 'yield_master.jpg',
          num_avail: 80,
          isSoldOut: true,
          isOnSale: false
        },
        {
          day: 'Day 3',
          blockNumber: 12162750,
          img: 'pool_shark.jpg',
          num_avail: 80,
          isSoldOut: false,
          isOnSale: true
        },
        {
          day: 'Day 4',
          blockNumber: false,
          img: 'lunar_prophet.jpg',
          num_avail: 80,
          isSoldOut: false,
          isOnSale: false
        },
        {
          day: 'Day 5',
          blockNumber: false,
          img: 'cyber_chef.jpg',
          num_avail: 80,
          isSoldOut: false,
          isOnSale: false
        }
      ]
    };
  },
  // created() {
  //   let ethereumReference = this.ethereum;
  //   this.getLaunchpadItemsTask = useTask(function* (signal) {
  //     this.config = yield initializeConfig();
  //     if (!ethereumReference.provider) {
  //       yield store.dispatch("ethers/initialize", async () => {
  //         await store.dispatch("items/getLaunchpadItems", "", { root: true });
  //       });
  //     } else {
  //       yield store.dispatch("items/getLaunchpadItems", "", { root: true });
  //     }
  //   });
  //   this.getLaunchpadItemsTask.perform();
  // },
  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      account: state => state.account,
      items: state => state.items
    })
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  text-align: center;
}

.header {
  margin: 50px 0px;
}

.header > img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.header > h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.author {
  margin-bottom: 10px;
}

.author > span {
  font-size: 20px;
  color: #7112ff;
}

.author > span:hover {
  cursor: pointer;
}

.description {
  max-width: 100ch;
  margin-bottom: 0px;
  text-align: center;
  opacity: 0.7;
  font-size: 14px;
}

.cards {
  margin: 10px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin: 15px;
}

.drop-days-container {
  display: flex;
  max-width: 1280px;
}

.drop-days-container-mobile {
  display: none;
  max-width: 1280px;
  width: 100%;
}

.whitelist-sale-date {
  margin-bottom: 20px;
  color: #7112ff;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.sold-out-day {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.sold-out-day.last {
  margin-right: 0px;
}

.day-title {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
}

.whitelist-drops {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image {
  position: absolute;
  top: -26px;
}

.day-image {
  width: 100%;
  border-radius: 8px;
  height: 100%;
}

.amount-available {
  margin-top: 4px;
}

.days {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1000px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.whitelist-days-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border: 1px solid #7112ff;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(113, 18, 255, 0.63);
}

.day-1-and-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
}

.day {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.day.last {
  margin-right: 0px;
}

.day-image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 26px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .whitelist-sale-date {
    font-size: 20px;
  }

  .day-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sold-out-day {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .days {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .day-1-and-2 {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .day {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .whitelist-drops {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .days {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .day {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .day.last {
    margin-bottom: 0px;
  }
}
</style>
