<template>
  <button class='icon-button'>
    <slot/>
  </button>
</template>

<style scoped lang='scss'>
  .icon-button {
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: var(--text-color);
    background-color: transparent;

    &:hover {
      background-color: rgba(var(--background-color-rgb-reverse), .1);
      cursor: pointer;
    }

    &:active {
      background-color: rgba(var(--background-color-rgb-reverse), .2);
    }
  }
</style>