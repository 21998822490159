'use strict';

// Imports.
import { ethers } from 'ethers';

// Define particular hard-coded information for every known farm.
const KNOWN_FARMS = {
  // Network ID
  '0x1': {
    // SuperFarm
    '0xf35A92585CeEE7251388e14F268D9065F5206207': {
      // Farm Point Data
      pointName: 'GEM',
      pointSymbol: '💎',
      // Pool Token
      '0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55': {
        pair: 'SUPER',
        pairImgs: ['super-purple-logo.png', 'empty.png'],
        getLink: 'https://v2.info.uniswap.org/pair/0x25647e01bd0967c1b9599fa3521939871d1d0888',
        contractLink: 'https://etherscan.io/address/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
        pairInfoLink: null,
        isLp: false
      },
      '0x25647E01Bd0967C1B9599FA3521939871D1d0888': {
        pair: 'ETH-SUPER',
        pairImgs: ['eth-circle-logo.png', 'super-purple-logo.png'],
        getLink: 'https://v2.info.uniswap.org/pair/0x25647e01bd0967c1b9599fa3521939871d1d0888',
        contractLink: 'https://etherscan.io/address/0x25647e01bd0967c1b9599fa3521939871d1d0888',
        pairInfoLink: 'https://v2.info.uniswap.org/pair/0x25647e01bd0967c1b9599fa3521939871d1d0888',
        isLp: true
      }
    },

    // Injective.
    '0x8e586D927acE36a3ef7bDDF9f899d2E385d5Fc9b': {
      // Farm Point Data
      pointName: 'ASTRO',
      pointSymbol: '🚀',
      // Pool Token
      '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30': {
        pair: 'INJ',
        pairImgs: ['injective-circle-logo.png', 'empty.png'],
        getLink: null,
        contractLink: 'https://etherscan.io/address/0xe28b3b32b6c345a34ff64674606124dd5aceca30',
        pairInfoLink: null,
        isLp: false
      },
    },

    // REVV.
    '0xb3EA98747440aDDC6A262735E71B5A5cB29edd80': {
      // Farm Point Data
      pointName: 'GEAR',
      pointSymbol: '⚙️',
      // Pool Token
      '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca': {
        pair: 'REVV',
        pairImgs: ['revv-circle-logo.png', 'empty.png'],
        getLink: null,
        contractLink: 'https://etherscan.io/address/0x557b933a7c2c45672b610f8954a3deb39a51a8ca',
        pairInfoLink: null,
        isLp: false
      },
    }
  }
};

// Fetch hard-coded farm information.

export const pair = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].pair;
};

export const pairImgs = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].pairImgs;
};

export const getLink = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].getLink;
};

export const contractLink = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].contractLink;
};

export const pairInfoLink = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].pairInfoLink;
};

export const isLp = (networkId, stakingAddress, poolToken) => {
  return KNOWN_FARMS[networkId][stakingAddress][poolToken].isLp;
};

export const pointName = (networkId, stakingAddress) => {
  return KNOWN_FARMS[networkId][stakingAddress].pointName;
};

export const pointSymbol = (networkId, stakingAddress) => {
  return KNOWN_FARMS[networkId][stakingAddress].pointSymbol;
};
