import {sortByOptions} from '@/components/common/filters/data'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const getStateByTab = state => {
    switch (state.activeTab) {
        case 0:
            return state.your
        case 1:
            return state.their
        default:
            return null
    }
}

export const defaultFilters = {
    status: [],
    price: {
        from: '',
        to: ''
    },
    currency: null,
    collections: [],
    sort_by: sortByOptions[0],
    search: ''
}

const state = {
    your: {
        filters: {...defaultFilters, collections: []},
        items: [],
        page: 1,
        totalPages: 56,
        selected: []
    },
    their: {
        filters: {...defaultFilters, collections: []},
        items: [],
        page: 1,
        totalPages: 70,
        selected: []
    },
    activeTab: 0
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}