<template>
  <div v-if="hasOffer">
    <div class="myItemBuyArea__bid">Highest Offer</div>
    <div class="myItemBuyArea__offerFrom">from <strong>username</strong></div>
  </div>

  <div v-if="onAuction">
    <div class="myItemBuyArea__bid">Top Bid</div>
  </div>

  <slot></slot>
  <!--  Has offer buttons -->
  <div class="myItemBuyArea__buttonsGrid" v-if="hasOffer">
    <div class="myItemBuyArea__buttonRow">
      <Button>
        <template v-slot:default>
          <div class="myItemBuyArea__buttonWrapper">
            <HandIcon :isFlipped="true" />
            <p>Accept Offer</p>
          </div>
        </template>
      </Button>
      <Button :isSecondary="true">
        <template v-slot:default>
          <div class="myItemBuyArea__buttonWrapper">
            <CloseIcon />
            <p>Decline Offer</p>
          </div>
        </template>
      </Button>
    </div>
    <div class="myItemBuyArea__buttonRow">
      <Button class="" :isSecondary="true">
        <template v-slot:default>
          <div class="myItemBuyArea__buttonWrapper">
            <TagIcon />
            <p>Put on Sale</p>
          </div>
        </template>
      </Button>
      <Button :isSecondary="true">
        <template v-slot:default>
          <div class="myItemBuyArea__buttonWrapper">
            <HammerIcon />
            <p>Put on Auction</p>
          </div>
        </template>
      </Button>
    </div>
    <Button :isSecondary="true">
      <template v-slot:default>
        <div class="myItemBuyArea__buttonWrapper">
          <TransferredIcon />
          <p>Transfer</p>
        </div>
      </template>
    </Button>
  </div>

  <!-- Auction countdown  -->
  <div v-if="onAuction" class="myItemBuyArea__countdown">
    <div class="myItemBuyArea__countdownText">Auction ending in</div>
    <DropsCountdownCard
      :date="new Date(2021, 5, 10)"
      :hideDate="true"
      :isLight="true"
    />
  </div>

  <!-- Primary button -->
  <Button class="myItemBuyArea__button">
    <template v-slot:default>
      <div class="myItemBuyArea__buttonWrapper">
        <CloseIcon />
        <p>{{ primaryButtonText }}</p>
      </div>
    </template>
  </Button>
  <!-- Secondary button -->
  <Button class="myItemBuyArea__button secondary" :isSecondary="true">
    <template v-slot:default>
      <div class="myItemBuyArea__buttonWrapper">
        <TransferredIcon />
        <p>Transfer</p>
      </div>
    </template>
  </Button>
</template>

<script>
// Component imports
import Button from '/src/components/ui/Button';
import DropsCountdownCard from '/src/components/pages/drops/DropsCountdownCard';

// Icon imports
import HandIcon from '/src/components/icons/HandIcon';
import CloseIcon from '/src/components/icons/CloseIcon';
import TagIcon from '/src/components/icons/TagIcon';
import HammerIcon from '/src/components/icons/HammerIcon';
import TransferredIcon from '/src/components/icons/TransferredIcon';

export default {
  components: {
    Button,
    HandIcon,
    CloseIcon,
    TagIcon,
    HammerIcon,
    TransferredIcon,
    DropsCountdownCard
  },

  computed: {
    hasOffer() {
      return false;
    },

    onAuction() {
      return true;
    },

    onSale() {
      return false;
    },

    primaryButtonText() {
      return this.onSale ? 'Cancel Sale' : 'Cancel Auction';
    }
  }
};
</script>

<style scoped>
.myItemBuyArea__bid {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.myItemBuyArea__offerFrom {
  margin-bottom: 5px;
}

.myItemBuyArea__offerFrom > strong:hover {
  cursor: pointer;
  text-decoration: underline;
}

.myItemBuyArea__buttonsGrid {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

.myItemBuyArea__buttonRow {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.myItemBuyArea__buttonWrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
}

.myItemBuyArea__countdown {
  margin-bottom: 20px;
}
.myItemBuyArea__countdownText {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.myItemBuyArea__button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
}

.secondary {
  margin-top: 10px;
}
</style>
