<template>
  <div class="user-card">
    <div class="user-card__banner">
      <img :src="bgImage" class="user-card__banner-img" alt="" />
    </div>
    <div class="user-card__info">
      <img :src="image" class="user-card__avatar" alt="" />
      <div class="user-card__info-wrapper">
        <div class="user-card__username">{{ username }}</div>
        <div class="user-card__description">{{ description }}</div>
        <Button isSecondary class="user-card__follow-button">Follow</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button';

export default {
  components: {
    Button
  },
  props: {
    image: String,
    username: String,
    description: String,
    bgImage: String
  }
};
</script>

<style lang="scss" scoped>
.user-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 14px 40px 0 rgb(0 0 0 / 25%);
  }

  &:active {
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  }

  &__banner {
    height: 140px;
  }

  &__banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;
    padding: 20px;
  }

  &__avatar {
    width: 80px;
    height: 80px;
    margin-top: -60px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 3px;
    border-color: $white;
    border-radius: 50%;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__username {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__follow-button {
    border-width: 1px;
  }
}
</style>
