<template>
  <BaseDropdown ref='dropdown'>
    <SelectButton icon-position='right'>
      <template #icon><ChevronDownIcon/></template>
      {{buttonText}}
    </SelectButton>
    <template #dropdown>
      <DropdownContent width='197px'>
        <FromToForm :values='modelValue'
                    :min-max-values='fromToMinMax'
                    :onSave='handleChange'
                    :onClear='handleClear'
                    :isShowErrors='isShowErrors'/>
      </DropdownContent>
    </template>
  </BaseDropdown>
</template>

<script>
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import {SelectButton} from '@/components/ui/select'
  import {ChevronDownIcon} from '@/components/icons'

  import {FromToForm} from './fromTo'

  export default {
    components: {
      BaseDropdown, DropdownContent,
      SelectButton, FromToForm,
      ChevronDownIcon
    },
    props: {
      modelValue: {
        type: Object,
        required: true
      },
      fromToMinMax: {
        type: Object,
        default: () => ({
          min: .1,
          max: 999
        })
      },
      label: {
        type: String,
        required: false
      },
      isShowErrors: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      buttonText() {
        if (this.label) {
          return this.label
        }

        return `${this.modelValue?.from || this.fromToMinMax.min} - ${this.modelValue?.to || this.fromToMinMax.max} ETH`
      }
    },
    methods: {
      handleChange(values) {
        this.$emit('update:modelValue', values)

        this.$refs?.dropdown?.click()
      },
      handleClear() {
        this.$emit('onClear', {from: '', to: ''})
      }
    },
    emits: ['update:modelValue', 'onClear']
  }
</script>
