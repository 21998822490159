<template>
  <div class="container">
    <div
      data-poster-url="https://assets.website-files.com/605275dcb2aba8729d95885b/605530de53114a40e6c12789_superfarm_video_bg_001-poster-00001.jpg"
      data-video-urls="https://assets.website-files.com/605275dcb2aba8729d95885b/605530de53114a40e6c12789_superfarm_video_bg_001-transcode.mp4,https://assets.website-files.com/605275dcb2aba8729d95885b/605530de53114a40e6c12789_superfarm_video_bg_001-transcode.webm"
      data-autoplay="true"
      data-loop="true"
      data-wf-ignore="true"
      class="background-video w-background-video"
    >
      <video
        autoplay
        loop=""
        style="
          background-image: url('https://assets.website-files.com/605275dcb2aba8729d95885b/605530de53114a40e6c12789_superfarm_video_bg_001-poster-00001.jpg');
        "
        muted=""
        playsinline=""
        data-wf-ignore="true"
        data-object-fit="cover"
      >
        <source
          src="images/superfarm-nft-marketplace.mp4"
          data-wf-ignore="true"
        />
      </video>
    </div>
    <div class="splash-info">
      <a href="#drops" v-smooth-scroll>
        <div @mouseover="isHover" class="logo-container" ref="tiltLogo">
          <div class="container-new">
            <div class="black-bar">
              <img
                src="../images/SuperFarm-mulish-extra-bold-logo-white.svg"
                loading="lazy"
                alt=""
                class="superfarm-logo-on-black-bar"
              />
            </div>
          </div>
        </div>
      </a>
      <div class="down-drop">
        <a href="#drops" v-smooth-scroll>
          <DropsButton :dropBlock="12181920" />
        </a>
        <router-link class="roadmap-link" to="/roadmap">
          <div class="roadmap">
            <img
              src="https://assets.website-files.com/605275dcb2aba8729d95885b/6054f49c81dcaf69af17eb27_map.png"
            />
            <div>
              <p>v.0.0.23</p>
              <p>Codename Alpha</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import { useTask } from 'vue-concurrency';
import header_logo from '../images/super-farm-logo__black-2.svg';

// Component imports.
import VanillaTilt from 'vanilla-tilt';
import DropsButton from '../components/pages/launchpad/DropsButton';

export default {
  components: { DropsButton },

  data() {
    return {
      headerLogo: header_logo,
      nextDropDate: Date.UTC(2021, 3, 1, 22, 41, 25)
    };
  },
  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      account: state => state.account,
      items: state => state.items
    }),
    walletPrompt() {
      if (!this.ethereum.provider) {
        return '...';
      }
      return this.ethereum.hasLocalProvider
        ? 'Connect Wallet'
        : 'Install a Wallet';
    }
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {
    if (!this.isMobile()) {
      VanillaTilt.init(this.$refs.tiltLogo, {
        reverse: true, // reverse the tilt direction
        max: 30, // max tilt rotation (degrees)
        perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
        speed: 1000, // Speed of the enter/exit transition
        transition: true, // Set a transition on enter/exit.
        reset: true, // If the tilt effect has to be reset on exit.
        'full-page-listening': true
      });
    }
  }
};
</script>

<style scoped>
.roadmap {
  font-family: 'Work Sans', sans-serif;
  font-size: 0.75rem;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  text-align: left;
  font-style: italic;
  align-items: center;
  transition: ease-in 0.1s;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 20px;
}

.roadmap:hover {
  background: lightgray;
  cursor: pointer;
}

a.roadmap-link {
  color: black;
  text-decoration: none;
}

.roadmap > img {
  width: 35px;
  height: 35px;
}

.roadmap > img:hover {
  transform: none;
}

.container {
  position: relative;
}

.background-video,
.splash-info {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.splash-info {
  position: relative;
  z-index: 1;
  color: black;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.splash-info > a {
  margin-top: auto;
  margin-bottom: auto;
}

.header > img {
  height: 70px;
  width: 70px;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.background-video {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.w-background-video {
  position: absolute;
  object-fit: contain;
  overflow: hidden;
  color: white;
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
}

.wallet_button:hover {
  cursor: pointer;
  background: black;
  color: white;
}

.wallet_button:active {
  transform: scale(0.9);
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.header > img {
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  transform: scale(1.05);
}

.wallet_button {
  margin-left: auto;
}

.logo-container {
  width: 100%;
  height: inherit;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.black-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  transition: ease 0.2s;
}

a {
  text-decoration: none;
}

.drop {
  animation: mymove 5s infinite;
}

@keyframes mymove {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}

.container-new {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  transition: ease 0.2s;
}

.container-new:hover {
  cursor: pointer;
}

.superfarm-logo-on-black-bar {
  width: 60%;
  transform: translate3d(0px, 0px, 67px);
  transform-style: preserve-3d;
  transition: ease 0.2s;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.wallet-modal-container {
  position: fixed;
  z-index: 999;
}

@media (max-width: 520px) {
  .header {
    display: flex;
    align-items: center;
  }
  .header > img {
    width: 45px;
    height: 45px;
  }

  .wallet_button {
    font-size: 0.8rem;
    width: 150px;
  }

  .wallet_button > svg {
    height: 15px;
    width: 15px;
  }

  .roadmap {
    margin-top: 15px;
    margin-right: auto;
  }
}

.down-drop {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
