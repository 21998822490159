<template>
  <a v-if='!!value' class='website' :href='value' target='_blank'>
    <span class='website-url'>{{formatUrl(value)}}</span>
    <NewWindowIcon />
  </a>
</template>

<script>
  import {NewWindowIcon} from '@/components/icons'
  import {formatUrl} from '@/utility/format'

  export default {
    components: {NewWindowIcon},
    props: {
      value: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        formatUrl
      }
    }
  }
</script>

<style scoped lang='scss'>
  .website {
    display: flex;
    align-items: center;
    color: var(--text-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    .website-url {
      margin-right: 5px;
      font-weight: 600;
      text-decoration: none;
    }
  }
</style>