<template>
  <div class="cardSkeleton">
    <div class="image">
      <Skeletor width="100%" height="100%" />
    </div>
    <div class="row"><Skeletor width="100%" height="100%" /></div>
    <div class="row"><Skeletor width="100%" height="100%" /></div>
  </div>
</template>
<script>
'use strict';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  }
};
</script>
<style scoped>
.cardSkeleton {
  display: flex;
  position: relative;
  flex-direction: column;
  border-style: none;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: var(--background-color-nft);
  box-shadow: 0 2px 8px 0 rgba(var(--text-color-rgb), 0.1);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  text-decoration: none;
  cursor: pointer;
  max-width: 500px;
  width: 100%;
  margin: auto;
  height: 350px;
  padding-bottom: 10px;
}

.image {
  height: 250px;
  width: 100%;
  margin-bottom: 20px;
}

.row {
  height: 70px;
  width: 100%;
  padding: 15px 20px;
}
</style>
