<template>
  <div class="container">
    <div class="owner" v-for="owner in owners" :key="owner.username">
      <div class="profile-pic">
        <img src="" />
      </div>
      <div class="row-data">
        <div class="row-info">
          <!-- not for sale -->
          <div v-if="owner.salePrice === null">
            <p>{{ owner.numEditionsForSale }} in wallet. Not for sale.</p>
          </div>
          <!-- single edition for sale -->
          <div v-else-if="owner.numEditionsForSale === 1">
            <p>
              Selling for
              <span class="row-info-price">{{ owner.salePrice }} ETH</span>
            </p>
          </div>
          <!-- multiple editions for sale -->
          <div v-else-if="owner.numEditionsForSale > 1">
            <p>
              {{ owner.numEditionsForSale }} editions on sale for
              <span class="row-info-price">{{ owner.salePrice }} ETH</span>
            </p>
          </div>
        </div>
        <div class="row-username">{{ formatAddress(owner.username) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAddress } from '../../../utility';

export default {
  data() {
    return {
      formatAddress,
      owners: [
        {
          username: '0xbB9212423423412412422341232cC8',
          salePrice: 0.1,
          numEditionsForSale: 1
        },
        {
          username: '0xbB9212423423412412422341232cC8',
          salePrice: null,
          numEditionsForSale: 1
        },
        {
          username: '0xbB9212423423412412422341232cC8',
          salePrice: 0.1,
          numEditionsForSale: 36
        }
      ]
    };
  }
};
</script>

<style scoped>
.profile-pic {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
  background-color: #7112ff;
  background-image: linear-gradient(45deg, #7500ff, #9a00ff);
  margin-right: 10px;
}

.owner {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.row-username {
  font-weight: 600;
  cursor: pointer;
}

.row-info-price {
  font-weight: 600;
}
</style>
