<template>
  <h3 class='name'><slot/></h3>
</template>

<style scoped lang='scss'>
  .name {
    font-size: 30px;
    line-height: 1em;
    font-weight: 500;
  }
</style>