<template>
  <div class="profile-info-container">
    <div class="user-image-wrapper">
      <img src="../../../images/profile-avatar-placeholder.gif" />
    </div>
    <div class="profile-username">
      {{ profile.username }}
    </div>
    <div class="address-wrapper" v-if="showAddress" @click="copyToClipboard">
      <div class="address">{{ formatAddress(profile.address) }}</div>
      <CopyIcon />
    </div>
    <div class="address-wrapper" v-else>
      Address Copied!
    </div>
    <!-- <div class="profile-buttons-wrapper">
      <div class="profile-button">
        <img src="../images/copy.svg" />
      </div>
      <div class="profile-button">
        <img src="../images/copy.svg" />
      </div>
    </div> -->
    <div class="stats-wrapper">
      <div v-for="stat in profile.stats" :key="stat.label" class="stat-wrapper">
        <div class="stat">{{ stat.value }}</div>
        <div class="stat-type">{{ stat.label }}</div>
      </div>
    </div>
    <div class="profile-description">
      {{ profile.bio }}
    </div>
    <div class="website-wrapper">
      <div class="url">{{ profile.website }}</div>
      <NewWindowIcon />
    </div>
    <SocialButtons :socials="profile.socials" />
  </div>
</template>

<script>
// Imports
import { formatAddress } from '../../../utility';
import useClipboard from 'vue-clipboard3';

// Component Imports
import SocialButtons from './SocialButtons';

// Icon imports
import NewWindowIcon from '../../icons/NewWindowIcon';
import CopyIcon from '/src/components/icons/CopyIcon';

export default {
  components: {
    SocialButtons,
    NewWindowIcon,
    CopyIcon
  },

  props: {
    profile: {
      type: Object
    }
  },

  data() {
    return {
      formatAddress,
      showAddress: true
    };
  },

  methods: {
    async copyToClipboard() {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(this.profile.address);
        this.showAddress = false;
        setTimeout(() => {
          this.showAddress = true;
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped>
.profile-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.user-image-wrapper {
  overflow: hidden;
  width: 140px;
  height: 140px;
  margin-top: -90px;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 50%;
  background-color: #fff;
}

.user-image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.profile-username {
  margin-bottom: 5px;
  font-size: 30px;
  line-height: 1em;
  font-weight: 500;
}

.address-wrapper {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  opacity: 0.5;
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.address-wrapper:hover {
  opacity: 1;
  cursor: pointer;
}

.address-wrapper > img {
  height: 13px;
}

.address {
  margin-right: 5px;
  color: var(--text-color);
  opacity: 0.8;
}

.profile-buttons-wrapper {
  display: grid;
  margin-bottom: 15px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.profile-button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: color 200ms ease, background-color 200ms ease;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
}

.profile-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.profile-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.stats-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px 15px;
  justify-content: center;
}

.stat-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  font-size: 14px;
}

.stat {
  margin-right: 5px;
  font-weight: 700;
}

.stat-type {
  opacity: 0.5;
}

.profile-description {
  max-width: 60ch;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--text-color);
}

.website-wrapper {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.url {
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
}

.website-wrapper:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
