<template>
  <div class="farmRow">
    <Skeletor width="100%" height="100%" />
  </div>
</template>

<script>
// Component imports.
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

('use strict');

export default {
  components: {
    Skeletor
  }
};
</script>

<style scoped>
.farmRow {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  height: 90px;
  padding: 20px;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}
</style>
