<template>
  <ThemeToggleIcon v-if='!isHasChildren'
                   :isActive='isDarkMode'
                   @click.stop="toggle"/>
  <slot v-else :toggleTheme='toggle' :isActive='isDarkMode'/>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import ThemeToggleIcon from './ThemeToggleIcon'

export default {
  components: {ThemeToggleIcon},
  computed: {
    ...mapGetters('theme', ['isDarkMode']),
    isHasChildren() {
      return !!this.$slots.default
    }
  },
  methods: {
    ...mapActions('theme', ['setDarkMode']),
    toggle() {
      this.setDarkMode({value: !this.isDarkMode})

      document.documentElement.setAttribute('data-theme', this.isDarkMode ? 'darkMode' : '')
    }
  }
};
</script>
