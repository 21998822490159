<template>
  <main class="common-component pool-details">
    <section class="pool-details__info-container">
      <div class="common-container pool-details__info">
        <PoolInfo :isLoading="isLoading" :pool="pool" />
        <SwapCard :isLoading="isLoading" :pool="pool" />
      </div>
    </section>
    <section class="pool-details__tabs-container">
      <div class="common-container pool-details__tabs">
        <TabsComponent :tabs="['Pool Details', 'About the Project', 'Your Allocations']">
          <template #tab-1>
            <PoolDetails :isLoading="isLoading" :pool="pool" />
          </template>
          <template #tab-2>
            <AboutProject :isLoading="isLoading" :pool="pool" />
          </template>
          <template #tab-3>
            <YourAllocations :isLoading="isLoading" :pool="pool" />
          </template>
        </TabsComponent>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PoolInfo from "./components/pool-info/PoolInfo";
import SwapCard from "./components/swap-card/SwapCard"
import TabsComponent from "../../components/common/tabs-component/TabsComponent"
import PoolDetails from "./components/pool-details"
import AboutProject from "./components/about-project/AboutProject"
import YourAllocations from "./components/your-allocations/YourAllocations"

export default {
  components: {
    PoolInfo,
    SwapCard,
    TabsComponent,
    PoolDetails,
    AboutProject,
    YourAllocations
  },
  computed: {
    ...mapState("pools", {
      pool: state => state.single,
      isLoading: state => state.isLoadingSingle
    })
  },
  methods: {
    ...mapActions("pools", ["fetchSinglePools"])
  },
  mounted() {
    this.fetchSinglePools();
  }
};
</script>

<style lang="scss" scoped>
.pool-details {
  &__info-container {
    background-color: $bgViolet;
  }
  &__info {
    display: grid;
    gap: 30px;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
    justify-items: start;
    padding: 80px 15px;
    @include media-max-width ($mediumScreen) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
  &__tabs {
  }
}
</style>
