import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
    isPublic: true,
    profile: null,
    pinnedItems: [],
    isLoading: false
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}