<template>
  <Ethereum :callback="initialize" />
  <PoolModal :isOpen="showModal" :modalData="modalData" @exit="showModal = false" />
  <div class="farmDeployer">
    <div class="title">
      <h1>All Farms</h1>
      <Button class="button" @click="navigateTo('/deploy-farm')">New Farm</Button>
    </div>
    <Skeletor v-if="mockfarms.length === 0" height="200" width="100%" pill style="margin-top: 40px" />
    <div v-else v-for="farm in mockfarms" :key="farm.name" class="farm">
      <div class="farmHeader">
        <div class="title">{{ farm.name }}</div>
        <Button :isSecondary="true" @click="navigateTo('/edit-farm', farm.address)">
          Edit Farm
        </Button>
      </div>

      <FarmDeployerTable :pools="farm.pools" @openModal="openEditPool" />

      <Button :isSecondary="true" @click="openModal">
        + Add Pool
      </Button>
    </div>
  </div>
</template>
<script>
'use strict';
// Imports
import { useStore } from 'vuex';
import { ref, onMounted, computed } from 'vue';
import router from '/src/router';

// Component imports.
import Button from '/src/components/ui/Button';
import Ethereum from '/src/components/common/Ethereum';
import FarmDeployerTable from '/src/components/pages/farms-deployer/FarmDeployerTable';
import PoolModal from '/src/components/pages/farms-deployer/PoolModal';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Button,
    Ethereum,
    FarmDeployerTable,
    PoolModal,
    Skeletor
  },

  setup() {
    const store = useStore();
    const titles = ['Pool', 'Total Staked', 'Total Earned', 'Estimated Yield Per $1000', 'APY'];
    const showModal = ref(false);
    const modalData = ref({});

    // Load only the necessary information to display deployed farms.
    const mockfarms = computed(() => {
      let farms = [];

      for (const farm of store.state.farms.farms) {
        let pools = [];

        for (const pool of Object.keys(farm.poolTokens)) {
          pools.push({
            token: {
              symbol: farm.token.symbol,
              address: farm.token.address
            },
            pair: farm.poolData[pool].pair,
            pairImgs: farm.poolData[pool].pairImgs,
            isLp: farm.poolData[pool].isLp,
            point: farm.point,
            // This status controls the button state for each row in the FarmDeployerTable.
            status: 'Deployed'
          });
        }
        farms.push({ name: farm.name, address: farm.token.address, pools: pools });
      }

      return farms;
    });

    const initialize = async () => {
      let userAddress = null;
      if (store.state.ethers.canSign) {
        userAddress = store.state.ethers.address;
      }

      await store.dispatch('farms/retrieveFullFarmsInformation', userAddress, {
        root: true
      });
    };

    const navigateTo = (route, parameter = null) => {
      if (parameter) {
        console.log(`${route}/${parameter}`);
        router.push({ path: `${route}/${parameter}` });
      } else {
        router.push(route);
      }
    };

    const openModal = () => {
      showModal.value = true;
      modalData.value = {};
    };

    const openEditPool = pool => {
      showModal.value = true;
      modalData.value = {
        address: pool.token.address
      };
    };

    return {
      mockfarms,
      initialize,
      titles,
      navigateTo,
      showModal,
      openModal,
      modalData,
      openEditPool
    };
  }
};
</script>
<style scoped lang="scss">
.farmDeployer {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 15px;
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;

    h1 {
      font-size: 40px;
      font-weight: 700;
    }

    .button {
      height: 60px;
      padding-right: 40px;
      padding-left: 40px;
      border-radius: 30px;
      font-size: 20px;
      color: var(--background-color);
      background-color: var(--text-color-secondary);
    }
  }

  .farm {
    padding: 40px 0px;
    .farmHeader {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .title {
        margin-right: 20px;
        font-size: 26px;
        font-weight: 700;
      }
    }

    .farmTable {
      width: 100%;
      margin-bottom: 20px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(var(--text-color-rgb), 0.2);
      border-bottom: none;

      .header {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);

        .title {
          font-weight: 600;
          display: flex;
          padding: 20px;
          align-items: center;
        }
      }
    }
  }
}
</style>
