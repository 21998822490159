<template>
  <div class="claimPage">
    <ClaimCard />
  </div>
</template>
<script>
import ClaimCard from '/src/components/pages/claim/ClaimCard';
export default {
  components: {
    ClaimCard
  }
};
</script>
<style scoped lang="scss">
.claimPage {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
}
</style>
