<template>
  <div class="activity__content-container">
    <div class="activity__filters activity__filters-desktop">
      <transition-group name="fade" mode="out-in">
        <Button
          class="activity__filter"
          :class="{
            activity__filter_selected: tabFilters[activeTab].includes(filter),
          }"
          v-for="filter in filters[activeTab].filters"
          :key="filter"
          isSecondary
          @click="selectFilter(filter)"
        >
          <img
            :src="
              require(`@/images/social/icons/activity/${filterHashMap[filter]}.svg`)
            "
            class="activity__filter-image"
            alt=""
          />
          {{ splitFilter(filter) }}
        </Button>
        <BaseDropdown v-if="filters[activeTab].price" :bgShadow="false">
          <template v-slot:default="slotProps">
            <Button class="activity__filter activity__filter-dropdown" isSecondary :class="{'activity__filter-dropdown_opened': slotProps.opened}">
              <span style="margin-right: 10px">
                Price ({{ filters[activeTab].price.from }} -
                {{ filters[activeTab].price.to }} ETH)
              </span>
              <img src="@/images/dropdown-chevron.svg" alt="" />
            </Button>
          </template>
          <template #dropdown>
            <FilterDropdown />
          </template>
        </BaseDropdown>
      </transition-group>
    </div>
    <div class="activity__filters activity__filters-mobile">
      <Button class="activity__filter activity__filter-dropdown filter-button" isSecondary @click="filterModal = true" v-if="$route.params.name !== 'watchlist'">
        <img
          src="@/images/social/icons/activity/filter.svg"
          alt=""
          class="activity__filter-image"
        />
        Filter
      </Button>
      <FilterModal
        v-model="filterModal"
        name="filterModal"
        class="filter-modal"
        :filterTypesNumber="Object.keys(filters[activeTab]).length"
      >
        <template #header-1> Activity Type </template>
        <template #body-1>
          <div class="filter-modal__content">
            <Button
              class="filter-modal__filter"
              :class="{
                'filter-modal__filter_selected': tabFilters[activeTab].includes(
                  filter
                ),
              }"
              v-for="filter in filters[activeTab].filters"
              :key="filter"
              isSecondary
              @click="selectFilter(filter)"
            >
              <img
                :src="
                  require(`@/images/social/icons/activity/${filterHashMap[filter]}.svg`)
                "
                class="activity__filter-image"
                alt=""
              />
              {{ splitFilter(filter) }}
            </Button>
          </div>
        </template>

        <template #header-2>
          Price ({{ filters[activeTab].price.from }} -
              {{ filters[activeTab].price.to }} ETH)
        </template>
        <template #body-2>
          <FilterDropdown />
        </template>
      </FilterModal>
    </div>
    <transition name="fade" mode="out-in">
      <div class="activity__skeletor-cards" v-if="isLoading">
        <div class="activity__skeletor-card" v-for="i in 5" :key="i">
          <Skeletor circle width="60" height="60" style="flex: 1 0 auto" />
          <Skeletor />
        </div>
      </div>
      <div class="activity__content" v-else>
        <ActivityCard
          v-for="activity in activities"
          :key="activity.id"
          :activity="activity"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import ActivityCard from "../activity-card/ActivityCard";
import Button from "@/components/ui/Button";
import { mapState, mapActions } from "vuex";
import FilterModal from "../filter-modal/FilterModal";
import {BaseDropdown} from "@/components/ui/baseDropdown";
import FilterDropdown from "../filter-dropdown/FilterDropdown.vue";

export default {
  name: "ActivityContent",
  components: {
    ActivityCard,
    Skeletor,
    Button,
    FilterModal,
    BaseDropdown,
    FilterDropdown
  },
  methods: {
    ...mapActions("activity", [
      "setActivitiesFilter",
      "clearActivitiesFilters",
    ]),
    splitFilter(filter) {
      let wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
      return filter.match(wordRe).join(" ");
    },
    selectFilter(filter) {
      this.setActivitiesFilter({
        tab: this.activeTab,
        filter,
      });
    },
  },
  data() {
    return {
      filters: {
        global: {
          filters: [
            "Listings",
            "Auctions",
            "Bids",
            "Sales",
            "Transfers",
            "OffersMade",
          ],
          // price: {
          //   from: 0.1,
          //   to: 6,
          // },
        },
        following: {
          filters: [
            "Listings",
            "Auctions",
            "Bids",
            "Sales",
            "Transfers",
            "OffersMade",
          ],
          // price: {
          //   from: 0.13,
          //   to: 2,
          // },
        },
        myactivity: {
          filters: [
            "Listings",
            "Auctions",
            "Bids",
            "Purchases",
            "Sales",
            "Transfers",
            "Burns",
            "OffersMade",
            "OffersReceived",
          ]
        },
        watchlist: {
        },
      },
      filterHashMap: {
        Listings: "listing",
        Auctions: "auction",
        Bids: "bid",
        Purchases: "purchase",
        Sales: "sale",
        Transfers: "transfer",
        Burns: "burn",
        OffersMade: "offer_made",
        OffersReceived: "offer_receive",
      },
      filterModal: false,
    };
  },
  computed: {
    ...mapState("activity", {
      activities: (state) => state.activities,
      isLoading: (state) => state.isLoading,
      tabFilters: (state) => state.filters,
    }),
    activeTab() {
      return this.$route.params.name;
    },
  },
};
</script>

<style src="../../ActivityPage.scss" lang="scss" scoped></style>
