<template>
  <div class='item' :class='{"active": isActive, "error": isError}'>
    <slot/>
  </div>
</template>

<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
        required: false
      },
      isError: {
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped lang='scss'>
.item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  color: var(--text-color);
  transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);
  border-radius: 24px;

  &.error {
    color: $red;
  }

  @include media-min-width($largeScreen) {
    &.error:hover {
      background-color: rgba(241, 40, 72, .1);
    }

    &:hover {
      background-color: rgba(var(--background-color-rgb-reverse), 0.1);
    }
  }

  &.active {
    background-color: rgba(var(--background-color-rgb-reverse), .05);
  }
}
</style>