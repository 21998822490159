<template>
  <Select :options='options'
          :modelValue='modelValue'
          contentWidth='100%'
          @update:modelValue='handleChange'>
    <template #icon><ChevronUpDown/></template>
  </Select>
</template>

<script>
  import {Select} from '@/components/ui/select'
  import {ChevronUpDown} from '@/components/icons'

  import {sortByOptions} from './data'

  export default {
    components: {ChevronUpDown, Select},
    props: {
      modelValue: {
        type: Object,
        required: false,
        default: sortByOptions[0]
      },
      options: {
        type: Array,
        required: false,
        default: sortByOptions
      }
    },
    methods: {
      handleChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>
