<template>
  <div class="quantityWrapper" @click.stop>
    <div class="quantitySelector">
      <button class="button" @click="decrement">-</button>
      <div class="">
        <input type="number" class="input" @input="handleInput" :value="modelValue" min="1" />
      </div>
      <button class="button" @click="increment">+</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      content: this.modelValue
    };
  },
  methods: {
    handleInput(e) {
      if (e.target.value === '') this.$emit('update:modelValue', 0);
      else this.$emit('update:modelValue', parseInt(e.target.value));
    },
    increment() {
      this.content = this.modelValue;
      this.content = parseInt(this.content) + 1;
      this.$emit('update:modelValue', this.content);
    },
    decrement() {
      if (this.content > 0) {
        this.content = this.modelValue;
        this.content = parseInt(this.content) - 1;
        this.$emit('update:modelValue', this.content);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.quantityWrapper {
  padding: 5px;
}
.quantity {
  margin-bottom: 5px;
  text-align: left;
  font-size: 14px;
  line-height: 1.3em;
  color: rgba(var(--text-color-rgb), 0.8);
}
.quantitySelector {
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    width: 40px;
    height: 40px;
    padding: 10px 15px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(var(--text-color-rgb), 0.2);
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: rgba(var(--text-color-rgb), 0.8);
    text-decoration: none;
    background-color: rgba(var(--background-color-rgb), 0.8);
    cursor: pointer;
  }
  .button::selection {
    color: none;
    background: none;
  }
  .input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgba(var(--text-color-rgb), 1);
    text-align: center;
    background-color: rgba(var(--background-color-rgb), 0.8);
    border: 1px solid rgba(var(--text-color-rgb), 0.3);
    height: 40px;
    margin-bottom: 0px;
    outline: none;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
