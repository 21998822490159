<template>
  <div>
    <Filters name='in-wallet-section'/>
    <WalletItems :is-show-dropdown-toggle='!isPublic'
                 :items='items'
                 card-state='onAuction'
                 :on-pin='setPin'/>
  </div>
</template>

<script>
  import {reactive, provide, watch} from 'vue'
  import {mapActions, mapGetters} from 'vuex'

  import WalletItems from '@/components/pages/my-items/WalletItems'
  import {sortByOptions} from '@/components/common/filters'

  import Filters from '../components/Filters'

  export default {
    components: {WalletItems, Filters},
    computed: {
      ...mapGetters('profile', ['isPublic'])
    },
    methods: {
      ...mapActions('profile', ['setPin'])
    },
    data() {
      return {
        items: [
          {
            id: '1',
            isPinned: true,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb935738e434e6050ead7_nft-2.jpg',
              state: 'notListed'
            }
          },
          {
            id: '2',
            isPinned: true,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb938dbb61df64aa0a583_nft.jpg',
              state: 'notListed'
            }
          },
          {
            id: '3',
            isPinned: true,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a0d743a3b854604a2_nft.png',
              state: 'onAuction'
            }
          },
          {
            id: '4',
            isPinned: true,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb9362793fe221848f607_nft-1.jpg',
              state: 'onSale'
            }
          },
          {
            id: '5',
            isPinned: true,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a64f683fa80daa6fa_nft-1.png',
              state: 'notListed'
            }
          },
          {
            id: '6',
            isPinned: false,
            metadata: {
              name: 'NFT Name Goes Here',
              collection: 'Collection Name',
              image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bbdcfe183a2f741ea9f1f_nft-3.png',
              state: 'notListed'
            }
          }
        ]
      }
    },
    setup() {
      const stateData = reactive({
        filters: {
          search: '',
          status: [],
          price: {
            from: '',
            to: ''
          },
          currency: null,
          collections: [],
          sort_by: sortByOptions[0]
        },
        page: 1
      })

      const setExactFilter = data => stateData.filters[data.type] = data.value

      const setAllFilters = values => {
        Object.keys(values).forEach(key => {
          if (values.hasOwnProperty(key)) {
            setExactFilter({type: key, value: values[key]})
          }
        })
      }

      provide('in-wallet-section', {
        filters: stateData.filters,
        setExactFilter,
        setAllFilters
      })

      watch(stateData.filters, (newValue, oldValue) => {
        // Todo api request
      })
    }
  }
</script>
