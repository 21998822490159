'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = () => ({
  currentHighBid: 0,
  currentHighBidder: '',
  highBidTime: 0,
	userIsHighBidder: false,
  userIsBeneficiary: false,
  endTime: 0,
  isFinished: false,
  minimumBid: 0,
  suggestedBid: 0,
  bidCount: 0,
  ethPrice: 0,
  bidHistory: []
});

// Return the module prepared for use.
export default {
	namespaced: true,
	state,
	actions,
	mutations
};
