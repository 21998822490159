<template>
  <h3 class="social__title">Websites and Social Links</h3>
  <div class="social__wrap">
    <web-site/>
    <social/>
  </div>
</template>

<script>
import WebSite from "./link-field/WebSite";
import Social from "./link-field/Social";

export default {
  components: {
    WebSite,
    Social
  }
}
</script>

<style lang="scss" scoped>
.social__title {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.social__wrap {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
  border-top: 1px solid rgba(0, 0, 0, 20%);
  border-bottom: 1px solid rgba(0, 0, 0, 20%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button:first-child {
    margin-bottom: 40px;
  }
}
</style>