<template>
  <FilterHeader :on-clear='onResetForm'/>

  <ModalBody>
    <Accordion>
      <AccordionItem title='Status' v-if="!notShowFilters.includes('Status')">
        <SelectListButtons :modelValue='form.values["status"]'
                           :options='statusOptions'
                           @update:modelValue='$value => form.setFieldValue("status", $value)'/>
      </AccordionItem>

      <AccordionItem title='Price' v-if="isShowFromTo && !notShowFilters.includes('Price')">
        <FromToFields :names='{from: "from", to: "to"}' isShowErrors/>
      </AccordionItem>

      <AccordionItem title='Currency' v-if="!notShowFilters.includes('Currency')">
        <SelectList isClearable
                    :options='currencyOptions'
                    :modelValue='form.values["currency"]'
                    @update:modelValue='$value => form.setFieldValue("currency", $value)'/>
      </AccordionItem>

      <AccordionItem title='Collections' v-if="!notShowFilters.includes('Collections')">
        <CollectionsList :modelValue='form.values["collections"]'/>
      </AccordionItem>
    </Accordion>
  </ModalBody>

  <ModalFooter>
    <Button class='accept-action' @click='onSubmit'>View 15 Items</Button>
  </ModalFooter>
</template>

<script>
  import {inject} from 'vue'
  import {useForm, useField} from 'vee-validate'

  import {FilterHeader, ModalBody, ModalFooter} from '@/components/common/modal'
  import {Accordion, AccordionItem} from '@/components/ui/accordion'
  import {SelectList, SelectListButtons} from '@/components/ui/select'
  import Button from '@/components/ui/Button'

  import {currencyOptions, statusOptions} from './data'
  import {fromToValidationSchema, FromToFields} from './fromTo'
  import CollectionsList from './CollectionsList'

  const prepareInitialValues = values => {
    const {price, collections, sort_by, ...rest} = values

    return {
      ...rest,
      from: price.from,
      to: price.to,
      collections: collections ? [...collections] : []
    }
  }

  export default {
    components: {
      FilterHeader, ModalBody,
      ModalFooter, Accordion,
      AccordionItem, SelectList,
      Button, CollectionsList,
      FromToFields, SelectListButtons
    },
    props: {
      initialValues: {
        type: Object,
        required: true
      },
      minMaxValues: {
        type: Object,
        required: true
      },
      isShowFromTo: {
        type: Boolean,
        default: true,
        required: false
      },
      notShowFilters: {
        type: Array,
        default: () => [],
        required: false
      },
      onAccept: {
        type: Function,
      }
    },
    data: () => ({
      currencyOptions,
      statusOptions
    }),
    setup(props) {
      const form = useForm({
        initialValues: prepareInitialValues(props.initialValues),
        validationSchema: props.isShowFromTo
            ? fromToValidationSchema(props.minMaxValues.min, props.minMaxValues.max)
            : undefined
      })

      useField('status')
      useField('currency')
      useField('collections')

      const {close} = inject('filterContainer', {
        close: () => {}
      })

      const onSubmit = form.handleSubmit(values => {
        close()

        const {from, to, ...rest} = values

        props?.onAccept({
          ...rest,
          price: {from, to}
        })
      })

      const onResetForm = () => form.resetForm({
        values: {
          status: [],
          collections: [],
          currency: null,
          from: '',
          to: ''
        }
      })

      return {
        form,
        onSubmit,
        onResetForm
      }
    }
  }
</script>

<style scoped lang='scss'>
  :deep .select-buttons-list {
    display: grid;
    justify-content: center;
    justify-items: stretch;
    grid-auto-flow: row;
    grid-auto-columns: minmax(160px, 1fr);
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  :deep .select-buttons-list-item {
    width: 100%;

    :deep .content-wrapper {
      width: auto;
    }
  }
</style>
