<template>
  <!-- listed -->
  <div v-if="action === 'listed'" class="info-wrapper">
    <TagIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> listed for
      <strong>{{ price }}</strong>
      {{ dateText }}
    </p>
  </div>
  <!-- auctioned -->
  <div v-if="action === 'auctioned'" class="info-wrapper">
    <HammerIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> put on auction for
      <strong>{{ price }}</strong>
      {{ dateText }}
    </p>
  </div>
  <!-- offered -->
  <div v-if="action === 'offered'" class="info-wrapper">
    <HandIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> offered
      <strong>{{ price }}</strong> to
      <strong>{{ receiver }}</strong>
      {{ dateText }}
    </p>
  </div>
  <!-- received offer -->
  <div v-if="action === 'received offer'" class="info-wrapper">
    <HandIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> offered
      <strong>{{ price }}</strong> for your item
      {{ dateText }}
    </p>
  </div>
  <!-- sold -->
  <div v-if="action === 'sold'" class="info-wrapper">
    <CoinsIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> sold for
      <strong>{{ price }}</strong> to
      <strong>{{ receiver }}</strong>
      {{ dateText }}
    </p>
  </div>
  <!-- transferred -->
  <div v-if="action === 'transferred'" class="info-wrapper">
    <TransferredIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> transferred to
      <strong>{{ receiver }}</strong>
      {{ dateText }}
    </p>
  </div>
  <!-- liked -->
  <div v-if="action === 'liked'" class="info-wrapper">
    <LikedIcon />
    <p class="description">you liked this item {{ dateText }}</p>
  </div>
  <!-- bid -->
  <div v-if="action === 'bid'" class="info-wrapper">
    <HammerIcon />
    <p class="description">
      <span :style="senderStyle">{{ formattedSender }}</span> bid
      <strong>{{ price }}</strong> {{ dateText }}
    </p>
  </div>
</template>

<script>
// Imports
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

// Icon imports
import TagIcon from '../../icons/TagIcon';
import HammerIcon from '../../icons/HammerIcon';
import HandIcon from '../../icons/HandIcon';
import CoinsIcon from '../../icons/CoinsIcon';
import TransferredIcon from '../../icons/TransferredIcon';
import LikedIcon from '../../icons/LikedIcon';

export default {
  components: {
    TagIcon,
    HammerIcon,
    HandIcon,
    CoinsIcon,
    TransferredIcon,
    LikedIcon
  },

  props: {
    action: {
      type: String
    },
    price: {
      type: String
    },
    receiver: {
      type: String
    },
    sender: {
      type: String,
      required: false,
      default: ''
    },
    date: {
      type: Date
    }
  },

  computed: {
    dateText() {
      return timeAgo.format(this.date);
    },

    formattedSender() {
      if (this.sender.length > 0) {
        return this.sender;
      } else {
        return 'you';
      }
    },

    senderStyle() {
      if (this.sender.length > 0) {
        return { fontWeight: '600' };
      } else {
        return {};
      }
    }
  }
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  align-items: center;
}

.description {
  margin-left: 10px;
  color: black;
}
</style>
