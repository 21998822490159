<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      d="M78.5499 23.934C70.8046 23.934 59.8623 24.6029 54.2704 30.3719C51.8494 32.871 50.6405 36.9449 50.9544 41.556C50.9951 42.165 51.3875 42.6839 51.9512 42.876C52.5121 43.065 53.1282 42.888 53.5119 42.4231C56.6217 38.646 60.4871 35.6191 65.0093 33.426C65.393 33.237 65.8464 33.222 66.259 33.372C66.6224 33.507 66.9071 33.762 67.0553 34.0891C67.3633 34.7611 67.2646 35.6461 66.2416 36.144C66.1834 36.174 66.1311 36.2131 66.0731 36.2401C66.0528 36.2491 66.0295 36.2461 66.012 36.2551C54.8692 41.6612 49.3908 51.0932 46.9029 60.7921C45.0894 48.6481 41.2589 41.499 37.6405 37.1101C35.0219 33.6062 32.4468 31.7491 30.9589 30.6992C30.6828 30.5041 29.8487 29.9162 29.5028 29.5591C28.9361 28.9741 28.9361 28.0231 29.5028 27.4381C30.0696 26.856 30.9938 26.856 31.6244 27.504C31.7785 27.6451 32.0255 27.8251 32.3394 28.0411L32.5981 28.2211C34.7022 29.709 38.62 32.472 42.0959 38.421C42.4069 38.952 43.0085 39.2371 43.5927 39.123C44.1856 39.015 44.6505 38.5441 44.7669 37.935C45.5254 33.9361 45.1854 28.002 41.7298 24.435C36.1381 18.669 25.1958 18 17.4533 18C16.6511 17.9999 16 18.6719 16 19.4999C16 27.4949 16.6481 38.7899 22.2369 44.562C24.5678 46.968 28.163 48.048 31.7347 48.048C34.6497 48.048 37.5067 47.2949 39.6952 45.9899C42.6799 52.3739 45.0632 61.9109 45.0632 76.5C45.0632 77.328 45.7143 78 46.5164 78C47.3186 78 47.9696 77.328 47.9696 76.5C47.9696 68.361 49.3211 58.5929 54.346 50.5529C56.3601 52.584 59.8274 53.871 63.6695 53.9909C63.8613 53.997 64.0503 53.9999 64.2391 53.9999C68.1423 53.9999 71.592 52.737 73.7631 50.4929C79.3548 44.7209 80 33.4259 80 25.4308C80.0031 24.6029 79.3549 23.934 78.5499 23.934Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
