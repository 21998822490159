<template>
  <ProfileLayout :isLoading='isLoading'>
    <template #loading-skeleton>
      <Skeleton :isHideAddress='false' isRounded/>
    </template>

    <template v-if='collection && collection?.bannerUrl' #banner>
      <img :src='collection.bannerUrl'>
    </template>

    <template #info>
      <Info v-if='collection'/>
      <div class='error-message' v-else>
        <h3>Failed to get information</h3>
      </div>
    </template>

    <TabsRouterContainer :items='tabItems'>
      <template v-slot='{activeTab}'>
        <transition name='fade' mode='out-in'>
          <component :is='renderTabContent(activeTab)'/>
        </transition>
      </template>
    </TabsRouterContainer>
  </ProfileLayout>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import {ProfileLayout, Skeleton} from '@/layouts/profile-layout'
  import {TabsRouterContainer} from '@/components/common/tabs'
  import withProgressBar from '@/utility/mixins/withProgressBar'

  import Info from './components/Info'
  import AllNfts from './tabSections/all-nfts'

  export default {
    mixins: [withProgressBar()],
    components: {
      Info,
      AllNfts,
      Skeleton,
      ProfileLayout,
      TabsRouterContainer
    },
    data() {
      return {
        tabItems: ['All NFTs', 'Activity']
      }
    },
    computed: {
      ...mapGetters('collection', ['collection', 'isLoading'])
    },
    methods: {
      ...mapActions('collection', ['getCollection']),
      renderTabContent(activeTab) {
        switch (activeTab) {
          case 0:
            return AllNfts
          default:
            return null
        }
      }
    },
    async created() {
      await this.progressBar(async () => {
        document.title = 'Collection Page - SuperFarm NFT Platform'

        await this.getCollection(this.$route.params?.id)
      })
    }
  }
</script>

<style scoped lang='scss'>
  .error-message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $errorRed;
  }
</style>
