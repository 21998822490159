<template>
  <div class="item__header">
    <div class="item__picture">
      <Skeletor circle size="40" style="color: red" />
    </div>
    <Skeletor width="52" height="24" pill />
  </div>
  <div class="item__info">
    <h3 class="item__title">
      <Skeletor width="90%" height="25" pill />
    </h3>
    <p class="item__total-raise">
      <Skeletor width="30%" height="36" pill />
    </p>
  </div>
  <div class="item__footer">
    <p class="item__metadata" v-for="count in 3" :key="count">
      <Skeletor
        class="item__metadata-title mb"
        width="65"
        height="18"
        style="margin-bottom: 5px"
        pill
      />
      <Skeletor class="item__metadata-value" width="25" height="18" pill />
    </p>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  components: {
    Skeletor
  }
};
</script>

<style src="../card/Card.scss" lang="scss" scoped></style>
