'use strict';

// Imports.
import { claimService } from '../../services';

// Specify actions that can be performed by this module.
export default {
  async getClaim({ dispatch, commit }) {
    try {
      let claim = await claimService.getClaim();
      commit('updateClaim', claim);
    } catch (error) {
      console.log(error);
      await dispatch('alert/error', error, { root: true });
    }
  },

  async claimSuper({ dispatch }) {
    try {
      await claimService.claimSuper();
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
