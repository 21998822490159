<template>
  <div class="container">
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: center;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >SuperFarm Privacy Policy</span
      >
    </p>
    <p><br /></p>
    <p dir="ltr" style="line-height: 1.2; margin-top: 0pt; margin-bottom: 12pt">
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Last Updated:</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;March 31, 2021.</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >This Privacy Policy is designed to help you understand how SuperFarm
        Foundation &nbsp;(&ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >SuperFarm</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >,&rdquo; &ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >we</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >,&rdquo; &ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >us</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >,&rdquo; and &ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >our</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&rdquo;) collects, uses, and shares your personal information and to
        help you understand and exercise your privacy rights.</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Capitalized terms not defined in this Privacy Policy have the meaning
        set forth in the SuperFarm </span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        ><router-link to="terms">Terms of Service</router-link></span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >.</span
      >
    </p>
    <p><br /></p>
    <p>
      <img
        src="https://docs.google.com/drawings/u/4/d/sExGflNQrbV1w8LL7ncsKtg/image?w=0&h=15&rev=1&ac=1&parent=1etsPgj91kgnuTDxqEq5yOI9q_jp7AHxG"
        width="0"
        height="15"
        style="border: none"
      /><img
        src="https://docs.google.com/drawings/u/4/d/sYXF4WEpXLv42pBImzBx64w/image?w=304&h=15&rev=1&ac=1&parent=1etsPgj91kgnuTDxqEq5yOI9q_jp7AHxG"
        width="304"
        height="15"
        style="border: none"
      />
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SCOPE</span
          >
        </h1>
      </li>
    </ol>
    <h1
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >This Privacy Policy applies to personal information processed by
        SuperFarm on our website (&ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Site</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&rdquo;) including without limitation: the purchase, sale, exchange, or
        display of certain digital assets and/or non-fungible tokens
        (&ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >NFTs</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&rdquo;); and our online services and software provided on or in
        connection with those services that are accessible via the Site
        including the SuperFarm Platform. To make this Privacy Policy easier to
        read, the Site and the aforementioned services are collectively called
        the &ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Services</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >.&rdquo;</span
      >
    </h1>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CHANGES TO OUR PRIVACY POLICY</span
          >
        </h1>
      </li>
    </ol>
    <h1
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We may revise this Privacy Policy from time to time in our sole
        discretion. If there are any material changes to this Privacy Policy, we
        will notify you as required by applicable law. You understand and agree
        that you will be deemed to have accepted the updated Privacy Policy if
        you continue to use our Services after the new Privacy Policy takes
        effect.</span
      >
    </h1>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >PERSONAL INFORMATION WE COLLECT</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The categories of personal information we collect depend on how you
        interact with us, our Services and the requirements of applicable law.
        We collect information that you provide to us, information we obtain
        automatically when you use our Services, and information from other
        sources such as third-party services and organizations, as described
        below.</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Information You Provide to Us Directly</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We may collect the following personal information that you provide to
        us.</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Account Creation</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may collect information when you create a SuperFarm account,
            such as name and email address. We may also require that you provide
            additional identity and verification information.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Wallet and Transaction Information.&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >In order to engage in transactions on the Services, you will need
            to provide us with access to or information about your crypto
            wallet. As you use the Services, we may collect personal information
            and details associated with the transactions you are involved
            with.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Your Communications with Us</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may collect personal information, such as email address, phone
            number, or mailing address when you request information about our
            Services, register for our newsletter or marketing, request customer
            or technical support, apply for a job or otherwise communicate with
            us.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Interactive Features</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We and others who use our Services may collect personal
            information that you submit or make available through our
            interactive features (e.g., commenting functionalities, forums,
            blogs, and social media pages). Any personal information you provide
            on the public sections of these features will be considered
            &ldquo;public,&rdquo; unless otherwise required by applicable law,
            and is not subject to the privacy protections referenced
            herein.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Surveys</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may contact you to participate in surveys. If you decide to
            participate, you may be asked to provide certain information which
            may include personal information.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Sweepstakes or Contests</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may collect personal information you provide for any
            sweepstakes or contests that we offer. In some jurisdictions, we are
            required to publicly share information of sweepstakes and contest
            winners.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Conferences, Trade Shows, and Other Events.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;We may collect personal information from individuals when we
            attend conferences, trade shows, and other events.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Business Development and Strategic Partnerships.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;We may collect personal information from individuals and
            third parties to assess and pursue potential business
            opportunities.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Job Applications.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;We may post job openings and opportunities on our Services.
            If you reply to one of these postings by submitting your
            application, CV and/or cover letter to us, we will collect and use
            your information to assess your qualifications.</span
          >
        </p>
      </li>
    </ul>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Information Collected Automatically&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We may collect personal information automatically when you use our
        Services:</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Automatic Data Collection</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may collect certain information automatically when you use our
            Services, such as your Internet protocol (IP) address, user
            settings, MAC address, cookie identifiers, mobile carrier, mobile
            advertising and other unique identifiers, browser or device
            information, location information (including approximate location
            derived from IP address), and Internet service provider. We may also
            automatically collect information regarding your use of our
            Services, such as pages that you visit before, during and after
            using our Services, information about the links you click, the types
            of content you interact with, the frequency and duration of your
            activities, and other information about how you use our
            Services.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Cookies, Pixel Tags/Web Beacons, and Other Technologies</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We, as well as third parties that provide content, advertising,
            or other functionality on our Services, may use cookies, pixel tags,
            local storage, and other technologies (&ldquo;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Technologies</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&rdquo;) to automatically collect information through your use of
            our Services.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Cookies</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. Cookies are small text files placed in device browsers that store
            preferences and facilitate and enhance your experience.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Pixel Tags/Web Beacons</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. A pixel tag (also known as a web beacon) is a piece of code
            embedded in our Services that collects information about engagement
            on our Services. The use of a pixel tag allows us to record, for
            example, that a user has visited a particular web page or clicked on
            a particular advertisement. We may also include web beacons in
            e-mails to understand whether messages have been opened, acted on,
            or forwarded.</span
          >
        </p>
      </li>
    </ul>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Our uses of these Technologies fall into the following general
        categories:&nbsp;</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Operationally Necessary</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. This includes Technologies that allow you access to our Services,
            applications, and tools that are required to identify irregular
            website behavior, prevent fraudulent activity and improve security
            or that allow you to make use of our functionality;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Performance-Related</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use Technologies to assess the performance of our
            Services, including as part of our analytic practices to help us
            understand how individuals use our Services (</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: italic;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >see Analytics below</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >);</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Functionality-Related</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use Technologies that allow us to offer you enhanced
            functionality when accessing or using our Services. This may include
            identifying you when you sign into our Services or keeping track of
            your specified preferences, interests, or past items viewed;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: italic;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Advertising- or Targeting-Related</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use first party or third-party Technologies to deliver
            content, including ads relevant to your interests, on our Services
            or on third-party websites.</span
          >
        </p>
      </li>
    </ul>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: italic;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >See Section 6 below to understand your choices regarding these
        Technologies.</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Analytics</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use Technologies and other third-party tools to process
            analytics information on our Services.&nbsp;</span
          >
        </p>
      </li>
    </ul>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Information Collected from Other Sources</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Third-Party Sources.</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;We may obtain information about you from other sources, including
        through third-party services and organizations. For example, if you
        access our Services through a third-party application, such as an app
        store, a third-party login service, or a social networking site, we may
        collect information about you from that third-party application that you
        have made available via your privacy settings.&nbsp;</span
      >
    </p>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >HOW WE USE YOUR INFORMATION</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We use your information for a variety of business purposes, including
        to provide our Services, for administrative purposes, and to market our
        products and Services, as described below.</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Provide Our Services</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We use your information to fulfil our contract with you and provide you
        with our Services, such as:</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Managing your information and accounts;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Providing access to certain areas, functionalities, and features of
            our Services;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Answering requests for customer or technical support;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Communicating with you about your account, activities on our
            Services, and policy changes;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Processing your wallet information to facilitate transactions via
            the Services;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Processing applications if you apply for a job, we post on our
            Services; and&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Allowing you to register for events.</span
          >
        </p>
      </li>
    </ul>
    <p><br /></p>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Administrative Purposes</span
          >
        </p>
      </li>
    </ol>
    <p dir="ltr" style="line-height: 1.2; margin-top: 0pt; margin-bottom: 12pt">
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We use your information for various administrative purposes, such
        as:</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Pursuing our legitimate interests such as direct marketing,
            research and development (including marketing research), network and
            information security, and fraud prevention;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Detecting security incidents, protecting against malicious,
            deceptive, fraudulent or illegal activity, and prosecuting those
            responsible for that activity;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Measuring interest and engagement in our Services;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Improving, upgrading or enhancing our Services;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Developing new products and Services;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Ensuring internal quality control and safety;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Authenticating and verifying individual identities;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Debugging to identify and repair errors with our Services;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Auditing relating to interactions, transactions and other
            compliance activities;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Enforcing our agreements and policies; and</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 6pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Complying with our legal obligations.</span
          >
        </p>
      </li>
    </ul>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 12pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Marketing and Advertising our Products and Services</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 12pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We may use personal information to tailor and provide you with content
        and advertisements. We may provide you with these materials as permitted
        by applicable law.&nbsp;</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Some of the ways we may market to you include email campaigns, custom
        audiences advertising, and &ldquo;interest-based&rdquo; or
        &ldquo;personalized advertising,&rdquo; including through cross-device
        tracking.</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If you have any questions about our marketing practices or if you would
        like to opt out of the use of your personal information for marketing
        purposes, you may contact us at any time as set forth below.&nbsp;</span
      >
    </p>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 12pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Other Purposes</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We also use your information for other purposes as requested by you or
        as permitted by applicable law.</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Consent</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use personal information for other purposes that are
            clearly disclosed to you at the time you provide personal
            information or with your consent.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >De-identified and Aggregated Information.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;We may use personal information and other information about
            you to create de-identified and/or aggregated information, such as
            de-identified demographic information, de-identified location
            information, information about the device from which you access our
            Services, or other analyses we create.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Share Content with Friends or Colleagues.&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Our Services may offer various tools and functionalities. For
            example, we may allow you to provide information about your friends
            or colleagues through our referral services. Our referral services
            may allow you to forward or share certain content with a friend or
            colleague, such as an email inviting your friend to use our
            Services.</span
          >
        </p>
      </li>
    </ul>
    <ol
      start="5"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >HOW WE DISCLOSE YOUR INFORMATION</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We disclose your information to third parties for a variety of business
        purposes, including to provide our Services, to protect us or others, or
        in the event of a major business transaction such as a merger, sale, or
        asset transfer, as described below.&nbsp;</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Disclosures to Provide our Services</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The categories of third parties with whom we may share your information
        are described below.&nbsp;</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Notice Regarding Use of Blockchain.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;If you elect to sell, purchase, or trade any NFTs, any
            financial transactions that you engage in will be conducted solely
            through the blockchain network governing such NFT. Information about
            your transactions will be provided to the applicable blockchain
            network and may be accessible to third parties due to the nature of
            the blockchain.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Other Users of the Services and Parties You Transact With.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;Some of your personal information may be visible other users
            of the Services (e.g., bio information featured on generally
            accessible parts of profile). In addition, to complete transactions
            via the Services, we will need to share some of your personal
            information with the person or company that you are transacting
            with. &nbsp; &nbsp; &nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Third Party Websites and Applications.&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You may choose to share personal information or interact with
            Third-Party Websites and/or Third-Party Applications. Once your
            personal information has been shared with a Third-Party Website or a
            Third-Party Application, it will also be subject to such third
            party&rsquo;s privacy policy. We encourage you to closely read each
            Third-Party Website or Third-Party Application privacy policy before
            sharing your personal information or otherwise interacting with
            them. Please note that we do not control and we are not responsible
            for the Third-Party Website&rsquo;s or the Third-Party
            Application&rsquo;s processing of your personal information.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Service Providers</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may share your personal information with our third-party
            service providers who use that information to help us provide our
            Services. This includes service providers that provide us with IT
            support, hosting, payment processing, customer service, and related
            services.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Business Partners</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may share your personal information with business partners to
            provide you with a product or service you have requested. We may
            also share your personal information to business partners with whom
            we jointly offer products or services.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Affiliates</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may share your personal information with members of our
            corporate family.&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Advertising Partners</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may share your personal information with third-party
            advertising partners. These third-party advertising partners may set
            Technologies and other tracking tools on our Services to collect
            information regarding your activities and your device (e.g., your IP
            address, cookie identifiers, page(s) visited, location, time of
            day). These advertising partners may use this information (and
            similar information collected from other services) for purposes of
            delivering personalized advertisements to you when you visit digital
            properties within their networks. This practice is commonly referred
            to as &ldquo;interest-based advertising&rdquo; or
            &ldquo;personalized advertising.&rdquo;&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >APIs/SDKs</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. We may use third-party application program interfaces
            (&ldquo;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >APIs</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&rdquo;) and software development kits (&ldquo;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SDKs</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&rdquo;) as part of the functionality of our Services. For more
            information about our use of APIs and SDKs, please contact us as set
            forth below.</span
          >
        </p>
      </li>
    </ul>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Disclosures to Protect Us or Others</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We may access, preserve, and disclose any information we store
        associated with you to external parties if we, in good faith, believe
        doing so is required or appropriate to: comply with law enforcement or
        national security requests and legal process, such as a court order or
        subpoena; protect your, our, or others&rsquo; rights, property, or
        safety; enforce our policies or contracts; collect amounts owed to us;
        or assist with an investigation or prosecution of suspected or actual
        illegal activity.</span
      >
    </p>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: upper-alpha;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Disclosure in the Event of Merger, Sale, or Other Asset
            Transfers</span
          >
        </p>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If we are involved in a merger, acquisition, financing due diligence,
        reorganization, bankruptcy, receivership, purchase or sale of assets, or
        transition of service to another provider, your information may be sold
        or transferred as part of such a transaction, as permitted by law and/or
        contract.</span
      >
    </p>
    <ol
      start="6"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >YOUR PRIVACY CHOICES AND RIGHTS</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Your Privacy Choices</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >. The privacy choices you may have about your personal information are
        determined by applicable law and are described below.&nbsp;</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Email Communications</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. If you receive an unwanted email from us, you can use the
            unsubscribe link found at the bottom of the email to opt out of
            receiving future emails. Note that you will continue to receive
            transaction-related emails regarding products or Services you have
            requested. We may also send you certain non-promotional
            communications regarding us and our Services, and you will not be
            able to opt out of those communications (e.g., communications
            regarding our Services or updates to our Terms of Service or this
            Privacy Policy).&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&ldquo;Do Not Track</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&rdquo;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;Do Not Track (&ldquo;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >DNT</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&rdquo;) is a privacy preference that users can set in certain web
            browsers. Please note that we do not respond to or honor DNT signals
            or similar mechanisms transmitted by web browsers.</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Cookies and Interest-Based Advertising</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >. You may stop or restrict the placement of Technologies on your
            device or remove them by adjusting your preferences as your browser
            or device permits. However, if you adjust your preferences, our
            Services may not work properly. Please note that cookie-based
            opt-outs are not effective on mobile applications. However, you may
            opt-out of personalized advertisements on some mobile applications
            by following the instructions for&nbsp;</span
          ><a
            href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
            style="text-decoration: none"
            ><span
              style="
                font-size: 11pt;
                font-family: Calibri, sans-serif;
                color: #0000ff;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: underline;
                -webkit-text-decoration-skip: none;
                text-decoration-skip-ink: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Android</span
            ></a
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >,&nbsp;</span
          ><a
            href="https://support.apple.com/en-us/HT202074"
            style="text-decoration: none"
            ><span
              style="
                font-size: 11pt;
                font-family: Calibri, sans-serif;
                color: #0000ff;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: underline;
                -webkit-text-decoration-skip: none;
                text-decoration-skip-ink: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >iOS</span
            ></a
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;and&nbsp;</span
          ><a
            href="https://www.networkadvertising.org/mobile-choice/"
            style="text-decoration: none"
            ><span
              style="
                font-size: 11pt;
                font-family: Calibri, sans-serif;
                color: #0000ff;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: underline;
                -webkit-text-decoration-skip: none;
                text-decoration-skip-ink: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >others</span
            ></a
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >.</span
          >
        </p>
      </li>
    </ul>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The online advertising industry also provides websites from which you
        may opt out of receiving targeted ads from data partners and other
        advertising partners that participate in self-regulatory programs. You
        can access these and learn more about targeted advertising and consumer
        choice and privacy by visiting the&nbsp;</span
      ><a
        href="http://www.networkadvertising.org/managing/opt_out.asp"
        style="text-decoration: none"
        ><span
          style="
            font-size: 11pt;
            font-family: Calibri, sans-serif;
            color: #0000ff;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            -webkit-text-decoration-skip: none;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Network Advertising Initiative</span
        ></a
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >,&nbsp;</span
      ><a href="http://www.aboutads.info/choices/" style="text-decoration: none"
        ><span
          style="
            font-size: 11pt;
            font-family: Calibri, sans-serif;
            color: #0000ff;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            -webkit-text-decoration-skip: none;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >the Digital Advertising Alliance</span
        ></a
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >,&nbsp;</span
      ><a href="https://www.youronlinechoices.eu/" style="text-decoration: none"
        ><span
          style="
            font-size: 11pt;
            font-family: Calibri, sans-serif;
            color: #0000ff;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            -webkit-text-decoration-skip: none;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >the European Digital Advertising Alliance</span
        ></a
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >, and&nbsp;</span
      ><a href="https://youradchoices.ca/choices/" style="text-decoration: none"
        ><span
          style="
            font-size: 11pt;
            font-family: Calibri, sans-serif;
            color: #0000ff;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            -webkit-text-decoration-skip: none;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >the Digital Advertising Alliance of Canada</span
        ></a
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >. &nbsp;</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Please note you must separately opt out in each browser and on each
        device.&nbsp;</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Your Privacy Rights</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >. In accordance with applicable law, you may have the right to:</span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Access Personal Information&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >about you, including: (i) confirming whether we are processing your
            personal information; (ii) obtaining access to or a copy of your
            personal information; or (iii) receiving an electronic copy of
            personal information that you have provided to us, or asking us to
            send that information to another company (aka the right of data
            portability);</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Request Correction&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >of your personal information where it is inaccurate or incomplete.
            In some cases, we may provide self-service tools that enable you to
            update your personal information;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Request Deletion&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >of your personal information;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Request Restriction of or Object</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;to our processing of your personal information;
            and&nbsp;</span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Withdraw Your Consent&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >to our processing of your personal information.</span
          ><span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;</span
          >
        </p>
      </li>
    </ul>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If you would like to exercise any of these rights, please contact us as
        set forth in Section 15 below. We will process such requests in
        accordance with applicable laws</span
      >
    </p>
    <p><br /></p>
    <ol
      start="7"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SECURITY OF YOUR INFORMATION</span
          >
        </h1>
      </li>
    </ol>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We take steps designed to ensure that your information is treated
        securely and in accordance with this Privacy Policy.</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: italic;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Unfortunately, no system is 100% secure, and we cannot ensure or
        warrant the security of any information you provide to us. To the
        fullest extent permitted by applicable law, we do not accept liability
        for unauthorized disclosure.</span
      >
    </p>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >By using our Services or providing personal information to us, you
        agree that we may communicate with you electronically regarding
        security, privacy, and administrative issues relating to your use of our
        Services. If we learn of a security system&rsquo;s breach, we may
        attempt to notify you electronically by posting a notice on our
        Services, by mail or by sending an email to you.&nbsp;</span
      >
    </p>
    <p><br /></p>
    <ol
      start="8"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 0pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >INTERNATIONAL DATA TRANSFERS</span
          >
        </h1>
      </li>
    </ol>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >All information processed by us may be transferred, processed, and
        stored anywhere in the world, including, but not limited to, the United
        States or other countries, which may have data protection laws that are
        different from the laws where you live. We endeavor to safeguard your
        information consistent with the requirements of applicable laws.</span
      >
    </p>
    <ol
      start="9"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >RETENTION OF PERSONAL INFORMATION&nbsp;</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >We store the personal information we collect as described in this
        Privacy Policy for as long as you use our Services or as necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws. &nbsp;</span
      >
    </p>
    <ol
      start="10"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >This Supplemental California Privacy Policy only applies to our
        processing of personal information that is subject to the California
        Consumer Privacy Act of 2018 (&ldquo;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >CCPA</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&rdquo;). The CCPA provides California residents with the right to know
        what categories of personal information SuperFarm has collected about
        them and whether SuperFarm disclosed that personal information for a
        business purpose (e.g., to a service provider) in the preceding twelve
        months. California residents can find this information below:</span
      >
    </p>
    <p><br /></p>
    <div align="left" dir="ltr" style="margin-left: -2.9pt">
      <table style="border: none; border-collapse: collapse">
        <tbody>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="
                  line-height: 1.2;
                  text-align: center;
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                "
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Category of Personal Information Collected by
                  SuperFarm&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="
                  line-height: 1.26;
                  text-align: center;
                  margin-top: 0pt;
                  margin-bottom: 0pt;
                "
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Categories of Third Parties Personal Information is Disclosed
                  to for a Business Purpose</span
                >
              </p>
            </td>
          </tr>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Identifiers&nbsp;</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <ul
                style="
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-inline-start: 48px;
                "
              >
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Service providers</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Blockchain networks&nbsp;</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Other users or third parties you interact with</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Advertising partners</span
                    >
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Personal information categories listed in Cal. Civ. Code
                  &sect; 1798.80(e)</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <ul
                style="
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-inline-start: 48px;
                "
              >
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Service providers</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Blockchain networks&nbsp;</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Other users or third parties you interact with</span
                    >
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Commercial information</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <ul
                style="
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-inline-start: 48px;
                "
              >
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Service providers</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Blockchain networks&nbsp;</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Other users or third parties you interact with</span
                    >
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Internet or other electronic network activity</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <ul
                style="
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-inline-start: 48px;
                "
              >
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Service providers</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Blockchain networks&nbsp;</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Other users or third parties you interact with</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Advertising partners</span
                    >
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="height: 0pt">
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
              >
                <span
                  style="
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    white-space: pre-wrap;
                  "
                  >Inferences drawn from other personal information to create a
                  profile about a consumer</span
                >
              </p>
            </td>
            <td
              style="
                border-left: solid #bfbfbf 0.5pt;
                border-right: solid #bfbfbf 0.5pt;
                border-bottom: solid #bfbfbf 0.5pt;
                border-top: solid #bfbfbf 0.5pt;
                vertical-align: top;
                padding: 2.9pt 2.9pt 2.9pt 2.9pt;
                overflow: hidden;
                overflow-wrap: break-word;
              "
            >
              <ul
                style="
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-inline-start: 48px;
                "
              >
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Service providers</span
                    >
                  </p>
                </li>
                <li
                  dir="ltr"
                  style="
                    list-style-type: disc;
                    font-size: 11pt;
                    font-family: Calibri, sans-serif;
                    color: #000000;
                    background-color: transparent;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-decoration: none;
                    vertical-align: baseline;
                    white-space: pre;
                    margin-left: -18pt;
                  "
                >
                  <p
                    dir="ltr"
                    style="
                      line-height: 1.26;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      style="
                        font-size: 11pt;
                        font-family: Calibri, sans-serif;
                        color: #000000;
                        background-color: transparent;
                        font-weight: 400;
                        font-style: normal;
                        font-variant: normal;
                        text-decoration: none;
                        vertical-align: baseline;
                        white-space: pre;
                        white-space: pre-wrap;
                      "
                      >Advertising partners</span
                    >
                  </p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The categories of sources from which we collect personal information
        and our business and commercial purposes for using personal information
        are set forth above.&nbsp;</span
      >
    </p>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Additional Privacy Rights for California Residents</span
      >
    </p>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&ldquo;Sales&rdquo; of Personal Information under the CCPA.&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >For purposes of the CCPA, SuperFarm does not &ldquo;sell&rdquo;
        personal information, nor do we have actual knowledge of any
        &ldquo;sale&rdquo; of personal information of minors under 16 years of
        age.</span
      >
    </p>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Non-Discrimination.</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;California residents have the right not to receive discriminatory
        treatment by us for the exercise of their rights conferred by the
        CCPA.</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 14pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Authorized Agent.&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Only you, or someone legally authorized to act on your behalf, may make
        a verifiable consumer request related to your personal information. To
        designate an authorized agent, please contact us as set forth
        below.</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 14pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Verification</span
      ><span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >. When you make a request, we will ask you to provide sufficient
        information that allows us to reasonably verify you are the person about
        whom we collected personal information or an authorized representative,
        which may include confirming the email address associated with any
        personal information we have about you.&nbsp;</span
      >
    </p>
    <p><br /></p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If you are a California resident and would like to exercise any of your
        rights under the CCPA, please contact us as set forth below. We will
        process such requests in accordance with applicable laws.&nbsp;</span
      >
    </p>
    <p><br /></p>
    <ol
      start="11"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If you are a resident of Nevada, you have the right to opt-out of the
        sale of certain personal information to third parties who intend to
        license or sell that personal information. You can exercise this right
        by contacting us as set forth below with the subject line &ldquo;Nevada
        Do Not Sell Request&rdquo; and providing us with your name and the email
        address associated with your account. Please note that we do not
        currently sell your personal information as sales are defined in Nevada
        Revised Statutes Chapter 603A.</span
      >
    </p>
    <ol
      start="12"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 12pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CHILDREN&rsquo;S INFORMATION</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Services are not directed to children under 13 (or other age as
        required by local law), and we do not knowingly collect personal
        information from children. If you learn that your child has provided us
        with personal information without your consent, you may contact us as
        set forth below. &nbsp;If we learn that we have collected a
        child&rsquo;s personal information in violation of applicable law, we
        will promptly take steps to delete such information.</span
      >
    </p>
    <p><br /></p>
    <ol
      start="13"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >THIRD-PARTY WEBSITES/APPLICATIONS</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Services may contain links to other websites/applications and other
        websites/applications may reference or link to our Services. These
        third-party services are not controlled by us. We encourage our users to
        read the privacy policies of each website and application with which
        they interact. We do not endorse, screen or approve, and are not
        responsible for, the privacy practices or content of such other websites
        or applications. Providing personal information to third-party websites
        or applications is at your own risk.&nbsp;</span
      >
    </p>
    <ol
      start="14"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SUPERVISORY AUTHORITY</span
          >
        </h1>
      </li>
    </ol>
    <h1
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If you are located in the European Economic Area, Switzerland, or the
        United Kingdom you have the right to lodge a complaint with a
        supervisory authority if you believe our processing of your personal
        information violates applicable law.</span
      >
    </h1>
    <ol
      start="15"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px"
    >
      <li
        dir="ltr"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <h1
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span
            style="
              font-size: 11pt;
              font-family: Calibri, sans-serif;
              color: #000000;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CONTACT US</span
          >
        </h1>
      </li>
    </ol>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: Calibri, sans-serif;
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;If you have any questions about our privacy practices or this
        Privacy Policy, or to exercise your rights as detailed in this Privacy
        Policy, please contact us at business@superfarm.io</span
      >
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding: 30px 50px;
  max-width: 80vw;
  margin: auto;
}

* {
  color: black !important;
  font-family: Work Sans !important;
}
</style>
