<template>
  <div class='tab-container' :class='{"is-active": isSelected}'>
    {{title}}
  </div>
</template>

<script>
  export default {
    props: ['title', 'isSelected']
  }
</script>

<style lang='scss' scoped>
  /* temporary for the sake of time-- when we have a chance want to migrate both tab components into one */
  .tab-container {
    min-height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: background-color 200ms ease, color 200ms ease;
    color: rgba(var(--text-color-rgb), 0.5);
    text-align: center;
    font-weight: 600;
    white-space: nowrap;

    &:hover {
      background-color: rgba(var(--text-color-rgb), 0.1);
      color: var(--text-color);
    }

    &:active {
      background-color: rgba(var(--text-color-rgb), 0.2);
      color: rgb(var(--text-color-rgb));
    }

    &.is-active {
      box-shadow: inset 0 -3px 0 0 var(--text-color);
      color: var(--text-color);
    }
  }
</style>
