<template>
  <div>
    <div class='fields'>
      <TextField :value='from.value.value'
                 :error-message='from.errorMessage.value'
                 :is-error-message-visible='isShowErrors'
                 :on-change='from.handleChange'/>
      <div>to</div>
      <TextField :value='to.value.value'
                 :error-message='to.errorMessage.value'
                 :is-error-message-visible='isShowErrors'
                 :on-change='to.handleChange'/>
    </div>
    <slot/>
  </div>
</template>

<script>
  import {useField} from 'vee-validate'

  import TextField from '@/components/ui/text-fields/TextField'

  export default {
    components: {TextField},
    props: {
      names: {
        from: {
          type: String,
          required: true
        },
        to: {
          type: String,
          required: true
        }
      },
      isShowErrors: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const from = useField(props.names.from)
      const to = useField(props.names.to)

      from.setErrors('')
      to.setErrors('')

      return {from, to}
    }
  }
</script>

<style scoped lang='scss'>
  .fields {
    display: grid;
    align-items: center;
    grid-auto-columns: 1fr;
    gap: 10px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    margin-bottom: 10px;
    text-align: center;
    color: var(--text-color);
  }
</style>
