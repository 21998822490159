<template>
  <div class="farmTable">
    <div class="header">
      <div class="title" v-for="title in titles" :key="title">{{ title }}</div>
    </div>
    <div class="farms-row-wrapper" v-for="pool in pools" :key="pool.token">
      <div class="farms-row">
        <div class="pair-wrapper">
          <img v-if="pool.pairImgs.length === 1" class="pair-img" :src="pool.pairImgs[0]" />
          <img v-if="pool.pairImgs.length > 1" class="pair-img" :src="require(`@/images/${pool.pairImgs[0]}`)" />
          <img v-if="pool.pairImgs.length > 1" class="pair-img" :src="require(`@/images/${pool.pairImgs[1]}`)" />
          <p class="pair">{{ pool.pair }}</p>
        </div>
        <div class="amount-staked-wrapper">
          <p>
            123
            {{ pool.pair }}
          </p>
        </div>

        <div class="amount-earned-wrapper">
          <div class="amount-earned">
            <p class="amount-earned-value" v-if="showTokenYield(pool)">
              <img class="tokenImage" :src="require(`@/images/${pool.pairImgs[1]}`)" />
              123.45678
            </p>
            <div class="view-store">
              <p class="amount-earned-value link">
                <span class="view-store">{{ pool.point.symbol }}</span>
                123.45678
              </p>
            </div>
          </div>
        </div>

        <div class="yield-wrapper">
          <div v-if="showTokenYield(pool)" class="yield-pair-wrapper">
            <div class="svg-wrapper">
              <img class="tokenImage tokenImage-small" :src="require(`@/images/${pool.pairImgs[1]}`)" />
            </div>
            <div class="yield-container">
              <div class="yield-value">
                123.45678
              </div>
              <div class="yield-label">{{ pool.token.symbol }}/day</div>
            </div>
          </div>
          <div class="yield-pair-wrapper">
            <div class="svg-wrapper">
              <div>
                {{ pool.point.symbol }}
              </div>
            </div>
            <div class="yield-container">
              <div class="yield-value">
                123.45678
              </div>
              <div class="yield-label">{{ pool.point.name }}/day</div>
            </div>
          </div>
        </div>

        <div class="apy-wrapper">
          <p class="apy link" v-if="showTokenYield(pool)">205.67%</p>
          <p v-else>
            N/A
          </p>
        </div>

        <div class="detailsButtonWrapper">
          <Button v-if="pool.status === 'Deployed' || pool.status === 'Started'" :isSecondary="true" class="detailsButton" @click="editPool(pool)">
            <template v-slot:default>
              <div class="button">
                <p class="button-label">Edit Pool</p>
              </div>
            </template>
          </Button>

          <Button v-if="pool.status === 'Deployed'" :isSecondary="true" class="detailsButton">
            <template v-slot:default>
              <div class="button">
                <p class="button-label">Start Pool</p>
              </div>
            </template>
          </Button>

          <Button v-if="pool.status === 'Started'" :isSecondary="true" class="detailsButton">
            <template v-slot:default>
              <div class="button">
                <p class="button-label">Stop Pool</p>
              </div>
            </template>
          </Button>

          <Button v-if="!pool.status" :isSecondary="true" class="detailsButton">
            <template v-slot:default>
              <div class="button">
                <p class="button-label">Details</p>
                <DownCaretIcon />
              </div>
            </template>
          </Button>
        </div>
      </div>

      <!-- mobile details button -->
      <Button :isSecondary="true" class="detailsButton__mobile">
        <template v-slot:default>
          <div class="button">
            <p class="button-label">Details</p>
            <div class="button-icon" :class="showDetails ? 'button-icon-open' : ''">
              <DownCaretIcon />
            </div>
          </div>
        </template>
      </Button>
      <Button class="detailsButton__mobile">
        <template v-slot:default>
          <div class="view-store-button">View {{ pool.point.name }} Store</div>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { BigNumber, ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component Imports
import Button from '../../ui/Button';

// Icon Imports
import DownCaretIcon from '../../icons/DownCaretIcon';

export default {
  props: {
    pools: {
      type: Object,
      required: true
    }
  },

  components: {
    DownCaretIcon,
    Button
  },

  emits: ['openModal'],

  data() {
    return {
      ethers,
      formatNumber,
      showDetails: false,
      showModal: false,
      modalData: {},
      titles: ['Pool', 'Total Staked', 'Total Earned', 'Estimated Yield Per $1000', 'APY']
    };
  },

  methods: {
    showTokenYield(pool) {
      return pool.isLp;
    },

    editPool(pool) {
      this.$emit('openModal', pool);
    }
  }
};
</script>

<style scoped lang="scss">
.farmTable {
  width: 100%;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-bottom: none;

  .header {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);

    .title {
      font-weight: 600;
      display: flex;
      padding: 20px;
      align-items: center;
    }
  }
}

.farms-row-wrapper {
  padding: 20px;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.farms-row-wrapper:hover {
  cursor: pointer;
  background-color: rgba(var(--text-color-rgb), 0.025);
}

.farms-row-wrapper:active {
  background-color: rgba(var(--text-color-rgb), 0.075);
}

.farms-row {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.farms-row > * {
  margin-left: 5px;
}

.pair-wrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.pair-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: -16px;
}

.pair {
  margin-left: 10px;
}

.amount-earned {
  margin-bottom: 2px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
}

.amount-earned-value {
  white-space: nowrap;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 5px;
  border-radius: 50px;
  padding: 10px;
}

.link:hover {
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.link:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.view-store {
  text-decoration: none;
  font-weight: 700;
  color: var(--text-color);
  width: fit-content;
}

.view-store:hover {
  cursor: pointer;
}

.view-store-button {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.growth-wrapper {
  display: flex;
  align-items: center;
}

.growth-value {
  margin-right: 10px;
  font-weight: 700;
}

.question-icon {
  opacity: 0.5;
  cursor: pointer;
}

.yield-wrapper {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
}

.yield-pair-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0px;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
}

.yield-pair-wrapper:hover {
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.svg-wrapper {
  display: flex;
  margin-right: 10px;
}

.yield-label {
  opacity: 0.5;
  font-size: 12px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-label {
  margin-right: 10px;
}

.button-icon {
  transition: transform 0.5s ease;
  display: flex;
}

.button-icon-open {
  transform: rotateZ(-180deg);
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0px;
}

.rowDetails {
  height: 173px;
}

.detailsButton__mobile {
  display: none;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.super-icon {
  max-width: 15px;
  max-height: 20px;
  margin-right: 2px;
}

.apy-wrapper {
  width: fit-content;
}

.apy {
  width: fit-content;
  padding: 10px;
  border-radius: 50px;
}

.apy:hover {
  cursor: pointer;
}

.tokenImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tokenImage-small {
  width: 15px;
  height: 15px;
}

.yield-container {
  cursor: pointer;
}

.detailsButtonWrapper {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
  width: 100%;
}

@media (max-width: 971px) {
  .detailsButton {
    display: none;
  }

  .detailsButton__mobile {
    display: initial;
  }

  .farms-row-wrapper {
    padding: 20px 10px;
  }

  .farms-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
}

@media (max-width: 520px) {
  .pair-img {
    height: 25px;
    width: 25px;
  }
  .pair,
  .amount-staked-wrapper,
  .amount-earned-value,
  .yield-wrapper,
  .yield-label {
    font-size: 11px;
  }

  .svg-wrapper {
    display: none;
  }

  .yield-pair-wrapper {
    padding: 5px 0px;
  }

  .yield-pair-wrapper:hover {
    background-color: initial;
  }

  .apy-wrapper {
    font-size: 10px;
  }
}
</style>
