<template>
  <div
    class="drop-section"
    :style="{
      'background-image': 'url(' + require(`@/images/dropday/${drop.bannerImage}`) + ')'
    }"
  >
    <div class="backdrop"></div>
    <video autoplay loop muted playsinline="" data-object-fit="cover" class="video-container">
      <source :src="require(`@/images/dropday/${drop.bannerImage}`)" />
    </video>
    <div class="drop-details">
      <!-- <video></video> -->

      <div class="drop-details-wrapper">
        <div class="drop-banner-creator">
          {{ drop.username }}
        </div>
        <div class="drop-banner-title">
          {{ drop.title }}
        </div>
        <div v-if="isLive">
          <LiveNowTimer :date="drop.endDate" />
        </div>
        <div v-else class="countdown-card">
          <DropsCoundownCard :startDate="drop.startDate" :endDate="drop.endDate" />
        </div>
        <div class="stats-wrapper">
          <div class="stat-wrapper" v-for="stat in drop.metadata" :key="stat">
            <div class="stat">{{ stat.value }}</div>
            <div class="stat-type">{{ stat.label }}</div>
          </div>
        </div>
        <div class="drop-description">
          {{ drop.description }}
        </div>
        <div v-if="isLive" class="total-remaining">
          <strong>{{ remaining }}</strong>
          <span class="remaining-label">{{ remainingLabel }}</span>
        </div>
        <ProgressBar v-if="isLive" class="" :total="total" :remaining="remaining" />
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

import LiveNowTimer from '../drops/LiveNowTimer';
import DropsCoundownCard from '../drops/DropsCountdownCard';
import ProgressBar from '../../ui/ProgressBar';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';

export default {
  components: {
    LiveNowTimer,
    DropsCoundownCard,
    ProgressBar
  },

  props: {
    drop: {
      type: Object
    }
  },

  setup(props) {
    const store = useStore();

    const remainingLabel = computed(() => {
      if (isLive.value) {
        return 'remaining';
      } else {
        return 'total';
      }
    });

    const isLive = computed(() => {
      return props.drop.startDate < new Date() && props.drop.endDate > new Date();
    });

    const pools = computed(() => {
      return store.state.items.launchpadItemList.pools;
    });

    const total = computed(() => {
      let count = ethers.BigNumber.from(0);
      if (!pools.value) return count;
      console.log(pools.value);
      for (const pool of pools.value) {
        for (const item of pool.items) {
          count = count.add(item.cap);
        }
      }
      return count.toNumber();
    });

    const remaining = computed(() => {
      let count = ethers.BigNumber.from(0);
      if (!pools.value) return count.toNumber();
      console.log(pools.value);
      for (const pool of pools.value) {
        for (const item of pool.items) {
          count = count.add(item.minted);
        }
      }
      return total.value - count.toNumber();
    });

    return { remainingLabel, isLive, total, remaining };
  }
};
</script>

<style scoped>
.drop-section {
  display: flex;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  display: flex;
  height: auto;
  min-height: 500px;
  margin-bottom: 1px;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  color: #fff;
  text-align: center;
  position: relative;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.drop-details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 2;
}

.drop-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-banner-creator {
  margin-bottom: 10px;
  opacity: 0.5;
  line-height: 1em;
  font-size: 30px;
}

.drop-banner-title {
  margin-bottom: 20px;
  font-size: 70px;
  line-height: 1em;
  font-weight: 500;
}

.live-now {
  display: block;
  margin-bottom: 5px;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: #f12848;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.end-in {
  margin-bottom: 20px;
}

.stats-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.stat-wrapper {
  display: flex;
  padding: 5px 10px;
}

.stat {
  margin-right: 5px;
  font-weight: 700;
}

.stat-type {
  opacity: 0.5;
}

.drop-description {
  max-width: 60ch;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.total-remaining {
  margin-bottom: 10px;
}

.remaining-label {
  margin-left: 5px;
  opacity: 0.5;
}

.countdown-card {
  margin-bottom: 20px;
}

.video-container {
  background-size: cover;
  background-position: 100% 100%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  object-position: 0px 15%;
}
</style>
