<template>
  <div>
    <CardModal/>

    <template v-if='!isLoading'>
      <section class='banner-section container'>
        <div class='banner-container'>
          <slot name='banner'/>
        </div>
      </section>

      <section class='section'>
        <div class='container'>
          <slot name='info'/>
        </div>
      </section>
    </template>

    <slot v-else name='loading-skeleton'/>

    <slot name='below-info'/>

    <section class='section'>
      <div class='container'>
        <slot/>
      </div>
    </section>
  </div>
</template>

<script>
  import CardModal from '@/components/nft-detail-modal/CardModal'

  export default {
    components: {
      CardModal
    },
    props: {
      isLoading: {
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped lang='scss'>
  .banner-container {
    padding-bottom: 16.625%;
    min-height: 140px;
    position: relative;

    & > :deep img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
      object-fit: cover;
    }
  }

  .section {
    padding: 30px 20px;

    @include media-max-width($smallScreen) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
</style>
