<template>
  <div class="card" :class="{ large: isLarge }" @mouseover="mouseEnter" @mouseleave="mouseLeave">
    <!-- <TransferModal
      :isOpen="openModal === 'transfer'"
      @exit="closeModal"
      @click.stop=""
      @mouseover.stop=""
    />
    <ListItemModal
      :isOpen="openModal === 'listItem'"
      @exit="closeModal"
      @click.stop=""
      @mouseover.stop=""
    />
    -->

    <transition>
      <div v-show="isShowDropdownToggle && showDropdownToggle" class="toggle-button">
        <BaseNFTCardToggle :state="item.metadata.state" :is-pinned="item?.isPinned || false" @onPin="onPin" @triggerModal="handleTriggerModal($event)" />
      </div>
    </transition>

    <slot name="header" />
    <div class="img-container">
      <img v-if="mediaType === 'png' || mediaType === 'jpg'" :src="item.metadata.image" />
      <video class="video" v-if="mediaType === 'mp4'" loop muted ref="video" :key="item.metadata.video">
        <!-- TODO support animation_url as fallback to video. -->
        <!-- TODO: no video on card preview -->
        <source :src="item.metadata.video" :type="`video/${mediaType}`" />
      </video>
      <!-- Skeleton Loader here. -->
    </div>
    <div class="card-content">
      <slot name="info"></slot>
      <slot name="price"></slot>
    </div>
    <!-- <div class="card-info">
      <div class="card-info-top">
        <div class="card-names">
          <p class="collection-name">{{ item.metadata.collection }}</p>
          <p class="nft-name">{{ item.metadata.name }}</p>
        </div>
        <div
          v-if="transferEnabled"
          @click.prevent="toggleDropdown"
          class="dropdown-button"
        >
          <img src="../images/dots.svg" />
        </div>
        <div class="dropdown-menu" v-if="showDropdown">
          <div class="context-menu-item" @click="toggleModal">
            <svg
              width="13"
              height="15"
              viewbox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.70711 0.792908L0.707107 3.79291L0 4.50001L0.707107 5.20712L3.70711 8.20712L5.12132 6.79291L3.82843 5.50001H10.9142V3.50001H3.82843L5.12132 2.20712L3.70711 0.792908ZM8.5 11.5L7.20711 12.7929L8.62132 14.2071L11.6213 11.2071L12.3284 10.5L11.6213 9.79291L8.62132 6.79291L7.20711 8.20712L8.5 9.50001H1.41421V11.5H8.5Z"
                fill="currentColor"
              ></path>
            </svg>
            <div class="context-menu-text">Transfer</div>
          </div>
        </div>
      </div>
      <div class="card-info-bottom"></div>
    </div> -->
  </div>
</template>
<script>
'use strict';
// Component imports.
import TransferModal from '../my-items-modal/TransferModal';
import ListItemModal from '../my-items-modal/ListItemModal';
import BaseNFTCardToggle from './BaseNFTCardToggle';
// Set up the default component.
export default {
  components: {
    // TransferModal,
    // ListItemModal,
    BaseNFTCardToggle
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isShowDropdownToggle: {
      type: Boolean,
      required: false
    },
    isLarge: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      nftName: 'NFT Name',
      showDropdown: false,
      showModal: false,
      transferEnabled: false,
      showDropdownToggle: false,
      openModal: ''
    };
  },
  emits: ['onPin'],
  computed: {
    mediaType() {
      let video = this.item.metadata.video;
      let animationUrl = this.item.metadata['animation_url'];
      let image = this.item.metadata.image;
      if (video) {
        let videoPath = this.item.metadata.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.item.metadata['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.item.metadata.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    }
  },
  methods: {
    onPin(type) {
      this.$emit('onPin', { type, item: this.item });
    },
    handleTriggerModal(event) {
      this.openModal = event.type;
      setTimeout(() => {
        this.showDropdownToggle = false;
      }, 500);
    },
    closeModal() {
      this.openModal = '';
    },
    mouseEnter() {
      this.showDropdownToggle = true;
      if (this.$refs.video) {
        this.$refs.video.play();
      }
    },
    mouseLeave() {
      this.showDropdownToggle = false;
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    }
  },
  provide() {
    return {
      nft: this.item
    };
  }
};
</script>
<style scoped>
.video {
  width: 100%;
  max-width: 300px;
}
.card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-style: none;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: var(--background-color-nft);
  box-shadow: 0 2px 8px 0 rgba(var(--text-color-rgb), 0.1);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  text-decoration: none;
  cursor: pointer;
  max-width: 500px;
  margin: auto;
  height: 100%;
}
.card:hover {
  box-shadow: 0 14px 40px 0 rgba(var(--text-color-rgb), 0.25);
}
.card-info-top {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 304px;
}
.img-container > * {
  max-height: 304px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.card-content {
  padding: 20px;
}
.toggle-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity ease 0.1s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.large .img-container {
  width: 100%;
}
.large .img-container {
  height: auto;
}
.large .img-container > * {
  max-height: none;
  max-width: initial;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
