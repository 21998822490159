'use strict';

// Format a number with place delimiters.
import * as Url from 'url'

export const formatNumber = function(value, decimals = 6) {
  value = value.toString().split('.');
  value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
  if (value[1]) {
    value[1] = value[1].substring(0, decimals);
    value[1] = value[1].padEnd(decimals, '0');
  } else {
    value[1] = ''.padEnd(decimals, '0');
  }
  value = value.join('.');
  if (decimals === 0) {
    value = value.substring(0, value.length - 1);
  }
  return value;
};

// Format an address to have an ellipsis in the middle
export const formatAddress = function(address) {
  const length = address.length;
  if (length > 0) {
    const shortenedAddress =
      address.substring(0, 6) + '...' + address.substring(length - 4);
    return shortenedAddress;
  }
  return '';
};

export const abbreviateNumber = function(value) {
  if (value < 1000) {
    return value;
  }
  var si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (value >= si[i].v) {
      break;
    }
  }
  return (
    (value / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    si[i].s
  );
};

export const formatUrl = rawUrl => rawUrl?.replace(/(^\w+:|^|)(\/)/g, '') || ''
