'use strict';

import localStorage from '@/services/local-storage.service'

// Export state mutation functions.
export default {
  setDarkMode(state, value) {
    state.isDarkMode = value;

    localStorage.set('isDarkMode', value)
  }
};
