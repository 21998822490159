<template>
  <div class="appContainer" :class="superBackground">
    <div class="main">
      <feature-inline-guard requiredFlag="layout.header.new" v-if="$flags.variation('layout.header.new', false)">
        <NewHeader :style="headerStyles" />
      </feature-inline-guard>
      <Header :headerStyles="headerStyles" v-else />
      <router-view />
    </div>
    <TheFooter :style="footerStyles" />
  </div>
</template>

<script>
import Header from './components/Header';
import NewHeader from './components/new-header';

import FeatureInlineGuard from '@/components/utility/FeatureInlineGuard.vue';

export default {
  components: {
    Header,
    NewHeader,
    FeatureInlineGuard
  },
  computed: {
    headerStyles() {
      switch (this.$route.name) {
        case 'Home':
        case 'Farms':
          return {
            backgroundColor: 'transparent',
            position: 'absolute',
            borderBottom: 'none'
          };
        case 'Help Center':
          return {
            backgroundColor: 'transparent',
            position: 'absolute',
            borderBottom: 'none'
          };
        case 'LiquidityProgram':
          return {
            backgroundColor: styles.bgViolet
          };
        default:
          return {};
      }
    },

    footerStyles() {
      switch (this.$route.name) {
        case 'Home':
          return {
            display: 'none',
            backgroundColor: 'transparent'
          };
        case 'Farms':
          return {
            backgroundColor: 'transparent'
          };
        case 'Help Center':
          return {
            backgroundColor: 'transparent'
          };
        default:
          return {};
      }
    },

    superBackground() {
      return this.$route.name === 'Farms' || this.$route.name === '' || this.$route.name === 'Help Center' ? 'superBackground' : '';
    }
  }
};
</script>

<style lang="scss">
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);

  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.superBackground {
  background-image: url('../../images/homepage-background-logo.svg'), url('../../images/gradient.svg');
  background-position: 50% -70vh, 50% 50%;
  background-size: 150vh, cover;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, fixed;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
}

body {
  overflow-y: scroll; // Show vertical scrollbar
}
</style>
