<template>
  <label class="deployFormLabel">
    <slot></slot>
    <span v-if="hasTooltip" class="deployFormLabel__tooltipWrapper">
      <transition name="fade">
        <div v-if="showTooltip" class="deployFormLabel__tooltip">
          <span class="deployFormLabel__tooltipText">{{ tooltip }}</span>
        </div>
      </transition>
      <QuestionMarkIcon class="deployFormLabel__tooltipIcon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false" />
    </span>
  </label>
</template>
<script>
// Imports
import { ref } from '@vue/reactivity';

// Icon Imports.
import QuestionMarkIcon from '/src/components/icons/QuestionMarkIcon';
import { computed } from 'vue';

export default {
  components: {
    QuestionMarkIcon
  },

  props: {
    label: {
      type: String
    },
    tooltip: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const showTooltip = ref(false);

    const toggleTooltip = () => {
      showTooltip.value = !showTooltip.value;
    };

    const hasTooltip = computed(() => {
      return props.tooltip.length > 0;
    });

    return {
      showTooltip,
      toggleTooltip,
      hasTooltip
    };
  }
};
</script>
<style scoped lang="scss">
.deployFormLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.deployFormLabel__tooltipWrapper {
  position: relative;
}

.deployFormLabel__tooltipIcon {
  margin-left: 5px;
  margin-top: 5px;
  height: 12px;
  width: 12px;
  opacity: 0.8;
  cursor: pointer;
}

.deployFormLabel__tooltip {
  position: absolute;
  bottom: 100%;
  left: -170px;
  min-width: 400px;
  width: fit-content;
  z-index: 1;
  border-radius: 25px;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  backdrop-filter: blur(5px);
}

.deployFormLabel__tooltipText {
}

@media (max-width: 521px) {
  .deployFormLabel__tooltipWrapper {
    display: none;
  }
}

// Animations
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
