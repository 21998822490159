<template>
  <div  class="user-info-component">
    <div class="user-info__field">
      <label class="user-info__label" for="name">Username</label>
      <Field
        name="name"
        class="user-info__input"
        id="name"
        v-model="info.name"
      />
      <ErrorMessage name="name" />
    </div>
    <div class="user-info__field">
      <label class="user-info__label" for="email">Email</label>
      <Field
        name="email"
        class="user-info__input"
        type="email"
        id="email"
        v-model="info.email"
      />
      <ErrorMessage class="error" name="email" />
    </div>
    <div class="user-info__field">
      <label class="user-info__label" for="description">Description</label>
      <Field
        as="textarea"
        name="description"
        class="user-info__input"
        type="text"
        id="description"
        rows="3"
        v-model="info.description"
      />
      <ErrorMessage name="description" />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
  components: {
    Field,
    ErrorMessage
  },
  data() {
    return {
      info: {
        name: "",
        email: "",
        description: ""
      }
    }
  }
}
</script>

<style lang="scss" src="./UserInfo.scss" scoped></style>