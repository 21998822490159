import axios from 'axios';
import qs from 'qs';
import store from '../store';
import { authService } from './auth.service';
import tokenStorage from './token-storage.service';

const responseBody = response => response?.data;

const req_service = axios.create();

const trimStringValues = data => {
  const deepTrim = obj => {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const type = typeof value;

      if (value !== null && (type === 'string' || type === 'object') && obj.hasOwnProperty(key)) {
        if (type === 'object') {
          deepTrim(obj[key]);
        } else {
          obj[key] = obj[key].trim();
        }
      }
    });
  };

  let requestData;

  if (Array.isArray(data)) {
    requestData = [...data];

    requestData.map(deepTrim);
  } else if (data instanceof FormData) {
    requestData = data;
  } else {
    requestData = { ...data };

    deepTrim(requestData);
  }

  return requestData;
};

const customParamsSerializer = params => qs.stringify(trimStringValues(params), { arrayFormat: 'repeat' });

// Interceptor to refresh access token when it expired
req_service.interceptors.response.use(undefined, async error => {
  const {
    config,
    response: { status, data }
  } = error;

  if (data.message === 'Unauthorized' && status === 401) {
    const refreshToken = tokenStorage.getRefreshToken()
    if (refreshToken) {
      await store.dispatch('auth/refreshToken', refreshToken);

      req_service(config);
    } else {
      store.dispatch('alert/error', { message: 'You are not authorized', duration: 2000 });
    }
  }
});

req_service.interceptors.request.use(config => {
  const token = tokenStorage.getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const requests = {
  post: (url, data, config) => {
    const requestData = trimStringValues(data);

    return req_service.post(url, requestData, config).then(responseBody);
  },
  patch: (url, data) => req_service.patch(url, trimStringValues(data)).then(responseBody),
  delete: (url, data) => req_service.delete(url, trimStringValues(data)).then(responseBody),
  put: (url, data, config) => req_service.put(url, trimStringValues(data), config).then(responseBody),
  get: (url, config) =>
    req_service
      .get(url, {
        ...config,
        paramsSerializer: customParamsSerializer
      })
      .then(responseBody)
};

export default requests;
