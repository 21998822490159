<template>
  <div>
    <div class='title'>
      <h2>{{title}}</h2>
    </div>
    <div class='content'>
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TitledSection',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang='scss'>
  .title {
    margin-bottom: 20px;

    h2 {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .content {
    width: 100%;
  }
</style>