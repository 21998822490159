<template>
  <div class='header'>
    <slot/>
  </div>
</template>

<style scoped lang='scss'>
  .header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(var(--background-color-rgb-reverse), .2);
    min-height: 67px;
  }
</style>