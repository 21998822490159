<template>
  <slot/>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import localStorage from '@/services/local-storage.service'

  export default {
    computed: {
      ...mapGetters('theme', ['isDarkMode'])
    },
    methods: {
      ...mapActions('theme', ['setDarkMode'])
    },
    mounted() {
      this.setDarkMode({
        value: localStorage.get('isDarkMode') === 'true'
      })

      document.documentElement.setAttribute('data-theme', this.isDarkMode ? 'darkMode' : '')
    }
  }
</script>