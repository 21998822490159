<template>
  <div class="info-container">
    <div class="info-left">
      <slot/>
      <div>
        <div class="item-name">{{ item.metadata.name }}</div>
        <div class="collection-name">{{ item.metadata.collection }}</div>
      </div>
    </div>

    <!-- Display the 'like' button. -->
    <div class="info-right" v-if="item.metadata.likes">
      <div class="like-button-wrapper">
        <HeartIcon />
        <div class="like-amount">
          {{ abbreviateNumber(item.metadata.likes) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Imports
import { abbreviateNumber } from '/src/utility';

// Icon Imports
import HeartIcon from '../../../icons/HeartIcon';

export default {
  props: {
    item: {
      type: Object
    }
  },

  components: {
    HeartIcon
  },

  data() {
    return {
      showDropdown: false,
      abbreviateNumber
    };
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }
  }
};
</script>

<style scoped>
.info-container {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-start;
}

.info-left {
  display: flex;
  flex-direction: column;
}

.item-name {
  margin-right: 10px;
  font-weight: 700;
  line-height: 1.3em;
}

.collection-name {
  opacity: 0.5;
  font-size: 12px;
  text-align: left;
}

.info-right {
  display: flex;
  align-items: center;
}

.like-button-wrapper {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.like-amount {
  margin-left: 4px;
}

.toggle-button {
  margin-left: 10px;
}
</style>
