<template>
  <Field :name='name' v-model='type'>
    <BaseDropdown ref='socialDropdownRef'>
      <SelectButton hoverable class='trigger' icon-position='right'>
        <template #icon>
          <ChevronDownIcon/>
        </template>
        <div class='select-option-row'>
          <div class='icon'>
            <component :is='currentIcon'/>
          </div>
          <span class='text'>{{type?.label}}</span>
        </div>
      </SelectButton>

      <template #dropdown>
        <DropdownContent class='content-wrapper' width='178px' height='220px'>
          <ListItem v-for='(item, index) in options' class='list-item'
                    :key='index'
                    :disabled='disableItem(item, index)'
                    @click='!disableItem(item, index) ? changeType(item) : undefined'>
            <div v-if='item.icon' class='list-item-icon'>
              <component :is='item.icon'/>
            </div>
            <p>{{item.label}}</p>
            <div v-if='(item.value === type?.value) || disableItem(item, index)' class='list-item-check'>
              <CheckIcon/>
            </div>
          </ListItem>
        </DropdownContent>
      </template>
    </BaseDropdown>
  </Field>
</template>

<script>
  import {Field} from 'vee-validate'
  import {find, omit, filter, some} from 'lodash'

  import {SelectButton} from '@/components/ui/select'
  import {ChevronDownIcon, CheckIcon} from '@/components/icons'
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import ListItem from '@/components/ui/list/ListItem'

  export default {
    components: {
      SelectButton, BaseDropdown,
      ListItem, Field,
      ChevronDownIcon, CheckIcon,
      DropdownContent
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      socials: {
        type: Array,
        required: true
      },
      options: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        type: this.value
      }
    },
    computed: {
      currentIcon() {
        return find(this?.options || [], item => item?.value === this.type?.value)?.icon
      },
      selectIndex() {
        return parseInt(this.name.match(/\d+/g)[0])
      },
    },
    methods: {
      changeType(type) {
        this.type = omit(type, 'icon')
      },
      disableItem(listItem) {
        const items = filter(this.socials, (item, index) => {
          if (this.selectIndex !== index) {
            return item?.type?.value === listItem.value
          }
        })

        return (items.length === 2) && some(this.socials,(item, index) => {
          if (this.selectIndex !== index) {
            return item?.type?.value === listItem.value
          }
        })
      }
    },
    watch: {
      type(newValue, oldValue) {
        const dropdownRef = this.$refs.socialDropdownRef

        if ((newValue?.value !== oldValue?.value) && dropdownRef?.opened) {
          dropdownRef?.click()
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  .select-option-row {
    display: flex;
    align-items: center;
  }

  .text {
    border: none;
    width: 100%;
    outline: none;
    text-align: center;
    cursor: pointer;
  }

  .content-wrapper {
    .list-item {
      height: 40px;
      padding: 5px 15px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 1.3;
      display: flex;
      align-items: center;

      &-check {
        font-size: 16px;
      }

      &-icon {
        margin-right: 10px;
      }

      &-check {
        margin-left: 20px;
      }
    }
  }

  .icon {
    margin-right: 10px;
  }

  .icon, .list-item-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>