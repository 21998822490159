<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
    <path
      :fill="color"
      d="M10.98 12.34L5.64 7l5.34-5.36L9.33 0l-7 7 7 7 1.65-1.66z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: ['color']
};
</script>
