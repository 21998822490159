<template>
  <SelectList isMultiple
              :options='options'
              :modelValue='modelValue'
              @update:modelValue='onChange'>
    <template v-slot='{options, isActive, handleChange}'>
      <div class='select-buttons-list'>
        <SelectButton v-for='(option, index) in options'
                      :key='index'
                      class='select-buttons-item'
                      @click='handleChange(option)'
                      :isActive='isActive(option)'>
          <template v-if='!!option?.icon' #icon>
            <component :is='option.icon'/>
          </template>
          {{option.label}}
        </SelectButton>
      </div>
    </template>
  </SelectList>
</template>

<script>
  import {SelectButton, SelectList} from '@/components/ui/select'

  export default {
    components: {SelectButton, SelectList},
    props: {
      modelValue: {
        type: Object,
        required: true
      },
      options: {
        type: Array,
        default: () => []
      },
    },
    methods: {
      onChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>
