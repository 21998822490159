import requests from './requests.service'

const baseUrl = process.env.VUE_APP_SOCIAL_BASE_URL

const mePath = `${baseUrl}/v1/me`

const Me = {
    getBadges: () => requests.get(`${mePath}/badges`)
}

const usersPath = `${baseUrl}/v1/users`

const Users = {
    getProfile: id => requests.get(`${usersPath}/${id}`),
    follow: id => requests.post(`${usersPath}/${id}/followers`),
    unfollow: id => requests.delete(`${usersPath}/${id}/followers`),
    report: (id, data) => requests.post(`${usersPath}/${id}/report`, data),
    playlists: (id, data) => requests.post(`${usersPath}/${id}/playlists`, data),
    pinnedItems: id => requests.get(`${usersPath}/${id}/items/pinned`)
}

const collectionsPath = `${baseUrl}/v1/collections`

const Collections = {
    getDetails: id => requests.get(`${collectionsPath}/${id}`),
    follow: id => requests.post(`${collectionsPath}/${id}/followers`),
    unFollow: id => requests.delete(`${collectionsPath}/${id}/followers`),
    report: (id, data) => requests.post(`${collectionsPath}/${id}/report`, data)
}

const notificationsPath = `${baseUrl}/v1/notifications`

const Notifications = {
    list: params => requests.get(notificationsPath, {params})
}

export const socialService = {
    Me,
    Users,
    Collections,
    Notifications
}
