<template>
  <div class="container">
    <div class="item-wrapper">
      <img
        class="image"
        src="../../../../images/profile-avatar-placeholder.gif"
      />
      <div class="item-info">
        <ItemActionInfo
          :action="nft.info.action"
          :price="nft.info.price"
          :receiver="nft.info.user"
          :date="nft.info.date"
        />
        <div class="item-label">
          <div class="item-name">{{ nft.name }}</div>
          <div class="item-collection">{{ nft.collection }}</div>
        </div>
      </div>
    </div>
    <Button v-if="showButton" :isSecondary="true">
      <template v-slot:default>
        <div class="button">
          <CloseIcon />
          <div class="button-text">{{ buttonText }}</div>
        </div>
      </template>
    </Button>
  </div>
</template>

<script>
import ItemActionInfo from '../ItemActionInfo';
import Button from '../../../ui/Button';
import CloseIcon from '../../../icons/CloseIcon';

export default {
  components: {
    ItemActionInfo,
    Button,
    CloseIcon
  },
  props: {
    nft: {
      type: Object
    }
  },

  computed: {
    showButton() {
      return (
        this.nft.info.action === 'listed' ||
        this.nft.info.action === 'auctioned' ||
        this.nft.info.action === 'offered'
      );
    },

    buttonText() {
      if (this.nft.info.action === 'listed') {
        return 'Cancel Sale';
      } else if (this.nft.info.action === 'auctioned') {
        return 'Cancel Auction';
      } else if (this.nft.info.action === 'offered') {
        return 'Cancel Offer';
      } else {
        return '';
      }
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto minmax(auto, 180px);
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  transition: none 0ms ease;
  text-decoration: none;
  cursor: pointer;
}

.item-wrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
}

.item-label {
  margin-top: 10px;
  text-align: left;
}

.item-name {
  font-weight: 700;
}

.item-collection {
  opacity: 0.5;
  font-size: 12px;
}

.image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.button {
  display: flex;
  align-items: center;
}

.button-text {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .container {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
</style>
