'use strict';

// Imports.
import { ethers } from 'ethers';

// Define particular hard-coded information for every known farm.
const KNOWN_DROPS = {
  // Network ID
  '0x1': {
    drops: [
      {
        id: 'open-edition',
        username: 'SuperFarm',
        title: 'Open Edition',
        startDate: new Date(2021, 4, 21, 11, 0, 0),
        endDate: new Date(2021, 4, 21, 11, 0, 0),
        image: 'falcon.jpg',
        bannerImage: 'falcon.jpg',
        metadata: [
          { label: 'Access', value: 'Public' },
          { label: 'Issue Type', value: 'Unique' },
          { label: 'Max Allocation', value: '1' }
        ],
        description: 'Sample Description'
      },
      {
        id: 'genesis',
        username: 'SuperFarm',
        title: 'Genesis',
        startDate: new Date(2021, 2, 31, 12, 0, 0),
        endDate: new Date(2021, 4, 21, 11, 0, 0),
        image: 'cyber_chef.jpg',
        bannerImage: 'cyber_chef.jpg',
        metadata: [
          { label: 'Access', value: 'Public' },
          { label: 'Issue Type', value: 'Unique' },
          { label: 'Max Allocation', value: '1' }
        ],
        description: 'Sample Description'
      },
      {
        id: 'injective',
        username: 'Injective',
        title: 'Injective Genesis Series',
        startDate: new Date(Date.UTC(2021, 5, 18, 19, 0, 0)),
        endDate: new Date(Date.UTC(2021, 5, 19, 19, 0, 0)),
        image: 'injective.jpg',
        bannerImage: 'injective-background.mp4',
        metadata: [
          { label: 'Access', value: 'Public' },
          { label: 'Issue Type', value: 'Unique' },
          { label: 'Max Allocation', value: 'Unlimited' }
        ],
        description: 'An exclusive series of NFTs featuring the dual-natured Injectid and their unyielding guardian, Nemesis'
      },
      {
        id: 'katun',
        username: 'Katun',
        title: 'Apes Stand Together',
        startDate: new Date(Date.UTC(2021, 7, 17, 18, 0, 0)),
        endDate: new Date(Date.UTC(2021, 7, 18, 18, 0, 0)),
        image: 'mystical-fruits-open.jpg',
        bannerImage: 'mystical-fruits-open.mp4',
        metadata: [
          { label: 'Access', value: 'Whitelist' },
          { label: 'Issue Type', value: 'Unique' },
          { label: 'Max Allocation', value: 'Unlimited' }
        ],
        description: 'An evolutionary NFT drop featuring pieces from the famous graffiti artist Katun. On the 17th of October at 18:00 UTC, 20% of all "Mystical Fruits" NFTs will evolve into "Garden of Bloom" NFTs, a scarce special edition NFT. The winners will be chosen at random from the pool of all "Mystical Fruits" owners.'
      }
    ],

    featuredDropIds: new Set(['katun'])
  },

  '0x4': {
    drops: [
      {
        id: 'katun',
        username: 'Katun',
        title: 'Apes Stand Together',
        startDate: new Date(Date.UTC(2021, 7, 17, 18, 0, 0)),
        endDate: new Date(Date.UTC(2021, 7, 18, 18, 0, 0)),
        image: 'mystical-fruits-open.jpg',
        bannerImage: 'mystical-fruits-open.mp4',
        metadata: [
          { label: 'Access', value: 'Public' },
          { label: 'Issue Type', value: 'Unique' },
          { label: 'Max Allocation', value: 'Unlimited' }
        ],
        description: 'An evolutionary NFT drop featuring pieces from the famous graffiti artist Katun.'
      }
    ],

    featuredDropIds: new Set(['katun'])
  }
};

const networkId = '0x1';

// Fetch hard-coded farm information.
export const drops = () => {
  return KNOWN_DROPS[networkId].drops;
};

export const featuredDropIds = () => {
  return KNOWN_DROPS[networkId].featuredDropIds;
};
