<template>
  <Base3dModel :src="'/3dmodels/model.glb'" />
</template>

<script>
import Base3dModel from "@/components/ui/Base3dModel"
export default {
  components: {
    Base3dModel
  }
}
</script>

<style scoped>
body {
  overflow: hidden;
}
</style>