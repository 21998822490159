<template>
  <!-- Begin Mailchimp Signup Form -->

  <!--End mc_embed_signup-->
  <div class="container">
    <p class="title">
      Don't miss the next drop.
    </p>
    <p class="subtitle">
      Subscribe and we’ll notify you when a drop is about to go live.
    </p>
    <div class="form-container">
      <div id="mc_embed_signup">
        <form
          action="https://superfarm.us1.list-manage.com/subscribe/post?u=52f1470ac884f62b57b20744b&amp;id=60ddadaf20"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll" class="form-container">
            <input
              type="email"
              value=""
              name="EMAIL"
              class="email textfield"
              id="mce-EMAIL"
              placeholder="Enter your email"
              required
            />
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_52f1470ac884f62b57b20744b_60ddadaf20"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button submit-btn"
              />
            </div>
          </div>
        </form>
      </div>

      <!-- <form @submit.prevent="onSubmit">
        <input
          type="email"
          maxlength="256"
          name="email"
          class="textfield"
          placeholder="Enter your email address"
        /><input type="submit" value="Subscribe" class="submit-btn" />
      </form> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onSubmit() {}
  }
};
</script>

<style scoped>
.container {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #000;
  color: #fff;
  text-align: center;
}
.form-container {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.subtitle {
  margin-bottom: 20px;
}

form {
  display: flex;
}
.textfield {
  width: 280px;
  height: 40px;
  max-width: 100%;
  margin-right: 5px;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 24px;
  background-color: #000;
  transition: border-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
}

.textfield:hover {
  border-color: hsla(0, 0%, 100%, 0.3);
}

.textfield:focus {
  outline: none;
  border: 2px solid white;
}

.submit-btn {
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #000;
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: white;
  color: black;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  border: none;
}

.submit-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}

.submit-btn:active {
  outline: none;
}

.done {
  display: none;
}

.fail {
  display: none;
}
</style>
