<template>
  <Ethereum :callback="initialize" />
  <CardModal />
  <div class="dropDetailsItems">
    <WalletItems :items="nftItems" cardState="onDrops" />
  </div>
</template>

<script>
// Imports
import { mapState } from 'vuex';
import store from '/src/store';
import { ethers } from 'ethers';

// Component Imports
import CardModal from '/src/components/nft-detail-modal/CardModal';
import Ethereum from '/src/components/common/Ethereum';
import WalletItems from '/src/components/pages/my-items/WalletItems';

export default {
  components: {
    Ethereum,
    CardModal,
    WalletItems
  },

  data() {
    let DROP_SHOPS = {
      injective: '0x079d2F011b3914511818Ea33F5c6325B1e08F210',
      katun: '0x56677e200953ad16F056a1E8Dac2f3ebD3581EF1'
    };

    let shopAddress = DROP_SHOPS[this.drop.id];

    return {
      ethers,
      refreshInterval: null,
      nftItems: ['loading'],
      shopAddress
    };
  },

  props: {
    drop: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      ethereum: (state) => state.ethers,
      items: (state) => state.items
    })
  },

  methods: {
    async initialize() {
      let userAddress = null;
      if (this.ethereum.canSign) {
        userAddress = this.ethereum.address;
      }
      await store.dispatch('items/getLaunchpadItems', [this.shopAddress], { root: true });
      this.getItems();
    },

    getItems() {
      if (this.items.launchpadItemList.pools && this.items.launchpadItemList.pools.length < 0) {
        this.nftItems = ['loading'];
        this.getItems();
      }

      let items = ['loading'];

      if (this.items.launchpadItemList.pools !== undefined && this.items.launchpadItemList.pools.length > 0) {
        items = [];
        for (let pool of this.items.launchpadItemList.pools) {
          for (let item of pool.items) {
            // TODO: kill me this is bad. genericize pls
            for (let price of item.prices) {
              price.staker = pool.staker;
            }

            // TODO: I feel like this is also a stupid thing to do.
            item.shop = this.shopAddress;
            items.push(item);
          }
        }
      }
      this.nftItems = items;
    }
  }
};
</script>
<style scoped lang="scss">
.dropDetailsItems {
  padding: 30px 20px;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}
</style>
