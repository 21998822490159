<template>
  <div class='address-wrapper' v-if='!!value'>
    <transition name='slide'>
      <div v-if='addressHover' class='copy-tooltip'>
        <p>{{copyText}}</p>
      </div>
    </transition>
    <div class='address-row'
         @click='copyToClipboard'
         @mouseover='addressHover = true'
         @mouseleave='copyTooltipLeave'>
      <p class='address'>{{formatAddress(value)}}</p>
      <CopyIcon/>
    </div>
  </div>
</template>

<script>
  import useClipboard from 'vue-clipboard3'

  import CopyIcon from '@/components/icons/CopyIcon'
  import {formatAddress} from '@/utility'

  export default {
    props: {
      value: {
        type: String,
        required: true
      }
    },
    components: {CopyIcon},
    data: () => ({
      copyText: 'Copy Address',
      addressHover: false,
      formatAddress
    }),
    methods: {
      async copyToClipboard() {
        if (this.value) {
          const {toClipboard} = useClipboard()
          await toClipboard(this.value)
          this.copyText = 'Copied to clipboard!'
        }
      },
      copyTooltipLeave() {
        this.addressHover = false
        this.copyText = 'Copy Address'
      }
    }
  }
</script>

<style scoped lang='scss'>
  .address-wrapper {
    display: inline-flex;
    position: relative;
    color: var(--text-color);
  }

  .address-row {
    display: inline-flex;
    align-items: center;
    transition: opacity 200ms cubic-bezier(.215, .61, .355, 1);
    opacity: .5;

    .address {
      margin-right: 5px;
      opacity: .8;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.2s ease-in;
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
  }

  .copy-tooltip {
    position: absolute;
    padding: 10px;
    border-radius: 8px;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 14px;
    background-color: var(--background-color-reverse);
    color: var(--text-color-reverse);
    width: max-content;
  }
</style>