<template>
  <section class="activity common-component">
    <div class="activity__header-section">
      <div class="social-container">
        <h1 class="activity__header">Activity</h1>
      </div>
    </div>
    <div class="activity__main-section social-container">
      <VuePerfectScrollbar class="activity__tabs">
        <router-link
          class="activity__tab-link"
          v-for="tab in tabs"
          :key="tab"
          :to="{
            name: 'ActivitySection',
            params: {
              name: tab.split(' ').join('').toLowerCase(),
            },
          }"
        >
          <BaseTabItem
            :title="tab"
            :class="{
              selected: activeTab === tab.split(' ').join('').toLowerCase(),
            }"
            color="black"
            secondaryColor="hardBlack"
            bgColor="lightBlack"
            style="font-weight: 600"
          />
        </router-link>
      </VuePerfectScrollbar>
      <router-view />
    </div>
  </section>
</template>

<script>
import BaseTabItem from "@/components/ui/BaseTabItem";
import VuePerfectScrollbar from "vue-perfect-scrollbar/index.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ActivityPage",
  components: {
    BaseTabItem,
    VuePerfectScrollbar,
  },
  data() {
    return {
      tabs: ["Global", "Following", "My Activity", "Watchlist"],
    };
  },
  methods: {
    ...mapActions("activity", ["fetchActivities"])
  },
  computed: {
    ...mapState("activity", {
      tabFilters: (state) => state.filters,
    }),
    activeTab() {
      return this.$route.params.name
    }
  },
  watch: {
    activeTab(val) {
      this.fetchActivities({type: val, filters: this.tabFilters[this.activeTab]})
    }
  },
  mounted() {
    this.fetchActivities({type: this.activeTab});
  },
};
</script>

<style src="./ActivityPage.scss" lang="scss" scoped></style>
