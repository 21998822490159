<template>
  <MyItemsModal
    :isOpen="isOpen"
    :isDisabled="isDisabled"
    @buttonPressed="placeBid"
    @exit="exit"
  >
    <template v-slot:title>
      <p>Place a Bid</p>
      <p>{{ baseBid }}</p>
    </template>
    <template v-slot:form>
      <div class="form">
        <form>
          <label class="form-label">Your Bid:</label>
          <div class="currency-input-wrapper">
            <input
              class="form-input"
              type="number"
              maxlength="256"
              v-model="bidAmount"
            />
            ETH
          </div>
          <p class="converted-price">${{ convertedPrice }} USD</p>
        </form>
      </div>
    </template>
    <template v-slot:buttonText>
      <p>Bid {{ bidAmount }} ETH</p>
    </template>
  </MyItemsModal>
</template>

<script>
// Imports
import axios from 'axios';
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';
import { mapState, mapActions } from 'vuex';
import store from '/src/store';

// Component Imports
import MyItemsModal from './MyItemsModal';

export default {
  components: {
    MyItemsModal
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    baseBid: {
      type: Number
    }
  },

  data() {
    return {
      ethers,
      formatNumber,
      bidAmount: 0,
      ethPrice: 0
    };
  },

  watch: {
    baseBid(newValue) {
      this.bidAmount = newValue;
    }
  },

  computed: {
    ...mapState({
      itemModal: state => state.itemModal,
      auction: state => state.auction
    }),

    isDisabled() {
      return (
        this.auction.minimumBid - this.bidAmount >= 0 || this.auction.isFinished
      );
    },

    convertedPrice() {
      return formatNumber(this.ethPrice * this.bidAmount, 2);
    }
  },

  emits: ['exit'],

  created() {
    this.initializePage();
  },

  mounted() {
    this.bidAmount = this.baseBid;
    this.startPollingMinimum();
  },

  unmounted() {
    this.stopPollingMinimum();
  },

  methods: {
    ...mapActions('auction', [
      'updateAuctionData',
      'updateUserBidStatus',
      'placeBid'
    ]),

    // Initialize the page.
    async initializePage() {
      // Retrieve the current price of Ether from CoinGecko.
      try {
        let priceResponse = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
        );
        this.ethPrice = priceResponse.data.ethereum.usd;
      } catch (error) {
        console.error(error);
      }
      await store.dispatch('auction/updateAuctionData', '', { root: true });
      this.bidAmount = this.baseBid;
    },

    exit() {
      this.stopPollingMinimum();
      this.$emit('exit');
    },

    async placeBid() {
      await store.dispatch(
        'auction/placeBid',
        { amount: this.bidAmount },
        { root: true }
      );
      // Close modal after bid is placed.
      this.$emit('exit');
    },

    async startPollingMinimum() {
      const bidUpdate = async () => {
        // if(this.bidAmount < this.auction.minimumBid){
        //   this.bidAmount = this.auction.minimumBid;
        // }
      };
      this.refreshInterval = setInterval(bidUpdate, 3000);
    },

    async stopPollingMinimum() {
      clearInterval(this.refreshInterval);
    }
  }
};
</script>

<style scoped>
.form {
  text-align: left;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.form-input {
  position: relative;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: border-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 17px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  line-height: 1.4;
  color: var(--text-color);
  background-color: var(--background-accent);
  border: 1px solid #cccccc;
  margin-right: 10px;
}

.currency-input-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 0px;
}

.converted-price {
  opacity: 0.5;
}

.form-input:hover {
  border-color: rgba(var(--text-color-rgb), 0.5);
}

.form-input:focus {
  outline: none;
  border-width: 2px;
  border-color: var(--text-color);
}
</style>
