<template>
  <button class="social-button">
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.social-button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  border-radius: 20px;
  font-weight: 500;
  color: $black;
  cursor: pointer;
  border: none;
  transition: background-color 200ms;
  background-color: transparent;
  white-space: nowrap;
  &:focus-visible {
    outline: $black solid;
  }
  &:hover {
    background-color: $lightBlack;
  }
}
</style>
