<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.70711 0L0.707107 3L0 3.7071L0.707107 4.41421L3.70711 7.41421L5.12132 6L3.82843 4.7071H10.9142V2.7071H3.82843L5.12132 1.41421L3.70711 0ZM8.5 10.7072L7.20711 12L8.62132 13.4143L11.6213 10.4143L12.3284 9.7071L11.6213 9L8.62132 6L7.20711 7.41421L8.5 8.7071H1.41421V10.7072H8.5Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
