<template>
  <li :class='{"wrapper": true, "is-open": isOpen}'>
    <div class='trigger' @click='open'>
      <h3>{{title}}</h3>

      <ChevronDownIcon class='icon'/>
    </div>
    <transition name='accordion'
                @before-enter='beforeEnter'
                @enter='enter'
                @before-leave='beforeLeave'
                @leave='leave'>
      <div class='menu' ref='menu' v-show='isOpen'>
        <div ref='menu-content' class='menu-content'>
          <slot/>
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
  import {ChevronDownIcon} from '@/components/icons'

  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    },
    inject: ['Accordion'],
    components: {ChevronDownIcon},
    data: () => ({
      index: null
    }),
    computed: {
      isOpen() {
        return this.index === this.Accordion.active
      }
    },
    methods: {
      open() {
        if (this.isOpen) {
          this.Accordion.active = null
        } else {
          this.Accordion.active = this.index
        }
      },
      beforeEnter(el) {
        el.style.height = '0'
      },
      enter(el) {
        el.style.height = `${el.scrollHeight}px`
      },
      beforeLeave(el) {
        el.style.height = `${el.scrollHeight}px`
      },
      leave(el) {
        el.style.height = '0'
      },
      resize() {
        const menu = this.$refs?.menu
        const menuContent = this.$refs['menu-content']

        if (menu && menuContent && this.isOpen) {
          menu.style.height = `${menuContent.scrollHeight}px`
        }
      }
    },
    created() {
      window.addEventListener('resize', this.resize)

      this.index = this.Accordion.count++
    },
    unmounted() {
      window.removeEventListener('resize', this.resize)
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    position: relative;
    width: 100%;
    border-radius: 30px;
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
    overflow: hidden;
    background-color: var(--background-color);

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .icon {
      transition: transform .5s ease;
    }

    &.is-open {
      .icon {
        transform: rotate(180deg);
      }

      .trigger {
        border-bottom-color: rgba(var(--text-color-rgb), 0.2)
      }
    }
  }

  .trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    @include media-min-width($mediumScreen) {
      &:hover {
        background-color: rgba(var(--background-color-rgb-reverse), .1);
      }
    }

    & > * {
      font-weight: 500;
    }
  }

  .menu {
    border-radius: 0 0 30px 30px;
    background-color: var(--tab-menu-background);

    &-content {
      border-radius: 0 0 30px 30px;
      padding: 10px;

      @media screen and (max-width: 479px) {
        padding: 20px;
      }
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: all 0.5s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
</style>