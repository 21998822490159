<template>
  <ReportModal title='Report user'
                   :isLoading='isSubmitting'
                   :onSubmit='submitReport'/>
  <InfoContainer>
    <template #avatar>
      <Avatar :value='profile?.avatarUrl'/>
    </template>

    <template #name>
      <Name>{{profile?.username}}</Name>
    </template>

    <template #address>
      <Address :value='profile?.ethAddress'/>
    </template>

    <template #actions>
      <div class='profile-actions'>
        <router-link v-if='!isPublic' to='/social/edit-profile'>
          <Button isSecondary>Edit profile</Button>
        </router-link>
        <template v-if='isPublic'>
          <Button isSecondary>
            Follow
          </Button>

          <router-link to='/social/trade'>
            <Button class='action-button' isSecondary>
              <div><ExchangeIcon/></div>
            </Button>
          </router-link>

          <Additional @onItemClick='onItemClick'/>
        </template>
      </div>
    </template>

    <template #stats>
      <Stats :data='profile'/>
    </template>

    <template #description>
      <Description :value='profile?.description'/>
    </template>

    <template #website>
      <WebsiteUrl :value='profile?.websiteUrl' class='profile-website'/>
    </template>

    <template #social>
      <Socials :values='profile?.socialLinks'/>
    </template>
  </InfoContainer>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import {socialService} from '@/services'
  import Button from '@/components/ui/Button'
  import {ExchangeIcon} from '@/components/icons'
  import {
    Avatar, Stats, Socials, WebsiteUrl,
    InfoContainer, Name, Description,
    Additional
  } from '@/layouts/profile-layout'
  import {ReportModal} from '@/components/common/modal'

  import Address from './Address'

  export default {
    components: {
      Address, Avatar,
      Stats, WebsiteUrl,
      Socials, InfoContainer,
      Name, Description,
      Button, ExchangeIcon,
      Additional, ReportModal
    },
    data() {
      return {
        isSubmitting: false
      }
    },
    computed: {
      ...mapGetters('profile', ['profile', 'isPublic'])
    },
    methods: {
      ...mapActions('modal', ['open']),
      onItemClick(type) {
        switch (type) {
          case 'report':
            this.open('report-modal')

            break
          default:
            break
        }
      },
      async submitReport(message, close) {
        if (this.profile && this.isPublic) {
          this.isSubmitting = true

         try {
           await socialService.Users.report(this.profile.id, {message})

           close()
         } finally {
           this.isSubmitting = false
         }
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
  .profile-actions {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    grid-auto-flow: column;

    a {
      text-decoration: none;
    }

    .action-button {
      width: 40px;
    }
  }
</style>
