<template>
  <div class='footer'>
    <slot/>
  </div>
</template>

<style scoped lang='scss'>
  .footer {
    padding: 20px 15px;
    border-top: 1px solid rgba(var(--background-color-rgb-reverse), .2);
  }
</style>