<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.72704 4.23707L3.43828 0.647095L8.09348 3.28311L5.75407 5.38654L11.2048 8.481L10.7194 9.34727L5.26867 6.24947L4.66191 9.33722L0 6.69444L2.72704 4.23707ZM0.0299518 10.3586H5.49413V11.353H0.0299518V10.3586Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
