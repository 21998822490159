'use strict';

// Imports.
import { itemService } from '../../services';

// Specify actions that can be performed by this module.
export default {
  // Use the item service to deploy an item.
  async deploy(
    { dispatch, commit },
    {
      name,
      description,
      imageFile,
      amount,
      shopAddress,
      priceData,
      royalty,
      shouldList,
      listAmount
    }
  ) {
    commit('deployRequest', {
      name,
      description,
      imageFile,
      amount,
      shopAddress,
      priceData,
      royalty,
      shouldList,
      listAmount
    });
    try {
      let imageUploadResponse = await itemService.uploadImage(imageFile);
      let generatedItemId = imageUploadResponse.data.name;
      let imageUri = imageUploadResponse.data.imageUri;
      let metadata = {
        name: name,
        description: description,
        image: imageUri,
        itemId: generatedItemId
      };
      await itemService.uploadMetadata(metadata);
      let metadataUri = `https://superfarm-images.s3.amazonaws.com/${generatedItemId}/{id}.json`;
      let response = await itemService.deployLocally(
        metadataUri,
        amount,
        shopAddress,
        priceData,
        royalty,
        shouldList,
        listAmount
      );
      let itemData = response.item;
      let itemAddress = itemData.address;

      // If the deploy is successful, store item information.
      if (itemAddress) {
        commit('deploySuccess', itemData);
        await dispatch(
          'alert/success',
          `Item successfully deployed to ${itemAddress}.`,
          { root: true }
        );
        await dispatch('getAllItems', { root: true });
      } else {
        commit('deployFailure', null);
        await dispatch(
          'alert/error',
          `Item '${name}' deployed with no return address!`,
          { root: true }
        );
      }

      // Catch deployment errors.
    } catch (error) {
      commit('deployFailure', error);
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the item service to get a list of all a user's items.
  async getAllItems({ dispatch, commit }) {
    try {
      let response = await itemService.getAllItems();
      commit('updateItemsList', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the item service to get a list of a user's owned items.
  async getOwnedItems({ dispatch, commit }, address) {
    try {
      console.log(`Fetching inventory for ${address}...`);
      let response = await itemService.getOwnedItems(address);
      commit('updateOwnedItems', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the item service to get a list of available launchpad items.
  async getLaunchpadItems({ dispatch, commit }, shopAddresses) {
    try {
      let response = await itemService.getLaunchpadItems(shopAddresses);
      commit('updateLaunchpadItemsList', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Purchase an item from the launchpad.
  async purchaseLaunchpadItem(
    { dispatch, commit },
    { shopAddress, poolId, groupId, assetId, amount, value }
  ) {
    try {
      await itemService.purchaseLaunchpadItem(shopAddress, poolId, groupId, assetId, amount, value);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
