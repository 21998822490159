<template>
  <div class='avatar-wrapper' :class='{
    "rounded": isRounded,
    "with-content": hasContent
  }'>
    <div class='avatar' :style='{width, height}'>
      <img v-if='src' :src='src'>
    </div>
    <div class='content' v-if='hasContent'><slot/></div>
  </div>
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: false,
        default: '40px'
      },
      height: {
        type: String,
        required: false,
        default: '40px'
      },
      isRounded: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      hasContent() {
        return !!this.$slots.default
      }
    }
  }
</script>

<style scoped lang='scss'>
  .avatar-wrapper {
    display: flex;
    align-items: center;

    .avatar {
      border-radius: 60px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }

    &.rounded {
      .avatar {
        border-radius: 6px;
      }
    }

    &.with-content {
      .avatar {
        margin-right: 10px;
      }
    }

    .content {
      word-break: break-word;
    }
  }
</style>