<template>
  <svg
    v-if="fullColor"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
  >
    <defs />
    <g clip-path="url(#clip0)">
      <path fill="#8DC63F" d="M16 7.96a8 8 0 11-16 .08 8 8 0 0116-.08z" />
      <path
        fill="#F9E988"
        d="M15.4 7.97a7.4 7.4 0 11-14.8.07 7.4 7.4 0 0114.8-.07z"
      />
      <path
        fill="#fff"
        d="M8.14 1.08a4.33 4.33 0 012.78.46c.42.23.78.54 1.15.84.36.3.72.6 1.06.93a6.28 6.28 0 011.55 2.39c.31.9.42 1.88.26 2.81h-.04a12.99 12.99 0 00-1.24-3.87c-.22-.4-.46-.79-.72-1.16a4.2 4.2 0 00-2.2-1.58c-.41-.17-.83-.33-1.26-.46-.44-.13-.88-.22-1.34-.31v-.05z"
      />
      <path
        fill="#8BC53F"
        d="M11.75 5.36c-.53-.16-1.09-.38-1.65-.6-.04-.14-.16-.32-.41-.54-.37-.31-1.06-.3-1.66-.16A4.14 4.14 0 006.09 4C.98 5.4 3.88 8.84 2 12.3c.27.57 3.16 3.88 7.32 2.99 0 0-1.42-3.43 1.8-5.07 2.6-1.34 4.49-3.82.64-4.86z"
      />
      <path fill="#fff" d="M12.38 7.6a.31.31 0 11-.62 0 .31.31 0 01.63 0z" />
      <path
        fill="#009345"
        d="M8.03 4.05c.37.03 1.72.47 2.07.7-.3-.84-1.27-.95-2.07-.7z"
      />
      <path
        fill="#fff"
        d="M8.38 6.18a1.43 1.43 0 11-2.86 0 1.43 1.43 0 012.86 0z"
      />
      <path fill="#58595B" d="M7.96 6.2a1 1 0 11-2.01 0 1 1 0 012 0z" />
      <path
        fill="#8BC53F"
        d="M13.54 8.24A7.16 7.16 0 019.2 9.67c-.88 0-1.06-.92-1.64-.47-.3.23-1.35.76-2.18.72A2.72 2.72 0 012.8 7.61c-.15 1.78-.23 3.1-.9 4.6 1.34 2.13 4.52 3.78 7.4 3.07-.3-2.16 1.59-4.28 2.65-5.37.4-.4 1.18-1.08 1.58-1.67z"
      />
      <path
        fill="#58595B"
        d="M13.5 8.29c-.36.33-.79.57-1.23.79-.44.2-.9.37-1.37.5-.47.12-.97.21-1.47.16a2 2 0 01-1.34-.58l.02-.02c.4.26.87.35 1.34.37a6.28 6.28 0 002.78-.54c.44-.19.88-.4 1.25-.7l.02.02z"
      />
    </g>
    <defs>
      <clipPath id="clip0"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
    </defs>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <defs></defs>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M16 7.96a8 8 0 11-16 .08 8 8 0 0116-.08zm-.6 0a7.4 7.4 0 01-6.09 7.33c-.3-2.17 1.59-4.3 2.65-5.38l.36-.34.6-.59c1.39-1.3 1.67-2.85-1.17-3.62-.53-.16-1.08-.38-1.64-.6h-.01a3.23 3.23 0 00-.06-.14c-.34-.72-1.25-.8-2.01-.57A4.14 4.14 0 006.09 4c-2.72.75-3.17 2.07-3.28 3.66l-.02.24c-.14 1.64-.24 2.89-.88 4.3A7.4 7.4 0 1115.4 7.98zm-1.91.32zm-.53.34A6.96 6.96 0 019.2 9.67c-.41 0-.67-.2-.88-.37a2.08 2.08 0 00-.22-.15v-.01c.41.26.88.35 1.35.37a6.28 6.28 0 002.78-.54c.24-.1.5-.22.73-.35zM8.38 6.18a1.43 1.43 0 11-2.86 0 1.43 1.43 0 012.86 0zm-.42.01a1 1 0 11-2.01 0 1 1 0 012 0zm4.42 1.42a.31.31 0 11-.61 0 .31.31 0 01.61 0z"
      clip-rule="evenodd"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    fullColor: {
      type: Boolean
    }
  }
};
</script>
