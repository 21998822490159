<template>
  <div class='mobile-filter-wrapper'>
    <SelectButton @click='open' hoverable>
      <template #icon>
        <FilterIcon/>
      </template>
      Filter {{count}}
    </SelectButton>

    <teleport to='body'>
      <Modal :name='name' class='mobile-filter-modal'>
        <slot :close='close'/>
      </Modal>
    </teleport>
  </div>
</template>

<script>
  import {useStore} from 'vuex'
  import {computed, provide} from 'vue'

  import {SelectButton} from '@/components/ui/select'
  import {FilterIcon} from '@/components/icons'

  import Modal from '../BaseModal'

  export default {
    components: {
      SelectButton,
      Modal, FilterIcon
    },
    props: {
      activeCount: {
        type: Number,
        required: false
      }
    },
    computed: {
      count() {
        return !!this.activeCount ? `(${this.activeCount})` : null
      }
    },
    setup() {
      const {dispatch, getters} = useStore()
      const name = 'global-filter-container'

      const open = () => dispatch('modal/open', name)
      const close = () => dispatch('modal/close', name)

      const isOpen = computed(() => {
        const allOpen = getters['modal/allOpen']

        return allOpen?.includes(name)
      })

      provide('filterContainer', {
        close, open,
        name, isOpen
      })

      return {
        name, isOpen,
        open, close
      }
    }
  }
</script>

<style scoped lang='scss'>
  .mobile-filter-modal {
    :deep .modal {
      height: 500px;

      .footer {
        .accept-action {
          width: 100%;
        }
      }
    }
  }

  @include media-max-width($mediumScreen) {
    .mobile-filter-modal {
      :deep .modal {
        .body {
          padding: 0;
        }

        li {
          border-radius: 0 !important;
          margin-bottom: 0 !important;

          .menu, .menu-content {
            border-radius: 0;
          }
        }
      }
    }
  }
</style>
