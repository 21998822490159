<template>
  <div class="container">
    <div class="page-wrapper">
      <div class="page-info">
        <h1 class="title">SuperFarm Liquidity Program</h1>
        <p class="info-subtitle">
          This is the SuperFarm liquidity program where SUPER-ETH liquidity
          farms additional SUPER tokens.
        </p>
        <div v-if="ethereum.canSign">
          <p class="subtitle">{{ message }}</p>
          <BaseButton
            class="liquidity-button"
            @click.stop="addLiquidity"
            :buttonText="liquidityButtonText"
            disabledText=""
          />
        </div>
      </div>

      <!-- Your Information Card -->
      <div class="page-body">
        <div v-if="ethereum.canSign" class="card">
          <div class="card-top">
            <div class="card-title">Your Information</div>
            <div class="card-row">
              <div class="card-subtitle">Your Value Locked</div>
              <div class="card-amount">${{ formattedUserValueLocked }}</div>
            </div>
            <div class="card-row">
              <div class="card-subtitle">Your Monthly Reward</div>
              <div class="card-amount">{{ formattedMonthROI }} SUPER</div>
            </div>
          </div>
          <div class="card-bottom">
            <div class="card-subtitle">Your Locked LP Tokens</div>
            <div class="card-amount">{{ formattedUserStaked }}</div>
          </div>
        </div>

        <!-- Reward Program Card -->
        <div class="card">
          <div class="card-top">
            <div class="card-title">Reward Program</div>
            <div class="card-row">
              <div class="card-subtitle">Total Value Locked</div>
              <div class="card-amount">${{ formattedTotalValueLocked }}</div>
            </div>
            <div class="card-row">
              <div class="card-subtitle">Annual Percentage Yield</div>
              <div class="card-amount">{{ formattedYearROI }}%</div>
            </div>
          </div>
          <div class="card-bottom">
            <div class="card-subtitle">Total Locked LP Tokens</div>
            <div class="card-amount">{{ formattedTotalStaked }}</div>
          </div>
        </div>

        <div v-if="ethereum.canSign" class="card-flex">
          <div class="card-logo-wrapper">
            <img src="../images/SuperFarm-icon-black.svg" />
          </div>
          <div class="card-flex-amount">
            <div class="card-amount-large">
              <AnimatedNumber
                v-if="hasInitialized && userValueLocked.gt(0)"
                :number="earnings"
              />
              <div v-if="hasInitialized && userValueLocked.eq(0)">
                {{ formatNumber(ethers.utils.formatEther(earnings), 6) }}
              </div>
            </div>
            <div class="card-subtitle">Unclaimed SUPER</div>
          </div>
          <BaseButton
            class="harvest-button"
            buttonText="Harvest"
            :isDisabled="!hasEarnings"
            disabledText="No Earnings"
            @click.stop="harvestReward"
          />
        </div>

        <div v-if="ethereum.canSign" class="card-flex">
          <div class="card-logo-wrapper">
            <img src="../images/uniswap-logo.svg" />
          </div>

          <div class="card-flex-amount">
            <div class="card-amount">{{ formattedUserStaked }}</div>
            <div class="card-subtitle">SUPER-ETH UNI-V2 LP Locked</div>
          </div>

          <!-- If the user has not approved any tokens, prompt them to do so. -->
          <BaseButton
            v-if="!hasApproval"
            class="liquidity-button"
            @click.stop="approveTokens"
            buttonText="Approve Tokens"
            disabledText=""
          />

          <div>
            <p class="card-subtitle">
              Current Balance:
              <span class="card-amount">{{ formattedUserBalance }}</span>
            </p>
          </div>
          <div class="input-container">
            <div>
              <!-- Token deposit input. -->
              <div class="input-wrapper">
                <NumberInput
                  class="input"
                  v-if="hasApproval"
                  v-model="depositTokenAmount"
                  placeholder="Enter Amount"
                  :showText="false"
                />
                <div
                  v-if="hasApproval"
                  class="max-amount"
                  @click.stop="maximumDepositInput"
                >
                  Max
                </div>
              </div>
              <BaseButton
                v-if="hasApproval"
                class="input-button"
                @click.stop="depositTokens(depositTokenAmount)"
                buttonText="Deposit Tokens"
                disabledText=""
              />
            </div>
            <div>
              <!-- Token withdrawal input. -->
              <div class="input-wrapper">
                <NumberInput
                  class="input"
                  v-if="hasApproval"
                  v-model="withdrawTokenAmount"
                  placeholder="Enter Amount"
                  :showText="false"
                />
                <div
                  v-if="hasApproval"
                  class="max-amount"
                  @click.stop="maximumWithdrawInput"
                >
                  Max
                </div>
              </div>
              <BaseButton
                v-if="hasApproval"
                class="input-button"
                @click.stop="withdrawTokens(withdrawTokenAmount)"
                buttonText="Withdraw Tokens"
                disabledText=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import store from '../store';
import { ethers } from 'ethers';
import axios from 'axios';
import { useTask } from 'vue-concurrency';
import initializeConfig from '../initialize-config';
import { formatNumber } from '../utility';

// Component imports.
import AnimatedNumber from '../components/utility/AnimatedNumber';
import BaseButton from '../components/ui/BaseButton';
import NumberInput from '../components/ui/NumberInput';

// Set up the default component.
export default {
  components: {
    AnimatedNumber,
    BaseButton,
    NumberInput
  },

  mounted() {
    document.title = 'SuperFarm Liquidity Program';
  },

  data() {
    return {
      ethers,
      formatNumber,

      initializationTask: null,
      refreshInterval: null,
      hasInitialized: false,

      message: 'This farm works by staking SUPER-ETH UNI-v2 LP tokens.',
      liquidityButtonText: 'SUPER-ETH Uniswap Liquidity Pool',
      depositTokenAmount: '0',
      withdrawTokenAmount: '0',

      earnings: ethers.BigNumber.from(0),

      superPriceUsd: ethers.BigNumber.from(0),
      superPriceEth: ethers.BigNumber.from(0),

      totalStaked: 0,
      userStaked: 0,
      ethPrice: 0,
      totalValueLocked: 0,
      userValueLocked: 0,
      yearROI: 0,
      monthROI: 0,
      userValue: 0,
      lpBalanceUser: 0
    };
  },

  async created() {
    console.log(`Initializing data for pool ...`);
    this.config = await initializeConfig();

    // Initialize the Ethereum provider.
    this.initializationTask = useTask(function*(
      signal,
      ethereum,
      initializePage
    ) {
      if (!ethereum.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          await initializePage();
        });
      } else {
        yield initializePage();
      }
    });
    this.initializationTask.perform(this.ethereum, this.initializePage);
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      account: state => state.account,
      pool: state => state.pool
    }),

    localCanSign() {
      return this.ethereum.canSign;
    },

    hasApproval() {
      return this.pool.approvedAmount > 0;
    },

    hasEarnings() {
      return this.earnings > 0;
    },

    formattedUserValueLocked() {
      return formatNumber(ethers.utils.formatEther(this.userValueLocked), 2);
    },

    formattedTotalValueLocked() {
      return formatNumber(ethers.utils.formatEther(this.totalValueLocked), 0);
    },

    formattedMonthROI() {
      return formatNumber(ethers.utils.formatEther(this.monthROI), 2);
    },

    formattedYearROI() {
      return formatNumber(ethers.utils.formatEther(this.yearROI), 0);
    },

    formattedUserStaked() {
      return formatNumber(ethers.utils.formatEther(this.userStaked), 6);
    },

    formattedTotalStaked() {
      return formatNumber(ethers.utils.formatEther(this.totalStaked), 6);
    },

    formattedUserBalance() {
      return formatNumber(ethers.utils.formatEther(this.lpBalanceUser), 6);
    }
  },

  methods: {
    ...mapActions('pool', ['stakeTokens', 'unstakeTokens', 'claimReward']),

    // Initialize the page.
    async initializePage() {
      // Retrieve the current price of Ether from CoinGecko.
      try {
        let priceResponse = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
        );
        this.ethPrice = ethers.utils
          .parseEther(`${priceResponse.data.ethereum.usd}`)
          .div(ethers.constants.WeiPerEther);
        this.totalValueLocked = this.ethPrice.mul(this.pool.poolValueEth);

        // Retrieve the current price of SUPER token.
        let superPriceResponse = await axios.get(
          'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55&vs_currencies=eth%2Cusd'
        );
        this.superPriceUsd =
          superPriceResponse.data[
            '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55'
          ].usd;
        this.superPriceEth = ethers.utils.parseEther(
          `${superPriceResponse.data['0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55'].eth}`
        );
      } catch (error) {
        console.error(error);
      }

      // Always retrieve public information for the liquidity program.
      await this.updatePoolData();
      await this.calcYearROI();

      // Only retrieve personal user data if the provider is a signer.
      if (this.ethereum.canSign) {
        await this.startPolling();
      }
      this.hasInitialized = true;
    },

    async startPolling() {
      const pageUpdate = async () => {
        await this.updatePoolData();
        await this.calcYearROI();

        // await this.getSuperPrice();
        await this.updateUserData();
        await this.updateEarnings();
        await this.calcMonthROI();
      };
      await pageUpdate();
      this.refreshInterval = setInterval(pageUpdate, 1000);
    },

    async stopPolling() {
      clearInterval(this.refreshInterval);
    },

    // Direct users to Uniswap where they may add liquidity.
    async addLiquidity() {
      window.open(
        'https://app.uniswap.org/#/add/ETH/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
        '_blank'
      );
    },

    // Approve a token spend for the user.
    async approveTokens() {
      await store.dispatch('pool/approveTokens', '', { root: true });
    },

    // Set the input for deposit to the user's account maximum.
    async maximumDepositInput() {
      this.depositTokenAmount = ethers.utils.formatEther(this.lpBalanceUser);
    },

    // Set the input for withdrawal to the user's account maximum.
    async maximumWithdrawInput() {
      this.withdrawTokenAmount = ethers.utils.formatEther(this.userStaked);
    },

    // Deposit tokens for the user.
    async depositTokens(amount) {
      this.stakeTokens({ amount: ethers.utils.parseEther(amount) });
    },

    // Withdraw tokens for the user.
    async withdrawTokens(amount) {
      this.unstakeTokens({ amount: ethers.utils.parseEther(amount) });
    },

    async harvestReward() {
      this.claimReward();
    },

    async updatePoolData() {
      await store.dispatch('pool/getTotalStakedBalance', '', { root: true });
      this.totalStaked = this.pool.amountStakedGlobal;

      await store.dispatch('pool/getPoolValueEth', '', { root: true });
      let poolValue = this.pool.poolValueEth.mul(this.ethPrice);
      this.totalValueLocked = poolValue;

      await store.dispatch('pool/getRewardRate', '', { root: true });
      this.rewardRate = this.pool.rewardRate;
    },

    async updateUserData() {
      await store.dispatch('pool/getStakedBalance', '', { root: true });
      this.userStaked = this.pool.amountStakedUser;

      await store.dispatch('pool/getLPBalance', '', { root: true });
      this.lpBalanceUser = this.pool.lpBalanceUser;

      await store.dispatch('pool/checkAllowances', '', { root: true });
    },

    async updateEarnings() {
      await store.dispatch('pool/getEarnedBalance', '', { root: true });
      this.earnings = this.pool.earnings;
      this.userValueLocked = this.userStaked
        .mul(this.totalValueLocked)
        .div(this.totalStaked);
    },

    async calcYearROI() {
      let secondsInYear = 3600 * 24 * 365;
      this.yearROI = this.pool.rewardRate
        .mul(this.superPriceEth)
        .mul(secondsInYear)
        .mul(100)
        .div(this.pool.poolValueEth);
    },

    async calcMonthROI() {
      let secondsInMonth = 3600 * 24 * 30;
      this.monthROI = this.pool.rewardRate
        .mul(secondsInMonth)
        .mul(this.pool.amountStakedUser)
        .div(this.pool.amountStakedGlobal);
    }
  },

  // Watch the Ethereum store for an address change to detect a wallet connection.
  watch: {
    localCanSign: {
      deep: true,
      handler: async function(newValue) {
        if (newValue) {
          await this.startPolling();
        } else {
          await this.stopPolling();
        }
      }
    }
  }
};
</script>

<style scoped>
.container {
  background-color: rgba(113, 18, 255, 0.03);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-wrapper {
  flex-grow: 1;
}

.page-info {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.title {
  margin-bottom: 16px;
  color: #000;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.subtitle {
  margin-bottom: 16px;
  color: #7112ff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.info-subtitle {
  margin-bottom: 16px;
  color: black;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.liquidity-button {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.harvest-button {
  width: 90%;
}

.input-button {
  margin-top: 10px;
}

.page-body {
  display: grid;
  width: 100%;
  max-width: 1000px;
  margin-top: 32px;
  grid-auto-flow: row;
  grid-auto-columns: minmax(420px, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  grid-template-rows: auto;
  padding: 10px 15px 80px 15px;
  margin-left: auto;
  margin-right: auto;
}

.card {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
}

.card-top {
  display: grid;
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
}

.card-title {
  text-align: left;
  color: #7112ff;
  font-size: 28px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.card-amount {
  color: #7112ff;
  font-size: 16px;
  font-weight: 500;
}

.card-amount-large {
  color: #7112ff;
  font-family: 'Fira Mono', courier;
  font-size: 35px;
  font-weight: 600;
}

.card-bottom {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(113, 18, 255, 0.2);
}

.card-flex {
  display: grid;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  align-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
}

.card-logo-wrapper {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #7112ff;
  border-radius: 50%;
  box-shadow: inset 0 0 8px 0 #7112ff;
}

.card-logo-wrapper > img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.input-wrapper {
  display: flex;
}

.input-container {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.input {
  width: 100%;
}

.max-amount {
  color: #7112ff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -50px;
  z-index: 2;
}

.max-amount:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
