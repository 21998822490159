<template>
  <div class="share-buttons-wrapper">
    <div
      v-if="socials.twitter"
      @click="openLink(socials.twitter)"
      class="social-button twitter"
    >
      <TwitterIcon />
    </div>

    <div v-if="socials.instagram" class="social-button instagram">
      <InstagramIcon />
    </div>

    <div v-if="socials.facebook" class="social-button facebook">
      <FacebookIcon />
    </div>

    <div v-if="socials.youtube" class="social-button youtube">
      <YoutubeIcon />
    </div>

    <div v-if="socials.linkedin" class="social-button linkedin">
      <LinkedinIcon />
    </div>

    <div v-if="socials.email" class="social-button email">
      <EmailIcon />
    </div>

    <div v-if="socials.twitch" class="social-button twitch ">
      <TwitchIcon />
    </div>

    <div v-if="socials.telegram" class="social-button telegram">
      <TelegramIcon />
    </div>
    <div v-if="socials.discord" class="social-button discord">
      <DiscordIcon />
    </div>
    <div v-if="socials.snapchat" class="social-button snapchat">
      <SnapchatIcon />
    </div>
  </div>
</template>

<script>
// Icon imports
import TwitterIcon from '../../icons/TwitterIcon';
import InstagramIcon from '../../icons/InstagramIcon';
import FacebookIcon from '../../icons/FacebookIcon';
import YoutubeIcon from '../../icons/YoutubeIcon';
import LinkedinIcon from '../../icons/LinkedinIcon';
import EmailIcon from '../../icons/EmailIcon';
import TwitchIcon from '../../icons/TwitchIcon';
import TelegramIcon from '../../icons/TelegramIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import SnapchatIcon from '../../icons/SnapchatIcon';

export default {
  components: {
    TwitterIcon,
    InstagramIcon,
    FacebookIcon,
    YoutubeIcon,
    LinkedinIcon,
    EmailIcon,
    TwitchIcon,
    TelegramIcon,
    DiscordIcon,
    SnapchatIcon
  },

  props: {
    socials: {
      type: Object
    }
  },

  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.share-buttons-wrapper {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: center;
}

.social-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  -webkit-transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: var(--text-color);
  opacity: 0.7;
}

.social-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
  cursor: pointer;
}

.social-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.social-button.w--current:hover {
  background-color: transparent;
}

.social-button.twitter:hover {
  color: #1da1f2;
}

.social-button.instagram:hover {
  color: #e1306c;
}

.social-button.facebook:hover {
  color: #1977f3;
}

.social-button.youtube:hover {
  color: red;
}

.social-button.linkedin:hover {
  color: #0e65c2;
}

.social-button.email:hover {
  color: #ea4336;
}

.social-button.twitch:hover {
  color: #9047ff;
}

.social-button.telegram:hover {
  color: #229ed9;
}

.social-button.discord:hover {
  color: #7289da;
}

.social-button.snapchat:hover {
  color: #ffc400;
}
</style>
