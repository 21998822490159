<template>
  <DropDetailsBanner v-if="drop" :drop="drop" />
  <DropDetailsItems v-if="drop" :drop="drop" :items="[]" />
  <NewsletterForm />
</template>

<script>
'use strict';

// Imports.
import { ref, onMounted, computed, provide } from 'vue';
import router from '../router';
import { useStore } from 'vuex';

// Component imports.
import DropDetailsBanner from '../components/pages/drop-details/DropDetailsBanner';
import DropDetailsItems from '../components/pages/drop-details/DropDetailsItems';
import NewsletterForm from '../components/pages/launchpad/NewsletterForm';

export default {
  components: {
    DropDetailsBanner,
    DropDetailsItems,
    NewsletterForm
  },

  setup() {
    const store = useStore();
    const drop = computed(() => {
      if (store.state.drops.drops)
        return store.state.drops.drops.find(drop => {
          return drop.id === router.currentRoute.value.params.drop;
        });
      else return null;
    });

    const initialize = async () => {
      await store.dispatch('drops/updateDropsData');
    };

    provide('drop', drop);

    onMounted(async () => {
      window.scrollTo(0, 0);
      await initialize();
    });

    return { drop };
  }
};
</script>

<style scoped>
.nft-section {
  padding: 30px 20px;
}

.nft-container {
  display: block;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.nft-grid {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: auto;
}
</style>
