<template>
  <BaseDropdown
    :showDropdown="search.length > 0 && flags['search.suggestions']"
    :ignoreTriggerClose="true"
    :bgShadow="false"
    class="search__dropdown"
    ref="searchDropdown"
  >
    <div
      class="header__search search"
      v-if="windowWidth > 991 || mobileSearchOpened"
    >
      <img src="@/images/search-icon.svg" alt="" class="search__icon" />
      <input
        type="text"
        class="search__input"
        placeholder="Search NFTs, Farms, and Users"
        v-model="search"
      />
      <!-- <img
        src="@/images/social/icons/cancel.svg"
        alt=""
        class="search__icon"
        style="right: 20px; left: auto; width: 10px"
        v-show="windowWidth < 991"
        @click="closeModalSearch"
      /> -->
      <search-icon
        class="search__icon search__icon_cancel"
        style="right: 20px; left: auto; width: 10px"
        @click="windowWidth > 991 ? search = '' : closeModalSearch()"
        v-show="windowWidth > 991 ? search.length > 0 : true"
      >
      </search-icon>
    </div>
    <template #dropdown>
      <feature-inline-guard requiredFlag="search.suggestions">
        <SearchSuggestions :search="search" />
      </feature-inline-guard>
    </template>
  </BaseDropdown>
</template>

<script>
import SearchIcon from '@/components/icons/CloseIcon.vue';
import {BaseDropdown} from "@/components/ui/baseDropdown";
import SearchSuggestions from "../search-suggestions/SearchSuggestions";

import withFlags from "@/utility/mixins/withFlags"
import FeatureInlineGuard from '@/components/utility/FeatureInlineGuard.vue';

export default {
  name: "HeaderSearch",
  mixins: [withFlags()],
  components: {
    BaseDropdown,
    SearchSuggestions,
    SearchIcon,
    FeatureInlineGuard,
  },
  props: {
    windowWidth: {
      type: Number,
    },
    mobileSearchOpened: {
      type: Boolean,
    },
  },
  methods: {
    closeModalSearch() {
      if (this.search.length > 0) {
        this.search = "";
        this.$refs.searchDropdown.close()
      } else {
        this.$emit("closeModalSearch")
      }
    }
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style src="../../Header.scss" scoped lang="scss"></style>
