<template>
  <div v-if="isEnded">
    <div class="ended">
      Ended
    </div>
  </div>
  <div v-else>
    <div class="live-now">
      Live Now
    </div>
    <div class="end-in">
      ends in
      <span v-if="showDays">{{ days }}d </span>
      <span v-if="showHours">{{ hours }}h </span>
      <span v-if="showMinutes">{{ minutes }}m </span>
      <span v-if="showSeconds">{{ seconds }}s </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: Date
    }
  },

  data() {
    return {
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      distance: ''
    };
  },

  computed: {
    showDays() {
      return this.distance > 1000 * 60 * 60 * 24;
    },

    showHours() {
      return this.distance > 1000 * 60 * 60;
    },

    showMinutes() {
      return this.distance > 1000 * 60;
    },

    showSeconds() {
      return this.distance > 1000;
    },

    isEnded() {
      return this.distance < 0;
    }
  },

  mounted() {
    let x = setInterval(() => {
      // Find the distance between now and the count down date
      this.distance = this.date.getTime() - new Date().getTime();

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor(
        (this.distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      // If the count down is finished
      if (this.distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }
};
</script>

<style scoped>
.live-now {
  display: flex;
  margin: auto;
  margin-bottom: 5px;
  padding: 5px 10px;
  align-items: center;
  background-color: #f12848;
  color: #fff;
  width: fit-content;
}

.end-in {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.ended {
  background-color: #fff;
  color: #000;
  margin-bottom: 20px;
  padding: 5px 8px;
}
</style>
