<template>
  <DropContainer :group-name='groupName'
                 name='selected'
                 @onDrop='applyDrop'>
    <SideCard class='trade-block'>
      <div class='trade-block-title'>
        <h3>{{groupName === 'your' ? 'Offering' : 'Requesting'}}</h3>
      </div>
      <div class='trade-block-subtitle'>
        <p>{{groupName === 'your'
            ? 'These are the items you will lose in the trade.'
            : 'These are the items you will receive in the trade.'}}
        </p>
      </div>

      <div class='items-container'>

        <DragItem v-for='item in selectedItems'
                  :key='item.id'
                  :item='item'>
          <ItemCard :item='item'/>
        </DragItem>
        <PlaceholderItems :items='placeholderItems'/>
      </div>
      <div class='add-more-section'>
        <span>And</span>
        <TextField :value='amount.value.value'
                   :on-change='amount.handleChange'
                   class='add-more-value'
                   :error-message='amount.errorMessage.value'
                   variant='native'/>
        <CurrencyFilter :model-value='currency.value.value'
                        @update:modelValue='handleChangeFieldValue("currency", $event)'
                        isSelectWithOptionIcon/>
      </div>
    </SideCard>
  </DropContainer>
</template>

<script>
  import {inject} from 'vue'
  import {mapGetters, mapActions} from 'vuex'
  import {useField} from 'vee-validate'

  import {CurrencyFilter} from '@/components/common/filters'
  import {TextField} from '@/components/ui/text-fields'

  import SideCard from '../components/SideCard'
  import ItemCard from '../components/ItemCard'
  import DropContainer from '../components/DropContainer'
  import DragItem from '../components/DragItem'
  import PlaceholderItems from '../components/PlaceholderItems'

  export default {
    components: {
      SideCard, ItemCard,
      DropContainer, DragItem,
      PlaceholderItems, CurrencyFilter,
      TextField
    },
    props: {
      groupName: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('trade', ['getSelectedItems']),
      selectedItems() {
        if (!this.getSelectedItems) {
          return []
        }

        return this.getSelectedItems[this.groupName] || []
      },
      placeholderItems() {
        const items = [0, 1, 2, 3]

        return items.filter((_, index) => !this.selectedItems[index])
      }
    },
    methods: {
      ...mapActions('trade', ['applyDrop'])
    },
    setup(props) {
      const amount = useField(`${props.groupName}.amount`)
      const currency = useField(`${props.groupName}.currency`)

      const {form} = inject('trade-form', {
        form: null
      })

      const handleChangeFieldValue = (name, value) => form?.setFieldValue(`${props.groupName}.${name}`, value)

      return {
        amount,
        currency,
        handleChangeFieldValue
      }
    }
  }
</script>

<style scoped lang='scss'>
  .trade-block {
    padding: 20px;
    box-sizing: border-box;

    &-title {
      text-align: center;
      margin-bottom: 10px;

      h3 {
        font-size: 30px;
        font-weight: 600;
      }
    }

    &-subtitle {
      margin-bottom: 20px;
      text-align: center;
    }

    .items-container {
      position: relative;
      min-height: 213px;
      display: grid;
      width: 100%;
      gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-template-rows: auto;
    }

    .add-more-section {
      display: flex;
      margin-top: 20px;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      span {
        display: block;
      }
    }

    .add-more-value {
      width: 120px;
    }
  }
</style>