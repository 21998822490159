<template>
  <div class='drop-container'
       @dragenter.prevent
       @dragover.prevent @drop='onDrop'>
    <slot/>
  </div>
</template>

<script>
  import {provide} from 'vue'

  export default {
    name: 'DropContainer',
    props: {
      groupName: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },
    methods: {
      onDrop(event) {
        const droppedData = JSON.parse(event.dataTransfer.getData('text/plain'))

        if (droppedData) {
          if ((droppedData?.groupName === this?.groupName) && (droppedData?.from !== this?.name)) {
            const returnData = {
              droppedItem: droppedData.item,
              from: droppedData.from,
              to: this.name,
              groupName: droppedData.groupName
            }

            this.$emit('onDrop', returnData, event)
          }
        }
      }
    },
    emits: ['onDrop'],
    setup(props) {
      provide('dropContainer', {
        groupName: props.groupName,
        name: props.name
      })
    }
  }
</script>