<template>
  <div class='trade-filters'>
    <div class='search-wrapper'>
      <SearchField :modelValue='filtersState.search'
                   @update:modelValue='handleSearch'
                   placeholder='Search 249 NFTs'/>
    </div>
    <div class='trade-filters-wrapper'>
      <div class='trade-filters-row'>
        <FilterContainer v-if='isCompact' :activeCount='filtersCount'>
          <BaseFilterForm :initialValues='{...mobileFilterValues}'
                          :minMaxValues='fromToMinMax'
                          :onAccept='onAcceptMobileFilters'/>
        </FilterContainer>

        <template v-else>
          <StatusFilter :modelValue='filtersState.status'
                        @update:modelValue='value => changeExactFilter({value: value, type: "status"})'/>

          <PriceFilter :modelValue='filtersState.price'
                       label='Price'
                       @onClear='value => changeExactFilter({value: value, type: "price"})'
                       @update:modelValue='$value => changeExactFilter({value: $value, type: "price"})'/>

          <CurrencyFilter :modelValue='filtersState.currency'
                          label='Currency'
                          isClearable
                          @update:modelValue='value => changeExactFilter({value: value, type: "currency"})'/>

          <CollectionsFilter :modelValue='filtersState.collections'
                             @update:modelValue='value => changeExactFilter({value: value, type: "collections"})'/>
        </template>
      </div>
      <SortBy :modelValue='filtersState.sort_by'
              class='sort-by'
              :options='sortingOptions'
              @update:modelValue='value => changeExactFilter({value: value, type: "sort_by"})'/>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import {omit} from 'lodash'

  import {SearchField} from '@/components/ui/text-fields'
  import {
    CurrencyFilter,
    StatusFilter,
    SortBy,
    CollectionsFilter,
    PriceFilter,
    BaseFilterForm,
    getFiltersCount,
    getExactSortOption
  } from '@/components/common/filters'
  import {FilterContainer} from '@/components/common/modal'
  import useWindowSize from '@/utility/hooks/windowSize'

  export default {
    components: {
      CurrencyFilter, StatusFilter,
      SortBy, CollectionsFilter,
      PriceFilter, SearchField,
      FilterContainer, BaseFilterForm
    },
    data() {
      return {
        fromToMinMax: {
          min: .1,
          max: 6
        },
        sortingOptions: [
          getExactSortOption('most_recent'),
          {value: 'most_relevant', label: 'Most Relevant'},
          getExactSortOption('price_to_low'),
          getExactSortOption('price_to_high')
        ]
      }
    },
    computed: {
      ...mapGetters('trade', ['filtersState']),
      isCompact() {
        return this.windowWidth < 992
      },
      mobileFilterValues() {
        return omit(this.filtersState, ['search', 'sort_by'])
      },
      filtersCount() {
        return getFiltersCount(this.filtersState, ['search', 'sort_by'])
      }
    },
    methods: {
      ...mapActions('trade', ['changeExactFilter', 'onAcceptMobileFilters']),
      handleSearch(e) {
        this.changeExactFilter({value: e.target.value, type: 'search'})
      }
    },
    setup() {
      const {width} = useWindowSize()

      return {windowWidth: width}
    }
  }
</script>

<style scoped lang='scss'>
  .trade-filters {
    margin-bottom: 20px;
  }

  .trade-filters-wrapper {
    display: flex;
    align-items: center;

    .sort-by {
      margin-left: 10px;

      :deep .select-action {
        width: 139px;
      }
    }

    @include media-max-width($smallScreen) {
      flex-direction: column;

      .trade-filters-row {
        width: 100%;
        margin-bottom: 10px;

        :deep .select-button {
          width: 100%;
        }
      }

      .sort-by {
        margin-left: 0;
        width: 100%;

        :deep .select-button {
          width: 100%;
        }
      }
    }
  }

  .trade-filters-row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    gap: 10px;
  }

  .search-wrapper {
    margin-bottom: 15px;
  }
</style>
