<template>
  <div class="itemBuyArea__bid" v-if="noPrice || isAuction">
    {{ headerText }}
  </div>
  <slot></slot>
  <div class="itemBuyArea__message" v-if="noPrice">
    Item is not for sale, but you can make an offer.
  </div>

  <Button
    v-else-if="isDefault"
    class="itemBuyArea__button"
    @click.stop="purchaseFarmedItem"
  >
    <template v-slot:default>
      <p>{{ buttonText }}</p>
    </template>
  </Button>
</template>

<script>
'use strict';

// Imports.
import store from '/src/store';
import { mapState, mapActions } from 'vuex';

// Component imports.
import Button from '/src/components/ui/Button';

// Set up the default component.
export default {
  components: {
    Button
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      itemModal: state => state.itemModal
    }),

    buttonText() {
      if (this.isDefault) {
        return 'Buy Now';
      } else if (this.noPrice) {
        return 'Make an Offer';
      } else if (this.isAuction) {
        return 'Place a Bid';
      } else {
        return '';
      }
    },

    headerText() {
      return this.noPrice ? 'Highest Offer' : 'Top Bid';
    },

    isDefault() {
      return true;
    },
    noPrice() {
      return false;
    }
  },

  methods: {
    ...mapActions('auction', [
      'updateAuctionData',
      'updateUserBidStatus',
      'placeBid'
    ]),

    async purchaseFarmedItem() {
      let groupId = this.itemModal.item.groupId;
      await store.dispatch('items/purchaseLaunchpadItem', {
        shopAddress: this.itemModal.item.shop,
        poolId: 0,
        groupId: groupId,
        assetId: 0,
        amount: 1
      }, {
        root: true
      });
    }
  }
};
</script>

<style scoped>
.itemBuyArea__button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
}
.itemBuyArea__bid {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}
.itemBuyArea__message {
  margin-bottom: 20px;
  opacity: 0.5;
}

.itemBuyArea__countdown {
  margin-bottom: 20px;
}
.itemBuyArea__countdownText {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
</style>
