<template>
  <MyItemsModal :isOpen="isOpen">
    <template v-slot:title>
      <p>Transfer</p>
    </template>
    <template v-slot:form>
      <div class="form">
        <form>
          <label class="form-label">Quantity:</label>
          <input
            class="form-input"
            :class="isReadonly ? 'read-only' : ''"
            type="number"
            maxlength="256"
            v-model="quantity"
            :readonly="quantity === 1"
          />
          <label class="form-label"
            >Destination Wallet Address or ENS Name:</label
          >
          <input class="form-input" maxlength="256" v-model="destination" />
        </form>
      </div>
    </template>
    <template v-slot:buttonText>
      <p>Transfer</p>
    </template>
  </MyItemsModal>
</template>

<script>
// Component Imports
import MyItemsModal from './MyItemsModal';

export default {
  components: {
    MyItemsModal
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      quantity: 2,
      destination: ''
    };
  },

  computed: {
    isReadonly() {
      return this.quantity === 1;
    }
  }
};
</script>

<style scoped>
.form {
  text-align: left;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.form-input {
  position: relative;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: border-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 17px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.form-input:last-child {
  margin-bottom: 0px;
}

.form-input:hover {
  border-color: rgba(0, 0, 0, 0.5);
}

.form-input:focus {
  outline: none;
  border-width: 2px;
  border-color: #000;
}

.read-only {
  color: gray;
  background-color: whitesmoke;
  cursor: default;
}
.read-only:hover {
  border-color: rgba(0, 0, 0, 0.2);
}

.read-only:focus {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}
</style>
