<template>
  <div class="section about">
    <div class="container">
      <div class="about-section-top-grid">
        <div>
          <blockquote class="block-quote">
            “SuperFarm is committed to innovating and being the leader in NFTs.”
          </blockquote>
          <div class="quote-by-line">
            <img
              src="../../../images/about_us/unknown-3.png"
              loading="lazy"
              alt=""
              class="image-18"
            />
            <div>
              <strong>Elliot Wainman</strong><br />aka EllioTrades<br />Co-Founder
              &amp; CEO
            </div>
          </div>
        </div>
        <div class="features-grid">
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/wheat.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>NFT Farming</div>
          </div>
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/create-new-pencil-button.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>NFT Generation</div>
          </div>
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/gamepad.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>Video Games</div>
          </div>
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/786331.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>Celebrities &amp; Brands</div>
          </div>
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/907884.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>Influencers</div>
          </div>
          <div class="about-section-icon-wrapper">
            <div class="feature-wrapper">
              <img
                src="../../../images/about_us/67745.svg"
                loading="lazy"
                alt=""
                class="about-section-icon"
              />
            </div>
            <div>Art</div>
          </div>
        </div>
      </div>
      <div class="about-section-middle">
        <div class="values-wrapper">
          <div class="value-item">
            <img
              src="../../../images/about_us/group.svg"
              loading="lazy"
              alt=""
              class="value-icon"
            />
            <div>
              We believe in the power of a community and releasing regular
              product releases based on community wants.
            </div>
          </div>
          <div class="value-item">
            <img
              src="../../../images/about_us/link.svg"
              loading="lazy"
              alt=""
              class="value-icon"
            />
            <div>We believe in Interoperability and cross-chain.</div>
          </div>
          <div class="value-item">
            <img
              src="../../../images/about_us/programming-code-signs.svg"
              loading="lazy"
              alt=""
              class="value-icon"
            />
            <div>
              We believe anyone should easily be able to create, buy and sell
              NFTs without writing any code.
            </div>
          </div>
          <div class="value-item">
            <img
              src="../../../images/about_us/earnings.svg"
              loading="lazy"
              alt=""
              class="value-icon"
            />
            <div>
              We believe in creators having power and the freedom so they can
              earn more.
            </div>
          </div>
        </div>
        <div class="superfarm-thrives">
          SuperFarm thrives to make NFTs easier and more accessible by
          innovating ways to create, farm and sell NFTs.
        </div>
      </div>
      <div class="about-section-bottom">
        <div class="nfts-future">We believe NFTs are the future.</div>
        <a
          href="https://superfarm.com/#/roadmap"
          target="_blank"
          class="link-to-roadmap"
          >Check out our roadmap to see what new features are coming next →</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  font-family: 'Work Sans', sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 1.3;
}

.superfarm-thrives {
  max-width: 50ch;
  margin-right: auto;
  margin-left: auto;
  font-size: 35px;
  line-height: 1.3em;
  font-weight: 200;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.section {
  padding: 60px 30px;
}

.section.about {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #000;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.2)),
      to(rgba(0, 0, 0, 0.2))
    ),
    url('../../../images/about-us/purple-bg.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url('../../../images/about_us/purple-bg.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.block-quote {
  margin-bottom: 40px;
  padding-right: 0px;
  border-left-color: #24e7fc;
  font-size: 35px;
  line-height: 1.3em;
  font-weight: 600;
}

.values-wrapper {
  display: -ms-grid;
  display: grid;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 16px;
  line-height: 1.3em;
}

.about-section-middle {
  display: -ms-grid;
  display: grid;
  margin-bottom: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.features-grid {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.about-section-top-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 60px;
  padding-bottom: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #fff;
}

.quote-by-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-section-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.value-icon {
  max-width: 40px;
  margin-bottom: 20px;
}

.link-to-roadmap {
  display: inline-block;
  color: #24e7fc;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
}

.link-to-roadmap:hover {
  text-decoration: underline;
}

.about-section-icon {
  max-height: 40px;
  max-width: 40px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.nfts-future {
  margin-bottom: 40px;
  font-size: 35px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
}

.about-section-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.value-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 18px;
  font-weight: 300;
}

.image-18 {
  width: 60px;
  margin-right: 10px;
  border-radius: 100px;
}

.feature-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .about-section-middle {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .about-section-top-grid {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .values-wrapper {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .about-section-middle {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .features-grid {
    grid-column-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .about-section-top-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .superfarm-thrives {
    font-size: 25px;
  }

  .section {
    padding: 30px 15px;
  }

  .block-quote {
    font-size: 30px;
  }

  .values-wrapper {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .features-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
