"use strict";

// Imports.
import { userService } from "../services";
import router from "../router";

// Specify state for this module.
const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

// Specify actions that can be performed by this module.
const actions = {
  // Use the user service to call and handle a register request.
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);
    userService.register(user).then(
      response => {
        user = response.data;
        commit("registerSuccess", user);
        router.push("/login");

        // Display a success message after the route change completes.
        setTimeout(() => {
          dispatch("alert/success", "Registration successful", { root: true });
        });
      },
      error => {
        commit("registerFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  // Use the user service to call and handle a login request.
  login({ dispatch, commit }, { email, password }) {
    commit("loginRequest", { email });
    userService.login(email, password).then(
      response => {
        let responseUser = response.data;

        // If the login is successful, store our user information and proceed to
        // the authenticated application home page.
        if (responseUser.SessionTicket) {
          localStorage.setItem("user", JSON.stringify(responseUser));
        }
        commit("loginSuccess", responseUser);
        router.push("/");
      },
      error => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  // Use the user service to logout.
  logout({ commit }) {
    userService.logout();
    commit("logout");
    router.push("/login");
  }
};

// Specify state mutations on specific events.
const mutations = {
  // Registration events.
  registerRequest(state, user) {
    state.status = { registering: true };
  },
  registerSuccess(state, user) {
    state.status = {};
    state.user = user;
  },
  registerFailure(state, error) {
    state.status = {};
  },

  // Login events.
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },

  // Logout event.
  logout(state) {
    state.status = {};
    state.user = null;
  }
};

// Export the account module.
export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
