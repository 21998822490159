<template>
  <div class="container">
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: center;
        margin-top: 12pt;
        margin-bottom: 12pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: 'Times New Roman';
          color: rgb(255, 255, 255);
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: small-caps;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre-wrap;
        "
        >SUPERFARM</span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: center;
        margin-top: 12pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: small-caps;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >COPYRIGHT POLICY</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 14pt;
        margin-bottom: 14pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >Notification of Copyright Infringement:</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >SuperFarm Foundation (&ldquo;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >SuperFarm</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&rdquo;) respects the intellectual property rights of others and
          expects its users to do the same. &nbsp;</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >It is SuperFarm&rsquo;s policy, in appropriate circumstances and at
          its discretion, to disable and/or terminate the accounts of users who
          repeatedly infringe or are repeatedly charged with infringing the
          copyrights or other intellectual property rights of others.</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >In accordance with the Digital Millennium Copyright Act of 1998, the
          text of which may be found on the U.S. Copyright Office website
          at&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >http://www.copyright.gov/legislation/dmca.pdf</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >, SuperFarm will respond expeditiously to claims of copyright
          infringement committed using the SuperFarm website (the &ldquo;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >Site</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&rdquo;) that are reported to SuperFarm&rsquo;s Designated Copyright
          Agent, identified in the sample notice below.&nbsp;</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >If you are a copyright owner, or are authorized to act on behalf of
          one, or authorized to act under any exclusive right under copyright,
          please report alleged copyright infringements taking place on or
          through the Site by completing the following DMCA Notice of Alleged
          Infringement and delivering it to SuperFarm&rsquo;s Designated
          Copyright Agent. &nbsp;Upon receipt of the Notice as described below,
          SuperFarm</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >will take whatever action, in its sole discretion, it deems
          appropriate, including removal of the challenged material from the
          Site.</span
        ></span
      >
    </p>
    <h2
      dir="ltr"
      style="
        line-height: 1.2;
        text-indent: 36pt;
        text-align: justify;
        margin-top: 12pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >DMCA Notice of Alleged Infringement (</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&ldquo;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >Notice</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&rdquo;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >)</span
        ></span
      >
    </h2>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-indent: -36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
        padding: 0pt 0pt 0pt 36pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >1.<span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;</span
          >Identify the copyrighted work that you claim has been infringed, or -
          if multiple copyrighted works are covered by this Notice - you may
          provide a representative list of the copyrighted works that you claim
          have been infringed.</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-indent: -36pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
        padding: 0pt 0pt 0pt 36pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >2.<span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;</span
          >Identify the material that you claim is infringing (or to be the
          subject of infringing activity) and that is to be removed or access to
          which is to be disabled, and information reasonably sufficient to
          permit us to locate the material, including at a minimum, if
          applicable, the URL of the link shown on the Site(s) where such
          material may be found.</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >3.<span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;</span
          >Provide your mailing address, telephone number, and, if available,
          email address.</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >4.<span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;</span
          >Include both of the following statements in the body of the
          Notice:</span
        ></span
      >
    </p>
    <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: 'Times New Roman';
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 54pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span style="color: rgb(255, 255, 255)"
            ><span
              style="
                font-size: 11pt;
                font-family: 'Times New Roman';
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
              "
              >&ldquo;I hereby state that I have a good faith belief that the
              disputed use of the copyrighted material is not authorized by the
              copyright owner, its agent, or the law (e.g., as a fair
              use).&rdquo;</span
            ></span
          >
        </p>
      </li>
      <li
        dir="ltr"
        style="
          list-style-type: disc;
          font-size: 11pt;
          font-family: 'Times New Roman';
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 54pt;
        "
      >
        <p
          dir="ltr"
          style="
            line-height: 1.2;
            text-align: justify;
            margin-top: 0pt;
            margin-bottom: 12pt;
          "
        >
          <span style="color: rgb(255, 255, 255)"
            ><span
              style="
                font-size: 11pt;
                font-family: 'Times New Roman';
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre-wrap;
              "
              >&ldquo;I hereby state that the information in this Notice is
              accurate and, under penalty of perjury, that I am the owner, or
              authorized to act on behalf of the owner, of the copyright or of
              an exclusive right under the copyright that is allegedly
              infringed.&rdquo;</span
            ></span
          >
        </p>
      </li>
    </ul>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >5.<span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;</span
          >Provide your full legal name and your electronic or physical
          signature.</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 12pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          ><span class="Apple-tab-span" style="white-space: pre"
            >&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span
          >Deliver this Notice, with all items completed, to</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >&nbsp;</span
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >SuperFarm&rsquo;s Designated Copyright Agent:</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >Copyright Agent<br />c/o SuperFarm</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >OMC Chambers, Wickhams Cay 1,</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span style="color: rgb(255, 255, 255)"
        ><span
          style="
            font-size: 11pt;
            font-family: 'Times New Roman';
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >Road Town, Tortola</span
        ></span
      >
    </p>
    <p
      dir="ltr"
      style="
        line-height: 1.2;
        margin-left: 36pt;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          font-family: 'Times New Roman';
          color: rgb(255, 255, 255);
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre-wrap;
        "
        >British Virgin Islands &nbsp;</span
      >
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style scoped>
.container {
  background-color: #7112ff;
  padding: 30px 50px;
}

* {
  color: white !important;
  font-family: Work Sans !important;
}
</style>
