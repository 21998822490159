<template>
  <div class='container'><slot/></div>
</template>

<style scoped lang='scss'>
  .container {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
</style>