<template>
  <label class='radio'>
    <div class='radio-input'>
      <input v-bind='$attrs'
             name='radio'
             type='radio'/>
      <div class='radio-control'/>
    </div>
    <span class='radio-text'>{{label}}</span>
  </label>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang='scss'>
  .radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 11px;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  .radio-input {
    display: flex;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      pointer-events: none;

      &:checked + .radio-control {
        border-width: 4px;
        border-color: $violet;
        box-shadow: 0 0 0 transparent;
      }

      &:focus + .radio-control {
        box-shadow: 0 0 3px 1px rgb(113 118 255 / 50%);
      }
    }

    .radio-control {
      width: 14px;
      height: 14px;
      margin-top: 0;
      border: 1px solid #696969;
      border-radius: 50%;
    }
  }

  .radio-text {
    display: inline-block;
    font-weight: normal;
  }
</style>