"use strict";

// Export state mutation functions.
export default {
  setUpcomingPools(state, pools) {
    state.upcoming = pools;
  },
  setFeaturedPools(state, pools) {
    state.featured = pools;
  },
  setSinglePool(state, pool) {
    state.single = pool;
  },
  setIsLoadingUpcoming(state, value) {
    state.isLoadingUpcoming = value;
  },
  setIsLoadingFeatured(state, value) {
    state.isLoadingFeatured = value;
  },
  setIsLoadingSingle(state, value) {
    state.isLoadingSingle = value;
  }
};
