<template>
  <DropdownContent class='menu-content' height='563px' width='400px'>
    <div class='menu-content-header'>
      <h3>Notifications</h3>
      <router-link :to="{name: 'Notifications'}" class='view-all-link'>
        <Button is-text>View all</Button>
      </router-link>
    </div>
    <div class='menu-content-container'>
      <template v-if='isLoading'>
        <skeleton v-for='index in 6' :key='index' is-centered/>
      </template>
      <template v-else>
        <NotificationCard v-for='(notification, index) in notifications'
                           :key='index'
                           :data='notification'
                           is-show-unread-marker
                           :is-with-border='false'/>
      </template>
    </div>
  </DropdownContent>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  import NotificationCard from '@/components/common/notificationCard'
  import Skeleton from '@/components/common/notificationCard/Skeleton'
  import Button from '@/components/ui/Button'
  import {DropdownContent} from '@/components/ui/baseDropdown'

  export default {
    props: {
      badgeCount: {
        type: Number,
        required: true
      },
      onClearBadgeCount: {
        type: Function,
        required: false
      }
    },
    components: {
      NotificationCard, Button,
      Skeleton, DropdownContent
    },
    data: () => ({
      isLoading: false
    }),
    computed: {
      ...mapState({notifications: state => state.notifications.latestNotifications})
    },
    methods: {
      ...mapActions('notifications', ['fetchLatestNotifications']),
      async fetchData() {
        this.isLoading = true

        try {
          await this.fetchLatestNotifications(this.badgeCount)

          this?.onClearBadgeCount()
        } finally {
          this.isLoading = false
        }
      }
    },
    mounted() {
      this.fetchData()
    }
  }
</script>

<style scoped lang='scss'>
  .menu-content {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .view-all-link {
    text-decoration: none;
  }

  :deep .skeleton {
    padding: 10px;
  }

  :deep .notification-card {
    padding: 10px;
    transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);

    &:not(.offer) {
      .content-wrapper {
        flex-direction: row;
        align-items: center;
      }
    }

    &:hover {
      background-color: rgba(var(--text-color-rgb), .1);
    }
  }

  .menu-content-container {
    flex-grow: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }

  .menu-content-header {
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(var(--text-color-rgb), .2);

    h3 {
      font-size: 20px;
      font-weight: 600;
    }
  }
</style>
