<template>
  <div class='filters'>
    <div class='content'>
      <FilterContainer v-if='isCompact' :activeCount='filtersCount'>
        <MobileFilterContent :initialValues='[...filters.statuses]'/>
      </FilterContainer>

      <FiltersList v-if='!isCompact'
                   :modelValue='[...filters.statuses]'
                   @update:modelValue='changeFilter'/>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  import {getFiltersCount} from '@/components/common/filters'
  import {FilterContainer} from '@/components/common/modal'
  import useWindowSize from '@/utility/windowSize'

  import FiltersList from './FiltersList'
  import MobileFilterContent from './MobileFilterContent'

  export default {
    components: {
      FiltersList,
      FilterContainer,
      MobileFilterContent
    },
    props: {
      values: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState({
        filters: state => state.notifications.filters,
      }),
      isCompact() {
        return this.windowSize < 992
      },
      filtersCount() {
        return getFiltersCount(this.filters.statuses)
      }
    },
    methods: {
      ...mapActions('notifications', ['changeFilter']),
    },
    setup() {
      const {width} = useWindowSize()

      return {
        windowSize: width
      }
    }
  }
</script>

<style scoped lang='scss'>
  .filters {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .content {
    :deep .list {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      gap: 10px;
      grid-template-columns: auto;
      grid-template-rows: auto;
    }

    @include media-max-width($smallScreen) {
      display: flex;
      flex-direction: column;
      width: 100%;

      :deep .select-button {
        width: 100%;
      }
    }
  }
</style>
