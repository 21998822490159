<template>
  <div class='skeleton'>
    <skeletor height='800'/>
    <div class='skeleton-content'>
      <div class='skeleton-event'>
        <skeletor width='100%' height='18' pill/>
      </div>
      <div class='skeleton-info'>
        <div class='skeleton-info-left'>
          <skeletor width='200' height='18' pill/>
        </div>
        <div>
          <skeletor width='80' height='18' pill/>
        </div>
      </div>
      <div class='skeleton-price'>
        <div>
          <skeletor width='50' height='15' pill/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Skeletor} from 'vue-skeletor'

  export default {
    components: {
      Skeletor
    }
  }
</script>

<style scoped lang='scss'>
  .skeleton {
    display: flex;
    position: relative;
    flex-direction: column;
    border-style: none;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: $white;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    text-decoration: none;

    &-content {
      padding: 20px;
    }

    &-event {
      margin-bottom: 10px;
    }

    &-info {
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: flex-start;

      &-left {
        display: flex;
        flex-direction: column;

        :first-child {
          margin-bottom: 2px;
        }
      }
    }

    &-price {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & > div {
        :first-child {
          margin-bottom: 2px;
        }
      }
    }
  }
</style>