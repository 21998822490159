'use strict';

// Export state mutation functions.
export default {

	updateTokenApproval (state, amount) {
		state.approvedAmount = amount;
	},
	updateTotalStakedBalance (state, totalStaked) {
    state.amountStakedGlobal = totalStaked;
  },
	updateUserStakedBalance (state, balance) {
		state.amountStakedUser = balance;
	},
  updateEarnedBalance (state, earnings) {
		state.earnings = earnings;
	},
  updatePeriodFinish (state, periodFinish) {
		state.periodFinish = periodFinish;
	},
  updatePoolValueEth (state, ethValue) {
    state.poolValueEth = ethValue;
  },
  updateRewardRate (state, rewardRate) {
    state.rewardRate = rewardRate;
  },
	updateAllowanceAmount (state, allowanceAmount) {
		state.approvedAmount = allowanceAmount;
	},
	updateLPBalance (state, balance) {
		state.lpBalanceUser = balance;
	}
};
