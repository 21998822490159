<template>
  <Section class='trade-section'>
    <Container>
      <div class='title-wrapper'>
        <h2>Trading with username</h2>
      </div>
      <div class='sides-container'>
        <LeftSide/>
        <RightSide/>
      </div>
    </Container>
  </Section>
</template>

<script>
  import {Section, Container} from '@/components/layout'

  import LeftSide from './leftSide'
  import RightSide from './rightSide'

  export default {
    components: {
      Section, Container,
      LeftSide, RightSide
    },
    created() {
      document.title = 'SuperFarm Trade'
    }
  }
</script>

<style scoped lang='scss'>
  .trade-section {
    background-color: var(--trade-page-background);
    flex: 1
  }

  .title-wrapper {
    margin-bottom: 30px;
    text-align: center;

    & > * {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .sides-container {
    display: grid;
    align-items: start;
    gap: 20px;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
</style>