<template>
  <BaseDropdown ref="dropdown">
    <SelectButton class='select-action' icon-position='right'>
      <template #icon>
        <slot v-if='isHasIcon' name='icon'/>
        <ChevronDownIcon v-else/>
      </template>
      <template #default>
        <div class='select-option-row'>
          <div v-if='optionIcon' class='select-option-icon-wrapper'>
            <component class='select-option-icon' :is='optionIcon'/>
          </div>
          <span class='select-button-text'>{{!!label ? label : modelValue?.label || '-'}}</span>
        </div>
      </template>
    </SelectButton>
    <template #dropdown>
      <DropdownContent :width='contentWidth'
                       :max-height='contentMaxHeight'
                       :height='contentHeight'>
        <SelectList :modelValue='modelValue'
                    :isMultiple='isMultiple'
                    :isClearable='isClearable'
                    :options='options'
                    @singleChange='singleChange'
                    @update:modelValue='handleChange'/>
      </DropdownContent>
    </template>
  </BaseDropdown>
</template>

<script>
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import {ChevronDownIcon} from '@/components/icons'

  import SelectButton from './SelectButton'
  import SelectList from './SelectList'

  export default {
    components: {
      BaseDropdown, DropdownContent,
      SelectButton, ChevronDownIcon,
      SelectList
    },
    props: {
      modelValue: {
        type: [Array, Object],
        default: null,
        required: false
      },
      isMultiple: {
        type: Boolean,
        required: false,
        default: false
      },
      isClearable: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        required: false
      },
      options: {
        type: Array,
        required: true,
        default: () => []
      },
      contentWidth: {
        type: String,
        required: false
      },
      contentHeight: {
        type: String,
        required: false
      },
      contentMaxHeight: {
        type: String,
        required: false
      },
      isSelectWithOptionIcon: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      isHasIcon() {
        return !!this.$slots.icon
      },
      optionIcon() {
        if (this.isSelectWithOptionIcon) {
          const foundOption = this.options.find(item => item?.value === this.modelValue?.value)

          if (foundOption) {
            return foundOption?.icon || null
          }
        }

        return null
      }
    },
    methods: {
      handleChange(option) {
        this.$emit('update:modelValue', option)
      },
      singleChange() {
        this.$refs?.dropdown?.click()
      }
    },
    emits: ['update:modelValue']
  }
</script>

<style scoped lang='scss'>
  .select-option-row {
    display: flex;
    align-items: center;
  }

  .select-option-icon-wrapper {
    display: flex;
  }

  .select-option-icon {
    margin-right: 10px;
  }

  .select-button-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  @include media-max-width($smallScreen) {
    :deep .dropdown__list {
      width: 100%;
    }
  }
</style>