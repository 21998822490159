export default {
  setActivityList(state, payload) {
    state.activities = payload;
  },
  clearActivityList(state) {
    state.activities = [];
  },
  setActivityTabFIlter(state, payload) {
    state.filters[payload.tab] = state.filters[payload.tab].concat(payload.filter);
  },
  deleteActivityTabFilter(state, payload) {
    state.filters[payload.tab] = state.filters[payload.tab].filter((filter) => {
      return filter !== payload.filter;
    });
  },
  deleteAllTabFilters(state, payload) {
    state.filters[payload.tab] = []
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
};
