<template>
  <Header>
    <div class='mobile-filter-header-content'>
      <div class='mobile-filter-clear-button'>
        <Button is-text @click='onClear'>Clear</Button>
      </div>
      <h3>Filter</h3>
    </div>
  </Header>
</template>

<script>
  import Button from '@/components/ui/Button'

  import Header from '../Header'

  export default {
    components: {Button, Header},
    props: {
      onClear: Function
    }
  }
</script>

<style scoped lang='scss'>
  .mobile-filter-header-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 20px;
      line-height: 1.3em;
      font-weight: 700;
    }
  }

  .mobile-filter-clear-button {
    position: absolute;
    left: 20px;
    top: 15px;
    font-size: 16px;
  }
</style>