<template>
  <div @mouseenter.stop="">
    <transition name="fade" appear>
      <div class="overlay" @click.stop="exitModal" v-if="isOpen" />
    </transition>
    <transition name="slide" appear>
      <div class="modal" v-if="isOpen">
        <div class="close-button" @click.stop="exitModal">
          <CloseIcon />
        </div>
        <div class="modal-header-wrapper">
          <div class="modal-title">
            <slot name="title"> </slot>
          </div>
        </div>
        <div class="modal-section">
          <img
            v-if="mediaType === 'png' || mediaType === 'jpg'"
            :src="nft.value.metadata.image"
            class="modal-image"
            @click="toggleDetails"
          />
          <video
            class="modal-image"
            v-if="mediaType === 'mp4'"
            autoplay
            loop
            muted
            :key="nft.value.metadata.video"
          >
            <!-- TODO support animation_url as fallback to video. -->
            <!-- TODO: no video on card preview -->
            <source
              :src="nft.value.metadata.video"
              :type="`video/${mediaType}`"
            />
          </video>

          <!-- <img class="modal-image" src="../../images/profile-avatar-placeholder.gif" /> -->
          <div class="modal-content-wrapper">
            <div class="modal-info-wrapper">
              <div class="item-name">{{ nft.value.metadata.name }}</div>
              <div class="collection-name">
                {{ nft.value.metadata.collection }}
              </div>
              <div class="token-id">Token ID# {{ token_id }}</div>
            </div>
            <slot name="form"> </slot>
          </div>
        </div>
        <div class="modal-footer">
          <Button class="buttonWrapper" :isDisabled='isDisabled' @click="buttonPressed">
            <slot name="buttonText"> </slot>
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CloseIcon from '/src/components/icons/CloseIcon';
import Button from '../../../ui/Button';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Button,
    // Icons
    CloseIcon
  },

  emits: ['exit', 'buttonPressed'],

  inject: ['nft'],

  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean
    }
  },

  data() {
    return {
      nft_name: 'Sample NFT Name',
      collection_name: 'Collection Name',
      token_id: '123098124241'
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      itemModal: state => state.itemModal,
      auction: state => state.auction
    }),

    mediaType() {
      console.log(this.nft.value);
      let video = this.nft.value.metadata.video;
      let animationUrl = this.nft.value.metadata['animation_url'];
      let image = this.nft.value.metadata.image;
      if (video) {
        let videoPath = this.nft.value.metadata.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.nft.value.metadata['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.nft.value.metadata.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    }
  },

  methods: {
    exitModal() {
      this.$emit('exit');
    },

    beforeLeave(el) {
      console.log(el);
    },

    buttonPressed() {
      if(!this.isDisabled){
        this.$emit('buttonPressed');
        console.log(this.nft.value.metadata);
        console.log(this.itemModal.item.metadata);
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 640px;
  height: 440px;
  max-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  background-color: var(--background-accent);
  z-index: 999;
  cursor: default;
}

.overlay {
  content: '';
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: black;
  opacity: 0.6;
  cursor: default;
}

.modal-header-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-title {
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 700;
}

.modal-section {
  display: grid;
  overflow: visible;
  height: 100%;
  padding: 20px;
  align-items: center;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
}

.modal-image {
  height: 233px;
  width: 233px;
  object-fit: contain;
}

.modal-content-wrapper {
  display: grid;
  align-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.modal-info-wrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  text-align: left;
  margin-top: 5px;
}

.buttonWrapper {
  width: 100%;
}

.item-name {
  font-size: 24px;
  margin-bottom: 5px;
}

.token-id {
  opacity: 0.5;
}

.modal-footer {
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  right: 13px;
  top: 15px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.close-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.close-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.close-button > img {
  height: 12px;
  width: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(200px);
}
</style>
