<template>
  <select class='select' :class='{"is-dark-mode": isDarkMode}' v-bind='$attrs'>
    <slot/>
  </select>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters('theme', ['isDarkMode'])
    }
  }
</script>

<style scoped lang='scss'>
  .select {
    margin-top: 10px;
    width: 100%;
    padding: 8px 12px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(113, 18, 255, 0.2);
    border-radius: 10px;
    background-color: var(--background-color);
    background-image: url("~@/images/chevron-down.svg");
    background-position: 97% 50%;
    background-size: auto;
    background-repeat: no-repeat;
    transition: border-color 200ms ease;
    outline: none;
    height: 38px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    appearance: none;
    font: -moz-pull-down-menu;

    :deep option {
      font: -moz-pull-down-menu;
    }

    &:hover {
      border-color: rgba(113, 18, 255, 0.5);
    }

    &:focus {
      border-width: 2px;
      border-color: $violet;
    }

    &.is-dark-mode {
      color: $white;
      border-color: rgb(255, 255, 255, .2);
      background-image: url("~@/images/chevron-down-white.svg");

      &:hover {
        border-color: rgb(255, 255, 255, .5);
      }

      &:focus {
        border-color: $white;
      }
    }
  }
</style>
