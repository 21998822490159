'use strict';

// Imports.
import { poolService } from '../../services';

// Specify actions that can be performed by this module.
export default {

  async registerListener ({ dispatch, commit }, { event, listener }) {
    await poolService.registerListener(event, listener);
  },

  // TODO: eventually consume a specific token as a parameter; when we support multiple tokens.
  async approveTokens ({ dispatch, commit }) {
    try {
      let amount = await poolService.approveTokens();
      commit('updateTokenApproval', amount);

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getTotalStakedBalance ({ dispatch, commit }) {
    try {
      let totalStaked = await poolService.getTotalStakedBalance();
      commit('updateTotalStakedBalance', totalStaked);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getStakedBalance ({ dispatch, commit }) {
    try {
      let amount = await poolService.getStakedBalance();
      commit('updateUserStakedBalance', amount);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getEarnedBalance ({ dispatch, commit }) {
    try {
      let earnings = await poolService.getEarnedBalance();
      commit('updateEarnedBalance', earnings);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getPeriodFinish ({ dispatch, commit }) {
    try {
      let periodFinish = await poolService.getPeriodFinish();
      commit('updatePeriodFinish', periodFinish);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getPoolValueEth ({ dispatch, commit }) {
    try {
      let ethValue = await poolService.getPoolValueEth();
      commit('updatePoolValueEth', ethValue);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRewardRate ({ dispatch, commit }) {
    try {
      let rewardRate = await poolService.getRewardRate();
      commit('updateRewardRate', rewardRate);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async checkAllowances ({ dispatch, commit }) {
    try {
      let allowance = await poolService.checkAllowances();
      commit('updateAllowanceAmount', allowance);
    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getLPBalance ({ dispatch, commit }) {
    try {
      let balance = await poolService.getLPBalance();
      //console.log("balance", balance);
      commit('updateLPBalance', balance);
    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

// writable

  async stakeTokens ({ dispatch, commit }, {amount}) {
    try {
      let stakedAction = await poolService.stakeTokens(amount);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async unstakeTokens ({ dispatch, commit }, {amount}) {
    try {
      let unstakedAction = await poolService.unstakeTokens(amount);
      //commit('updateRewardRate', rewardRate);

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async claimReward ({ dispatch }) {
    try {
      let claimAction = await poolService.claimReward();

    // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

};