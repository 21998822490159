<template>
  <Autocomplete :modelValue='modelValue'
                isMultiple
                :requestOptions='requestOptions'
                @update:modelValue='handleChange'/>
</template>

<script>
  import Autocomplete from '@/components/common/Autocomplete'

  export default {
    components: {Autocomplete},
    props: {
      modelValue: {
        type: Array,
        required: true
      }
    },
    methods: {
      requestOptions(search) {
        // TODO add api request to get list of collections

        return [
          {value: 'collection-1', label: 'Collection 1'},
          {value: 'collection-2', label: 'Collection 2'},
          {value: 'collection-3', label: 'Collection 3'},
          {value: 'collection-4', label: 'Collection 4'},
          {value: 'collection-5', label: 'Collection 5'},
          {value: 'collection-6', label: 'Collection 6'},
          {value: 'collection-7', label: 'Collection 7'}
        ]
      },
      handleChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>
