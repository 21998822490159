'use strict';

// Imports.
import { auctionService } from '../../services';

// Specify actions that can be performed by this module.
export default {

  async registerListener ({ dispatch, commit }, { event, listener }) {
    await auctionService.registerListener(event, listener);
  },
  // no signer required / pub - not logged in
  async updateAuctionData ({ dispatch, commit }) {
    try {
      let auctionData = await auctionService.getAuctionData();
      commit('updateAuctionData', auctionData);

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async updateBidHistory ({ dispatch, commit }) {
    try {
      let bidHistory = await auctionService.getBidHistory();
      commit('updateBidHistory', bidHistory);

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async startBidListener ({ dispatch, commit }) {
    try {
      await auctionService.listenForBids(dispatch);

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },
  // signer required
	async updateUserBidStatus ({ dispatch, commit }) {
		try {
      let userIsHighBid = await auctionService.getUserBidStatus();
      commit('updateUserBidStatus', userIsHighBid);

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
	},

  async placeBid ({ dispatch, commit}, { amount }) {
    try {
      await auctionService.placeBid(dispatch, amount);

      let userIsHighBid = await auctionService.getUserBidStatus();
      commit('updateUserBidStatus', userIsHighBid);

      let auctionData = await auctionService.getAuctionData();
      commit('updateAuctionData', auctionData);

      let bidHistory = await auctionService.getBidHistory();
      commit('updateBidHistory', bidHistory);
    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async acceptResults({ dispatch, commit }) {
    try {
      let accept = await auctionService.acceptResults();

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async declineResults({ dispatch, commit }) {
    try {
      let accept = await auctionService.declineResults();

    // Catch any errors from the transaction.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }

};
