import {every, forEach, isEmpty, keys, omit} from 'lodash'

export const getFiltersCount = (filters, excludeKeys) => {
    if (isEmpty(filters)) return 0

    const list = []

    forEach(keys(omit(filters, excludeKeys)), key => {
        const value = filters[key]
        const type = typeof value

        if (!isEmpty(value)) {
            if (type === 'object') {
                const empty = every(forEach(keys(value)), k => isEmpty(value[k]))

                if (!empty) {
                    list.push(key)
                }
            } else {
                list.push(key)
            }
        }
    })

    return list.length
}