<template>
  <div draggable='true' @dragstart='dragStart'>
    <slot/>
  </div>
</template>

<script>
  import {inject} from 'vue'

  export default {
    name: 'DragItem',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    methods: {
      dragStart(event) {
        if (this.item) {
          const data = {
            item: this.item,
            from: this.name,
            groupName: this.groupName
          }

          event.dataTransfer.dropEffect = 'move'
          event.dataTransfer.effectAllowed = 'move'
          event.dataTransfer.setData('text/plain', JSON.stringify(data))
        }
      }
    },
    setup() {
      const {groupName, name} = inject('dropContainer', {
        groupName: null,
        name: null
      })

      return {groupName, name}
    }
  }
</script>