<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.0001 18H36.0001C32.6911 18 30.0001 20.691 30.0001 24V60C30.0001 63.309 32.6911 66 36.0001 66H72.0001C75.3091 66 78.0001 63.309 78.0001 60V24C78.0001 20.691 75.3091 18 72.0001 18ZM36.0001 60V24H72.0001L72.0061 60H36.0001ZM24 35.9999H18V71.9999C18 75.3089 20.691 77.9999 24 77.9999H60V71.9999H24V35.9999ZM45.0001 44.9999L48.0001 47.9999L57.0001 35.9999L69.0001 53.9999H39.0001L45.0001 44.9999Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
