<template>
  <div class="buy-container">
    <DropsItemBuyArea v-if="config === 'onDrops'"></DropsItemBuyArea>

    <MyItemBuyArea v-if="config === 'myItems'">
      <template v-slot:default>
        <div class="price-wrapper">
          <div class="price-container">
            <EthIcon />
            <div class="price-value">4.69 ETH</div>
          </div>
          <div class="usd-price">
            $8,466.62
          </div>
        </div>
      </template>
    </MyItemBuyArea>

    <ItemBuyArea v-if="config === 'onSale'">
      <template v-slot:default>
        <div class="price-wrapper">
          <div class="price-container">
            <div class="price-value">{{ priceSymbol }} {{ formatNumber(ethers.utils.formatEther(item.prices[0].price), 0) }}</div>
          </div>
          <!-- <div class="usd-price">
            $8,466.62
          </div> -->
        </div>
      </template>
    </ItemBuyArea>
    <AuctionBuyArea v-if="config === 'onAuction'"> </AuctionBuyArea>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component imports.
import DropsItemBuyArea from './DropsItemBuyArea';
import MyItemBuyArea from './MyItemBuyArea';
import ItemBuyArea from './ItemBuyArea';
import AuctionBuyArea from './AuctionBuyArea';
// import DropsBuyArea from './DropsBuyArea';

// Icon imports.
import EthIcon from '/src/components/icons/EthIcon';

// Set up the default component.
export default {
  components: {
    EthIcon,
    DropsItemBuyArea,
    MyItemBuyArea,
    ItemBuyArea,
    AuctionBuyArea
    // DropsBuyArea
  },

  props: {
    config: {
      type: String
    },
    item: {
      type: Object
    }
  },

  data() {
    // TODO: move to databse config
    let POINT_DATA = {
      '0xf35A92585CeEE7251388e14F268D9065F5206207': { symbol: '💎' },
      '0x8e586D927acE36a3ef7bDDF9f899d2E385d5Fc9b': { symbol: '🚀' },
      '0xb3EA98747440aDDC6A262735E71B5A5cB29edd80': { symbol: '⚙️' }
    };
    let priceSymbol;
    if (this.item.prices && this.item.prices[0]?.assetType === 0 && this.item.prices[0]?.asset) {
      priceSymbol = POINT_DATA[this.item.prices[0]?.asset].symbol;
    } else if (this.item.prices && this.item.prices[0]?.staker) {
      priceSymbol = POINT_DATA[this.item.prices[0]?.staker].symbol;
    }

    return {
      ethers,
      formatNumber,

      priceSymbol
    };
  }
};
</script>

<style scoped>
.buy-container {
  display: flex;
  height: auto;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
  background-color: var(--background-color);
}

.price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.price-container {
  display: flex;
  align-items: center;
}

.price-container > * {
  fill: black;
}

.price-value {
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 700;
  margin-left: 5px;
}

.usd-price {
  opacity: 0.5;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 500;
}

.buy-button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
}

.secondary-btn {
  margin-top: 10px;
}

@media (max-width: 990px) {
  .buy-container {
    border-top: transparent;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
  }
}
</style>
