<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      d="M18 65.3334V78.0001H30.6667L67.3333 41.0001L54.6667 28.3335L18 65.3334ZM77 31.3335C78.3333 30.0001 78.3333 28.0001 77 26.6668L69.3333 19C68 17.6667 66 17.6667 64.6667 19L58.6667 25L71.3333 37.6667L77 31.3335Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
