<template>
  <div v-if='renderContentByType' class='notification-card' :class='{
      follow: data.type === "user_followed_you",
      offer: data.type === "user_offered_for_your_item",
      "is-with-border": isWithBorder
    }'>
    <div class='avatar-wrapper'>
      <img :src='data.imageUrl'>
    </div>

    <div class='content-wrapper'>
      <div class='description'>
        <div class='icon' v-if='!!renderContentByType?.icon'>
          <component :is='renderContentByType.icon'/>
        </div>
        <div class='content-text' v-if='!!renderContentByType?.text' v-html='renderContentByType.text'/>
      </div>
      <div class='actions-row' v-if='data.type === "user_offered_for_your_item"'>
        <Button @click.prevent='onClickActions("accept")'>
          <HandIcon class='accept-icon'/> Accept
        </Button>
        <Button isSecondary @click.prevent='onClickActions("decline")'>
          <CloseIcon/> Decline
        </Button>
      </div>
    </div>

    <div v-if='isUnread' class='is-unread'/>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'

  import {
    ThinCheckIcon, HandIcon, HeartFilledIcon,
    TransferredIcon, HammerIcon, CloseIcon,
    EthSmallIcon
  } from '@/components/icons'
  import Button from '@/components/ui/Button'

  import {formatRelative} from '@/utility/time'

  export default {
    components: {
      Button, HandIcon,
      CloseIcon
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      isShowUnreadMarker: {
        type: Boolean,
        required: false
      },
      isWithBorder: {
        type: Boolean,
        required: false,
        default: true
      },
      onAccept: {
        type: Function,
        required: false
      },
      onDecline: {
        type: Function,
        required: false
      }
    },
    computed: {
      isUnread() {
        return this.isShowUnreadMarker && this.data?.isUnread
      },
      renderContentByType() {
        const sender = this.data?.metadata?.senderUsername || ''
        const otherCount = this.data?.metadata?.otherCount || ''
        const date = formatRelative(this.data.createdAt)
        const price = `${this.data?.metadata?.amount?.value || ''} ${this.data?.metadata?.amount?.currency || ''}`

        switch (this.data.type) {
          case 'user_followed_you':
            return {
              icon: ThinCheckIcon,
              text: `<strong>${sender}</strong> ${!!otherCount ? `and <strong>${otherCount} others</strong>` : ''} followed you ${date}`
            }
          case 'user_liked_your_item':
            return {
              icon: HeartFilledIcon,
              text: `<strong>${sender}</strong> ${!!otherCount ? `and <strong>${otherCount} others</strong>` : ''} liked your item ${date}`
            }
          case 'user_offered_for_your_item':
            return {
              icon: HandIcon,
              text: `<strong>${sender}</strong> offered <strong>${price}</strong> for your item ${date}`
            }
          case 'user_transferred_item_to_you':
            return {
              icon: TransferredIcon,
              text: `<strong>${sender}</strong> transferred this item to you ${date}`
            }
          case 'user_bid_on_your_item':
            return {
              icon: HammerIcon,
              text: `<strong>${sender}</strong> bid <strong>${price}</strong> on your item ${date}`
            }
          case 'user_purchased_your_item':
            return {
              icon: EthSmallIcon,
              text: `<strong>${sender}</strong> purchased your item for <strong>${price}</strong> ${date}`
            }
          default:
            return null
        }
      }
    },
    methods: {
      ...mapActions('notifications', ['acceptOffer', 'declineOffer']),
      onClickActions(type) {
        switch (type) {
          case 'accept':
            this.onAccept?.()
            break
          case 'decline':
            this.onDecline?.()
            break
          default:
            break
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
  .link {
    text-decoration: none;
    color: var(--text-color);
  }

  .notification-card {
    display: grid;
    width: 100%;
    padding: 10px 0;
    align-items: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: 20px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    box-shadow: none;

    &.is-with-border {
      border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
    }

    &.follow {
      .avatar-wrapper {
        border-radius: 50%;

        img {
          object-fit: cover;
        }
      }
    }
  }

  .content-text {
    word-break: break-word;
  }

  .avatar-wrapper {
    width: 60px;
    height: 60px;
    overflow: hidden;

    img {
      object-fit: contain;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  .description {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .actions-row {
    display: inline-grid;
    margin-top: 10px;
    grid-auto-columns: 1fr;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    svg {
      margin-right: 10px;
    }
  }

  .is-unread {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $amaranthRed;
    align-self: center;
  }

  .accept-icon {
    transform: scale(-1, 1);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>