export default {
    setPageState: (state, isPublic) => {
        state.isPublic = isPublic
    },
    setProfile: (state, item) => {
      state.profile = item
    },
    setPinnedItems: (state, items) => {
        state.pinnedItems = items
    },
    setLoading: (state, isLoading) => {
        state.isLoading = isLoading
    },
    addItem: (state, aItem) => {
        const foundItem = state.pinnedItems.find(item => item.id === aItem.id)

        if (!foundItem) {
            if (state.pinnedItems.length >= 5) {
                state.pinnedItems[state.pinnedItems.length - 1].isPinned = false

                state.pinnedItems.pop()
            }

            aItem.isPinned = true

            state.pinnedItems.push(aItem)
        }
    },
    removeItem: (state, rItem) => {
        const foundIndex = state.pinnedItems.findIndex(item => item?.id === rItem?.id)

        if (foundIndex !== -1) {
            rItem.isPinned = false

            state.pinnedItems.splice(foundIndex, 1)
        }
    }
}
