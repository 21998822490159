<template>
  <div class="dropsButton__container">
    <!-- <div class="dropsButton__text-wrapper">
      <p class="dropsButton__text">Whitelisted drop coming soon!</p>
    </div> -->
    <div v-if="isCurrentDrop" class="dropsButton__text-wrapper">
      <div class="dropsButton__red-dot"></div>
      <p class="dropsButton__text">Drop in progress</p>
    </div>
    <!-- <div v-else class="dropsButton__text-wrapper dropsButton__sold-out">
      <p v-if="!isCurrentDrop" class="dropsButton__text">🚀 Sold Out!</p>
      <p class="dropsButton__text next-sale">
        {{ `Next sale at block #${dropBlock}` }}
      </p>
      <p class="dropsButton__text next-sale" style="padding-bottom:10px;">
        {{ `Available in ${dropBlock - ethereum.blockNumber} blocks` }}
      </p>
    </div> -->
    <img
      src="../../../images/down-chevron.svg"
      loading="lazy"
      alt=""
      class="dropsButton__down-chevron"
    />
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';

// Set up the default component.
export default {
  props: ['dropBlock'],
  computed: {
    ...mapState({
      ethereum: state => state.ethers
    }),
    isCurrentDrop() {
      return (
        this.ethereum.blockNumber >= this.dropBlock &&
        this.ethereum.blocNumber < this.dropBlock + 10
      );
    }
  }
};
</script>

<style>
.dropsButton__container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.dropsButton__text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  z-index: 99;
}

.dropsButton__container {
  display: flex;
  justify-content: center;
}

.next-sale {
  padding-top: 20px;
  font-size: 20px !important;
}

.dropsButton__text-wrapper:hover {
  transform: scale(1.05);
}

.dropsButton__sold-out {
  flex-direction: column;
}

.dropsButton__text {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 30px;
  color: black;
}

.dropsButton__down-chevron {
  width: 35px;
  animation: dropsButton__chevron 1s ease-in-out infinite;
}

.dropsButton__red-dot {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: red;
  animation: dropsButton__fade 2s ease infinite;
}

@keyframes dropsButton__chevron {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes dropsButton__fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 380px) {
  .dropsButton__text {
    font-size: 7vw;
  }

  .dropsButton__red-dot {
    width: 4vw;
    height: 4vw;
  }

  .dropsButton__down-chevron {
    width: 7vw;
  }
}
</style>
