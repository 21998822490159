'use strict';

// Imports.
import axios from 'axios';
import initializeConfig from '../initialize-config';
import { ethers } from 'ethers';
import { authHeader } from '../utility';
import { ethersService } from './index';

// Hard coded farm information
import { pair, pairImgs, getLink, contractLink, pairInfoLink, isLp, pointName, pointSymbol } from '/src/constants/farms-information.js';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Issue the request to deploy a new farm to Ethereum.
const deploy = function(name, token, emissionSchedule) {
  return axios.post(`${config.apiUrl}/farm`, { name: name, token: token }, { headers: authHeader });
};

// Deploy the farm using the local Ethers provider.
const deployLocally = async function(name, token, tokenEmissionSchedule, pointEmissionSchedule, tokenMintAmount, tokenTransferAmount, initialPools) {
  let wallet = await ethersService.getWallet();
  let recordContractAddress = config.stakerRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(recordContractAddress, config.stakerRecordABI, wallet);

  // Parse the emissions schedule into block numbers and rates.
  tokenEmissionSchedule = tokenEmissionSchedule.map((o) => {
    return {
      blockNumber: o.block,
      rate: ethersService.parseEther('1').mul(o.value)
    };
  });
  pointEmissionSchedule = pointEmissionSchedule.map((o) => {
    return {
      blockNumber: o.block,
      rate: ethersService.parseEther('1').mul(o.value)
    };
  });

  // Parse the initial pools into pool data structs.
  initialPools = initialPools.map((o) => {
    return {
      poolToken: o.tokenAddress,
      tokenStrength: o.tokenStrength,
      pointStrength: o.pointStrength
    };
  });

  // Create a new Staker via the farm records contract.
  console.log(`Creating Staker in: ${recordContractAddress} ...`);
  let gasEstimate = await recordContract.estimateGas.createFarm(name, token, tokenEmissionSchedule, pointEmissionSchedule, initialPools);
  let createStakerTransaction = await recordContract.createFarm(name, token, tokenEmissionSchedule, pointEmissionSchedule, initialPools, {
    gasLimit: gasEstimate.add(gasEstimate.div(8))
  });
  let stakerCreatedReceipt = await createStakerTransaction.wait();
  let stakerCreatedEvent = stakerCreatedReceipt.events[stakerCreatedReceipt.events.length - 1];
  let newStakerAddress = stakerCreatedEvent.args[0];
  console.log(`... Staker created in transaction: ${createStakerTransaction.hash}.`);

  // Get access to the user's specified token and perform minting/transfer operations if requested.
  let tokenContract = ethersService.connectToContract(token, config.tokenABI, wallet);
  if (tokenMintAmount !== '') {
    let mintTransaction = await tokenContract.mint(newStakerAddress, ethersService.parseEther(tokenMintAmount));
    await mintTransaction.wait();
  }
  if (tokenTransferAmount !== '') {
    let transferTransaction = await tokenContract.transfer(newStakerAddress, ethersService.parseEther(tokenTransferAmount));
    await transferTransaction.wait();
  }

  // Return the same format that we do for the remote server-created contracts.
  return {
    status: 'ok',
    farm: {
      address: newStakerAddress
    }
  };
};

// Fetch all farms created by a particular user.
const getAllFarms = async function() {
  let wallet = await ethersService.getWallet();
  let walletAddress = await ethersService.getWalletAddress();
  let recordContractAddress = config.stakerRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(recordContractAddress, config.stakerRecordABI, wallet);

  let farmCount = await recordContract.getFarmCount(walletAddress);
  let farms = [];
  for (let i = 0; i < farmCount; i++) {
    let farm = await recordContract.farmRecords(walletAddress, i);
    let farmContract = ethersService.connectToContract(farm, config.farmABI, wallet);
    let farmName = await farmContract.name();
    let farmToken = await farmContract.token();
    let tokenContract = ethersService.connectToContract(farmToken, config.tokenABI, wallet);
    let tokenName = await tokenContract.name();
    let tokenSymbol = await tokenContract.symbol();
    farms.push({
      address: farm,
      name: farmName,
      token: {
        address: farmToken,
        name: tokenName,
        symbol: tokenSymbol
      }
    });
  }
  return farms;
};

// Retrieve the Staker contract.
const getStakerContract = async function(farmAddress, signer) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  // If a signer is available, use that to initiate connection to the staker.
  if (signer) {
    provider = signer;
  }
  let stakerContract = new ethers.Contract(farmAddress, config.stakerABI, provider);
  return stakerContract;
};

// Retrieve all detailed farms deployed to SuperFarm.
const retrieveOldFullFarmsInformation = async function(address) {
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let stakingAddresses = config.superFarmAddress[networkId];
  const farms = [];
  for (const stakingAddress of stakingAddresses) {
    farms.push(await retrieveFarmInformation(address, stakingAddress, provider, networkId));
  }
  return farms;
};

// Helper function to retrieve full farm information for a specified staking address.
const retrieveFarmInformation = async function(address, stakingAddress, provider, networkId) {
  let farmContract = new ethers.Contract(stakingAddress, config.stakerABI, provider);
  let farmName = await farmContract.name();
  let farmToken = await farmContract.token();
  let tokenContract = new ethers.Contract(farmToken, config.tokenABI, provider);
  let tokenName = await tokenContract.name();
  let tokenSymbol = await tokenContract.symbol();
  let poolTokens = {};
  let poolTokenCount = await farmContract.getPoolCount();
  for (let i = 0; i < poolTokenCount; i++) {
    let poolToken = await farmContract.poolTokens(i);
    let poolTokenContract = new ethers.Contract(poolToken, config.tokenABI, provider);
    let poolTokenName = await poolTokenContract.name();
    let poolTokenSymbol = await poolTokenContract.symbol();
    let poolInfo = await farmContract.poolInfo(poolToken);

    // Calculate the total supply of token staked in the pool.
    let poolTotalSupply = await poolTokenContract.balanceOf(stakingAddress);
    if (poolToken === farmToken) {
      poolTotalSupply = await farmContract.totalTokenDeposited();
    }

    // Retrieve the amount of WETH stored in the token's contract.
    let wethContract = new ethers.Contract(config.weth[networkId], config.tokenABI, provider);
    let wethAmount = await wethContract.balanceOf(poolToken);
    let wethValue = wethAmount.mul(2);
    let lpTotalSupply = await poolTokenContract.totalSupply();

    // Millionths of Ether per LP token.
    let lpTokenValue = wethValue.mul(1000000).div(lpTotalSupply);

    // Construct a reasonable pool object.
    let poolObject = {
      address: poolInfo.token,
      tokenContractWeth: lpTokenValue,
      name: poolTokenName,
      symbol: poolTokenSymbol,
      tokenStrength: poolInfo.tokenStrength,
      tokensPerShare: poolInfo.tokensPerShare,
      pointStrength: poolInfo.pointStrength,
      pointsPerShare: poolInfo.pointsPerShare,
      lastRewardBlock: poolInfo.lastRewardBlock,
      poolTotalSupply: poolTotalSupply
    };
    if (address) {
      let poolUserInfo = await farmContract.userInfo(poolToken, address);
      let pendingRewards = await farmContract.getPendingTokens(poolToken, address);
      let pendingPoints = await farmContract.getPendingPoints(poolToken, address);
      let userWalletBalance = await poolTokenContract.balanceOf(address);
      let allowance = await poolTokenContract.allowance(address, stakingAddress);
      poolObject.userAmount = poolUserInfo.amount;
      poolObject.userWalletBalance = userWalletBalance;
      poolObject.tokenPaid = poolUserInfo.tokenPaid;
      poolObject.pointPaid = poolUserInfo.pointPaid;
      poolObject.pendingRewards = pendingRewards;
      poolObject.pendingPoints = pendingPoints;
      poolObject.userAllowance = allowance;
    }
    poolTokens[poolInfo.token] = poolObject;
  }
  let totalTokenStrength = await farmContract.totalTokenStrength();
  let totalPointStrength = await farmContract.totalPointStrength();
  let currentBlock = await provider.getBlockNumber();
  let currentTokenRate = await farmContract.getTotalEmittedTokens(currentBlock, currentBlock + 1);
  let currentPointRate = await farmContract.getTotalEmittedPoints(currentBlock, currentBlock + 1);

  // Return a fully-detailed look at the farm.
  let farmObject = {
    address: stakingAddress,
    name: farmName,
    token: {
      name: tokenName,
      symbol: tokenSymbol,
      address: farmToken
    },
    point: {
      name: pointName(networkId, stakingAddress),
      symbol: pointSymbol(networkId, stakingAddress)
    },
    poolTokens: poolTokens,
    totalTokenStrength: totalTokenStrength,
    totalPointStrength: totalPointStrength,
    currentTokenRate: currentTokenRate,
    currentPointRate: currentPointRate
  };

  // Initialize hard coded data for each pool in the farm.
  farmObject.poolData = {};
  for (const poolToken of Object.keys(poolTokens)) {
    farmObject.poolData[poolToken] = {
      pair: pair(networkId, stakingAddress, poolToken),
      pairImgs: pairImgs(networkId, stakingAddress, poolToken),
      getLink: getLink(networkId, stakingAddress, poolToken),
      contractLink: contractLink(networkId, stakingAddress, poolToken),
      pairInfoLink: pairInfoLink(networkId, stakingAddress, poolToken),
      isLp: isLp(networkId, stakingAddress, poolToken)
    };
  }

  if (address) {
    let availableUserPoints = await farmContract.getAvailablePoints(address);
    farmObject.availableUserPoints = availableUserPoints;
  }
  return farmObject;
};

// Approve a farm to spend tokens, as a precursor to deposits.
const approve = async function(farmAddress, tokenAddress) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();

  let farmContract = await getStakerContract(farmAddress, signer);
  let tokenContract = new ethers.Contract(tokenAddress, config.tokenABI, signer);

  let maxApproval = ethers.constants.MaxUint256;
  let approvalTransaction = await tokenContract.approve(farmContract.address, maxApproval);
  await approvalTransaction.wait();
  return maxApproval;
};

// Deposit tokens into a farm.
const deposit = async function(farmAddress, tokenAddress, amount) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();

  let farmContract = await getStakerContract(farmAddress, signer);
  console.log(tokenAddress, amount);
  let depositTransaction = await farmContract.deposit(tokenAddress, amount);
  await depositTransaction.wait();
};

// Withdraw tokens from a farm.
const withdraw = async function(farmAddress, tokenAddress, amount) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();

  let farmContract = await getStakerContract(farmAddress, signer);
  let withdrawTransaction = await farmContract.withdraw(tokenAddress, amount);
  await withdrawTransaction.wait();
};

// Add a new pool to the farm.
const addPool = async function(farmAddress, tokenAddress, tokenStrength, pointStrength) {
  let wallet = await ethersService.getWallet();
  let farmContract = ethersService.connectToContract(farmAddress, config.farmABI, wallet);
  let addPoolTransaction = await farmContract.addPool(tokenAddress, tokenStrength, pointStrength);
  await addPoolTransaction.wait();
};

const retrieveFullFarmsInformation = async function(userAddress) {
  if (userAddress == null) {
    userAddress = ethers.constants.AddressZero;
  }
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let stakerReader = config.stakerReaderAddress[networkId];
  let stakerReaderContract = new ethers.Contract(stakerReader, config.stakerReaderABI, provider);
  let wethAddress = config.weth[networkId];

  let stakingAddresses = config.superFarmAddress[networkId];
  const farms = [];
  let poolData = {};
  let stakerReaderAppliance;
  let wethContract = new ethers.Contract(config.weth[networkId], config.tokenABI, provider);

  for (const stakingAddress of stakingAddresses) {
    stakerReaderAppliance = await stakerReaderContract.getFarmData(userAddress, stakingAddress, wethAddress);
    let stakerContract = new ethers.Contract(stakingAddress, config.stakerABI, provider);

    let farmContract = new ethers.Contract(stakingAddress, config.stakerABI, provider);
    let farmToken = await farmContract.token();

    for (const poolIndex of Object.keys(stakerReaderAppliance.poolTokens)) {
      const poolToken = stakerReaderAppliance.poolTokens[poolIndex].poolToken;

      let wethAmount = await wethContract.balanceOf(poolToken);
      let wethValue = wethAmount.mul(2);
      let poolTokenContract = new ethers.Contract(poolToken, config.tokenABI, provider);

      let poolTotalSupply = await poolTokenContract.balanceOf(stakingAddress);
      if (poolToken === farmToken) {
        poolTotalSupply = await farmContract.totalTokenDeposited();
      }

      let lpTotalSupply = await poolTokenContract.totalSupply();
      let lpTokenValue = wethValue.mul(1000000).div(lpTotalSupply);

      poolData[poolIndex] = {
        pair: pair(networkId, stakingAddress, poolToken),
        pairImgs: pairImgs(networkId, stakingAddress, poolToken),
        getLink: getLink(networkId, stakingAddress, poolToken),
        contractLink: contractLink(networkId, stakingAddress, poolToken),
        pairInfoLink: pairInfoLink(networkId, stakingAddress, poolToken),
        isLp: isLp(networkId, stakingAddress, poolToken),
        lpTokenValue: lpTokenValue,
        poolTotalSupply: poolTotalSupply
      };
      let poolUserData;
      if (userAddress != ethers.constants.AddressZero) {
        poolUserData = stakerReaderAppliance.poolUserData[poolIndex];

        poolData[poolIndex].userAmount = poolUserData.amount;
        poolData[poolIndex].userWalletBalance = poolUserData.userWalletBalance;
        poolData[poolIndex].tokenPaid = poolUserData.tokenPaid;
        poolData[poolIndex].pointPaid = poolUserData.pointPaid;
        poolData[poolIndex].pendingRewards = poolUserData.pendingRewards;
        poolData[poolIndex].pendingPoints = poolUserData.pendingPoints;
        poolData[poolIndex].userAllowance = poolUserData.userAllowance;
        poolUserData = {};
      }
    }

    const stakerReaderApplianceCopy = Object.create(stakerReaderAppliance);

    if (userAddress != ethers.constants.AddressZero) {
      let availableUserPoints = await stakerContract.getAvailablePoints(userAddress);
      stakerReaderApplianceCopy.availableUserPoints = availableUserPoints;
    }

    let point = {};
    point.name = pointName(networkId, stakingAddress);
    point.symbol = pointSymbol(networkId, stakingAddress);
    stakerReaderApplianceCopy.data = stakerReaderAppliance;
    stakerReaderApplianceCopy.poolData = poolData;
    stakerReaderApplianceCopy.point = point;
    farms.push(stakerReaderApplianceCopy);

    poolData = {};
  }

  return farms;
};

// Export the token service functions.
export const farmService = {
  deploy,
  deployLocally,
  getAllFarms,
  retrieveFullFarmsInformation,
  approve,
  deposit,
  withdraw,
  addPool
};
