'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethers } from 'ethers';
import axios from 'axios';
import { authHeader } from '../utility';
import { ethersService } from './index';

const ERRORS = {
  NO_LP: {
    originalMessage: '',
    friendlyMessage: ''
  }
};

let config;
let dispatch;
(async () => {
  config = await initializeConfig();
})();

const getAuctionContract = async function(withSigning) {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let auctionAcceptAddress = config.auctionAcceptAddress[networkId];
  let selectedProvider = withSigning ? await provider.getSigner() : provider;

  let auctionAcceptContract = new ethers.Contract(auctionAcceptAddress, config.auctionAcceptABI, selectedProvider);
  return auctionAcceptContract;
};

const registerListener = async function(event, listener) {
  let contract = await getAuctionContract(false);
  contract.on(event, listener);
};

const listenForBids = async function(dispatch) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let contract = await getAuctionContract(true);
  let formattedAmount;
  contract.on('HighestBidIncreased', async (bidder, amount, timestamp, event) => {
    if (bidder != walletAddress) {
      formattedAmount = ethers.utils.formatEther(amount.toString());
      //await dispatch('alert/clear', '', { root: true });
      await dispatch(
        'alert/info',
        {
          message: 'New Bid Placed!',
          amount: `${formattedAmount} ETH`,
          metadata: {
            transaction: bidTx.hash
          },
          duration: 10000
        },
        { root: true }
      );
    }
  });
};

const getAuctionData = async function() {
  let auctionContract = await getAuctionContract(false);
  let auction = await auctionContract.auctionData();

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let signedInUser = await signer.getAddress();

  let minBid = auction[3] > 0 ? auction[3] : auction[7];
  let suggestedBid = minBid.mul(105).div(100);

  let endDate = new Date(auction[6].toString() * 1000);
  let isFinished = endDate.getTime() - new Date().getTime() < 0;

  let auctionData = {
    beneficiary: auction[0],
    userIsBeneficiary: auction[0] == signedInUser,
    highBid: ethers.utils.formatEther(auction[3].toString()),
    highBidder: auction[4],
    highBidTime: auction[5],
    endTime: auction[6],
    isFinished: isFinished,
    minimumBid: ethers.utils.formatEther(minBid.toString()),
    suggestedBid: ethers.utils.formatEther(suggestedBid.toString()),
    bidCount: auction[8].toString()
    //"bidHistory": await getBidHistory()
  };
  console.log('retr auction data', auctionData);
  return auctionData;
};

const getBidData = async function(bidId) {
  let auctionContract = await getAuctionContract(false);
  let bidData = await auctionContract.bidHistory(bidId);
  return bidData;
};

const getUserBidStatus = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let auctionContract = await getAuctionContract(false);
  let highBidder = await auctionContract.highestBidder();
  return walletAddress == highBidder;
};

const getEndTime = async function() {
  let auctionContract = await getAuctionContract(false);
  let endTime = await auctionContract.auctionEndTime();
  return endTime;
};

const getBidCount = async function() {
  let auctionContract = await getAuctionContract(false);
  let bidCount = await auctionContract.bidCount();
  return bidCount.toString();
};

const getBidHistory = async function() {
  let auctionContract = await getAuctionContract(false);
  let bidHistory = [];
  let bids = await auctionContract.queryFilter('HighestBidIncreased');
  for (let i = 0; i < bids.length; i++) {
    let bid = bids[i];
    let bidMeta = {
      txhash: bid.transactionHash,
      amount: ethers.utils.formatEther(bid.args.amount.toString()),
      bidder: bid.args.bidder,
      timestamp: bid.args.timestamp.toString() * 1000
    };
    bidHistory.push(bidMeta);
  }
  return bidHistory.reverse();
};

const placeBid = async function(dispatch, amount) {
  let auctionContract = await getAuctionContract(true);
  let bidTx;
  try {
    bidTx = await auctionContract.bid({
      value: ethers.utils.parseEther(amount)
    });

    bidTx.wait().then(async result => {
      let txlink = 'http://etherscan.io/tx/' + result.transactionHash;
      // await dispatch('alert/clear', '', { root: true });
      await dispatch('alert/clear', { root: true });
      await dispatch(
        'alert/info',
        {
          message: 'Transaction Confirmed',
          metadata: {
            transaction: bidTx.hash
          },
          duration: 10000
        },
        { root: true }
      );

      // await dispatch(
      //   'alert/success',
      //   {
      //     message: `BID CONFIRMED ${txlink}`,
      //     duration: 10000
      //   },
      //   { root: true }
      // );
      await dispatch('auction/updateAuctionData', await getAuctionData(), {
        root: true
      });
      await dispatch('auction/updateUserBidStatus', await getUserBidStatus(), {
        root: true
      });
    });

    await dispatch(
      'alert/info',
      {
        message: `Waiting for confirmation`,
        duration: 1000000
      },
      { root: true }
    );
  } catch (error) {
    console.log('***ERROR', error);
    //await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/error',
      {
        message: `${error.data.message}`,
        duration: 10000
      },
      { root: true }
    );
  }

  // pending tx
  await dispatch('alert/clear', { root: true });
  // await dispatch(
  //   'alert/info',
  //   {
  //     message: `Waiting for confirmation`,
  //     duration: 1000000
  //   },
  //   { root: true }
  // );
  console.log('BID!!', bidTx);
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: bidTx.hash
      },
      duration: 300000
    },
    { root: true }
  );

  console.log({ 'tx subbed': bidTx });
};

const acceptResults = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let auctionContract = await getAuctionContract(true);
  let accept = await auctionContract.accept();
  return accept;
};

const declineResults = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let auctionContract = await getAuctionContract(true);
  let accept = await auctionContract.decline();
  return accept;
};

export const auctionService = {
  registerListener,
  listenForBids,
  getAuctionData,
  getBidData,
  getBidHistory,
  getUserBidStatus,
  getEndTime,

  placeBid,
  acceptResults,
  declineResults
};
