import actions from './actions'
import mutations from './mutations'

const state = {
    notifications: [],
    latestNotifications: [],
    cursor: null,
    isLoading: false,
    filters: {
        statuses: []
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
