<template>
  <div class="segmentedTabs__container">
    <button
      v-for="(item, index) in items"
      :key="index"
      class="segmentedTabs__item"
      :class="isSelected(item)"
      @click="selectTab(item)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    isSecondary: {
      type: Boolean,
      default: false
    }
  },

  emits: ['tabUpdated'],

  data() {
    return {
      currentItem: ''
    };
  },

  mounted() {
    if (this.items.length > 0) this.currentItem = this.items[0];
  },

  computed: {},

  methods: {
    selectTab(item) {
      this.currentItem = item;
      this.$emit('tabUpdated', item);
    },

    isSelected(item) {
      return item === this.currentItem ? 'segmentedTabs__item--selected' : '';
    }
  }
};
</script>

<style scoped>
.segmentedTabs__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.segmentedTabs__item {
  padding: 12px 22px;
  font-weight: 500;
  border: 2px solid black;
  text-align: center;
  background-color: transparent;
  transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.segmentedTabs__item:hover {
  background-color: black;
  color: white;
}

.segmentedTabs__item:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.segmentedTabs__item:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.segmentedTabs__item--selected {
  background-color: black;
  color: white;
}
</style>
