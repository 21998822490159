<template>
  <BaseTabItemSecondary :is-selected='isActive'
                        :title='title'
                        @click='activeTab'>
    <slot/>
  </BaseTabItemSecondary>
</template>

<script>
  import {computed, inject, watchEffect, getCurrentInstance} from 'vue'

  import BaseTabItemSecondary from '@/components/ui/BaseTabItemSecondary'

  export default {
    name: 'Tab',
    components: {BaseTabItemSecondary},
    props: {
      title: String
    },
    setup() {
      const instance = getCurrentInstance()

      const {tabs, selectTab, active} = inject('tabsState', {
        tabs: [],
        selectTab: () => {}
      })

      const index = computed(() => tabs.value.findIndex(target => target.uid === instance.uid))

      const isActive = computed(() => index.value === active.value)

      const activeTab = () => {
        selectTab(index.value)
      }

      watchEffect(() => {
        if (index.value === -1) {
          tabs.value.push(instance)
        }
      })

      return {
        activeTab,
        isActive
      }
    }
  }
</script>
