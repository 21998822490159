<template>
  <div :class='["textarea-wrapper", !!errorMessage ? "error" : undefined, variant]'>
    <textarea class='textarea'
              :value='modelValue'
              :placeholder='placeholder'
              @input='handleChange'/>
    <ErrorMessage v-if='isErrorMessageVisible && !!errorMessage'>{{errorMessage}}</ErrorMessage>
  </div>
</template>

<script>
  import ErrorMessage from './ErrorMessage'

  export default {
    components: {ErrorMessage},
    props: {
      modelValue: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: false,
        default: 'default'
      },
      placeholder: {
        type: String,
        required: false
      },
      errorMessage: {
        type: String,
        required: false
      },
      isErrorMessageVisible: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    methods: {
      handleChange(event) {
        this.$emit('update:modelValue', event)
      }
    }
  }
</script>

<style scoped lang='scss'>
  .textarea-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.default {
      .textarea:focus {
        border-color: $blue;
      }
    }
  }

  .textarea {
    outline: none;
    padding: 8px 12px;
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
    font-size: 14px;
    line-height: 1.42;
    min-height: 40px;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: 200ms cubic-bezier(.215, .61, .355, 1);
    width: 100%;
    resize: none;
  }
</style>