<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      d="M76.6059 49.504L55.6062 48.0041C55.3417 47.981 55.076 48.0305 54.8375 48.1471C54.5991 48.2638 54.3969 48.4432 54.2528 48.6661C54.1056 48.8862 54.0192 49.1413 54.0025 49.4055C53.9857 49.6697 54.0392 49.9336 54.1575 50.1705L56.6536 55.1625C47.73 62.7428 27.9186 59.7224 19.975 57.077C19.6718 56.9755 19.3441 56.9737 19.0398 57.0717C18.7355 57.1698 18.4705 57.3625 18.2835 57.6218C18.0966 57.8812 17.9974 58.1935 18.0006 58.5132C18.0037 58.8329 18.109 59.1432 18.301 59.3988C27.2875 71.3823 50.8738 74.9403 63.9281 66.7112L66.1575 71.1701C66.2823 71.4192 66.4739 71.6286 66.7109 71.7751C66.9479 71.9215 67.221 71.9992 67.4995 71.9994H67.523C67.8056 71.9949 68.0812 71.9107 68.318 71.7565C68.5549 71.6023 68.7434 71.3844 68.8618 71.1278L77.8617 51.6281C77.9635 51.4078 78.0103 51.1661 77.9981 50.9237C77.9858 50.6813 77.9149 50.4456 77.7915 50.2366C77.668 50.0277 77.4956 49.8519 77.2892 49.7243C77.0828 49.5967 76.8484 49.5211 76.6064 49.5041L76.6059 49.504Z"
      class="icon"
    ></path>
    <path
      d="M19.3938 46.4953L40.3935 47.9953C40.4301 47.9982 40.4652 47.9997 40.5004 47.9997C40.756 47.9996 41.0074 47.9341 41.2306 47.8096C41.4539 47.685 41.6416 47.5055 41.776 47.2881C41.9104 47.0706 41.987 46.8224 41.9986 46.5671C42.0101 46.3117 41.9563 46.0576 41.8421 45.8289L39.3461 40.8369C48.2726 33.2595 68.084 36.2799 76.0247 38.9224C76.3279 39.0238 76.6556 39.0257 76.9599 38.9276C77.2642 38.8296 77.5292 38.6369 77.7161 38.3775C77.9031 38.1182 78.0023 37.8059 77.9991 37.4862C77.996 37.1665 77.8907 36.8562 77.6987 36.6006C68.7138 24.6158 45.1245 21.0576 32.0716 29.2882L29.8422 24.8293C29.7133 24.5785 29.5175 24.3683 29.2766 24.222C29.0356 24.0756 28.7589 23.9988 28.477 24C28.1944 24.0045 27.9188 24.0887 27.682 24.2429C27.4451 24.3971 27.2566 24.615 27.1382 24.8716L18.1383 44.3713C18.0365 44.5916 17.9897 44.8333 18.0019 45.0757C18.0141 45.318 18.085 45.5538 18.2085 45.7627C18.332 45.9717 18.5044 46.1475 18.7108 46.2751C18.9172 46.4027 19.1515 46.4782 19.3936 46.4953L19.3938 46.4953Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
