<template>
  <teleport to='body'>
    <Modal :name='name' width='360px' class='report-modal' v-slot='{close}'>
      <ModalHeader>
        <div class='header-content'>
          <h3>{{title}}</h3>
        </div>
      </ModalHeader>
      <ModalBody class='body-container'>
        <div class='item' v-for='(option, i) in options' :key='i'>
          <BaseRadioButton :label='option.value'
                           @input='changeValue(option)'
                           :checked='option?.value === selected?.value'/>
          <div v-if='option.details && isWithDetails'>
            <BaseNativeSelect :name='`report-details-${i}`'
                              :id='`report-details-${i}`'
                              @input='changeValue({...option, detailsValue: $event.target.value})'>
              <option value='' :selected='selected?.detailsValue === ""'>Select one...</option>
              <option v-for='(detailsValue, o) in option.details'
                      :key='o'
                      :selected='detailsValue === selected?.detailsValue'
                      :value='detailsValue'>
                {{detailsValue}}
              </option>
            </BaseNativeSelect>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div class='actions'>
          <Button :isDisabled='isLoading' @click='handleCancel(close)' isSecondary>Cancel</Button>
          <Button :isDisabled='isLoading' @click='handleSubmit(close)'>Submit</Button>
        </div>
      </ModalFooter>
    </Modal>
  </teleport>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import {find, has} from 'lodash'

  import {ModalHeader, ModalBody, ModalFooter, Modal} from './index'

  import Button from '@/components/ui/Button'
  import BaseRadioButton from '@/components/ui/BaseRadioButton'
  import BaseNativeSelect from '@/components/ui/BaseNativeSelect'

  export default {
    components: {
      Modal, ModalHeader,
      ModalBody, ModalFooter,
      Button, BaseRadioButton,
      BaseNativeSelect
    },
    props: {
      title: {
        type: String,
        required: true
      },
      initialValues: {
        type: Object,
        required: false
      },
      name: {
        type: String,
        required: false,
        default: 'report-modal'
      },
      options: {
        type: Array,
        required: false,
        default: () => [ // TODO Temporary placement of a standard set of options. Until the entire set of options is known.
          {
            value: 'Infringes my rights',
            details: [
              'Copyright issue',
              'Privacy issue',
              'Trademark infringement',
              'Defamation',
              'Counterfeit',
              'Other legal issue'
            ]
          },
          {value: 'Sexual content'},
          {value: 'Violent or repulsive content'},
          {value: 'Hateful or abusive content'},
          {value: 'Harassment or bullying'},
          {value: 'Harmful or dangerous acts'},
          {value: 'Child abuse'},
          {value: 'Promotes violence'},
          {value: 'Spam or misleading'}
        ]
      },
      onSubmit: {
        type: Function,
        required: true
      },
      onCancel: {
        type: Function,
        required: false
      },
      isLoading: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        selected: this.initialValues ? this.initialValues : null
      }
    },
    computed: {
      ...mapGetters('modal', ['allOpen']),
      isOpen() {
        return this.allOpen?.includes(this.name)
      },
      isWithDetails() {
        return has(find(this.options, option => option?.value === this.selected?.value), 'details')
      }
    },
    methods: {
      ...mapActions('alert', ['error']),
      changeValue(data) {
        if (data?.details) {
          this.selected = {
            value: data.value,
            detailsValue: data.detailsValue ? data.detailsValue : ''
          }
        } else {
          this.selected = data
        }
      },
      handleSubmit(close) {
        if (!this.selected || (this.selected && this.isWithDetails && !this.selected?.detailsValue)) {
          this.error({message: 'The report cannot be empty', duration: 5000})

          return
        }

        this.onSubmit?.(this.selected?.detailsValue
            ? this.selected?.detailsValue
            : this.selected?.value, close)
      },
      handleCancel(close) {
        this.onCancel?.()

        close()
      },
      resetModal() {
        this.selected = this.initialValues ? this.initialValues : null
      }
    },
    watch: {
      isOpen(value) {
        if (!value) {
          this.resetModal()
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
  .report-modal {
    :deep .modal {
      border-radius: 40px;

      @include media-max-width($mediumScreen) {
        border-radius: 0;
      }
    }
  }

  .header-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 20px;
      line-height: 1.3em;
      font-weight: 700;
    }
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .body-container {
    padding: 20px;

    @include media-min-width($mediumScreen) {
      max-height: 378px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      flex: 1;
    }

    & > :first-child {
      margin-right: 16px;
    }
  }
</style>
