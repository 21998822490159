<template>
  <div class='wrapper' :class='{"rounded": isRounded}'>
    <div class='banner-wrapper'>
      <Skeletor class='item banner' as='div'/>
    </div>
    <div class='section'>
      <div class='container info'>
        <Skeletor width='140' height='140' circle class='item avatar'/>
        <Skeletor width='173' height='30' class='item username'/>
        <Skeletor v-if='isHideAddress' width='117' height='16' class='item address'/>
        <Skeletor width='180' height='40' class='item buttons'/>
        <Skeletor width='100%' height='20' class='item status'/>
        <Skeletor width='100%' height='135' class='item description'/>
        <Skeletor width='117' height='16' class='item website'/>
        <Skeletor width='100%' height='30' class='item socials'/>
      </div>
    </div>
  </div>
</template>

<script>
  import {Skeletor} from 'vue-skeletor'

  export default {
    components: {Skeletor},
    props: {
      isRounded: {
        type: Boolean,
        required: false
      },
      isShowAddress: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    width: 100%;

    &.rounded {
      .avatar {
        border-radius: 20px;
      }
    }
  }

  .banner-wrapper {
    max-width: 1600px;
    margin: 0 auto;
  }

  .item {
    background-color: var(--skeleton-color);

    &.banner {
      padding-bottom: 16.625%;
      border-radius: 0;
      height: 0;
      min-height: 140px;
    }

    &.avatar {
      border: 3px solid var(--background-color);
      transition: border-color 0.5s ease;
      margin-top: -100px;
    }

    &.status {
      max-width: 450px;
    }

    &.description {
      max-width: 589px;
    }

    &.socials {
      max-width: 329px;
    }

    &.address, &.status,
    &.description, &.website {
      margin-bottom: 20px !important;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .section {
    padding: 30px 20px;
    @include media-max-width($smallScreen) {
      padding: 30px 10px;
    }
  }

  .container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

    &.content {
      min-height: 820px;
    }
  }
</style>