<template>
  <div class='nft-header'>
    <router-link to='/profile/f2bab1f2-db2c-43f5-8895-e5a7fbecf2aa' v-slot='{navigate, href}' custom>
      <a :href='href' @click.stop='navigate' class='link'>
        <Avatar src='https://assets.website-files.com/606b8a262f3b7db261ad7f08/606b8db0bc62f41555cc4fae_user%20image.jpg'>
          username
        </Avatar>
      </a>
    </router-link>
    <BaseDropdown ref='followingDropdown' centered>
      <IconButton><DotsIcon/></IconButton>

      <template #dropdown>
        <DropdownContent width='125px'>
          <ListItem @click.stop='openReport' isError>Report</ListItem>
          <ListItem @click.stop='unfollowRequest()' isError>Unfollow</ListItem>
          <ListItem @click.stop='openDetails(item)'>Go to item</ListItem>
          <ListItem @click.stop='copyLink'>Copy link</ListItem>
        </DropdownContent>
      </template>
    </BaseDropdown>
  </div>
</template>

<script>
  import useClipboard from 'vue-clipboard3'
  import {mapActions} from 'vuex'

  import Avatar from '@/components/common/Avatar'
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import IconButton from '@/components/IconButton'
  import {DotsIcon} from '@/components/icons'
  import {ListItem} from '@/components/ui/list'

  export default {
    components: {
      Avatar, BaseDropdown,
      DropdownContent, ListItem,
      DotsIcon, IconButton
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      openDetails: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        isFollowLoading: false
      }
    },
    methods: {
      ...mapActions('modal', ['open']),
      ...mapActions('alert', ['info']),
      closeDropdown() {
        this.$refs?.followingDropdown?.click()
      },
      async unfollowRequest() {
        // TODO unfollow request

        this.closeDropdown()
      },
      openReport() {
        this.open("report-modal")

        this.closeDropdown()
      },
      async copyLink() {
        if (this.item && this.item?.metadata?.name) {
          // Todo temporary decision.

          let id = this.item.metadata.name.toLowerCase().split(' ')
          id[id.length - 1] = id[id.length - 1].replace('#', '')
          id = id.join('-')

          const {toClipboard} = useClipboard()
          const url = `${window.location.origin}/items/${id}`

          await toClipboard(url)

          this.info({
            message: 'Url copied to clipboard!',
            duration: 5000
          })

          this.closeDropdown()
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
  .nft-header {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    min-height: 80px;

    .link, .item-link {
      color: inherit;
      text-decoration: none;
    }
  }
</style>
