import actions from "./actions"
import mutations from "./mutations"

const state = () => ({
  loading: {
    bool: false,
    type: ''
  },
  type: 'users',
  items: []
})

const search_results = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default search_results