<template>
  <div
    class="drop-card-container"
    :style="{
      'background-image': 'url(' + require(`@/images/dropday/${drop.image}`) + ')'
    }"
  >
    <div class="img"></div>
    <div class="drop-info-wrapper" :style="cursorStyle">
      <div class="drop-card-creator">{{ drop.username }}</div>
      <div class="drop-card-title">{{ drop.title }}</div>
      <div class="drop-countdown-wrapper">
        <DropsCountdownCard :startDate="drop.startDate" :endDate="drop.endDate" :smallFont="true" />
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import DropsCountdownCard from './DropsCountdownCard';

export default {
  components: {
    DropsCountdownCard
  },

  props: {
    drop: {
      type: Object
    }
  },

  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${this.drop.image})`
      };
    },

    // Hack for first 2 SuperFarm drops.
    cursorStyle() {
      if (this.drop.username !== 'SuperFarm') {
        return {
          cursor: 'pointer'
        };
      }
      return {};
    }
  }
};
</script>

<style scoped>
.drop-card-container {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 385px;
  max-width: 90vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  text-decoration: none;
}

.drop-info-wrapper {
  width: 100%;
  height: 100%;
  padding: 60px 20px;
  justify-content: center;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.drop-info-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.drop-card-creator {
  margin-bottom: 10px;
  opacity: 0.5;
  font-size: 16px;
  line-height: 1em;
}

.drop-card-title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
}

.drop-card-date {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
}

.drop-countdown-wrapper {
  margin-bottom: 20px;
}

.countdown {
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  padding: 10px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  text-align: left;
}

.countdown-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.time-value {
  font-size: 20px;
  font-weight: 600;
}

.view-drop {
  border: 2px solid #fff;
  background-color: transparent;
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1), background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
}

.view-drop:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}
</style>
