<template>
  <div>
    <Ethereum :callback="initialize" />
    <CardModal />
    <div class="gemStore__pageHeader">
      <div class="gemStore__searchTagsWrapper">
        <div class="gemStore__searchResults">
          Buy Items with {{ pointSymbol }} <strong>{{ pointName }}</strong
          >s
        </div>
        <div v-if="!isLoading" class="gemStore__balance">
          Balance
          <div class="gemStore__balanceValue">
            {{ pointSymbol }}
            {{ userBalance }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gemStore__resultsContainer">
    <div class="gemStore__itemsGrid">
      <WalletItems :items="nftItems" cardState="onSale" />
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import store from '../store';
import { formatNumber } from '/src/utility';
import { ethers } from 'ethers';

// Icon imports.

// Component imports.
import Ethereum from '/src/components/common/Ethereum';
import WalletItems from '/src/components/pages/my-items/WalletItems';
import CardModal from '/src/components/nft-detail-modal/CardModal';

// Set up the default component.
export default {
  components: {
    Ethereum,
    CardModal,
    WalletItems
  },

  data() {
    let id = this.$route.params.id;

    let FARM_POINTS = {
      gem: { symbol: '💎', name: 'GEM', shopAddresses: ['0x8B61187300Ba3808a46daFF96957783eE43d10e4', '0x681a4241c73037928838Efde2fb5AF401AA51824'] },
      astro: { symbol: '🚀', name: 'ASTRO', shopAddresses: ['0x4242084Bb8b27089560d8A88F578b6BD4e9aEb9B'] },
      gear: { symbol: '⚙️', name: 'GEAR', shopAddresses: ['0xCf4286dB00f43f52a3F1188E61dD4581A30F3Bd3'] }
    };
    let pointSymbol = FARM_POINTS[id].symbol;
    let pointName = FARM_POINTS[id].name;
    let shopAddresses = FARM_POINTS[id].shopAddresses;

    return {
      ethers,
      formatNumber,

      pointSymbol,
      pointName,
      shopAddresses,
      nftItems: ['loading']
    };
  },

  computed: {
    ...mapState({
      ethereum: (state) => state.ethers,
      items: (state) => state.items,
      farms: (state) => state.farms
    }),

    currentFarm() {
      let id = this.$route.params.id;
      for (let farm of this.farms.farms) {
        if (farm.point.name.toLowerCase() === id.toLowerCase()) {
          return farm;
        }
      }
      return null;
    },

    userBalance() {
      if (this.currentFarm.availableUserPoints) {
        return formatNumber(ethers.utils.formatEther(this.currentFarm.availableUserPoints), 2);
      } else {
        return formatNumber(0, 2);
      }
    },

    isLoading() {
      return this.nftItems[0] === 'loading';
    }
  },

  methods: {
    async initialize() {
      let userAddress = null;
      if (this.ethereum.canSign) {
        userAddress = this.ethereum.address;
      }
      await store.dispatch('farms/retrieveFullFarmsInformation', userAddress, {
        root: true
      });
      await store.dispatch('items/getLaunchpadItems', this.shopAddresses, { root: true });
      this.getItems();
    },

    getItems() {
      if (this.items.launchpadItemList.pools && this.items.launchpadItemList.pools.length < 0) {
        this.nftItems = ['loading'];
        this.getItems();
      }

      let items = ['loading'];
      if (this.items.launchpadItemList.pools !== undefined && this.items.launchpadItemList.pools.length > 0) {
        items = [];
        for (let pool of this.items.launchpadItemList.pools) {
          for (let item of pool.items) {
            // TODO: kill me this is bad. genericize pls
            for (let price of item.prices) {
              if (pool.staker) {
                price.staker = pool.staker;
              }
            }

            // TODO: I feel like this is also a stupid thing to do.
            if (pool.staker) {
              item.shop = this.shopAddresses[0]; // TODO: multi-shop support
            } else {
              if (this.shopAddresses[1]) {
                item.shop = this.shopAddresses[1];
              } else {
                item.shop = this.shopAddresses[0];
              }
            }

            items.push(item);
          }
        }
      }
      this.nftItems = items;
    }
  }
};
</script>

<style scoped>
.gemStore__pageHeader {
  padding: 20px;
  display: flex;
  align-items: center;
}

.gemStore__searchResults {
  margin-right: 20px;
  font-size: 30px;
  font-weight: 400;
}

.gemStore__searchTagsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gemStore__searchTag {
  display: flex;
  margin: 5px;
  padding: 10px 15px;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  border: 1px solid var(--text-color);
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  width: fit-content;
}

.gemStore__tagText {
  margin-left: 10px;
}

.gemStore__itemsGrid {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: auto;
  padding: 0px 20px;
}

.gemStore__balance {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
  align-items: center;
  font-size: 16px;
  /* border: 1px solid rgba(var(--text-color-rgb), 0.2); */
  border: 1px solid rgba(var(--text-color-rgb), 0.5);
  background: rgba(var(--text-color-rgb), 0.1);
  padding: 5px 15px;
  border-radius: 100px;
  color: rgba(var(--text-color-rgb), 0.9);
}

.gemStore__balanceValue {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto 1fr;
  justify-content: center;
  align-items: center;
  color: var(--text-color-secondary);
  padding: 7px 15px;
  border: 1px solid var(--text-color-secondary);
  background-color: var(--pill-background);
  border-radius: 100px;
  margin-right: -10px;
  width: fit-content;
}

@media (max-width: 971px) {
  .gemStore__searchResults {
    font-size: 22px;
  }
}

@media (max-width: 520px) {
  .gemStore__searchTagsWrapper {
    flex-direction: column;
  }

  .gemStore__searchResults {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
</style>
