<template>
  <div class='placeholder-card' v-for='i in items' :key='i'/>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => [0],
        required: false
      }
    }
  }
</script>

<style scoped lang='scss'>
  .placeholder-card {
    width: 100%;
    min-height: 212px;
    border: 2px dashed rgba(var(--background-color-rgb-reverse), .5);
    transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1);
    border-radius: 0 0 30px 30px;
  }
</style>