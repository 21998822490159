<template>
  <div class="load-more-button-component">
    <button
      v-bind="$attrs"
      type="button"
      class="load-more-button"
    >
      {{ title }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Load More"
    }
  },
  methods: {
    handleClick() {
      this.$emit("handleClick");
    }
  }
};
</script>

<style lang="scss" scoped>
.load-more-button-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-button {
  text-align: center;
  height: 40px;
  padding: 0 15px;
  align-items: center;
  border-radius: 24px;
  border: 2px solid $violet;
  color: $violet;
  background-color: transparent;
  transition: color 200ms ease-in-out, box-shadow 200ms ease-in-out;

  &:hover {
    background-color: $violet;
    color: $white;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22), 0 14px 28px 0 rgba(0, 0, 0, 0.25);
  }
}
</style>