<template>
  <div class="results">
    <div class="results__filters">
      <div class="results__filters-left">
        <div class="results__quantity">
          <strong> {{ results.items.length }} </strong> results
        </div>
        <Button
          isSecondary
          class="results__button"
          @click="globalOfferModal = true"
          v-if="results.type === 'nfts'"
        >
          <OfferMade class="button-icon" />
          Global offer
        </Button>
        <GlobalOfferModal v-model="globalOfferModal" />
      </div>
      <div class="results__filters-right">
        <FilterContainer>
          <BaseFilterForm
            :notShowFilters="['Currency']"
            :initialValues="{ ...filters }"
            :minMaxValues="fromToMinMax"
            :onAccept="setAllFilters"
          />
        </FilterContainer>

        <BaseDropdown ref="optionsDropdown" class="results__filters-right-sorting">
          <Button isSecondary class="results__button results__button_light">
            <span class="button-icon"> {{ selectedSorting }} </span>
            <UpDownArrowsIcon />
          </Button>
          <template #dropdown>
            <div class="sorting-dropdown">
              <SocialButton
                class="sorting-dropdown__option"
                v-for="option in sortingOptions"
                :key="option"
                @click="selectOption(option)"
              >
                {{ option }}
              </SocialButton>
            </div>
          </template>
        </BaseDropdown>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        class="results__items"
        v-if="!results.loading.bool && results.loading.type === results.type"
      >
        <template v-if="results.type === 'nfts' && results.items[0].metadata">
          <div v-for="item in results.items" :key="item.id">
            <BaseNFTCard :item="item" :is-show-dropdown-toggle="true">
              <template v-slot:info>
                <BaseNFTCardInfo :item="item" />
              </template>
              <template v-slot:price>
                <BaseNFTCardPrice
                  :state="item.metadata.state"
                  :prices="item.price"
                  :item="item"
                />
              </template>
            </BaseNFTCard>
          </div>
        </template>
        <template v-else-if="results.type === 'users'">
          <BaseUserCard
            v-for="item in results.items"
            :key="item.id"
            v-bind="{ ...item }"
          />
        </template>
        <template v-else-if="results.type === 'collections'">
          <BaseUserCard
            v-for="item in results.items"
            :key="item.id"
            v-bind="{ ...item }"
          />
        </template>
      </div>
      <div v-else class="results__items">
        <Skeletor
          v-for="i in 10"
          :key="i"
          width="300"
          height="420"
          class="skeletor-card"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import BaseNFTCard from "@/components/pages/my-items/nft-card/BaseNFTCard.vue";
import BaseNFTCardInfo from "@/components/pages/my-items/nft-card/BaseNFTCardInfo.vue";
import BaseNFTCardPrice from "@/components/pages/my-items/nft-card/BaseNFTCardPrice.vue";
import Button from "@/components/ui/Button";
import OfferMade from "@/components/icons/OfferMade";
import FilterIcon from "@/components/icons/FilterIcon";
import UpDownArrowsIcon from "@/components/icons/UpDownArrowsIcon";
import { BaseDropdown } from "@/components/ui/baseDropdown";
import SocialButton from "@/components/social/social-button/SocialButton";
import GlobalOfferModal from "./global-offer-modal/GlobalOfferModal.vue";
import { BaseFilterForm } from "@/components/common/filters";
import { FilterContainer } from "@/components/common/modal";

import { mapActions, mapState } from "vuex";
import { Skeletor } from "vue-skeletor";
import BaseUserCard from "../../../components/ui/BaseUserCard.vue";

export default {
  components: {
    BaseNFTCard,
    BaseNFTCardInfo,
    BaseNFTCardPrice,
    BaseFilterForm,
    FilterContainer,
    Button,
    OfferMade,
    // FilterIcon,
    UpDownArrowsIcon,
    BaseDropdown,
    SocialButton,
    GlobalOfferModal,
    Skeletor,
    BaseUserCard,
  },
  data() {
    return {
      sortingOptions: [
        "Most Recent",
        "Most Popular",
        "Trending",
        "Price High to Low",
        "Price Low to High",
      ],
      selectedSorting: "Most Recent",
      globalOfferModal: false,
      fromToMinMax: {
        min: 0.1,
        max: 6,
      },
      filters: {
        status: [],
        price: {
          from: "",
          to: "",
        },
        collections: [],
      },
    };
  },
  computed: {
    ...mapState({
      results: (state) => state.searchResults,
    }),
  },
  methods: {
    ...mapActions("searchResults", ["getItems"]),
    selectOption(option) {
      this.selectedSorting = option;
      this.$refs["optionsDropdown"].hide();
    },
    setAllFilters(values) {
      Object.keys(values).forEach((key) => {
        if (values.hasOwnProperty(key)) {
          this.setExactFilter({ type: key, value: values[key] });
        }
      });
    },
    setExactFilter(data) {
      this.filters[data.type] = data.value;
    },
  },
  mounted() {
    this.getItems(this.$route.params.name);
  },
};
</script>

<style lang="scss" scoped>
.results {
  padding-top: 20px;
  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__filters-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__filters-right {
    display: flex;
    margin-left: auto;
  }
  &__filters-right-sorting {
    margin-left: 10px;
  }
  &__button {
    border: 1px solid $black;
    &-filter {
      margin-right: 10px;
    }
    &_light {
      &:active {
        color: $black;
        background-color: $mediumBlack;
      }
      &:hover {
        color: $black;
        background-color: $lightBlack;
      }
    }
  }
  &__quantity {
    margin-right: 10px;
  }
  &__items {
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
  }
}

.sorting-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  &__option {
    width: 100%;
    text-align: left;
  }
}
:deep .social-button {
  justify-content: flex-start;
}

.button-icon {
  margin-right: 10px;
}

.skeletor-card {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  justify-self: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
