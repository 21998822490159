<template>
  <button
    v-bind="$attrs"
    :type="typeButton"
    class="button-component"
    :style="{'backgroundColor': background, 'color': color}"
  >
    <i v-if="iconName.length" :class="['fas', 'button__icon', iconName]"></i>
    <span class="button__title">{{ title }}</span>
  </button>
</template>

<script>
export default {
  props: {
    typeButton: {
      type: String,
      default: "button"
    },
    title: {
      type: String,
      default: "Button title"
    },
    iconName: {
      type: String,
      default: ""  // name icon from fontawesome
    },
    background: {
      type: String,
      default: "transparent"
    },
    color: {
      type: String,
      default: "#000"
    }
  },
}
</script>

<style lang="scss" src="./Button.scss" scoped></style>