export default {
    setCollection: (state, payload) => {
        state.collection = payload
    },
    setLoading: (state, payload) => {
        state.isLoading = payload
    },
    setFollowLoading: (state, payload) => {
        state.isFollowLoading = payload
    },
    setFollowState: (state, payload) => {
        if (state.collection) {
            state.collection.isFollowed = payload
        }
    }
}