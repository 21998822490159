<template>
  <div class='skeleton' :class='{
    "centered": isCentered,
    "with-borders": isWithBorder
  }'>
    <skeletor class='item' width='60' height='60' circle/>
    <skeletor class='item' height='18' pill/>
  </div>
</template>

<script>
  import {Skeletor} from 'vue-skeletor'

  export default {
    props: {
      isCentered: {
        type: Boolean,
        required: false
      },
      isWithBorder: {
        type: Boolean,
        required: false
      }
    },
    components: {Skeletor}
  }
</script>

<style scoped lang='scss'>
  .skeleton {
    display: grid;
    width: 100%;
    padding: 10px 0;
    align-items: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: 20px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;

    .item {
      background-color: var(--skeleton-color);
    }

    &.centered {
      align-items: center;
    }

    &.with-borders {
      border-bottom: 1px solid rgba(var(--background-color-rgb), .2);
    }
  }
</style>