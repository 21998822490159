<template>
  <span class='error-message'><slot/></span>
</template>

<style scoped lang='scss'>
  .error-message {
    font-size: 12px;
    top: 100%;
    position: absolute;
    left: 0;
    padding-top: 2px;
    color: $errorRed;
  }
</style>