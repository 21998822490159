<template>
  <div>
    <div class="details-container">
      <div class="details-links-wrapper">
        <a :href="farm.getLink" class="details-link" v-if="farm.getLink"> GET {{ farm.pair }} </a>
        <a :href="farm.contractLink" class="details-link" v-if="farm.contractLink">
          View Contract
        </a>
        <a :href="farm.pairInfoLink" class="details-link" v-if="farm.pairInfoLink">
          See Pair Info
        </a>
        <router-link class="details-link" :to="`/store/${farm.point.name.toLowerCase()}`">View {{ farm.point.symbol }} Store </router-link>
        <div class="details-verified">
          <VerifiedIcon class="verified-icon" />
          Verified
        </div>
      </div>

      <div class="harvest-wrapper" v-if="farm.userTokenBalance && farm.userTokenBalance.gt(0)">
        <p class="harvest-label">
          <strong>{{ farm.token.symbol }}</strong> EARNED
        </p>
        <div class="harvest-amount-wrapper">
          <div>
            <div class="amount-earned">
              {{ formatNumber(ethers.utils.formatEther(farm.userTokenBalance), 2) }}
            </div>
            <p>
              ~{{ formatNumber(ethers.utils.formatEther(farm.userTokenBalance) * farm.tokenPrice, 2) }}
              USD
            </p>
          </div>
          <Button @click.stop="harvestToken" :isDisabled="isDisabled">
            <template v-slot:default>
              Harvest
            </template>
          </Button>
        </div>
      </div>

      <!-- Display a section for depositing tokens. -->
      <div class="harvest-wrapper">
        <p class="stake-label">DEPOSIT</p>
        <div class="deposit-field-grid">
          <!-- Token deposit input. -->
          <div class="deposit-field-wrapper">
            <NumberInput :classes="['deposit-field']" v-model="depositTokenAmount" placeholder="Enter Amount" :showText="false" />
            <div class="deposit-max-button" @click.stop="maximumDepositInput">
              Max
            </div>
          </div>

          <!-- If the user has not approved the token, display an approval button. -->
          <Button v-if="!hasApproval" @click.stop="approveToken" :isDisabled="isDisabled">
            <template v-slot:default>
              Approve
            </template>
          </Button>

          <!-- If the user has approved the token, display the deposit button. -->
          <Button v-if="hasApproval" @click.stop="depositToken" :isDisabled="isDisabled">
            <template v-slot:default>
              Deposit
            </template>
          </Button>
        </div>
        <p>
          Available:
          {{ formatNumber(ethers.utils.formatEther(farm.userBalance), 2) }}
          {{ farm.pair }}
        </p>
      </div>

      <!-- Display a section for withdrawing tokens. -->
      <div class="harvest-wrapper">
        <p class="stake-label">WITHDRAW</p>
        <div class="deposit-field-grid">
          <!-- Token withdrawal input. -->
          <div class="deposit-field-wrapper">
            <NumberInput :classes="['deposit-field']" v-model="withdrawTokenAmount" placeholder="Enter Amount" :showText="false" />
            <div class="deposit-max-button" @click.stop="maximumWithdrawInput">
              Max
            </div>
          </div>

          <!-- Handle user withdrawals. -->
          <Button @click.stop="withdrawToken" :isDisabled="isDisabled">
            <template v-slot:default>
              Withdraw
            </template>
          </Button>
        </div>
        <p>
          Available:
          {{ formatNumber(ethers.utils.formatEther(farm.amountStaked), 2) }}
          {{ farm.pair }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports
import { mapState, mapActions } from 'vuex';
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component imports
import Button from '../../ui/Button';
import NumberInput from '/src/components/ui/NumberInput';

// Icon imports
import VerifiedIcon from '/src/components/icons/VerifiedIcon';

// Set up the default component.
export default {
  props: {
    farm: {
      type: Object,
      required: true
    }
  },

  components: {
    Button,
    NumberInput,
    VerifiedIcon
  },

  mounted() {
    document.title = 'SuperFarm Farms';
  },

  data() {
    return {
      ethers,
      formatNumber,

      depositTokenAmount: '0',
      withdrawTokenAmount: '0'
    };
  },

  computed: {
    ...mapState({
      ethereum: (state) => state.ethers
    }),

    isDisabled() {
      return !this.ethereum.canSign;
    },

    hasApproval() {
      if (this.farm.userAllowance) {
        return this.farm.userAllowance.gt(0);
      } else {
        return false;
      }
    }
  },

  methods: {
    ...mapActions('farms', ['approve', 'deposit', 'withdraw']),

    // Approve a token spend for the user.
    async approveToken() {
      await this.approve({ farmAddress: this.farm.address, tokenAddress: this.farm.token.address });

      // Attempt immediate deposit after approval.
      await this.depositToken();
    },

    // Set the input for deposit to the user's maximum.
    async maximumDepositInput() {
      this.depositTokenAmount = ethers.utils.formatEther(this.farm.userBalance);
    },

    // Harvest a user's reward tokens.
    async harvestToken() {
      await this.deposit({
        farmAddress: this.farm.address,
        tokenAddress: this.farm.token.address,
        amount: ethers.BigNumber.from(0)
      });
    },

    // Deposit tokens from a user.
    async depositToken() {
      await this.deposit({
        farmAddress: this.farm.address,
        tokenAddress: this.farm.token.address,
        amount: ethers.utils.parseEther(this.depositTokenAmount)
      });
    },

    // Set the input for withdrawal to the user's maximum.
    async maximumWithdrawInput() {
      this.withdrawTokenAmount = ethers.utils.formatEther(this.farm.amountStaked);
    },

    // Withdraw tokens from a user.
    async withdrawToken() {
      await this.withdraw({
        farmAddress: this.farm.address,
        tokenAddress: this.farm.token.address,
        amount: ethers.utils.parseEther(this.withdrawTokenAmount)
      });
    }
  }
};
</script>

<style>
.details-container {
  display: grid;
  padding: 20px;
  justify-content: start;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  background-color: rgba(var(--background-color-rgb), 1);
  /* backdrop-filter: blur(20px); */
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.details-links-wrapper {
  display: grid;
  flex-direction: column;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

.details-link {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
}

.details-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.details-verified {
  display: flex;
  padding: 5px 8px 5px 5px;
  align-items: center;
  border: 2px solid var(--text-color);
  border-radius: 20px;
  font-size: 14px;
  width: fit-content;
}

.verified-icon {
  height: 17px;
  widows: 17px;
  margin-right: 10px;
}

.harvest-wrapper {
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;

  border-color: rgba(var(--text-color-rgb), 0.2);
  border-radius: 30px;
}

.harvest-label {
  font-size: 12px;
  margin-bottom: 10px;
}

.harvest-amount-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.amount-earned {
  font-size: 20px;
  font-weight: 700;
}

.stake-label {
  font-size: 12px;
  font-weight: 600;
}

.deposit-field-grid {
  display: grid;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  align-items: center;
  margin: 10px 0px;
}

.deposit-field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.deposit-field {
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  transition: border-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-color);
  border-radius: 10px;
  background-color: rgba(var(--background-color-rgb), 1);
}

.deposit-field:hover {
  border-color: rgba(var(--text-color-rgb), 0.5);
}

.deposit-field:focus {
  outline: none;
  border: 2px solid var(--text-color);
}

/* Chrome, Safari, Edge, Opera */
.deposit-field::-webkit-outer-spin-button,
.deposit-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.deposit-field[type='number'] {
  -moz-appearance: textfield;
}

.deposit-max-button {
  position: absolute;
  left: auto;
  right: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgba(var(--text-color-rgb), 0.1);
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1), background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: var(--text-color-primary);
  text-decoration: none;
  text-transform: uppercase;
}

.deposit-max-button:hover {
  background-color: rgba(var(--text-color-rgb), 0.2);
  cursor: pointer;
}

.deposit-max-button:active {
  background-color: rgba(var(--text-color-rgb), 0.3);
}

@media (max-width: 971px) {
  .details-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    grid-row-gap: 0px;
  }

  .harvest-amount-wrapper,
  .deposit-field-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .deposit-field-wrapper {
    width: 100%;
  }

  .deposit-field-grid > * {
    width: 100%;
    margin-bottom: 10px;
  }

  .harvest-wrapper {
    margin-bottom: 40px;
  }
}
</style>
