<template>
  <FarmsRoiModal :isOpen="showModal" :modalData="modalData" @exit="showModal = false" />
  <div class="farms-row-wrapper" @click="toggleDetails">
    <div class="farms-row">
      <div class="pair-wrapper">
        <img class="pair-img" :src="require(`@/images/${farm.pairImgs[0]}`)" />
        <img class="pair-img" :src="require(`@/images/${farm.pairImgs[1]}`)" />
        <p class="pair">{{ farm.pair }}</p>
      </div>
      <div class="amount-staked-wrapper">
        <p>
          {{ amountStakedValue }}
          {{ farm.pair }}
        </p>
      </div>

      <div class="amount-earned-wrapper">
        <div class="amount-earned">
          <p class="amount-earned-value" v-if="showTokenYield">
            <img class="tokenImage" :src="require(`@/images/${farm.pairImgs[1]}`)" />
            <AnimatedNumber :number="farm.userTokenBalance" />
          </p>
          <router-link class="view-store" :to="`/store/${farm.point.name.toLowerCase()}`">
            <p class="amount-earned-value link">
              <router-link class="view-store" :to="`/store/${farm.point.name.toLowerCase()}`">{{ farm.point.symbol }}</router-link>
              <AnimatedNumber :number="farm.amountEarned" />
              <!-- {{ amountEarnedValue }} -->
            </p>
          </router-link>
        </div>
      </div>

      <div class="yield-wrapper">
        <div v-if="showTokenYield" class="yield-pair-wrapper" @click.stop="openModal">
          <div class="svg-wrapper">
            <img class="tokenImage tokenImage-small" :src="require(`@/images/${farm.pairImgs[1]}`)" />
          </div>
          <div class="yield-container">
            <div class="yield-value">
              {{ tokenYieldValue }}
            </div>
            <div class="yield-label">{{ farm.token.symbol }}/day</div>
          </div>
        </div>
        <div class="yield-pair-wrapper" @click.stop="openModal">
          <div class="svg-wrapper">
            <div>
              {{ farm.point.symbol }}
            </div>
          </div>
          <div class="yield-container">
            <div class="yield-value">
              {{ pointYieldValue }}
            </div>
            <div class="yield-label">{{ farm.point.name }}/day</div>
          </div>
        </div>
      </div>

      <div class="apy-wrapper">
        <p class="apy link" @click.stop="openModal" v-if="showTokenYield">{{ tokenYieldApyValue }}%</p>
        <p v-else>
          N/A
        </p>
      </div>

      <div class="tvl apy-wrapper">
        <p>{{ farm.totalValueLocked }}</p>
      </div>

      <Button :isSecondary="true" class="detailsButton">
        <template v-slot:default>
          <div class="button">
            <p class="button-label">Details</p>
            <div class="button-icon" :class="showDetails ? 'button-icon-open' : ''">
              <DownCaretIcon />
            </div>
          </div>
        </template>
      </Button>
    </div>

    <!-- mobile details button -->
    <Button :isSecondary="true" class="detailsButton__mobile">
      <template v-slot:default>
        <div class="button">
          <p class="button-label">Details</p>
          <div class="button-icon" :class="showDetails ? 'button-icon-open' : ''">
            <DownCaretIcon />
          </div>
        </div>
      </template>
    </Button>
    <Button class="detailsButton__mobile">
      <template v-slot:default>
        <router-link class="view-store-button" :to="`/store/${farm.point.name.toLowerCase()}`"> View {{ farm.point.name }} Store </router-link>
      </template>
    </Button>
  </div>
  <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <FarmsRowDetails v-show="showDetails" :farm="farm" class="rowDetails" />
  </transition>
</template>

<script>
'use strict';

// Imports.
import { BigNumber, ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component Imports
import Button from '../../ui/Button';
import FarmsRowDetails from './FarmsRowDetails';
import FarmsRoiModal from './FarmsRoiModal';
import AnimatedNumber from '/src/components/utility/AnimatedNumber';

// Icon Imports
import DownCaretIcon from '../../icons/DownCaretIcon';
// import SuperFarmIcon from "/src/components/icons/SuperFarmIcon";
// import QuestionMarkIcon from '../../icons/QuestionMarkIcon';

export default {
  props: {
    farm: {
      type: Object,
      required: true
    }
  },

  components: {
    DownCaretIcon,
    Button,
    FarmsRowDetails,
    FarmsRoiModal,
    AnimatedNumber
  },

  data() {
    return {
      ethers,
      formatNumber,
      showDetails: false,
      showModal: false,
      modalData: {}
    };
  },

  computed: {
    showTokenYield() {
      return this.farm.isLp;
    },

    pointYieldValue() {
      return this.farm.pointYield.day ? formatNumber(ethers.utils.formatEther(this.farm.pointYield.day), 2) : formatNumber(0, 2);
    },

    tokenYieldValue() {
      return this.farm.tokenYield.day ? formatNumber(ethers.utils.formatEther(this.farm.tokenYield.day), 2) : formatNumber(0, 2);
    },

    tokenYieldApyValue() {
      let tokenYieldYear = this.farm.tokenYield.year;

      if (tokenYieldYear && this.farm.tokenPrice) {
        return formatNumber(
          ethers.utils.formatEther(
            tokenYieldYear
              .mul(Math.floor(this.farm.tokenPrice * 100000))
              .div(1000)
              .div(1000)
          ),
          2
        );
      } else {
        return formatNumber(0, 2);
      }
    },

    userBalanceValue() {
      return this.farm.userTokenBalance ? formatNumber(ethers.utils.formatEther(this.farm.userTokenBalance), 6) : formatNumber(0, 2);
    },

    amountEarnedValue() {
      return this.farm.amountEarned ? formatNumber(ethers.utils.formatEther(this.farm.amountEarned), 6) : formatNumber(0, 2);
    },

    amountStakedValue() {
      return this.farm.amountStaked ? formatNumber(ethers.utils.formatEther(this.farm.amountStaked), 4) : formatNumber(0, 2);
    }
  },

  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },

    enter(el) {
      el.style.height = 'auto';
      const height = getComputedStyle(el).height;
      el.style.height = 0;

      getComputedStyle(el).height;

      setTimeout(() => {
        el.style.height = height;
      });
    },

    afterEnter(el) {
      el.style.height = 'auto';
    },

    leave(el) {
      el.style.height = getComputedStyle(el).height;

      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      }, 10);
    },

    openModal() {
      // load data for modal
      this.modalData = this.showTokenYield
        ? {
            yield: this.farm.pointYield,
            tokenYield: this.farm.tokenYield,
            apr: this.calculateApr(),
            farm: this.farm
          }
        : {
            yield: this.farm.pointYield,
            tokenYield: null,
            apr: null,
            farm: this.farm
          };
      this.showModal = true;
    },

    calculateApr() {
      let apr = {};
      for (let k in this.farm.tokenYield) {
        apr[k] = this.farm.tokenYield[k]
          .mul(Math.floor(this.farm.tokenPrice * 100000))
          .div(1000)
          .div(1000);
      }
      return apr;
    }
  }
};
</script>

<style scoped>
.farms-row-wrapper {
  padding: 20px;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.farms-row-wrapper:hover {
  cursor: pointer;
  background-color: rgba(var(--text-color-rgb), 0.025);
}

.farms-row-wrapper:active {
  background-color: rgba(var(--text-color-rgb), 0.075);
}

.farms-row {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.farms-row > * {
  margin-left: 5px;
}

.pair-wrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.pair-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: -16px;
}

.pair {
  margin-left: 10px;
}

.amount-earned {
  margin-bottom: 2px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  margin-left: -10px;
}

.amount-earned-value {
  white-space: nowrap;
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 5px;
  border-radius: 50px;
  padding: 10px;
}

.link:hover {
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.link:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.view-store {
  text-decoration: none;
  font-weight: 700;
  color: var(--text-color);
  width: fit-content;
}

.view-store:hover {
  cursor: pointer;
}

.view-store-button {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.growth-wrapper {
  display: flex;
  align-items: center;
}

.growth-value {
  margin-right: 10px;
  font-weight: 700;
}

.question-icon {
  opacity: 0.5;
  cursor: pointer;
}

.yield-wrapper {
  margin-left: -5px;
  display: flex;
  flex-direction: column;
}

.yield-pair-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0px;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
}

.yield-pair-wrapper:hover {
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.svg-wrapper {
  display: flex;
  margin-right: 10px;
}

.yield-label {
  opacity: 0.5;
  font-size: 12px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-label {
  margin-right: 10px;
}

.button-icon {
  transition: transform 0.5s ease;
  display: flex;
}

.button-icon-open {
  transform: rotateZ(-180deg);
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0px;
}

.rowDetails {
  height: 173px;
}

.detailsButton__mobile {
  display: none;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.super-icon {
  max-width: 15px;
  max-height: 20px;
  margin-right: 2px;
}

.apy-wrapper {
  width: fit-content;
  margin-left: 10px;
}

.apy {
  width: fit-content;
  padding: 10px;
  border-radius: 50px;
  margin-left: -10px;
}

.apy:hover {
  cursor: pointer;
}

.tokenImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tokenImage-small {
  width: 15px;
  height: 15px;
}

.yield-container {
  cursor: pointer;
}

@media (max-width: 971px) {
  .detailsButton {
    display: none;
  }

  .detailsButton__mobile {
    display: initial;
  }

  .farms-row-wrapper {
    padding: 20px 10px;
  }

  .farms-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
}

@media (max-width: 730px) {
  .tvl {
    display: none;
  }
}

@media (max-width: 520px) {
  .pair-img {
    height: 25px;
    width: 25px;
  }
  .pair,
  .amount-staked-wrapper,
  .amount-earned-value,
  .yield-wrapper,
  .yield-label {
    font-size: 11px;
  }

  .svg-wrapper {
    display: none;
  }

  .yield-pair-wrapper {
    padding: 5px 0px;
  }

  .yield-pair-wrapper:hover {
    background-color: initial;
  }

  .apy-wrapper {
    font-size: 10px;
  }
}
</style>
