"use strict";

// Export state mutation functions.
export default {
  // Update page visibility details.
  update(state, { hidden, probablyHidden, timeWithoutAction }) {
    state.hidden = hidden;
    state.probablyHidden = probablyHidden;
    state.timeWithoutAction = timeWithoutAction;
  }
};
