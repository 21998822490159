<template>
  <div>
    <h1>Shops</h1>
    <p>Now that you're logged in, you can deploy shops on this page.</p>
    <Form @submit="handleSubmit">
      <div class="form-group">
        <label for="name">Shop Name</label>
        <Field
          name="name"
          as="input"
          v-model="name"
          class="form-control"
          :rules="validationRules.shopName"
        />
        <ErrorMessage name="name" />
      </div>
      <div class="form-group">
        <label for="shouldAddFarm">Attach Shop to Farms?</label>
        <input
          name="shouldAddFarm"
          type="checkbox"
          v-model="shouldAddFarm"
          class="form-control"
        />
      </div>

      <div v-if="shouldAddFarm" class="form-group">
        <MultiSelectionList
          header="Farms to Attach:"
          emptyMessage="You have not specified any farms."
          selectPlaceholder="Select a farm from the list."
          customPlaceholder="Enter the address of a custom farm."
          customInputText="Custom Farm Address"
          :columns="[
            {
              name: 'Farm Address',
              id: 'farmAddress',
              type: 'primary'
            }
          ]"
          primary="address"
          :elements="farms.farmList"
          template="${name} - ${address}"
          v-model="farmsToAttach"
        />
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="shops.status.deploying"
        >
          Deploy
        </button>
        <img
          v-show="shops.status.deploying"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </div>
    </Form>
    <br />
    <h1>Your Farms</h1>
    <ul v-if="farms.farmList.length > 0">
      <li v-for="farm in farms.farmList" :key="farm">
        {{ farm.name }} with ({{ farm.token.name }}) at
        <router-link :to="`/farms/${farm.address}`">{{
          farm.address
        }}</router-link>
      </li>
    </ul>
    <h3 v-if="farms.farmList.length === 0">
      You have not created any farms yet.
    </h3>
    <br />
    <h1>Your Shops</h1>
    <ul v-if="shops.shopList.length > 0">
      <li v-for="shop in shops.shopList" :key="shop">
        {{ shop.name }} ({{ shop.address }}) for farms {{ shop.farms }}
      </li>
    </ul>
    <h3 v-if="shops.shopList.length === 0">
      You have not created any shops yet.
    </h3>
    <br />
    <p>
      When your shop has deployed, move on to the
      <router-link to="/items">Items</router-link> tab to set up items.
    </p>
  </div>
</template>

<script>
"use strict";

// Imports.
import { mapState, mapActions } from "vuex";
import { validationRules } from "../utility";
import store from "../store";

// Component imports.
import { Form, Field, ErrorMessage } from "vee-validate";
import MultiSelectionList from "../components/MultiSelectionList.vue";

// Set up the component.
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    MultiSelectionList
  },
  data() {
    return {
      name: "",
      shouldAddFarm: false,
      farmsToAttach: [],

      // Include our standard validation rules.
      validationRules: validationRules
    };
  },
  async created() {
    console.log(`Initializing data for shops page ...`);
    await store.dispatch("farms/getAllFarms", "", { root: true });
    await store.dispatch("shops/getAllShops", "", { root: true });
  },
  computed: {
    ...mapState({
      farms: state => state.farms,
      shops: state => state.shops
    })
  },
  methods: {
    ...mapActions("shops", ["deploy"]),
    async handleSubmit() {
      const { name, farmsToAttach } = this;
      console.log(farmsToAttach);

      // If forms are valid, tell users what transactions to expect.
      if (name) {
        let transactionCount = 1;
        let userInfoString = `\n    ${transactionCount}) a transaction to deploy the shop`;
        for (let i = 0; i < farmsToAttach.length; i++) {
          transactionCount += 1;
          userInfoString += `\n    ${transactionCount}) a transaction to attach the farm at ${farmsToAttach[i].farmAddress}`;
        }
        userInfoString =
          `You will be prompted to sign ${transactionCount} transactions:` +
          userInfoString;
        await store.dispatch("alert/info", userInfoString, { root: true });

        // Attempt to deploy a shop.
        this.shops.status.deploying = true;
        this.deploy({ name, farmsToAttach });
      }
    }
  }
};
</script>
