<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      d="M31.7152 59.7315L38.039 57.3225L38.9423 62.8432C39.1431 64.2484 40.448 65.3526 41.8533 65.3526H53.8984C55.4041 65.3526 56.6086 64.2484 56.8093 62.8432L57.7127 57.3225L64.0364 59.6311C65.4417 60.133 66.847 59.1292 66.847 57.6236V52.3036C66.847 48.2886 64.3376 45.8796 62.5308 44.5747C61.2259 43.6713 60.5233 42.266 60.3225 40.7604C59.0176 27.5107 51.6901 20.4843 48.8796 18.3764C48.2773 17.8745 47.374 17.8745 46.7717 18.3764C43.9612 20.5847 36.6337 27.611 35.4292 40.9611C35.3288 42.4668 34.5258 43.872 33.3213 44.7754C31.5145 46.0803 29.0051 48.5897 29.0051 52.5044V57.8243C28.9047 59.2296 30.31 60.2334 31.7152 59.7315ZM47.8758 33.9348C50.586 33.9348 52.7943 36.143 52.7943 38.8532C52.7943 41.5634 50.586 43.7716 47.8758 43.7716C45.1657 43.7716 42.9574 41.5634 42.9574 38.8532C42.9574 36.143 45.0653 33.9348 47.8758 33.9348Z"
      class="icon"
    ></path>
    <path
      d="M39.5446 69.7692V73.7842C39.5446 74.788 40.3476 75.591 41.3514 75.591C42.3551 75.591 43.1582 74.788 43.1582 73.7842V69.7692C43.1582 68.7654 42.3551 67.9624 41.3514 67.9624C40.3476 67.9624 39.5446 68.7654 39.5446 69.7692Z"
      class="icon"
    ></path>
    <path
      d="M46.0691 69.7692V76.1933C46.0691 77.197 46.8721 78 47.8758 78C48.8796 78 49.6826 77.197 49.6826 76.1933V69.7692C49.6826 68.7654 48.8796 67.9624 47.8758 67.9624C46.8721 67.9624 46.0691 68.7654 46.0691 69.7692Z"
      class="icon"
    ></path>
    <path
      d="M54.4003 67.9624C53.3965 67.9624 52.5935 68.7654 52.5935 69.7692V73.7842C52.5935 74.788 53.3965 75.591 54.4003 75.591C55.4041 75.591 56.2071 74.788 56.2071 73.7842V69.7692C56.2071 68.7654 55.4041 67.9624 54.4003 67.9624Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
