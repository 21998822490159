<template>
  <div class="mobileMenuWrapper">
    <!-- Menu Icon -->
    <div class="icon" @click="toggleMenu">
      <div class="menu-icon">
        <input class="menu-icon__cheeckbox" type="checkbox" v-model="isVisible" />
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- Menu Navigation -->
    <transition name="slide">
      <div v-show="isVisible" class="mobileMenu" @click.stop="toggleMenu">
        <router-link to="/" class="item">Home</router-link>
        <router-link to="/drops" class="item">Drops</router-link>
        <router-link to="/farms" class="item">Farms</router-link>
        <a href="https://superstarter.co/" target="_blank" class="item">SuperStarter</a>
        <div class="item">
          <ThemeToggle class='toggle'/>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ref } from 'vue';

import {ThemeToggle} from '@/components/utility/theme'

export default {
  components: {
    ThemeToggle
  },

  emits: ['toggleTheme'],

  setup(props, context) {
    const isVisible = ref(false);
    const toggleMenu = () => {
      isVisible.value = !isVisible.value;
    };

    const toggleTheme = () => {
      context.emit('toggleTheme');
    };

    return {
      isVisible,
      toggleMenu,
      toggleTheme
    };
  }
};
</script>
<style scoped lang="scss">
.menu-icon {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid rgba(var(--text-color-rgb), 0.2);
  padding: 20px;
  border-radius: 50px;

  &:active {
    background-color: rgba(var(--text-color-rgb), 0.1);
  }

  .menu-icon__cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 3;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }
  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 22px;
    height: 12px;
  }
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(var(--text-color-rgb), 0.8);
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  &.active,
  .menu-icon__cheeckbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }

  &.active:hover span:first-of-type,
  &.active:hover span:last-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
    width: 22px;
  }

  &:hover {
    // no need hover effect on mobile.
    @media (min-width: 1024px) {
      span:first-of-type {
        width: 26px;
      }

      span:last-of-type {
        width: 12px;
      }
    }
  }
}

.icon {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 2;
  display: none;
}
.mobileMenu {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;

  .item {
    padding: 20px;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
  }
}
@media (max-width: 971px) {
  .icon {
    display: initial;
  }
}

// Animations
.slide-leave-active,
.slide-enter-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100vw);
}
</style>
