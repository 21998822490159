<template>
  <Ethereum :callback="initialize" />
  <div class="claimCard">
    <!-- Header -->
    <div class="claimCard__header">Super Presale Distribution Claim</div>
    <!-- Progress Bar -->
    <ClaimCardProgressContainer
      :availablePercentage="availablePercentage"
      :claimedPercentage="claimedPercentage"
    />
    <!-- Released Info -->
    <div className="claimCard__releasedContainer">
      <SuperFarmIcon :noText="true" class="claim-currency" />
      <div className="totalAvailable">
        {{ formatNumber(totalAvailable, 2) }}
      </div>
      <div className="totalAmount">
        /{{ formatNumber(totalAmount, 0) }} SUPER TOTAL
      </div>
    </div>
    <!-- Available + Claimed -->
    <div className="claimCard__availableClaimedContainer">
      <ClaimCardProgressBar
        title="Available"
        :current="available"
        :total="totalAmount - claimed"
      />
      <ClaimCardProgressBar
        title="Claimed"
        :current="claimed"
        :total="totalAmount"
      />
    </div>
    <!-- Claim Button -->
    <button class="claimCard__button" @click="claimSuper">Claim SUPER</button>
  </div>
</template>

<script>
// Imports.
import { computed, ref } from 'vue';
import { formatNumber } from '/src/utility/format';
import { useStore } from 'vuex';

// Component Imports.
import ClaimCardProgressContainer from './ClaimCardProgressContainer';
import ClaimCardProgressBar from './ClaimCardProgressBar';
import Ethereum from '/src/components/common/Ethereum';

// Icon Imports.
import SuperFarmIcon from '/src/components/icons/SuperFarmIcon';

export default {
  components: {
    ClaimCardProgressContainer,
    ClaimCardProgressBar,
    Ethereum,
    // Icons
    SuperFarmIcon
  },

  data() {
    return {
      formatNumber
    };
  },

  setup() {
    const store = useStore();

    const totalAmount = computed(() => {
      return store.state.claim.claim
        ? store.state.claim.claim.totalAmount.toNumber()
        : 0;
    });

    const claimed = computed(() => {
      return store.state.claim.claim
        ? store.state.claim.claim.amountClaimed.toNumber()
        : 0;
    });

    const available = computed(() => {
      return store.state.claim.claim
        ? store.state.claim.claim.claimableAmount.toNumber()
        : 0;
    });

    const totalAvailable = computed(() => {
      return claimed.value + available.value;
    });

    const claimedPercentage = computed(() => {
      return totalAmount.value > 0
        ? (claimed.value / totalAmount.value) * 100
        : 0;
    });

    const availablePercentage = computed(() => {
      return totalAmount.value > 0
        ? (totalAvailable.value / totalAmount.value) * 100
        : 0;
    });

    const initialize = async () => {
      // load up the user's claim
      await store.dispatch('claim/getClaim');
    };

    const claimSuper = async () => {
      await store.dispatch('claim/claimSuper');
    };

    return {
      totalAmount,
      claimed,
      available,
      totalAvailable,
      claimedPercentage,
      availablePercentage,
      initialize,
      claimSuper
    };
  }
};
</script>

<style lang="scss" scoped>
.claimCard {
  background-color: white;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 50px;
  width: 500px;
}

.claimCard__header {
  margin: auto;
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;
}

.claimCard__releasedContainer {
  margin-top: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .claim-currency {
    display: flex;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  .totalAvailable {
    text-align: center;
    margin-bottom: 5px;
    font-size: 50px;
    line-height: 1em;
    font-weight: 700;
    font-weight: bold;
  }

  .totalAmount {
    text-align: center;
    font-size: small;
  }
}

.claimCard__availableClaimedContainer {
  padding: 15px 0px;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.claimCard__button {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-image: linear-gradient(45deg, #ff7112, #e6a400);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
}

.claimCard__button:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22),
    0 14px 28px 0 rgba(0, 0, 0, 0.25);
}

.claimCard__button:active {
  box-shadow: none;
}
</style>
