<template>
  <div class="tab-container" :class="isSelected ? 'selected' : ''" :style="scssVars"><slot></slot>{{ title }}</div>
</template>

<script>
import styles from '../../assets/style/_variables.scss';
export default {
  props: {
    title: {
      type: String
    },
    isSelected: {
      type: Boolean
    },
    color: {
      type: String,
      default: 'violet'
    },
    bgColor: {
      type: String,
      default: 'lightViolet'
    }
  },
  computed: {
    scssVars() {
      return {
        '--color': styles[`${this.color}`],
        '--bgColor': styles[`${this.bgColor}`]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.tab-container {
  padding: 20px;
  transition: background-color 200ms ease, color 200ms ease;
  color: var(--text-color);
  opacity: 0.5;
  text-align: center;
  font-size: 14px;
  line-height: 1.3em;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}
.tab-container:hover {
  cursor: pointer;
  color: var(--text-color);
  background-color: rgba(var(--text-color-rgb), 0.1);
}
.tab-container:active {
  background-color: rgba(var(--text-color-rgb), 0.3);
}
.selected {
  border-bottom: 3px solid var(--text-color);
  opacity: 1;
  background-color: transparent;
  color: var(--text-color);
  font-weight: 600;
}
</style>
