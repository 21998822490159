<template>
  <div class="filter-dropdown">
    <form class="filter-dropdown__form">
      <BaseInput type="number" class="filter-dropdown__input" v-model="from" />
      <span>to</span>
      <BaseInput type="number" class="filter-dropdown__input" v-model="to" />
    </form>
    <Button class="filter-dropdown__button">Save</Button>
    <Button isSecondary class="filter-dropdown__button filter-dropdown__button_cancel">Clear</Button>
  </div>
</template>

<script>
import BaseInput from "@/components/ui/BaseInput";
import Button from "@/components/ui/Button";
export default {
  name: "FilterDropdown",
  components: {
    BaseInput,
    Button,
  },
  data() {
    return {
      from: 0,
      to: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-dropdown {
  padding: 10px;
  &__form {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    align-items: center;
    justify-items: center;
    margin-bottom: 10px;
    max-width: 100%;
  }
  &__input {
    -moz-appearance: textfield;
    width: 70px;
    @include media-max-width($largeScreen) {
      width: 100%;
    }
    &_cancel {
      &:hover {
        background-color: $lightBlack;
        border: none;
      }
    }
  }
  &__input::-webkit-outer-spin-button, &__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &__button {
    width: 100%;
    &_cancel {
      border: none;
      &:hover {
        background-color: $lightBlack;
        border: none;
        color: $black;
        box-shadow: none;
      }
    }
  }
}
</style>
