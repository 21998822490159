<template>
  <div class="drop-card-product-container" @click="openModal" ref="card">
    <div class="media-container">
      <img
        v-if="isMobile || mediaType === 'png' || mediaType === 'jpg'"
        :src="item.metadata.image"
      />
      <video
        :class="{ video: idx === 0, 'scaled-video': idx !== 0 }"
        v-if="!isMobile && mediaType === 'mp4'"
        :autoplay="!isMobile"
        loop
        muted
        :key="item.metadata.video"
      >
        <!-- TODO support animation_url as fallback to video. -->
        <!-- TODO: no video on card preview -->
        <source :src="item.metadata.video" :type="`video/${mediaType}`" />
      </video>
    </div>

    <div class="body">
      <p v-if="idx === 0" class="remaining" style="color: #228aff">Rare</p>
      <p v-if="idx === 1" class="remaining" style="color: #7122ff">Epic</p>
      <p v-if="idx === 2" class="remaining" style="color: #ffa922">Legendary</p>
      <p class="remaining">{{ remaining }}</p>
      <p class="cost">{{ cost }}</p>
      <BaseButton
        class="button-position"
        :isDisabled="true"
        :buttonText="buttonText"
        disabledText="Coming Soon"
        @click.stop="purchaseItem"
      />
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ethers } from 'ethers';
import { formatNumber } from '../../../utility';
import { mapState, mapActions } from 'vuex';

// Component imports.
import BaseButton from '../../ui/BaseButton';

export default {
  components: {
    BaseButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    poolId: {
      type: Number,
      required: true
    },
    startBlock: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      ethers,
      formatNumber
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      itemModal: state => state.itemModal
    }),

    mediaType() {
      let video = this.item.metadata.video;
      let animationUrl = this.item.metadata['animation_url'];
      let image = this.item.metadata.image;
      if (video) {
        let videoPath = this.item.metadata.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.item.metadata['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.item.metadata.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    },

    buttonText() {
      let isInStock = this.amountRemaining.gt(0);
      let canPurchase = true;
      if (isInStock) {
        if (canPurchase) {
          return 'Buy Now';
        } else {
          return 'Allocated';
        }
      } else {
        return 'Sold Out';
      }
    },

    cost() {
      return `${formatNumber(
        ethers.utils.formatEther(this.item.prices[0].price),
        0
      )} SUPER`;
    },

    amountRemaining() {
      return this.item.cap.sub(this.item.minted);
    },

    remaining() {
      if (this.amountRemaining.gt(0)) {
        return `${formatNumber(this.amountRemaining.toString(), 0)} left`;
      } else {
        return 'SOLD OUT';
      }
    },

    cardWidth() {
      return this.$refs.card.clientWidth;
    },

    isAvailable() {
      return this.ethereum.blockNumber >= this.startBlock;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
  },

  methods: {
    ...mapActions('itemModal', ['open']),
    ...mapActions({
      showWalletModal: 'alert/showWalletModal'
    }),
    ...mapActions('items', ['purchaseLaunchpadItem']),
    openModal: function() {
      let isDisabled = !this.isAvailable;
      this.open({
        isOpen: true,
        item: this.item,
        bannerImage: null,
        avatarImage: null,
        creatorHandle: null,
        isDisabled: isDisabled,
        startBlock: this.startBlock
      });
    },
    async purchaseItem() {
      if (!this.ethereum.canSign) {
        this.showWalletModal();
        return;
      }

      let poolId = this.poolId; // TODO: pool selection
      let groupId = this.item.groupId;
      let assetId = 0; // TODO: assetId selection
      let amount = 1; // TODO: eventual quantity input
      await this.purchaseLaunchpadItem({ poolId, groupId, assetId, amount });
    }
  }
};
</script>

<style scoped>
.video {
  height: 315px;
  transform: scale(1.01);
}

.scaled-video {
  width: auto;
  height: 315px;
}

.media-container {
  height: 315px;
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.drop-card-product-container {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #ced0d4;
  width: 230px;
}

.drop-card-product-container:hover {
  cursor: pointer;
  transition: ease 0.2s;
  background-color: whitesmoke;
}

.drop-card-product-container > img {
  width: 100%;
  height: 240px;
  object-fit: contain;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-bottom: -4px;
}

.button-position {
  margin: 10px;
}

.remaining {
  font-size: 16px;
  font-weight: 500;
  margin: 3px 0px;
}

.cost {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 7px;
}

.container {
  display: flex;
  width: 100%;
  height: 100;
}

.image-container {
  height: calc(100vh - 64px);
  pointer-events: inherit;
}

.details-page {
  width: 500px;
  height: 500px;
  background-color: yellow;
}

.body {
  border-top: 1px solid #ced0d4;
}
</style>
