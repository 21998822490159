<template>
  <div class="appsSection">
    <div class="container">
      <h2>Apps for the crypto community.</h2>
      <div class="appsGrid">
        <InfoCard v-for="app in apps" :key="app" class="card" :isActive="isActive(app)" @click="openUrl(app.url)">
          <template v-slot>
            <img :src="require(`@/images/${app.img}.svg`)" alt="" class="icon" />
            <div class="title">{{ app.title }}</div>
            <div v-if="!isActive(app)" class="comingSoon">
              (coming soon)
            </div>
            <div class="subtitle">
              {{ app.subtitle }}
            </div>
          </template>
        </InfoCard>
      </div>
    </div>
  </div>
</template>
<script>
// Component Imports.
import InfoCard from '/src/components/pages/launchpad/InfoCard';
export default {
  components: {
    InfoCard
  },

  setup() {
    const apps = [
      {
        img: 'superstarter',
        title: 'SuperStarter',
        subtitle: 'A new and exciting type of decentralized and permissionless crowdfunding platform.',
        url: 'https://superstarter.co/'
      },
      {
        img: 'impostors',
        title: 'Impostors',
        subtitle: 'An online multiplayer social deduction game developed and published by SuperFarm.'
      },
      {
        img: 'videogame',
        title: 'Video Game SDK',
        subtitle: 'Tools for creating games using blockchain technology for elaborate in-game economics.'
      },
      {
        img: 'multichain',
        title: 'Multi-Chain',
        subtitle: 'SuperFarm is a multi-chain protocol, compatible with all the top smart contract chains.'
      }
    ];

    const openUrl = url => {
      if (url) {
        window.open(url, '_blank');
      }
    };

    const isActive = app => {
      return app.url !== undefined;
    };

    return {
      apps,
      openUrl,
      isActive
    };
  }
};
</script>
<style scoped lang="scss">
.appsSection {
  padding: 140px 20px;
  flex: 1;

  .container {
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-top: 0px;
      margin-bottom: 40px;
      line-height: 1.1;
      text-align: center;
      font-size: 32px;
    }

    .subtitle {
      max-width: 60ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 24px;
      text-align: center;
    }

    .appsGrid {
      display: grid;
      max-width: 630px;
      margin-right: auto;
      margin-left: auto;
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      .icon {
        display: flex;
        width: 72px;
        height: 72px;
        margin-bottom: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        vertical-align: middle;
      }

      .title {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
      }

      .comingSoon {
        font-style: italic;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 497px) {
  .appsSection .container .appsGrid {
    grid-template-columns: 1fr;
  }
}
</style>
