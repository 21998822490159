<template>
  <ReportModal title='Report collection'
               :isLoading='isSubmitting'
               :onSubmit='submitReport'/>
  <InfoContainer>
    <template #avatar>
      <Avatar :value='collection?.avatarUrl' isRounded/>
    </template>

    <template #name>
      <Name>{{collection?.name}}</Name>
    </template>

    <template #actions>
      <div class='collection-actions'>
        <Button :isDisabled='isFollowLoading'
                @click='toggleFollowing(collection?.id)'
                isSecondary>
          {{collection?.isFollowed ? 'Unfollow' : 'Follow'}}
        </Button>
        <Additional @onItemClick='onItemClick'/>
      </div>
    </template>

    <template #stats>
      <Stats :data='collection'/>
    </template>

    <template #description>
      <Description :value='collection?.description'/>
    </template>

    <template #website>
      <WebsiteUrl :value='collection?.websiteUrl' class='profile-website'/>
    </template>

    <template #social>
      <Socials :values='collection?.socialLinks'/>
    </template>
  </InfoContainer>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import {socialService} from '@/services'
  import Button from '@/components/ui/Button'
  import {
    Avatar, Stats, Socials, WebsiteUrl,
    InfoContainer, Name, Description,
    Additional
  } from '@/layouts/profile-layout'
  import {ReportModal} from '@/components/common/modal'

  export default {
    components: {
      Avatar, Stats,
      WebsiteUrl, Socials,
      InfoContainer, Name,
      Description, Button,
      Additional, ReportModal
    },
    data() {
      return {
        isSubmitting: false
      }
    },
    computed: {
      ...mapGetters('collection', ['collection', 'isFollowLoading'])
    },
    methods: {
      ...mapActions('collection', ['toggleFollowing']),
      ...mapActions('modal', ['open']),
      onItemClick(type) {
        switch (type) {
          case 'report':
            this.open('report-modal')

            break
          default:
            break
        }
      },
      async submitReport(message, close) {
        if (this.collection) {
          this.isSubmitting = true

          try {
            await socialService.Collections.report(this.collection.id, {message})

            close()
          } finally {
            this.isSubmitting = false
          }
        }
      }
    }
  }
</script>

<style scoped lang='scss'>
  .collection-actions {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    grid-auto-flow: column;
  }
</style>
