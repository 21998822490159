<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.19883 3.70614C4.96549 3.70614 6.39766 2.98842 6.39766 2.10307C6.39766 1.21772 4.96549 0.5 3.19883 0.5C1.43216 0.5 0 1.21772 0 2.10307C0 2.98842 1.43216 3.70614 3.19883 3.70614ZM10.1344 6.95614C11.6928 6.95614 12.9562 6.23842 12.9562 5.35307C12.9562 4.46772 11.6928 3.75 10.1344 3.75C8.57591 3.75 7.31253 4.46772 7.31253 5.35307C7.31253 6.23842 8.57591 6.95614 10.1344 6.95614ZM10.1766 7.87833C8.15835 7.87833 7.35478 7.03251 7.35478 6.54257V8.64127C7.35478 9.5269 8.61823 10.2435 10.1766 10.2435C11.7366 10.2435 13.0001 9.5269 13.0001 8.64127V6.59295C13.0001 7.0837 12.1957 7.87833 10.1766 7.87833ZM7.35478 9.81776C7.35478 10.3077 8.37123 11.121 10.1766 11.121C11.9828 11.121 13.0001 10.2825 13.0001 9.79257V11.897C13.0001 12.7826 11.7366 13.5 10.1766 13.5C8.61823 13.5 7.35478 12.7826 7.35478 11.897V9.81776ZM0.101562 3.29257C0.101562 3.78251 1.01319 4.62833 3.30039 4.62833C5.58759 4.62833 6.50003 3.8337 6.50003 3.34295V5.39127C6.50003 6.2769 5.0684 6.99353 3.30039 6.99353C1.53401 6.99353 0.101562 6.2769 0.101562 5.39127V3.29257ZM3.30039 7.87102C1.25369 7.87102 0.101562 7.0577 0.101562 6.56776V8.64696C0.101562 9.53259 1.53401 10.25 3.30039 10.25C5.0684 10.25 6.50003 9.53259 6.50003 8.64696V6.54257C6.50003 7.03251 5.34628 7.87102 3.30039 7.87102ZM0.101562 9.81776C0.101562 10.3077 1.25369 11.121 3.30039 11.121C5.34628 11.121 6.50003 10.2825 6.50003 9.79257V11.897C6.50003 12.7826 5.0684 13.5 3.30039 13.5C1.53401 13.5 0.101562 12.7826 0.101562 11.897V9.81776Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
