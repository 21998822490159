<template>
  <div :class='{"image-wrapper": true, "rounded": isRounded}'>
    <img v-if='!!value' :src='value'>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      isRounded: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style scoped lang='scss'>
  .image-wrapper {
    overflow: hidden;
    width: 140px;
    height: 140px;
    border: 3px solid var(--background-color);
    border-radius: 50%;
    background-color: var(--background-color);

    &.rounded {
      border-radius: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>