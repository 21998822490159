const mockProfileData = {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    username: 'username',
    description: 'Username is a Franco-Belgian artist, living and working in Brussels. At the age of 28 he studied drawing and illustration at the Ecole Nationale des Beaux-Arts in Paris. Shortly after he ventured to the USA to improve himself, before settling in Belgium and devoting himself entirely to his art.',
    avatarUrl: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606cb1a0e8bd0e548409fdf0_image.gif',
    bannerUrl: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606fa1b7680f5b7f2a1e1413_banner.jpg',
    ethAddress: '0x26c0b38a4edf3c311fbfeee52da3168e65945069',
    totalNFTItems: 400,
    totalFollowers: 329,
    avgSaleAmount: 6.7,
    totalSaleAmount: 695,
    websiteUrl: 'https://example.com',
    socialLinks: {
        twitter: 'https://twitter.com/',
        instagram: 'https://instagram.com/',
        facebook: 'https://facebook.com/',
        youtube: 'https://youtube.com/',
        linkedin: 'https://linkedin.com/',
        email: 'testmail@some.com',
        twitch: 'https://twitch.tv/',
        telegram: 'https://telegram.org/',
        medium: 'https://medium.com/',
        discord: 'https://discord.com/',
        snapchat: 'https://snapchat.com/'
    }
}

const mockPublicProfileData = {
    ...mockProfileData,
    username: 'public username',
    totalNFTItems: 10,
    totalFollowers: 500,
    avgSaleAmount: 1.7,
    totalSaleAmount: 200,
    ethAddress: '0x45c0b38a4edf3c311fbfeee52da3168e65945069',
    socialLinks: {
        twitter: 'https://twitter.com/',
        instagram: 'https://instagram.com/',
        facebook: 'https://facebook.com/',
        youtube: 'https://youtube.com/',
        linkedin: 'https://linkedin.com/'
    }
}

const mockPinnedItems = [
    {
        id: '1',
        isPinned: true,
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb935738e434e6050ead7_nft-2.jpg',
            state: 'notListed'
        }
    },
    {
        id: '2',
        isPinned: true,
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb938dbb61df64aa0a583_nft.jpg',
            state: 'notListed'
        }
    },
    {
        id: '3',
        isPinned: true,
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a0d743a3b854604a2_nft.png',
            state: 'onAuction'
        }
    },
    {
        id: '4',
        isPinned: true,
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb9362793fe221848f607_nft-1.jpg',
            state: 'onSale'
        }
    },
    {
        id: '5',
        isPinned: true,
        metadata: {
            name: 'NFT Name Goes Here',
            collection: 'Collection Name',
            image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb93a64f683fa80daa6fa_nft-1.png',
            state: 'notListed'
        }
    }
]

export default {
    setPageState({commit}, isPublic) {
        commit('setPageState', isPublic)
    },
    async getProfile({state, commit}) {
        if (!!state.profile) {
            commit('setProfile', null)
        }

        commit('setProfile', state.isPublic ? mockPublicProfileData : mockProfileData)
    },
    async getPinnedItems({state, commit}) {
        if (state.pinnedItems.length !== 0) {
            commit('setPinnedItems', [])
        }

        commit('setPinnedItems', state.isPublic ? mockPinnedItems.splice(1, 2) : mockPinnedItems)
    },
    async getPageData({dispatch}) {
        await Promise.all([dispatch('getProfile'), dispatch('getPinnedItems')])
    },
    setPin({commit}, data) {
        if (data.type === 'unpin') {
            commit('removeItem', data.item)
        } else {
            commit('addItem', data.item)
        }
    }
}