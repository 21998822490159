import {decimal, maxNumberMessage, maxString, minNumberMessage, object} from '@/utility/validation-rules'

export const fromToValidationSchema = (min, max) => object({
    from: decimal.concat(maxString(30))
        .test('fromMin', minNumberMessage(min), value => {
            return !(!!value && Number(value) < min)
        })
        .test({
            name: 'fromMax',
            test: function(value, ctx) {
                const to = Number(ctx.parent.to)

                return !!to && (!!value && Number(value) > to) ?
                    this.createError({
                        message: maxNumberMessage(to),
                        path: 'from'
                    }) : true
            }
        }),
    to: decimal.concat(maxString(30))
        .test('toMax', maxNumberMessage(max), value => {
            return !(!!value && Number(value) > max)
        })
})