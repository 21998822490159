<template>
  <CardModalDetailView
    v-if="showDetails"
    @closeDetails="toggleDetails"
    :item="convertItemToModal(item)"
  />
  <div class="container">
    <div class="media-container">
      <div class="image-container">
        <!-- TODO: genericize this -->
        <img
          v-if="mediaType === 'png' || mediaType === 'jpg'"
          :src="item.image"
          class="video detail-hover"
          @click="toggleDetails"
        />
        <video
          class="video detail-hover"
          v-if="mediaType === 'mp4'"
          autoplay
          loop
          muted
          :key="item.video"
          @click="toggleDetails"
        >
          <!-- TODO support animation_url as fallback to video. -->
          <!-- TODO: no video on card preview -->
          <source :src="item.video" :type="`video/${mediaType}`" />
        </video>
      </div>
    </div>

    <!-- <CardModalSidePane :item="convertItemToModal(item)" class="side-pane" /> -->
    <CardModalSidePane
      :item="convertItemToModal(item)"
      :itemType="''"
      class="side-pane"
    />
  </div>
</template>

<script>
// Imports
import { ethers } from 'ethers';
import axios from 'axios';
import { mapState } from 'vuex';
import { useTask } from 'vue-concurrency';
import store from '../store';
import initializeConfig from '../initialize-config';
import router from '../router';

// Component Imports
import CardModalSidePane from '../components/nft-detail-modal/CardModalSidePane';
import CardModalDetailView from '/src/components/nft-detail-modal/CardModalDetailView';

export default {
  components: {
    CardModalSidePane,
    CardModalDetailView
  },

  data() {
    return {
      axios,
      ethers,
      item: null,
      showDetails: false
    };
  },

  async created() {
    let characterMapping = {
      'cyber-chef': 0,
      'hype-lord': 1,
      'lunar-prophet': 2,
      'pool-shark': 3,
      'yield-master': 4
    };
    let rarityOffset = {
      common: 0,
      rare: 5,
      epic: 10,
      legendary: 15
    };

    let id = this.$route.params.id;
    // TODO: determine if id is an item ID, if not then reconstruct ID.
    // TODO: in the future this should be a proper ID lookup.
    let itemName = id;
    // 'pool-shark-rare-20'
    let nameFragments = itemName.split('-');
    let issueNumber = nameFragments[nameFragments.length - 1];
    let rarity = nameFragments[nameFragments.length - 2];
    let character = nameFragments.slice(0, nameFragments.length - 2).join('-');

    let characterIndex = characterMapping[character.toLowerCase()];
    let rarityIndex = rarityOffset[rarity.toLowerCase()];
    let itemId = ethers.BigNumber.from(characterIndex + rarityIndex)
      .shl(128)
      .add(issueNumber);
    let itemIdString = itemId
      .toHexString()
      .substring(2)
      .padStart(64, '0');

    let itemMetadataUri = 'https://superfarm-metadata.s3.amazonaws.com/SuperFarm/{id}.json'.replace(
      '{id}',
      itemIdString
    );
    let metadataResponse = await axios.get(itemMetadataUri);
    let metadata = metadataResponse.data;
    this.item = metadata;

    // TODO Format the item name and display as page title.
    document.title =
      this.item.attributes[0].value + ' - SuperFarm NFT Platform';

    console.log(this.item);

    let ethereumReference = this.ethereum;
    this.getLaunchpadItemsTask = useTask(function*(signal) {
      this.config = yield initializeConfig();
      if (!ethereumReference.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          await store.dispatch('items/getLaunchpadItems', '', { root: true });
        });
      } else {
        yield store.dispatch('items/getLaunchpadItems', '', { root: true });
      }
    });
    this.getLaunchpadItemsTask.perform();
    window.scrollTo(0, 0);
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      account: state => state.account,
      items: state => state.items
    }),

    // item() {
    //   if (this.items.launchpadItemList.length > 0) {
    //     return this.items.launchpadItemList.pools[0].items[0];
    //   }
    //   return null;
    // },

    mediaType() {
      if (!this.item) {
        return null;
      }
      let video = this.item.video;
      let animationUrl = this.item['animation_url'];
      let image = this.item.image;
      if (video) {
        let videoPath = this.item.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.item['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.item.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    }
  },

  methods: {
    convertItemToModal(item) {
      if (item === null) return null;
      const newItem = {
        cap: ethers.BigNumber.from(item.groupSize / 2),
        minted: ethers.BigNumber.from(item.groupSize / 2),
        metadata: {
          description: item.description,
          name: item.name,
          attributes: item.attributes,
          image: item.image,
          video: item.video
        }
      };

      return newItem;
    },

    closeModal() {
      router.back();
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100vw;
  height: calc(100vh - 64px);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.media-container {
  position: relative;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  flex: 1;
}

.image-container {
  height: calc(100vh - 64px);
  position: relative;
  display: flex;
  width: 85%;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.video {
  max-height: 61.8%;
  width: auto;
  max-width: 100%;
}

.close-modal-button {
  z-index: 5;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 200ms ease;
}

.close-modal-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.close-modal-button:active {
  transform: scale(0.9);
}

.detail-hover {
  cursor: zoom-in;
}

@media (max-width: 990px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .media-container {
    overflow: initial;
    height: 60vh;
  }

  .image-container {
    height: 60vh;
  }
}

/* @media (max-width: 990px) {
  .modal-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    overflow: initial;
    height: 60vh;
  }

  .image-container {
    height: 95%;
  }

  .vfm__content {
    overflow: scroll;
  }
} */
</style>
