<template>
  <div class="progress-bar-container">
    <div v-if="blocksRemaining <= 0" class="progress-bar-wrapper">
      <div class="progress-label">{{ `${remaining} / ${total} left` }}</div>
      <div
        v-if="progressWidth > 0"
        :style="{ width: `${progressWidth}%` }"
        class="progress-level"
      >
        <div :style="{ width: `${progressWidth}%` }" class="shine"></div>
      </div>
    </div>
    <div v-else class="countdown-wrapper">
      {{ `Available in ${blocksRemaining} blocks!` }}
    </div>
    <div v-if="hint && blocksRemaining > 0" class="progress-bar-hint">
      {{ hint }}
    </div>
  </div>
</template>

<script>
"use strict";

// Imports.
import { mapState } from "vuex";

// Set up default component.
export default {
  props: {
    remaining: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    startBlock: {
      type: Number,
      required: true
    },
    hint: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers
    }),

    progressWidth() {
      return Math.floor((this.remaining / this.total) * 100);
    },

    dropDateText() {
      return this.dropDateCurr;
    },

    blocksRemaining() {
      return this.startBlock - this.ethereum.blockNumber;
    }
  }
};
</script>

<style>
.progress-bar-hint {
  position: absolute;
  left: 33%;
  top: -120px;
  visibility: hidden;
  width: 240px;
  max-width: 90vw;
  padding: 15px;
  border-radius: 24px;
  background-color: rgba(41, 41, 41, 0.9);
  backdrop-filter: blur(15px);
  color: #fff;
  z-index: 99;
}

.progress-bar-container:hover .progress-bar-hint {
  visibility: visible;
}

.progress-bar-container:hover {
  cursor: default;
}

.progress-label {
  position: absolute;
  z-index: 1;
  padding-left: 10px;
  color: white;
  font-weight: bold;
}

.progress-bar-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30px;
  margin-bottom: 5px;
  padding: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: rgba(255, 18, 18, 0.3);
  /* background-color: rgba(255, 18, 18, 0.5); */
}

.progress-level {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffd000),
    to(#ffd900)
  );
  background-image: linear-gradient(90deg, #ce0b0b, #ff0000);
  color: #fff;
  text-shadow: black;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
}

.shine {
  position: absolute;
  left: -100%;
  width: 100%;
  height: 30px;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(51%, #fff),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0),
    #fff 70%,
    hsla(0, 0%, 100%, 0)
  );
  opacity: 0.4;
  animation: shine 1s ease infinite;
  overflow: hidden;
}

.countdown-wrapper {
  position: static;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border: 1px solid #7112ff;
  border-radius: 20px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(117, 0, 255, 0.2)),
    to(rgba(154, 0, 255, 0.2))
  );
  background-image: linear-gradient(
    90deg,
    rgba(117, 0, 255, 0.2),
    rgba(154, 0, 255, 0.2)
  );
  color: #7112ff;
  font-weight: 500;
  text-align: center;
}

@keyframes shine {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
</style>
