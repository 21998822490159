"use strict";

// Export state mutation functions.
export default {
  // Deployment events.
  deployRequest(state, { name, ticker }) {
    state.status = { deploying: true };
  },
  deploySuccess(state, token) {
    state.status = {};
  },
  deployFailure(state, error) {
    state.status = {};
  },

  // Update the list of shops being tracked for display purposes.
  updateShopsList(state, shopsList) {
    state.shopList = shopsList;
  }
};
