const item = {
    metadata: {
        name: 'NFT Name Goes Here',
        collection: 'Collection Name',
        image: 'https://assets.website-files.com/606b8a262f3b7db261ad7f08/606bb938dbb61df64aa0a583_nft.jpg'
    }
}

export const genItems = (qty = 8) => {
    const items = []

    for (let i = 0;  i < qty; i++) {
        items.push({...item, id: i + 1})
    }

    return items
}

export default {
    async requestItems({commit}) {
        commit('setItems', genItems())
    },
    setTab({commit}, value) {
        commit('setTab', value)
    },
    changeExactFilter({commit}, data) {
        commit('setExactFilter', data)
    },
    applyDrop({commit}, data) {
        commit('setDroppedData', data)
    },
    changePage({commit}, page) {
        commit('setPage', page)
    },
    onAcceptMobileFilters({commit}, values) {
        commit('setFilters', values)
    }
}