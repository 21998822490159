<template>
  <TextField :value='modelValue' :placeholder='placeholder' :on-change='onSearch'>
    <template v-slot:inputIcon>
      <SearchIcon/>
    </template>
  </TextField>
</template>

<script>
  import TextField from './TextField'
  import {SearchIcon} from '../../icons'

  export default {
    props: {
      modelValue: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        default: 'Filter',
        required: false
      },
      debounceTime: {
        type: Number,
        default: 300,
        required: false
      }
    },
    components: {
      TextField,
      SearchIcon
    },
    data: () => ({
      debounce: null
    }),
    methods: {
      onSearch(event) {
        clearTimeout(this.debounce)

        this.debounce = setTimeout(() => {
          this.$emit('update:modelValue', event)
        }, this.debounceTime)
      }
    },
    emits: ['update:modelValue']
  }
</script>