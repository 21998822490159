<template>
  <section class="common-section-component">
    <div class="common-container common-section__container">
      <h2 class="common-section__title">Featured Pools</h2>
      <ul v-if="isLoading" class="common-section__list">
        <li class="common-section__item" v-for="count in 3" :key="count">
          <SkeletorTemplateFeatured />
        </li>
      </ul>
      <ul v-else class="common-section__list">
        <li class="common-section__item" v-for="pool in pools" :key="pool.id">
          <router-link
            class="common-section__link"
            :to="{ name: 'PoolDetails', params: { id: pool.id } }"
          >
            <Card :pool="pool">
              <template v-slot:footer-metadata>
                <p class="item__metadata">
                  <span class="item__metadata-title">Participants</span>
                  <span class="item__metadata-value">
                    {{ pool.participants }}
                  </span>
                </p>
                <p class="item__metadata">
                  <span class="item__metadata-title">Maximum</span>
                  <span class="item__metadata-value">{{ pool.maximum }}</span>
                </p>
              </template>
            </Card>
          </router-link>
          <Button
            v-if="pool.condition === 'live'"
            title="Join Pool"
            iconName="fa-plus"
            background="#7112ff"
            color="#fff"
            @click="joinPool"
          />
        </li>
      </ul>
      <!-- <load-more-button @click="handleClick" /> -->
    </div>
  </section>
</template>

<script>
import SkeletorTemplateFeatured from "../skeletor-template/SkeletorTemplateFeatured";
import LoadMoreButton from "../../../../components/ui/load-more-button/LoadMoreButton";
import Card from "../card/Card";
import Button from "@/components/Button/Button";

export default {
  components: {
    SkeletorTemplateFeatured,
    // LoadMoreButton,
    Card,
    Button
  },
  props: {
    pools: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      console.log("Featured Load More");
    },
    joinPool() {
      console.log("Join Pool")
    }
  }
};
</script>

<style src="../CommonSection.scss" lang="scss" scoped></style>
