import {
    TwitchIcon, InstagramIcon, FacebookIcon, YoutubeIcon,
    LinkedinIcon, EmailIcon, TwitterIcon, TelegramIcon,
    MediumIcon, DiscordIcon, SnapchatIcon
} from './'

const getSocialIconByKey = key => {
    switch (key) {
        case 'twitter':
            return TwitterIcon
        case 'instagram':
            return InstagramIcon
        case 'facebook':
            return FacebookIcon
        case 'youtube':
            return YoutubeIcon
        case 'linkedin':
            return LinkedinIcon
        case 'email':
            return EmailIcon
        case 'twitch':
            return TwitchIcon
        case 'telegram':
            return TelegramIcon
        case 'medium':
            return MediumIcon
        case 'discord':
            return DiscordIcon
        case 'snapchat':
            return SnapchatIcon
        default:
            return null
    }
}

export default getSocialIconByKey