<template>
  <component :is="importedComponent" v-if="show"></component>
</template>

<script>
import withFlags from '@/utility/mixins/withFlags'
export default {
  props: {
    component: { type: [Function, Object, Promise], required: true },
    requiredFlag: { type: String, required: true },
    fallbackPath: { type: [String, Object, Function], required: true },
  },
  mixins: [withFlags()],
  created() {
    this.redirectHandler()
  },
  methods: {
    redirectHandler() {
      if (this.exactFlag === false) {
        this.$router.push(this.fallbackPath);
      }
    },
  },
  watch: {
    flags() {
      this.redirectHandler()
    }
  },
  computed: {
    show() {
      // this.exactFlag is variation of required flag. Comes from withFlags mixin.
      return this.exactFlag === true;
    },
    importedComponent() {
      // Handle dynamically imported components
      if (!!this.component && typeof this.component.then === "function") {
        return () => this.component;
      }
      return this.component;
    },
  },
};
</script>
