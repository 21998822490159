<template>

  <div class="ended" v-if="isFinished">
    Ended
  </div>
  <div v-else class="countdown">
    <div class="ended" v-if="isEnding">
      Auction Ending Soon, Placing a bid now is risky and may not confirm by auction deadline
    </div>
    <div v-if="!isEnding && !isFinished" class="title">Auction ending in</div>
    <div class="countdown-grid" :style="gridColumns">
      <div v-if="showDays" class="time-block">
        <div class="time-value">{{ days }}</div>
        <div class="time-division">{{ daysLabel }}</div>
      </div>
      <div v-if="showHours" class="time-block">
        <div class="time-value">{{ hours }}</div>
        <div class="time-division">{{ hoursLabel }}</div>
      </div>
      <div v-if="showMinutes" class="time-block">
        <div class="time-value">{{ minutes }}</div>
        <div class="time-division">{{ minutesLabel }}</div>
      </div>
      <div v-if="showSeconds" class="time-block">
        <div class="time-value">{{ seconds }}</div>
        <div class="time-division">{{ secondsLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: Date
    },

    hideDate: {
      type: Boolean,
      default: false,
      required: false
    },

    isLight: {
      type: Boolean,
      default: false,
      required: false
    },

    smallFont: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      distance: ''
    };
  },

  mounted() {
    let x = setInterval(() => {
      // Find the distance between now and the count down date
      this.distance = this.date.getTime() - new Date().getTime();

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor(
        (this.distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      // If the count down is finished
      if ((this.distance + (120 * 1000)) < 0) {
        clearInterval(x);
      }
    }, 1000);
  },

  computed: {
    daysLabel() {
      return this.days !== 1 ? 'days' : 'day';
    },

    hoursLabel() {
      return this.hours !== 1 ? 'hours' : 'hour';
    },

    minutesLabel() {
      return this.minutes !== 1 ? 'minutes' : 'minute';
    },

    secondsLabel() {
      return this.seconds !== 1 ? 'seconds' : 'second';
    },

    showDays() {
      return this.distance > 1000 * 60 * 60 * 24;
    },

    showHours() {
      return this.distance > 1000 * 60 * 60;
    },

    showMinutes() {
      return this.distance > 1000 * 60;
    },

    showSeconds() {
      return this.distance > 1000;
    },

    isEnding() {
      return (this.distance - (15 * 1000))  < 0 && (this.distance + (15 * 1000)) > 0
    },

    isFinished() {
      return (this.distance + (15 * 1000)) < 0
    },

    gridColumns() {
      if (this.distance > 1000 * 60 * 60 * 24) {
        return { gridTemplateColumns: 'repeat(4, 1fr)' };
      } else if (this.distance > 1000 * 60 * 60) {
        return { gridTemplateColumns: 'repeat(3, 1fr)' };
      } else if (this.distance > 1000 * 60) {
        return { gridTemplateColumns: 'repeat(2, 1fr)' };
      } else {
        return { gridTemplateColumns: 'repeat(1, 1fr)' };
      }
    },

    fullDateLabel() {
      const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        timeZoneName: 'short'
      };

      // options.timeZone = 'PST';
      // options.timeZoneName = 'short';
      return this.date.toLocaleDateString(undefined, options);
    }
  }
};
</script>

<style scoped>
.countdown {
}

.title {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.countdown-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: fit-content;
}

.time-block {
  width: fit-content;
}

.time-value {
  font-size: 30px;
  font-weight: 600;
}

.drop-banner-date {
  opacity: 0.5;
  font-weight: 500;
}

.ended {
  padding: 8px 12px;
  background-color: white;
  color: black;
  margin-bottom: 20px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
</style>
