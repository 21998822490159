'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = () => ({
  approvedAmount: 0,
  amountStakedGlobal: 0,
  amountStakedUser: 0,
  lpBalanceUser: 0,
  earnings: 0,
  periodFinish: 0,
  userValueLocked: 0,
  totalValueLocked: 0,
  poolValueEth: 0,
  rewardRate: 0,
  superPrice: 0,
  ethPrice: 0,
});

// Return the module prepared for use.
export default {
	namespaced: true,
	state,
	actions,
	mutations
};
