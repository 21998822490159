<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="global-offer-modal"
    content-class="global-offer-modal__window"
    name="globalOfferModal"
  >
    <template v-if="modalStatus === 'start'">
      <div class="global-offer-modal__header">
        <span class="global-offer-modal__header-text">
          Make a Global Offer
        </span>
        <div
          class="global-offer-modal__header-cross-container"
          @click="$vfm.hide('globalOfferModal')"
        >
          <close-icon class="global-offer-modal__header-cross" />
        </div>
      </div>
      <div class="global-offer-modal__content">
        <div class="content-form">
          <label for="globalOfferInput" class="content-form__label">
            Your Offer:
          </label>
          <div class="content-form__input-wrapper">
            <input
              type="number"
              id="globalOfferInput"
              class="content-form__input"
            />
            <BaseDropdown ref="currencyDropdown">
              <Button
                isSecondary
                class="content-form__button content-form__button_light"
              >
                <span class="button-icon"> {{ currency }} </span>
                <DownCaretIcon />
              </Button>
              <template #dropdown>
                <div class="sorting-dropdown">
                  <SocialButton
                    class="sorting-dropdown__option"
                    v-for="option in currencies"
                    :key="option"
                    @click="selectCurrency(option)"
                  >
                    {{ option }}
                  </SocialButton>
                </div>
              </template>
            </BaseDropdown>
          </div>
          <span class="content-form__price"> $1,234 USD </span>
        </div>
      </div>
      <div class="global-offer-modal__footer" @click="modalStatus = 'loading'">
        <Button class="global-offer-modal__footer-button">
          Offer 0.1 ETH on 16 items
        </Button>
      </div>
    </template>
    <template v-else-if="modalStatus === 'loading'">
      <div @click="modalStatus = 'success'">
        <div class="global-offer-modal__content">
          <div
            class="global-offer-modal__header-cross-container"
            @click="$vfm.hide('globalOfferModal')"
          >
            <close-icon class="global-offer-modal__header-cross" />
          </div>
          <Spinner class="global-offer-modal__spinner" />
          <span
            class="global-offer-modal__header-text confirmation-text"
            style=""
          >
            Waiting for Confirmation
          </span>
          <span class="offer-text"> You are offering 0.1 ETH on 6 items </span>
        </div>
        <div class="global-offer-modal__footer">
          <span class="confirm-footer-text">
            Confirm this transaction in your wallet
          </span>
        </div>
      </div>
    </template>
    <template v-else-if="modalStatus === 'success'">
      <div class="global-offer-modal__content">
        <div
          class="global-offer-modal__header-cross-container"
          @click="$vfm.hide('globalOfferModal')"
        >
          <close-icon class="global-offer-modal__header-cross" />
        </div>
        <BigUpArrow class="global-offer-modal__spinner" />
        <span
          class="global-offer-modal__header-text confirmation-text"
          style=""
        >
          Transaction Submitted
        </span>
        <Button isSecondary class="ether-button">
          View on Etherscan <LinkIcon class="link-icon" />
        </Button>
      </div>
      <div
        class="global-offer-modal__footer"
        @click="$vfm.hide('globalOfferModal')"
      >
        <Button class="global-offer-modal__footer-button"> Close </Button>
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar/index.vue";
import BaseAccordion from "@/components/ui/BaseAccordion";
import Button from "@/components/ui/Button";
import { mapActions } from "vuex";
import { BaseDropdown } from "@/components/ui/baseDropdown";
import DownCaretIcon from "@/components/icons/DownCaretIcon.vue";
import SocialButton from "@/components/social/social-button/SocialButton";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import Spinner from "@/components/icons/Spinner.vue";
import BigUpArrow from "@/components/icons/BigUpArrow.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";

export default {
  name: "GlobalOfferModal",
  inheritAttrs: false,
  data() {
    return {
      currencies: ["ETH", "USD"],
      currency: "ETH",
      modalStatus: "start",
    };
  },
  methods: {
    selectCurrency(option) {
      this.currency = option;
      this.$refs["currencyDropdown"].hide();
    },
  },
  components: {
    Button,
    BaseDropdown,
    SocialButton,
    CloseIcon,
    DownCaretIcon,
    BigUpArrow,
    LinkIcon,
    Spinner,
  },
};
</script>

<style lang="scss" scoped src="./GlobalOfferModal.scss"></style>
