<template>
  <div v-if="farm">
    <h1>{{ farm.name }}</h1>
    <p>
      This is a page where you can interact with the farm at
      {{ $route.params.address }}.
    </p>
    <p v-if="farm.availableUserPoints">
      On this farm, you have
      {{ formatNumber(ethers.utils.formatEther(farm.availableUserPoints), 6) }}
      points.
    </p>

    <Form @submit="handleAddPools">
      <div class="form-group">
        <MultiSelectionList
          header="Add Staking Pools:"
          emptyMessage="You have not specified any pools."
          selectPlaceholder="Select a pool token from the list."
          customPlaceholder="Enter the address of a custom pool token."
          customInputText="Custom Pool Token Address"
          :columns="[
            {
              name: 'Token Address',
              id: 'tokenAddress',
              type: 'primary'
            },
            {
              name: 'Token Strength',
              id: 'tokenStrength',
              type: 'number',
              value: '0'
            },
            {
              name: 'Point Strength',
              id: 'pointStrength',
              type: 'number',
              value: '0'
            }
          ]"
          primary="address"
          :elements="tokens.tokenList"
          template="${name} - ${address}"
          v-model="newStakingPools"
        />
      </div>

      <div class="form-group">
        <button class="btn btn-primary">Deploy Pools</button>
      </div>
    </Form>

    <h2>Available Pools</h2>
    <ul v-if="farm.poolTokens && farm.poolTokens.length > 0">
      <li v-for="(pool, index) in farm.poolTokens" :key="index">
        <div>
          <div>
            {{ pool.name }} ({{ pool.symbol }}) at {{ pool.address }}: you have
            staked
            {{
              formatNumber(
                ethers.utils.formatEther(pool.userAmount).toString(),
                2
              )
            }}
            <br />
            You have
            {{
              formatNumber(
                ethers.utils.formatEther(pool.userWalletBalance).toString()
              )
            }}
            ({{ pool.symbol }}) in your wallet right now.
            <br />
            You have
            {{
              formatNumber(
                ethers.utils.formatEther(pool.pendingRewards).toString()
              )
            }}
            pending reward tokens.
            <br />
            This pool receives
            {{
              (
                (pool.tokenStrength.toNumber() /
                  farm.totalTokenStrength.toNumber()) *
                100
              ).toFixed(2)
            }}% of reward tokens:
            {{
              formatNumber(
                ethers.utils.formatEther(farm.currentTokenRate).toString()
              )
            }}
            tokens per block.
            <br />
            This pool receives
            {{
              (
                (pool.pointStrength.toNumber() /
                  farm.totalPointStrength.toNumber()) *
                100
              ).toFixed(2)
            }}% of points:
            {{
              farm.currentPointRate
                .div(ethers.utils.parseEther('1'))
                .toNumber() *
                (
                  pool.pointStrength.toNumber() /
                  farm.totalPointStrength.toNumber()
                ).toFixed(6)
            }}
            points per block.
            <br />
            <span v-if="pool.userAmount.gt(0)"
              >You are earning
              {{
                (
                  (pool.userAmount
                    .div(ethers.utils.parseEther('1'))
                    .toNumber() /
                    pool.poolTotalSupply
                      .div(ethers.utils.parseEther('1'))
                      .toNumber()) *
                  100
                ).toFixed(2)
              }}% of this pool:
              {{
                farm.currentTokenRate
                  .div(ethers.utils.parseEther('1'))
                  .toNumber() *
                  (
                    pool.tokenStrength.toNumber() /
                    farm.totalTokenStrength.toNumber()
                  ).toFixed(6) *
                  (
                    pool.userAmount
                      .div(ethers.utils.parseEther('1'))
                      .toNumber() /
                    pool.poolTotalSupply
                      .div(ethers.utils.parseEther('1'))
                      .toNumber()
                  ).toFixed(2)
              }}
              tokens per block and
              {{
                farm.currentPointRate
                  .div(ethers.utils.parseEther('1'))
                  .toNumber() *
                  (
                    pool.pointStrength.toNumber() /
                    farm.totalPointStrength.toNumber()
                  ).toFixed(6) *
                  (
                    pool.userAmount
                      .div(ethers.utils.parseEther('1'))
                      .toNumber() /
                    pool.poolTotalSupply
                      .div(ethers.utils.parseEther('1'))
                      .toNumber()
                  ).toFixed(2)
              }}
              points per block.</span
            >
            <span v-else>You are not earning any tokens or points.</span>
          </div>

          <div>
            <label>Deposit Amount</label>
            <NumberInput v-model="depositAmounts[pool.address]" />
            <button
              class="btn btn-primary"
              @click="handleDeposit(pool.address)"
            >
              Deposit
            </button>
          </div>

          <div>
            <label>Withdrawal Amount</label>
            <NumberInput v-model="withdrawalAmounts[pool.address]" />
            <button
              class="btn btn-primary"
              @click="handleWithdrawal(pool.address)"
            >
              Withdraw
            </button>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" @click="claimReward(pool.address)">
            Claim Reward
          </button>
        </div>
      </li>
    </ul>

    <h3 v-if="!farm.poolTokens || farm.poolTokens.length === 0">
      This farm has no active staking pools.
    </h3>
    <br />
    <h2>Available Items</h2>
    <h3 v-if="allItems && allItems.length > 0">
      This farm has {{ shops.length }} attached shops with
      {{ allItems.length }} available items.
    </h3>
    <ul v-if="allItems && allItems.length > 0">
      <li v-for="item in allItems" :key="item">
        {{ parseInt(item.amount) }} x {{ item.metadata }} ({{
          parseInt(item.id)
        }}) at {{ item.address }} for
        <ul>
          <li v-for="(priceEntry, index) in item.prices" :key="index">
            Buy for {{ priceEntry.price.toString() }} {{ priceEntry.asset }}
            <button
              class="btn btn-primary"
              @click="
                handlePurchase(item.shop, item.id, index, priceEntry.price)
              "
            >
              Purchase
            </button>
          </li>
        </ul>
      </li>
    </ul>
    <h3 v-if="!allItems || allItems.length === 0">
      This farm has {{ shops.length }} attached shops with no available items.
    </h3>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import { formatNumber, validationRules } from '../utility';
import store from '../store';
import { ethers } from 'ethers';

// Component imports.
import { Form } from 'vee-validate';
import MultiSelectionList from '../components/MultiSelectionList.vue';
import NumberInput from '../components/ui/NumberInput.vue';

// Set up the component.
export default {
  components: {
    Form,
    MultiSelectionList,
    NumberInput
  },
  data() {
    return {
      ethers,

      name: '',
      token: '',
      depositAmounts: {},
      withdrawalAmounts: {},
      allItems: [],

      newStakingPools: [],

      // Include utilities.
      formatNumber,

      // Include our standard validation rules.
      validationRules
    };
  },
  async created() {
    console.log(`Initializing data for farm ${this.$route.params.address} ...`);
    await this.updatePage();
  },
  computed: {
    ...mapState({
      tokens: state => state.tokens,
      farm: function(state) {
        let stakingFarmAddress = this.$route.params.address;
        let farmList = state.farms.farmList;
        for (let i = 0; i < farmList.length; i++) {
          let farm = farmList[i];
          if (farm.address === stakingFarmAddress) {
            return farm;
          }
        }
      },
      shops: function(state) {
        let stakingFarmAddress = this.$route.params.address;
        let shopList = state.shops.shopList;
        let relevantShops = [];
        let allItems = [];
        for (let i = 0; i < shopList.length; i++) {
          let shop = shopList[i];
          if (shop.farms.includes(stakingFarmAddress)) {
            relevantShops.push(shop);
            allItems = allItems.concat(shop.items);
          }
        }
        this.allItems = allItems;
        return relevantShops;
      }
    })
  },
  methods: {
    ...mapActions('farms', ['deposit', 'withdraw', 'addPool']),
    ...mapActions('shops', ['purchase']),

    // TODO: this is ugly and throws errors on route switches.
    async updatePage() {
      await store.dispatch(
        'farms/retrieveFullFarmInformation',
        this.$route.params.address,
        { root: true }
      );
      await store.dispatch('shops/getAllShops', '', { root: true });
      await store.dispatch('items/getAllItems', '', { root: true });
      setTimeout(this.updatePage, 5000);
    },

    handleDeposit(tokenAddress) {
      let depositValue = parseFloat(this.depositAmounts[tokenAddress]);
      if (tokenAddress) {
        let farmAddress = this.farm.address;
        this.deposit({ farmAddress, depositValue, tokenAddress });
      }
    },

    handleWithdrawal(tokenAddress) {
      let withdrawalValue = parseFloat(this.withdrawalAmounts[tokenAddress]);
      if (tokenAddress) {
        let farmAddress = this.farm.address;
        this.withdraw({ farmAddress, withdrawalValue, tokenAddress });
      }
    },

    claimReward(tokenAddress) {
      let withdrawalValue = 0;
      if (tokenAddress) {
        let farmAddress = this.farm.address;
        this.withdraw({ farmAddress, withdrawalValue, tokenAddress });
      }
    },

    handlePurchase(shop, itemId, assetIndex, price) {
      if (itemId) {
        this.purchase({ shop, itemId, assetIndex, price });
      }
    },

    async handleAddPools() {
      let farmAddress = this.farm.address;

      // Tell the user what transactions to expect.
      let userInfoString = ``;
      let transactionCount = 0;
      for (let i = 0; i < this.newStakingPools.length; i++) {
        transactionCount += 1;
        userInfoString += `\n    ${transactionCount}) a transaction to add a staking pool for token ${this.newStakingPools[i].tokenAddress}`;
      }
      userInfoString =
        `You will be prompted to sign ${transactionCount} transactions:` +
        userInfoString;
      await store.dispatch('alert/info', userInfoString, { root: true });

      // Issue the transactions for adding the staking pools.
      for (let i = 0; i < this.newStakingPools.length; i++) {
        let poolToken = this.newStakingPools[i].tokenAddress;
        let tokenStrength = this.newStakingPools[i].tokenStrength;
        let pointStrength = this.newStakingPools[i].pointStrength;
        this.addPool({ farmAddress, poolToken, tokenStrength, pointStrength });
      }
    }
  }
};
</script>
