<template>
  <div class="suggestions-container">
    <div v-if="loading === false">
      <div
        class="suggestion"
        v-for="result in searchResults"
        :key="result.title"
      >
        <div class="suggestion__header">{{ result.title }}</div>
        <div class="suggestion__item" v-for="item in result.items" :key="item">
          <img
            :src="require('@/images/social/avatars/username.gif')"
            alt=""
            class="suggestion__image"
          />
          <div class="suggestion__text">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="suggestion__item" v-for="i in 3" :key="i" v-else>
      <Skeletor class="suggestion__image" />
      <Skeletor class="suggestion__text" />
    </div>
  </div>
</template>

<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import { ref } from "vue";
import debounce from "debounce";

export default {
  name: "SearchSuggestions",
  components: {
    Skeletor,
  },
  props: {
    search: String
  },
  data() {
    return {
      searchResults: [
        {
          title: "NFTs",
          items: ["The NFT Zone", "The NFT Zone two", "The NFT Zone three"],
        },
        {
          title: "Farms",
          items: ["Farm One", "Farm Two", "Farm three"],
        },
      ],
    };
  },
  setup() {
    const loading = ref(true);

    const debouncedSearch = debounce(() => {
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    }, 300);

    return { debouncedSearch, loading };
  },
  methods: {
    searchSuggestions() {
      this.loading = true;
      this.debouncedSearch();
    },
  },
  watch: {
    search() {
      this.searchSuggestions()
    }
  }
};
</script>

<style lang="scss" scoped>
.suggestions-container {
  width: 100%;
  padding: 10px;
}
.suggestion {
  margin-bottom: 5px;
  &__header {
    color: #00000066;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__item {
    &:hover {
      background-color: $lightViolet;
      color: $violet;
      cursor: pointer;
    }
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }
  &__image {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
