<template>
  <Select :modelValue='modelValue'
          :options='options'
          label='Status'
          @update:modelValue='handleChange'
          isMultiple
          contentWidth='174px'/>
</template>

<script>
  import {Select} from '@/components/ui/select'
  import {statusOptions} from '@/components/common/filters/data'

  export default {
    components: {Select},
    props: {
      modelValue: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data: () => ({options: statusOptions}),
    methods: {
      handleChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>