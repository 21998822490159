import {reject, find} from 'lodash'

import {getStateByTab, defaultFilters} from './'

export default {
    tradeItems: state => {
        const stateByTab = getStateByTab(state)

        if (!stateByTab) {
            return []
        }

        return reject(stateByTab.items, item => find(stateByTab.selected, {id: item.id}))
    },
    getSelectedItems: state => {
        return {
            your: state.your.selected,
            their: state.their.selected
        }
    },
    activeTab: state => state.activeTab,
    paginationState: state => {
        const stateByTab = getStateByTab(state)

        if (!stateByTab) {
            return {
                page: 0,
                totalPages: 0
            }
        }

        return {
            page: stateByTab.page,
            totalPages: stateByTab.totalPages
        }
    },
    filtersState: state => {
        const stateByTab = getStateByTab(state)

        if (!stateByTab) {
            return {...defaultFilters}
        }

        return stateByTab.filters
    }
}