<template>
  <div class="socials">
    <div class="container">
      <h2>A global community.</h2>
      <div class="subtitle">
        Learn more about SuperFarm, chat with the team, others in the community,
        and have your say in shaping the future of SuperFarm.
      </div>
      <div class="socialsGrid">
        <InfoCard
          class="card"
          @click="goTo('https://twitter.com/SuperFarmDAO')"
        >
          <template v-slot>
            <TwitterIcon class="twitter icon" />
            <div class="title twitterDescription">Twitter</div>
            <div class="description twitterDescription">
              Follow @SuperFarmDAO for updates and news.
            </div>
          </template>
        </InfoCard>

        <InfoCard class="card" @click="goTo('https://t.me/SuperFarmDAO')">
          <template v-slot>
            <TelegramIcon class="telegram icon" />
            <div class="title twitterDescription">Telegram</div>
            <div class="description twitterDescription">
              Chat in real time with the SuperFarm community.
            </div>
          </template>
        </InfoCard>

        <InfoCard class="card" @click="goTo('https://medium.com/superfarm')">
          <template v-slot>
            <MediumIcon class="icon" />
            <div class="title">Medium</div>
            <div class="description">
              Long form articles and updates.
            </div>
          </template>
        </InfoCard>

        <InfoCard
          class="card"
          @click="goTo('https://www.coingecko.com/en/coins/superfarm')"
        >
          <template v-slot>
            <CoingeckoIcon class="coingecko icon" :fullColor="true" />
            <div class="title coingeckoDescription">Coingecko</div>
            <div class="description coingeckoDescription">
              Price and market information.
            </div>
          </template>
        </InfoCard>
      </div>
    </div>
  </div>
</template>
<script>
// Component imports.
import InfoCard from './InfoCard';
// Icon imports
import TwitterIcon from '/src/components/icons/TwitterIcon';
import TelegramIcon from '/src/components/icons/TelegramIcon';
import MediumIcon from '/src/components/icons/MediumIcon';
import CoingeckoIcon from '/src/components/icons/CoingeckoIcon';

export default {
  components: {
    InfoCard,
    TwitterIcon,
    TelegramIcon,
    MediumIcon,
    CoingeckoIcon
  },

  setup() {
    const goTo = url => {
      window.open(url, '_blank');
    };

    return { goTo };
  }
};
</script>
<style scoped lang="scss">
.socials {
  padding: 120px 20px;
  flex: 1;

  .container {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;

    h2 {
      margin-top: 0px;
      margin-bottom: 40px;
      line-height: 1.1;
      text-align: center;
      font-size: 32px;
    }

    .subtitle {
      max-width: 60ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 24px;
      text-align: center;
    }

    .socialsGrid {
      display: grid;
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      color: var(--text-primary);

      .title {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
      }

      .description {
        font-size: 16px;
      }

      .twitterDescription {
        color: var(--blue-text);
      }
      .coingeckoDescription {
        color: var(--green-text);
      }
    }
  }
}
.icon {
  height: 50px;
  width: 50px;
}
.twitter {
  color: #1da1f2;
}

.telegram {
  color: #1da1f2;
}

.coingecko {
  color: #8bc53f;
}

.card {
  cursor: pointer;
  color: var(--text-color);
}

@media (max-width: 990px) {
  .socials .container .socialsGrid {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 467px) {
  .socials .container .socialsGrid {
    grid-template-columns: 1fr;
  }
}
</style>
