<template>
  <div class="pool-details">
    <div class="pool-details__container">
      <h2 class="pool-details__header">Pool Details</h2>
      <div class="pool-details__tables">
        <Skeletor v-if="isLoading" width="520" height="346" style="border-radius: 24px" />
        <div class="pool-details__table table" v-else>
          <div class="table__header">Pool Information</div>
          <div class="table__row">
            <div class="table__parameter">
              Token Distribution
            </div>
            <div class="table__parameter-value">
              {{ pool.pool_information.token_distribution }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Min. Allocation
            </div>
            <div class="table__parameter-value">
              {{ pool.pool_information.min_allocation }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Max. Allocation
            </div>
            <div class="table__parameter-value">
              {{ pool.pool_information.max_allocation }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Min. Swap Level
            </div>
            <div class="table__parameter-value">
              {{ pool.pool_information.min_swap_level }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Access Type
            </div>
            <div class="table__parameter-value">
              {{ pool.pool_information.access_type }}
            </div>
          </div>
        </div>
        <Skeletor v-if="isLoading" width="520" height="346" style="border-radius: 24px" />
        <div class="pool-details__table table" v-else>
          <div class="table__header">Token Information</div>
          <div class="table__row">
            <div class="table__parameter">
              Name
            </div>
            <div class="table__parameter-value">
              {{ pool.token_information.name }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Address
            </div>
            <div class="table__parameter-value">
              {{ pool.token_information.address }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Total Supply
            </div>
            <div class="table__parameter-value">
              {{ pool.token_information.total_supply }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Holders
            </div>
            <div class="table__parameter-value">
              {{ pool.token_information.holders }}
            </div>
          </div>
          <div class="table__row">
            <div class="table__parameter">
              Transfers
            </div>
            <div class="table__parameter-value">
              {{ pool.token_information.transfers }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  name: "PoolDetails",
  props: {
    pool: Object,
    isLoading: Boolean
  },
  components: {
    Skeletor
  }
}
</script>

<style src="./PoolDetails.scss" lang="scss" scoped>
</style>