<template>
  <div class="homePage">
    <!-- Hero Section -->
    <transition name="fade" appear>
      <div class="heroSection">
        <div class="heroContent">
          <h1 class="heading">NFTs thrive here.</h1>
          <div class="subtitle">
            SuperFarm is a passionate community building at the intersection of NFTs and DeFi.
          </div>

          <router-link to="/farms" style="textDecoration: none">
            <Button>
              Use Farms
            </Button>
          </router-link>
        </div>
      </div>
    </transition>

    <EcosystemSection />

    <ToolsSection />

    <AppsSection />

    <RecentAnnouncements />

    <SocialsSection />

    <TheFooter :style="{ backgroundColor: 'transparent' }" />
  </div>
</template>

<script>
'use strict';

// Component imports.
import Button from '/src/components/ui/Button';
import EcosystemSection from '/src/components/pages/launchpad/EcosystemSection';
import ToolsSection from '/src/components/pages/launchpad/ToolsSection';
import AppsSection from '/src/components/pages/launchpad/AppsSection';
import RecentAnnouncements from '/src/components/pages/launchpad/RecentAnnouncements';
import SocialsSection from '/src/components/pages/launchpad/SocialsSection';

// Imports.
import { onMounted } from 'vue';
// Set up the component.
export default {
  components: {
    Button,
    EcosystemSection,
    ToolsSection,
    AppsSection,
    RecentAnnouncements,
    SocialsSection
  },

  setup() {
    onMounted(() => {
      document.title = 'SuperFarm - NFT Platform where you can Buy, Create and Sell Crypto NFTs';
    });
  }
};
</script>

<style lang="scss" scoped>
.homePage {
  background-image: url('../images/homepage-background-logo.svg'), url('../images/gradient.svg');

  background-position: 50% -70vh, 50% 50%;
  background-size: 150vh, cover;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, fixed;
  background-color: var(--background-color);
  color: var(--text-color);

  .heroSection {
    display: flex;
    padding: 140px 20px;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;

    .heroContent {
      display: flex;
      width: 100%;
      max-width: 1240px;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      align-items: center;

      .heading {
        margin-top: 0px;
        margin-bottom: 40px;
        font-size: 104px;
        font-weight: 500;
        text-align: center;
      }

      .subtitle {
        margin-bottom: 40px;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}

// Animations
.fade-leave-active,
.fade-enter-active {
  transition: opacity 1.5s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 990px) {
  .homePage .heroSection .heroContent .heading {
    font-size: 70px;
  }
  .homePage .heroSection .heroContent .subtitle {
    font-size: 20px;
  }
}

@media (max-width: 630px) {
  .homePage .heroSection .heroContent .heading {
    font-size: 40px;
  }
  .homePage .heroSection .heroContent .subtitle {
    font-size: 16px;
  }
}
</style>
