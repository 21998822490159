<template>
  <ProfileLayout>
    <template v-if='profile && profile?.bannerUrl' #banner>
      <img :src='profile.bannerUrl'>
    </template>

    <template #info><Info/></template>

    <template #below-info>
      <feature-inline-guard requiredFlag='profile.pinneditems'>
        <PinnedItems :editable='!isPublic'/>
      </feature-inline-guard>
    </template>

    <TitledSection title='All NFTs'>
      <TabsRouterContainer :items='tabItems'>
        <template v-slot={activeTab}>
          <transition name='fade' mode='out-in'>
            <component :is='renderTabContent(activeTab)'/>
          </transition>
        </template>
      </TabsRouterContainer>
    </TitledSection>
  </ProfileLayout>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import {ProfileLayout, TitledSection} from '@/layouts/profile-layout'
  import {TabsRouterContainer} from '@/components/common/tabs'
  import FeatureInlineGuard from '@/components/utility/FeatureInlineGuard.vue'

  import Info from './components/Info'
  import PinnedItems from './components/PinnedItems'
  import InWalletSection from './tab-sections/in-wallet'
  import FavoritesSection from './tab-sections/favorites'

  export default {
    components: {
      ProfileLayout, TabsRouterContainer,
      InWalletSection, Info,
      PinnedItems, TitledSection,
      FeatureInlineGuard
    },
    data() {
      return {
        tabItems: []
      }
    },
    computed: {
      ...mapGetters('profile', ['profile', 'isPublic'])
    },
    methods: {
      ...mapActions('profile', ['getPageData', 'setPageState']),
      renderTabContent(activeTab) {
        switch (activeTab) {
          case 0:
            return InWalletSection
          case 1:
            return FavoritesSection
          default:
            return null
        }
      }
    },
    async created() {
      this.setPageState(!!this.$route?.params?.id)

      document.title = `${this.isPublic ? 'Public Account' : 'Profile'} - SuperFarm NFT Platform`

      this.tabItems = this.isPublic
          ? ['In Wallet', 'Favorites', 'Activity', 'Playlists']
          : ['In Wallet', 'Favorites', 'My Activity', 'Offers', 'Trades', 'Watchlist', 'Playlists']

      await this.getPageData()
    }
  }
</script>
