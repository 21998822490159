<template>
  <div class="cards-container">
    <div class="header">
      <img :src="headerImage" />
    </div>
    <div class="body">
      <!-- <div class="creator-container">
        <img :src="avatar" />
        <p>{{ creator }}</p>
      </div> -->
      <div class="collection">
        <p>{{ pool.name }}</p>
      </div>
      <div class="cards" ref="cards_container">
        <div class="card" v-for="(item, index) in pool.items" :key="index">
          <!-- TODO: that idx thing is a disgusting hack. Fix it. -->
          <DropCardProduct
            ref="card"
            :item="item"
            :idx="index"
            :poolId="pool.id"
            :startBlock="pool.startBlock.toNumber()"
          />
        </div>
      </div>
      <!-- <div class="scroll-left" @click="scrollLeft">
        <img src="../images/left-chevron.svg" />
      </div>
      <div class="scroll-right" @click="scrollRight">
        <img src="../images/right-chevron.svg" />
      </div> -->

      <!-- TODO: skeletonize this page to prevent initialization problems -->
      <!-- TODO: use bignumbers everywhere -->
      <div class="progress-bar">
        <BaseProgressBar
          :remaining="remaining.toNumber()"
          :total="cap.toNumber()"
          :startBlock="pool.startBlock.toNumber()"
          :hint="availableSoonHint"
        />
      </div>
      <div class="footer">
        <MetadataItem
          descriptor="Access"
          value="Whitelist"
          hint="You must be a part of the SuperFarm Genesis lottery whitelist to purchase from this pool."
        />
        <MetadataItem
          descriptor="Issue Type"
          value="Unique"
          hint="All items in this pool are unique with individual issue numbers."
        />
        <MetadataItem
          descriptor="Max Allocation"
          value="1"
          hint="Everyone may purchase only one item from this pool."
        />
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import { ethers } from 'ethers';

// Component imports.
import DropCardProduct from './DropCardProduct';
import BaseProgressBar from '../../ui/BaseProgressBar';
import MetadataItem from '../../common/MetadataItem';

// Set up the component.
export default {
  components: {
    DropCardProduct,
    BaseProgressBar,
    MetadataItem
  },

  props: {
    pool: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ethers,

      remaining: 0,
      cap: 0,
      startTime: 0,

      headerImage: 'superfarm-banner-genesis.png',
      avatar: 'logo-512.png',
      creator: '@SuperFarm',

      availableSoonHint:
        'Ethereum blocks take on average ~13 seconds to mine. This is only an average though!'
    };
  },

  created() {
    let remaining = ethers.BigNumber.from(0);
    let cap = ethers.BigNumber.from(0);
    for (let i = 0; i < this.pool.items.length; i++) {
      let item = this.pool.items[i];
      let itemRemaining = item.cap.sub(item.minted);
      remaining = remaining.add(itemRemaining);
      cap = cap.add(item.cap);
    }
    this.remaining = remaining;
    this.cap = cap;
    this.startTime = this.pool.startBlock.timestamp;
  },

  methods: {
    scrollLeft() {
      const cardWidth = this.$refs.card.cardWidth + 15;
      const scrollPos = this.$refs.cards_container.scrollLeft - 1;
      const nextCardPos = Math.floor(scrollPos / cardWidth) * cardWidth;
      this.$refs.cards_container.scrollLeft = nextCardPos;
    },

    scrollRight() {
      const cardWidth = this.$refs.card.cardWidth + 15;
      const scrollPos = this.$refs.cards_container.scrollLeft + 1;
      const nextCardPos = Math.ceil(scrollPos / cardWidth) * cardWidth;
      this.$refs.cards_container.scrollLeft = nextCardPos;
    }
  }
};
</script>

<style scoped>
.cards-container {
  position: relative;
  border: 1px solid rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  /* min-width: 720px; */
}
.header > img {
  /* width: 100%; */
  height: 90px;
  margin-bottom: -4px;
}

.header {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  /* background-color: #7112ff; */
  background-color: black;
}

.creator-container > img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff;
}

.creator-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: -25px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  margin-bottom: 10px;
}

.collection {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.cards {
  /* width: 720px; */
  display: flex;
  overflow-y: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.cards::-webkit-scrollbar {
  display: none;
}

.footer {
  display: flex;
}

.footer > * {
  margin-right: 10px;
}

.body {
  padding: 14px;
}

.progress-bar {
  margin: 10px 0px;
}

.card {
  margin-right: 15px;
}

.scroll-left {
  left: 30px;
}

.scroll-right {
  right: 30px;
}

.scroll-left,
.scroll-right {
  height: 40px;
  width: 40px;
  margin-top: -200px;
  position: absolute;
  background-color: white;
  border: 1px #ced0d4 solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.scroll-left:hover,
.scroll-right:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.scroll-left:active,
.scroll-right:active {
  transform: scale(0.9);
}

.scroll-left > img,
.scroll-right > img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}

@media (max-width: 760px) {
  .cards {
    width: 100%;
    flex-direction: column;
  }

  .card {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .header > img {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
  }

  .collection {
    text-align: center;
    font-size: 16px;
  }
}
</style>
