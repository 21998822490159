<template>
  <div class='filters-container'>
    <div class='content'>
      <FilterContainer :activeCount='filtersCount'>
        <BaseFilterForm :initialValues='{...filters}'
                        :minMaxValues='fromToMinMax'
                        :isShowFromTo='false'
                        :onAccept='setAllFilters'/>
      </FilterContainer>

      <SortBy :modelValue='filters.sort_by'
              class='sort-dropdown'
              @update:modelValue='value => setExactFilter({value: value, type: "sort_by"})'/>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import {FilterContainer} from '@/components/common/modal'
  import {BaseFilterForm, SortBy, getFiltersCount} from '@/components/common/filters'

  import useWindowSize from '@/utility/windowSize'

  export default {
    data() {
      return {
        fromToMinMax: {
          min: .1,
          max: 6
        }
      }
    },
    components: {
      SortBy, FilterContainer,
      BaseFilterForm
    },
    computed: {
      ...mapGetters('following', ['filters']),
      isCompact() {
        return this.windowWidth < 992
      },
      filtersCount() {
        return getFiltersCount(this.filters, ['sort_by'])
      }
    },
    methods: {
      ...mapActions('following', ['setExactFilter', 'setAllFilters'])
    },
    setup() {
      const {width} = useWindowSize()

      return {windowWidth: width}
    }
  }
</script>


<style scoped lang='scss'>
  .filters-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    & .content {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      gap: 10px;
      grid-template-columns: auto;
      grid-template-rows: auto;

      @include media-max-width($smallScreen) {
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }

  .dropdown-content {
    padding: 10px;
    box-sizing: border-box;

    &.sort {
      width: 173px;
    }
  }

  @include media-max-width($smallScreen) {
    .filters-container {
      flex-direction: column;

      & .content {
        display: flex;
        flex-direction: column;
        width: 100%;

        :deep .select-button {
          width: 100%;

          .content-wrapper {
            width: auto;
          }
        }
      }
    }
  }

  .price-trigger {
    width: 175px;
  }
</style>
