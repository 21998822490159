<template>
  <BaseDropdown ref='dropdown' centered isDisableHoverActive>
    <template v-slot='{opened}'>
      <Button isSecondary class='button' :isActive='opened'>
        <div class='button-icon-wrapper'><DotsIcon/></div>
      </Button>
    </template>
    <template #dropdown>
      <DropdownContent class='dropdown-report-content' width='157px'>
        <ListItem class='list-item report' @click='handleClick("report")'>
          <FlagIcon class='list-item-icon'/>
          Report Page
        </ListItem>
      </DropdownContent>
    </template>
  </BaseDropdown>
</template>

<script>
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import ListItem from '@/components/ui/list/ListItem'
  import Button from '@/components/ui/Button'
  import {FlagIcon, DotsIcon} from '@/components/icons'

  export default {
    components: {
      BaseDropdown, DropdownContent,
      Button, ListItem,
      FlagIcon, DotsIcon
    },
    methods: {
      handleClick(type) {
        this.$emit('onItemClick', type)

        this.$refs?.dropdown?.click()
      }
    },
    emits: ['onItemClick']
  }
</script>

<style scoped lang='scss'>
  .button {
    width: 40px;

    &-icon-wrapper {
      display: flex;
    }
  }

  .dropdown-report-content {
    .list-item {
      padding: 12px 15px;

      &-icon {
        margin-right: 10px;
        transition: color 200ms cubic-bezier(.215, .61, .355, 1);
      }

      &:hover {
        &.report .list-item-icon {
          color: #f12848;
        }
      }
    }
  }
</style>
