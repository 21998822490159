<template>
  <span class="number">{{ formatNumber(ethers.utils.formatEther(displayNumber)) }}</span>
</template>

<script>
'use strict';

// Imports.
import { ethers } from 'ethers';
import { formatNumber } from '../../utility';

// Define this component for export.
export default {
  props: {
    number: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ethers,
      formatNumber,
      interval: false,
      displayNumber: ethers.BigNumber.from(this.number)
    };
  },

  watch: {
    number() {
      clearInterval(this.interval);

      if (this.displayNumber.eq(this.number)) {
        return;
      }

      if (this.displayNumber.gt(this.number)) {
        this.displayNumber = this.number;
        return;
      }

      let change = this.number.sub(this.displayNumber).div(300);
      this.interval = window.setInterval(() => {
        if (!this.displayNumber.gt(this.number)) {
          this.displayNumber = this.displayNumber.add(change);
        }
      }, 50);
    }
  }
};
</script>

<style scoped>
.number {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}
</style>
