<template>
  <section class="header" :class="{ header__opened: mobileMenuOpened }">
    <div class="header__container">
      <button class="header__button-mobile header__button-mobile_menu" @click="mobileMenuOpened = !mobileMenuOpened">
        <img
          :src="mobileMenuOpened ? require('@/images/social/icons/cancel.svg') : require('@/images/social/icons/menu.svg')"
          :style="!mobileMenuOpened && { width: '17px', height: '20px' }"
          alt=""
        />
      </button>
      <router-link to="/" class="header__logo" v-show="windowWidth > 767 || (windowWidth < 767 && !mobileSearchOpened)">
        <img src="@/images/SuperFarm-icon-black.svg" alt="" />
      </router-link>
      <button
        class="header__button-mobile header__button-mobile_search"
        v-show="!mobileSearchOpened"
        @click="mobileSearchOpened = !mobileSearchOpened"
      >
        <img src="@/images/social/icons/search.svg" alt="" />
      </button>
      <HeaderSearch
        :windowWidth="windowWidth"
        :mobileSearchOpened="mobileSearchOpened"
        @closeModalSearch="mobileSearchOpened = !mobileSearchOpened"
      />
      <div class="header__menu" v-if="windowWidth > 991 || mobileMenuOpened">
        <div class="header__tabs">
          <router-link class="header__tab-link" to="/">
            <BaseTabItem
              color="black"
              bgColor="lightBlack"
              class="header__tab"
              :class="{ selected: $route.name === 'Drops' }"
              title="Drops"
            ></BaseTabItem>
          </router-link>
          <router-link class="header__tab-link" to="/farms">
            <BaseTabItem
              color="black"
              bgColor="lightBlack"
              class="header__tab"
              :class="{ selected: $route.name === 'Farms' }"
              title="Farms"
            ></BaseTabItem>
          </router-link>
          <router-link class="header__tab-link" to="/">
            <BaseTabItem
              color="black"
              bgColor="lightBlack"
              class="header__tab"
              :class="{ selected: $route.name === 'Discover' }"
              title="Discover"
            ></BaseTabItem>
          </router-link>
          <feature-inline-guard requiredFlag="following">
            <router-link class="header__tab-link" :to="{ name: 'Following' }">
              <BaseTabItem
                color="black"
                bgColor="lightBlack"
                class="header__tab"
                :class="{ selected: $route.name === 'Following' }"
                title="Following"
              ></BaseTabItem>
            </router-link>
          </feature-inline-guard>
          <feature-inline-guard requiredFlag="activity">
            <router-link class="header__tab-link" :to="{ name: 'ActivitySection', params: { name: 'global' } }">
              <BaseTabItem
                color="black"
                bgColor="lightBlack"
                class="header__tab"
                :class="{
                  selected: $route.matched.find(route => route.name === 'Activity')
                }"
                title="Activity"
              ></BaseTabItem>
            </router-link>
          </feature-inline-guard>
          <a class="header__tab-link" href="https://superstarter.co/" target="_blank">
            <BaseTabItem color="black" bgColor="lightBlack" class="header__tab" title="SuperStarter"></BaseTabItem>
          </a>
          <router-link class="header__tab-link header__tab-link_mobile" to="#">
            <BaseTabItem color="black" bgColor="lightBlack" class="header__tab" title="Notifications"></BaseTabItem>
          </router-link>
          <router-link class="header__tab-link header__tab-link_mobile" to="#">
            <BaseTabItem color="black" bgColor="lightBlack" class="header__tab" title="username"
              ><img src="@/images/person.svg" class="header__user"
            /></BaseTabItem>
          </router-link>
        </div>
        <feature-inline-guard requiredFlag="notifications">
          <NotificationsDropdown v-if="windowWidth > 991" />
        </feature-inline-guard>
        <BaseDropdown>
          <button class="header__user-menu user-menu menu-button" tabindex="0">
            <img src="@/images/person.svg" class="header__user" alt="" />
            <img src="@/images/dropdown-chevron.svg" alt="" />
          </button>
          <template #dropdown>
            <div class="user-menu__dropdown">
              <div class="user-menu__user">
                <div class="user-menu__username">username</div>
                <div class="user-menu__address">0xc4F3...A8ae</div>
              </div>
              <SocialButton class="user-menu__button"><img src="@/images/social/icons/items.svg" alt="" />My Items</SocialButton>
              <SocialButton class="user-menu__button"
                ><img src="@/images/social/icons/favorites.svg" alt="" />
                Favorites
              </SocialButton>
              <SocialButton class="user-menu__button"><img src="@/images/social/icons/offers.svg" alt="" />Offers</SocialButton>
              <SocialButton class="user-menu__button"><img src="@/images/social/icons/watchlist.svg" alt="" />Watchlist</SocialButton>
              <feature-inline-guard requiredFlag="profile.edit">
                <SocialButton class="user-menu__button"
                  ><img src="@/images/social/icons/edit-profile.svg" alt="" />
                  Edit Profile
                </SocialButton>
              </feature-inline-guard>
              <!-- <ThemeToggle v-slot="{ toggleTheme, isActive }">
                <SocialButton @click="toggleTheme" class="dark-mode__button">
                  <ThemeToggleIcon :isActive="isActive" class="dark-mode-toggle" />
                  Dark mode
                </SocialButton>
              </ThemeToggle> -->
              <SocialButton class="user-menu__button"
                ><img src="@/images/social/icons/disconnect.svg" alt="" />
                Disconnect
              </SocialButton>
            </div>
          </template>
        </BaseDropdown>
        <div class="header__tab menu-button">
          <theme-toggle />
        </div>
        <BaseDropdown :bgShadow="false" class="header__dropdown">
          <div class="header__create-button-container menu-button">
            <Button>
              <img src="@/images/plus.svg" alt="" class="header__create-button-plus" />
              Create
            </Button>
          </div>
          <template #dropdown>
            <div class="create-button__dropdown">
              <SocialButton class="create-button__button"><img src="@/images/social/icons/listing.svg" alt="" />Create Listing</SocialButton>
              <SocialButton class="create-button__button"><img src="@/images/social/icons/items.svg" alt="" />Create NFT</SocialButton>
              <SocialButton class="create-button__button"><img src="@/images/social/icons/farm.svg" alt="" />Create Farm</SocialButton>
              <SocialButton class="create-button__button"><img src="@/images/social/icons/trade.svg" alt="" />Trade</SocialButton>
            </div>
          </template>
        </BaseDropdown>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BaseTabItem from '@/components/ui/BaseTabItem';
import {BaseDropdown} from '@/components/ui/baseDropdown';
import Button from '@/components/ui/Button';
import SocialButton from '@/components/social/social-button/SocialButton';
import FeatureInlineGuard from '@/components/utility/FeatureInlineGuard.vue';
import {ThemeToggle, ThemeToggleIcon} from '@/components/utility/theme'

import useWindowSize from '@/utility/hooks/windowSize';

import HeaderSearch from './components/header-search/HeaderSearch';
import NotificationsDropdown from '@/layouts/social-layout/components/header/components/notifications-dropdown';

export default {
  name: 'SocialHeader',
  components: {
    BaseTabItem,
    BaseDropdown,
    Button,
    SocialButton,
    HeaderSearch,
    FeatureInlineGuard,
    NotificationsDropdown,
    ThemeToggle,
    // ThemeToggleIcon
  },
  data() {
    return {
      mobileMenuOpened: false,
      mobileSearchOpened: false,
      search: ''
    };
  },
  methods: {
    ...mapActions('notifications', ['fetchNotifications'])
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications,
      isLoading: state => state.notifications.isLoading
    })
  },
  watch: {
    $route() {
      if (this.mobileMenuOpened) {
        this.mobileMenuOpened = false
      }
    }
  },
  setup() {
    const { width } = useWindowSize();

    return { windowWidth: width };
  }
};
</script>

<style src="./Header.scss" lang="scss" scoped></style>
