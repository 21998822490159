<template>
  <div class="accordion">
    <div class="accordion__toggle" @click="expanded = !expanded">
      <div class="accordion__toggle-text"><slot name="toggle"></slot></div>
      <img src="@/images/dropdown-chevron.svg" class="accordion__chevron" :class="{accordion__chevron_reverse: expanded}" alt="" />
    </div>
    <transition
      name="expand"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div class="accordion__body" v-if="expanded">
        <slot name="body" :expanded="expanded"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseAccordion",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.height = '0';
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  overflow: hidden;
  width: 100%;
  border: 1px solid $mediumBlack;
  &__toggle {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    width: 100%;
    padding: 15px;
    img {
      width: 10px;
    }
  }
  &__toggle-text {
    font-size: 16px;
    line-height: 1.3em;
  }
  &__body {
    height: 0;
    position: relative;
    width: 100%;
    background-color: $grey;
    transition: height 300ms ease-in-out;
  }
  &__chevron {
    transition: transform 300ms ease-out;
    &_reverse {
      transform: rotateZ(180deg)
    }
  }
}
</style>
