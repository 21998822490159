"use strict";

// Import our module information.
import actions from "./actions";
import mutations from "./mutations";

// Specify state for this module.
const state = { status: {}, shopList: [] };

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
