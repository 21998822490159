<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewbox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.4C10.1 1.4 10.4 1.5 11.2 1.5 13.4 1.6 14.4 2.6 14.5 4.8 14.6 5.6 14.6 5.9 14.6 8 14.6 10.1 14.6 10.4 14.5 11.2 14.4 13.4 13.4 14.4 11.2 14.5 10.4 14.6 10.1 14.6 8 14.6 5.9 14.6 5.6 14.6 4.8 14.5 2.6 14.4 1.6 13.4 1.5 11.2 1.4 10.4 1.4 10.1 1.4 8 1.4 5.9 1.5 5.6 1.5 4.8 1.6 2.6 2.6 1.6 4.8 1.5 5.6 1.5 5.9 1.4 8 1.4V1.4ZM8 0C5.8 0 5.6 0 4.7 0 1.8 0.2 0.2 1.8 0 4.7 0 5.6 0 5.8 0 8 0 10.2 0 10.4 0 11.3 0.2 14.2 1.8 15.8 4.7 16 5.6 16 5.8 16 8 16 10.2 16 10.4 16 11.3 16 14.2 15.8 15.8 14.2 16 11.3 16 10.4 16 10.2 16 8 16 5.8 16 5.6 16 4.7 15.8 1.8 14.2 0.2 11.3 0 10.4 0 10.2 0 8 0V0ZM8 3.9C5.7 3.9 3.9 5.7 3.9 8 3.9 10.3 5.7 12.1 8 12.1 10.3 12.1 12.1 10.3 12.1 8 12.1 5.7 10.3 3.9 8 3.9ZM8 10.7C6.5 10.7 5.3 9.5 5.3 8 5.3 6.5 6.5 5.3 8 5.3 9.5 5.3 10.7 6.5 10.7 8 10.7 9.5 9.5 10.7 8 10.7ZM12.3 2.8C11.7 2.8 11.3 3.2 11.3 3.7 11.3 4.3 11.7 4.7 12.3 4.7 12.8 4.7 13.2 4.3 13.2 3.7 13.2 3.2 12.8 2.8 12.3 2.8Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
