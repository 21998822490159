import Nprogress from 'nprogress'

const withProgressBar = () => {
    return {
        methods: {
            async progressBar(task) {
                Nprogress.start()

                try {
                    await task()
                } finally {
                    Nprogress.done()
                }
            }
        }
    }
}

export default withProgressBar
