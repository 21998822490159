<template>
  <div class='left-side'>
    <SideCard class='left-side-card'>
      <Tabs :modelValue='activeTab' @update:modelValue='setTab'>
        <Tab v-for='(item, index) in tabsList' :key='index' :title='item'/>
      </Tabs>
      <div class='tabs-content'>
        <transition name='fade' mode='out-in'>
          <Items v-if='activeTab === 0' groupName='your'/>
          <Items v-else-if='activeTab === 1' groupName='their'/>
        </transition>
      </div>
    </SideCard>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import {Tabs, Tab} from '@/components/common/tabs'

  import SideCard from '../components/SideCard'
  import Items from './items'

  export default {
    components: {
      SideCard, Tabs,
      Tab, Items
    },
    data: () => ({
      tabsList: ['Your Items', 'Their Items']
    }),
    computed: {
      ...mapGetters('trade', ['activeTab'])
    },
    methods: {
      ...mapActions('trade', ['setTab'])
    }
  }
</script>

<style scoped lang='scss'>
  .left-side {
    height: 100%;
  }

  .left-side-card {
    position: sticky;
    top: 60px;
  }

  .tabs-content {
    padding: 20px;
  }
</style>
