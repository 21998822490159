<template>
  <transition name='modal-slide' mode='out-in'>
    <div class='wrapper' v-if='isOpen'>
      <div class='overlay' @click.prevent='close'/>

      <div class='modal'
           :class='{"is-full-screen": isMobile}'
           :style='{width, height: isMobile ? `${windowHeight}px` : undefined}'
           v-if='isOpen'>
        <button class='close-button' @click='close'>
          <CloseIcon/>
        </button>

        <slot :close='close'/>
      </div>
    </div>
  </transition>
</template>

<script>
  import cssVars from '@/assets/style/_variables.scss'

  import {CloseIcon} from '@/components/icons'

  import useWindowSize from '@/utility/windowSize'

  export default {
    name: 'Modal',
    props: {
      name: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: false,
        default: '400px'
      }
    },
    components: {
      CloseIcon
    },
    computed: {
      isActive() {
        return this.$store.getters['modal/active'] === this.name
      },
      isOpen() {
        return this.$store.getters['modal/allOpen'].includes(this.name)
      },
      hasBody() {
        return !!this.$slots.body
      },
      hasFooter() {
        return !!this.$slots.footer
      },
      isMobile() {
        return this.windowWidth <= parseInt(cssVars['mediumScreen'])
      }
    },
    methods: {
      close() {
        this.$store.dispatch('modal/close', this.name)
      }
    },
    beforeUnmount() {
      if (this.isOpen) this.close()
    },
    watch: {
      isOpen(value) {
        const documentElement = document.documentElement
        if (!!documentElement) {
          if (value) {
            documentElement.classList.add('locked')
          } else {
            documentElement.classList.remove('locked')
          }
        }
      }
    },
    setup() {
      const {height, width} = useWindowSize()
      return {windowHeight: height, windowWidth: width}
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

    @include media-max-width($mediumScreen) {
      display: none;
    }
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--background-color);
    border-radius: 24px;
    box-shadow: 0 14px 40px 0 rgba(var(--background-color-rgb-reverse), 0.25);
    z-index: 99;

    &.is-full-screen {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100% !important;
      border-radius: 0;
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    outline: none;
    border: none;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    cursor: pointer;
    background-color: var(--background-color);

    &:hover {
      background-color: rgba(var(--background-color-rgb-reverse), 0.1);
    }

    &:active {
      background-color: rgba(var(--background-color-rgb-reverse), .2);
    }
  }

  .modal-slide-enter-active,
  .modal-slide-leave-active {
    transition: all .2s ease-in-out;

    .modal {
      transition: all .2s ease-in-out;
    }
  }

  .modal-slide-enter-from,
  .modal-slide-leave-to {
    @include media-min-width($mediumScreen) {
      opacity: 0;
    }

    .modal {
      transform: translate3d(0, 60px, 0);
      transform-style: preserve-3d;

      @include media-min-width($mediumScreen) {
        opacity: 0;
      }

      @include media-max-width($mediumScreen) {
        transform: translate3d(0, 100vh, 0);
      }
    }
  }
</style>
