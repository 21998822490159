<template>
  <div class="watch-button">
    <svg
      v-if="filled"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="16"
      height="11"
    >
      <defs />
      <path
        fill="#1ec91e"
        d="M8 0a8.6 8.6 0 00-8 5.45 8.6 8.6 0 0016 0A8.6 8.6 0 008 0zm0 9.1a3.64 3.64 0 110-7.28 3.64 3.64 0 010 7.27zm0-5.83a2.18 2.18 0 100 4.36 2.18 2.18 0 000-4.36z"
      />
    </svg>

    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="11">
      <path
        fill="currentColor"
        d="M8 1.45a7.1 7.1 0 016.41 4 7.1 7.1 0 01-6.41 4 7.1 7.1 0 01-6.41-4 7.1 7.1 0 016.41-4zM8 0a8.6 8.6 0 00-8 5.45 8.6 8.6 0 0016 0A8.6 8.6 0 008 0zm0 3.64a1.82 1.82 0 110 3.63 1.82 1.82 0 010-3.63zm0-1.46a3.28 3.28 0 10.01 6.56A3.28 3.28 0 008 2.18z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['filled'],

  computed: {
    fill() {
      return this.filled ? '#24e024' : 'none';
    },

    stroke() {
      return this.filled ? 'none' : 'currentColor';
    }
  }
};
</script>

<style scoped>
.watch-button {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  transition: background-color 200ms ease;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.watch-button:hover {
  background-color: rgba(74, 224, 36, 0.1);
  color: #1ec91e;
}

.watch-button:active {
  background-color: rgba(74, 224, 36, 0.2);
}
</style>
