<template>
  <input
    class="base-input"
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: ["modelValue"],
};
</script>

<style lang="scss" scoped>
.base-input {
  height: 40px;
  border: 1px solid $mediumBlack;
  border-radius: 24px;
  color: #333333;
  padding: 8px 12px;
  &:hover {
    border-color: rgba(0, 0, 0, 0.5);
  }
  &:focus {
    outline: none;
    border-width: 2px;
    border-color: $black;
  }
}
</style>
