<template>
  <CardModal />
  <Ethereum :callback="initialize" />
  <div class="profile-banner-section">
    <!-- <div class="profile-banner-container">
      <img src="../images/profile-banner-placeholder.jpeg" />
    </div> -->
  </div>
  <div class="container">
    <!-- <ProfileInfo :profile="mockProfileData" /> -->
    <!--
    <div class="profile-top">
      <div class="profile-info">
        <div class="profile-img-container"></div>
        <div class="profile-data">
          <transition name="slide">
            <div v-if="addressHover" class="copy-tooltip">{{ copyText }}</div>
          </transition>
          <div class="profile-name" @click="copyToClipboard" @mouseover="addressHover = true" @mouseleave="copyTooltipLeave">
            <h1>{{ formatAddress(profileAddress) }}</h1>
            <img src="../images/copy.svg" />
          </div>
          <div class="profile-bio">
            {{ profileBio }}
          </div>
        </div>
      </div>
      <div class="profile-buttons">
        <div></div>
      </div>
    </div> -->

    <!-- Display the different tabbed views.  -->
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab">
        <BaseTabItem @click="currentTab = tab" :title="tab" :isSelected="currentTab === tab" />
      </div>
    </div>
    <div class="tab-pane">
      <transition name="fade" appear="" mode="out-in">
        <div v-if="currentTab === 'In Wallet'">
          <!-- <div v-for="pool in items.launchpadItemList.pools" :key="pool"> -->
          <WalletItems :items="nftItems" cardState="noteListed" />
          <!-- </div> -->
        </div>
        <!-- <div v-else-if="currentTab === 'Favorites'">
          <WalletItems :items="getItems()" />
        </div>
        <div v-else-if="currentTab === 'Offers'">
          <Offers />
        </div>
        <div v-else-if="currentTab === 'My Activity'">
          <Activity />
        </div>
        <div v-else-if="currentTab === 'Watchlist'">
          <Watchlist />
        </div> -->
      </transition>
    </div>

    <!-- uncomment to restore original functionality -->
    <!-- <transition name="fade" mode="out-in">
      <div v-if="currentTab === 'In Wallet'">
        <WalletItems
          :items="items.ownedItems"
        />
      </div>
      <div v-else>
        <Activity />
      </div>
    </transition> -->
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import store from '../store';
import { useTask } from 'vue-concurrency';
import useClipboard from 'vue-clipboard3';
import { formatAddress } from '../utility';
// import { sleep } from '../utility';

// Component imports.
import BaseTabItem from '../components/ui/BaseTabItem';
import WalletItems from '../components/pages/my-items/WalletItems';
import Activity from '../components/pages/my-items/activity/Activity';
import ProfileInfo from '../components/pages/my-items/ProfileInfo';
import CardModal from '../components/nft-detail-modal/CardModal';
import Offers from '../components/pages/my-items/offers/Offers';
import Watchlist from '../components/pages/my-items/watchlist/Watchlist';
import Ethereum from '/src/components/common/Ethereum.vue';

// Set up the component.
export default {
  components: {
    BaseTabItem,
    WalletItems,
    // Activity,
    // ProfileInfo,
    CardModal,
    Ethereum
    // Offers,
    // Watchlist
  },
  data: () => ({
    getOwnedItemsTask: null,

    // Page operation data.
    tabs: ['In Wallet'],
    // tabs: ['In Wallet', 'Favorites', 'My Activity', 'Offers', 'Watchlist'],
    // tabs: ['In Wallet'],
    currentTab: 'In Wallet',

    // User profile data.
    profileAddress: null,
    profileBio: 'These are your items.',
    addressHover: false,
    copyText: 'Copy address',
    nftItems: ['loading'],
    formatAddress
  }),

  created() {
    document.title = 'My Account - SuperFarm NFT Platform';
    let ethereumReference = this.ethereum;
    let id = this.$route.params.id;
    // TODO: determine if id is an address; if not retrieve address.
    let address = id;
    this.profileAddress = address;

    // Launch an asynchronous task to retrieve the requested inventory.
    this.getOwnedItemsTask = useTask(function*() {
      if (!ethereumReference.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          await store.dispatch('items/getOwnedItems', address, { root: true });
        });
      } else {
        yield store.dispatch('items/getOwnedItems', address, { root: true });
      }
    });
    this.getOwnedItemsTask.perform();
  },

  watch: {
    items(newItems) {
      this.nftItems = newItems.ownedItems;
    }
  },

  // uncomment to restore original functionality
  // created () {
  //   document.title = 'My Account - SuperFarm NFT Platform';
  //   let ethereumReference = this.ethereum;
  //   let id = this.$route.params.id;
  //   // TODO: determine if id is an address; if not retrieve address.
  //   let address = id;
  //   this.profileAddress = address;
  //   // Launch an asynchronous task to retrieve the requested inventory.
  //   this.getOwnedItemsTask = useTask(function * () {
  //     if (!ethereumReference.provider) {
  //       yield store.dispatch('ethers/initialize', async () => {
  //         await store.dispatch('items/getOwnedItems', address, { root: true });
  //       });
  //     } else {
  //       yield store.dispatch('items/getOwnedItems', address, { root: true });
  //     }
  //   });
  //   this.getOwnedItemsTask.perform();
  // },

  computed: {
    ...mapState({
      ethereum: (state) => state.ethers,
      account: (state) => state.account,
      items: (state) => Object.assign({}, state.items)
    }),

    profileAddressLabel() {
      const length = this.profileAddress.length;
      if (this.profileAddress.length > 0) {
        const shortenedAddress = this.profileAddress.substring(0, 6) + '...' + this.profileAddress.substring(length - 4);

        return shortenedAddress;
      }
      return '';
    },

    mockProfileData() {
      const profile = {
        username: 'username',
        address: this.profileAddress,
        stats: [
          {
            label: 'items',
            value: 400
          },
          {
            label: 'follwers',
            value: 329
          },
          {
            label: 'avg. sale',
            value: '6.7 ETH'
          },
          {
            label: 'traded',
            value: '695 ETH'
          }
        ],
        bio: 'Username is a Franco-Belgian artist, living and working in Brussels. At the age of 28 he studied drawing and illustration at the Ecole Nationale des Beaux-Arts in Paris. Shortly after he ventured to the USA to improve himself, before settling in Belgium and devoting himself entirely to his art.',
        website: 'example.com',
        socials: {
          twitter: 'https://twitter.com/',
          instagram: 'https://instagram.com/',
          facebook: 'https://facebook.com/',
          youtube: 'https://facebook.com/',
          linkedin: 'https://facebook.com/',
          email: 'https://facebook.com/',
          twitch: 'https://facebook.com/',
          telegram: 'https://facebook.com/',
          discord: 'https://facebook.com/',
          snapchat: 'https://facebook.com/'
        }
      };

      return profile;
    }
  },

  methods: {
    ...mapActions('account', ['logout']),
    handleLogout(e) {
      this.account.status.loggingOut = true;
      this.logout();
    },

    async initialize() {
      await store.dispatch('items/getOwnedItems', address, { root: true });
    },
    setActiveTab(tab) {
      let previousTab = document.getElementById(this.activeTab);
      previousTab.classList.remove('btn-group-wallet-nav-selected');
      let activeTab = document.getElementById(tab);
      console.log(activeTab.className);
      activeTab.classList.add('btn-group-wallet-nav-selected');
      this.activeTab = tab;
    },

    async copyToClipboard() {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(this.profileAddress);
        this.copyText = 'Copied to clipboard!';
      } catch (e) {
        console.error(e);
      }
    },

    copyTooltipLeave() {
      this.addressHover = false;
      this.copyText = 'Copy Address';
    },

    getItems() {
      return this.items.ownedItems;
    }
  }
};
</script>

<style scoped lang="css">
.container {
  max-width: 1630px;
  width: 100%;
  min-height: 70vh;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 30px;
}

.profile-top {
  display: flex;
  justify-content: space-between;
}

.profile-banner-container {
  display: block;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.profile-banner-container > img {
  width: 100%;
  max-width: 100%;
  min-height: 140px;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
}

.profile-info {
  display: flex;
  margin-top: -80px;
}

.profile-data {
  position: relative;
  text-align: left;
}

.profile-name {
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
  width: fit-content;
}

.profile-name:hover {
  cursor: pointer;
  opacity: 0.8;
}

.profile-name > h1 {
  margin-right: 15px;
  color: #fff;
  font-size: 40px;
  line-height: 1.3em;
  font-weight: 700;
  margin-bottom: 5px;
}

.profile-name > img {
  width: 15px;
  filter: invert(100%);
}

.profile-data {
  margin-left: 10px;
}

.profile-bio {
  width: 54ch;
  color: rgba(0, 0, 0, 0.8);
}

.profile-img-container {
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
  background-color: #7112ff;
  background-image: linear-gradient(45deg, #7500ff, #9a00ff);
}

.tabs {
  margin-top: 25px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: sticky;
}

.tab-pane {
  padding: 0px 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s ease-in;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.copy-tooltip {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: -40px;
  left: 15%;
  font-size: 14px;
}
</style>
