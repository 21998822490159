<template>
  <div class="upload-component">
    <h3 class="upload__subtitle">{{ subtitle }}</h3>
    <div v-if="dataImg" :class="['upload__result-load', uploadResultCurrentClass]">
      <img :class="uploadResultCurrentClass" :src="dataImg" :alt="imgAltTitle" :title="imgAltTitle">
      <div class="upload__remove-button">
        <button class="upload__button" type="button" @click="deleteImg">
          <CrossIcon />
          <span class="upload__button-text">Delete</span>
        </button>
      </div>
    </div>

    <div v-else
       ref="upload__pic"
       :class="['upload__pic', inputCurrentClass]"
       @drop.prevent
       @dragover.prevent
    >
      <label
        @dragover="overDragArea"
        @dragleave="leaveDragArea"
        @drop="handleFileDrop"
        class="upload__pic-label"
        :for="`upload-${typeImg}`"
      >
        <span>Choose an image</span>
        <span>or drag it here</span>
      </label>
      <input
        v-show="false"
        type="file"
        :id="`upload-${typeImg}`"
        accept="image/png, image/jpg, image/jpeg, image/gif"
        @change="handleFileLoad($event)"
      />
    </div>
    <div class="upload__hint">{{ onloadFileStatus }}</div>
  </div>
</template>

<script>
import CrossIcon from '@/components/icons/CrossIcon.vue'
export default {
  props: {
    subtitle: {
      type: String,
      default: "Avatar"
    },
    typeImg: {
      type: String,
      default: "user"
    }
  },
  components: {
    CrossIcon
  },
  data() {
    return {
      dataImg: null,
      hint: "Max file size 10MB, .png, .jpg, .jpeg, .gif"
    }
  },
  computed: {
    imgAltTitle() {
      return this.typeImg === "user" ? "User avatar" : "Banner";
    },
    inputCurrentClass() {
      return this.typeImg === "user" ? "upload__pic--user" : "upload__pic--banner";
    },
    uploadResultCurrentClass() {
      return this.typeImg === "user" ? "upload__result-load--user" : "upload__result-load--banner";
    },
    currentHint() {
      switch (this.typeImg) {
        case "user":
          return this.hint + ", 140x140";
        case "banner":
          return this.hint + ", 140x500";
        default:
          return this.hint;
      }
    },
    onloadFileStatus() {
      return this.dataImg ? "Uploaded" : this.currentHint;
    }
  },
  methods: {
    overDragArea() {
      const dropArea = this.$refs["upload__pic"];
      dropArea.classList.add("upload__pic--highlight");
    },
    leaveDragArea() {
      const dropArea = this.$refs["upload__pic"];
      dropArea.classList.remove("upload__pic--highlight");
    },
    getFile(file) {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const sizeFileToMB = file.size / 1024 / 1024;
        const roundSize = Math.floor(sizeFileToMB * 100) / 100;

        if (roundSize > 10) {
          this.$store.dispatch('alert/error', "Exceeding the maximum file size", { root: true });
        } else {
          this.dataImg = event.target.result;
        }
      }

      fileReader.onerror = () => {
        this.$store.dispatch('alert/error', fileReader.error, { root: true });
        fileReader.abort();
      }

      fileReader.readAsDataURL(file);
    },
    handleFileDrop(event) {
      const file = event.dataTransfer.files[0];
      if (!file.type.match(/image.[jpg, jpeg, gif, png]/)) {
        return this.leaveDragArea();;
      }

      if (file === undefined) return this.leaveDragArea();

      this.getFile(file);
      this.leaveDragArea();
    },
    handleFileLoad(event) {
      const file = event.target.files[0];
      this.getFile(file);
    },
    deleteImg() {
      this.dataImg = null;
    },
  }
}
</script>

<style lang="scss" src="./UploadImg.scss" scoped></style>