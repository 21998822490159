<template>
  <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1.5"
      width="93"
      height="93"
      rx="23.5"
      class="rectangle"
      stroke-width="3"
    ></rect>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1818 18H72.2727C76.7836 18 80.4546 21.6709 80.4546 26.1818V38.6516V43.5546V69.8182C80.4546 74.3291 76.7836 78 72.2727 78H23.1818C18.6709 78 15 74.3291 15 69.8182V43.5546V38.6516V26.1818C15 21.6709 18.6709 18 23.1818 18ZM20.4545 41.627L20.4545 43.5546C20.4545 43.5552 20.4545 43.5559 20.4545 43.5566C20.4557 46.0075 22.4489 48 24.9027 47.9973C27.3545 47.9973 29.3482 46.0036 29.3482 43.5518V42.9V34.3636H20.4545L20.4545 41.627ZM20.4545 52.3986V69.8182C20.4545 71.3236 21.6791 72.5455 23.1818 72.5455H72.2727C73.7755 72.5455 75 71.3236 75 69.8182V52.3986C73.6618 53.074 72.1504 53.4546 70.5518 53.4546C67.6255 53.4546 65.0182 52.1536 63.2046 50.1273C61.2791 52.1646 58.5709 53.4546 55.5518 53.4546C52.4346 53.4546 49.6609 52.0718 47.7273 49.9173C45.7936 52.0718 43.02 53.4546 39.9027 53.4546C36.8836 53.4546 34.1755 52.1646 32.25 50.1273C30.4364 52.1536 27.8291 53.4546 24.9027 53.4546C23.3041 53.4546 21.7928 53.074 20.4545 52.3986ZM75 43.5546L75 41.627L75 34.3636H66.1064V42.9V43.5546C66.1064 46.0064 68.1027 48 70.5518 48C73.0064 48 75 46.0064 75 43.5546ZM39.9027 48C42.7118 48 45 45.7118 45 42.9V34.3636H34.8027V42.9C34.8027 45.7118 37.0909 48 39.9027 48ZM55.5518 48C58.3636 48 60.6518 45.7118 60.6518 42.9V34.3636H50.4546V42.9C50.4546 45.7118 52.7427 48 55.5518 48Z"
      class="icon"
    ></path>
  </svg>
</template>

<style lang="scss" scoped>
.rectangle {
  fill: var(--background-accent);
  stroke: var(--icon-stroke);
}

.icon {
  fill: var(--text-color-secondary);
}
</style>
