<template>
  <main class="common-component pools-component">
    <upcoming-section
      :pools="upcoming"
      :isLoading="isLoadingUpcoming"
    />
    <featured-section
      :pools="featured"
      :isLoading="isLoadingFeatured"
    />
  </main>
</template>
<script>
import UpcomingSection from "./pools/upcoming-section/UpcomingSection";
import FeaturedSection from "./pools/featured-section/FeaturedSection";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    FeaturedSection,
    UpcomingSection
  },
  computed: {
    ...mapState("pools", {
      upcoming: state => state.upcoming,
      featured: state => state.featured,
      isLoadingUpcoming: state => state.isLoadingUpcoming,
      isLoadingFeatured: state => state.isLoadingFeatured
    })
  },
  methods: {
    ...mapActions("pools", ["fetchUpcomingPools", "fetchFeaturedPools"])
  },
  mounted() {
    this.fetchFeaturedPools();
    this.fetchUpcomingPools();
  }
};
</script>

<style lang="scss" scoped>
.pools-component {
  background-color: #7112ff07;
  padding-top: 80px;
  padding-bottom: 80px;

  @include media-max-width($mediumScreen) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
