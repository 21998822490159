<template>
  <div class="activity-card">
    <div class="activity-card__info-wrapper">
      <img
        src="@/images/social/avatars/username.gif"
        alt=""
        class="activity-card__image"
      />
      <div class="activity-card__info">
        <div class="activity-card__header">
          <img
            :src="
              require(`@/images/social/icons/activity/${activity.type}.svg`)
            "
            alt=""
            class="activity-card__type-image"
          />
          <div class="activity-card__description" v-html="description"></div>
        </div>
        <div class="activity-card__item">
          <div class="activity-card__item-name">NFT Name Goes Here</div>
          <div class="activity-card__item-collection">Collection Name</div>
        </div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <Button
        v-if="
          $route.params.name === 'myactivity' && cancelButtonText.length > 0
        "
        isSecondary
        class="activity-card__button"
      >
        <close-icon class="activity-card__button-icon" />
        Cancel {{ cancelButtonText }}
      </Button>
      <template v-if="$route.params.name === 'watchlist'">
        <Button class="activity-card__button">
          <Auction v-if="activity.type === 'auction'" class="activity-card__button-icon" />
          <OfferMade v-else-if="activity.type !== 'listing'" class="activity-card__button-icon" />
          {{ watchlistButtonText }}
        </Button>
        <Button isSecondary class="activity-card__button">
          <close-icon class="activity-card__button-icon" />
          Remove
        </Button>
      </template>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button.vue';
import CloseIcon from '../../../../components/icons/CloseIcon.vue';
import OfferMade from '@/components/icons/OfferMade.vue'
import Auction from '@/components/icons/Auction.vue'

export default {
  name: 'ActivityCard',
  components: {
    Button,
    CloseIcon,
    OfferMade,
    Auction
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    description() {
      switch (this.activity.type) {
        case 'auction':
          return `<strong>${this.activity.user}</strong> put on auction for <strong>${this.activity.price}</strong> ${this.activity.time} ago`;
        case 'offer_made':
          return `<strong>${this.activity.user}</strong> offered <strong>${this.activity.price}</strong> ${this.activity.time} ago`;
        case 'listing':
          return `<strong>${this.activity.user}</strong> listed for <strong>${this.activity.price}</strong> ${this.activity.time} ago`;
        case 'transfer':
          return `<strong>${this.activity.item}</strong>  →  <strong>${this.activity.user}</strong> ${this.activity.time} ago`;
        case 'sale':
          return `<strong>${this.activity.seller}</strong> sold for <strong>${this.activity.price}</strong> to <strong>${this.activity.buyer}</strong> ${this.activity.time} ago`;
        default:
          return '';
      }
    },
    watchlistButtonText() {
      switch (this.activity.type) {
        case 'auction':
          return 'Place a Bid';
        case 'transfer':
        case 'offer_made':
        case 'sale':
          return 'Make an Offer';
        case 'listing':
          return 'Buy Now';
        default:
          return '';
      }
    },
    cancelButtonText() {
      switch (this.activity.type) {
        case 'listing':
          return 'Sale';
        case 'auction':
          return 'Auction';
        case 'offer_made':
          return 'Offer';
        default:
          return '';
      }
    }
  }
};
</script>

<style src="./ActivityCard.scss" lang="scss" scoped></style>
