"use strict";

// Imports.
import axios from "axios";
import initializeConfig from "../initialize-config";
import { authHeader } from "../utility";
import { ethersService, farmService } from "./index";

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Issue the request to deploy a new shop to Ethereum.
const deploy = function (name, farm) {
  return axios.post(
    `${config.apiUrl}/shop`,
    { name: name, farm: farm },
    { headers: authHeader }
  );
};

// Deploy the shop using the local Ethers provider.
const deployLocally = async function (name, farms) {
  let wallet = await ethersService.getWallet();
  let recordContractAddress =
    config.shopRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(
    recordContractAddress,
    config.shopRecordABI,
    wallet
  );

  // Parse farm addresses out of the input farm data.
  let farmAddresses = [];
  for (let i = 0; i < farms.length; i++) {
    farmAddresses.push(farms[i].farmAddress);
  }

  // Create a new item shop via the farm records contract.
  console.log(`Creating shop in: ${recordContractAddress} ...`);
  let createShopTransaction = await recordContract.createShop(
    name,
    farmAddresses
  );
  let shopCreatedReceipt = await createShopTransaction.wait();
  let shopCreatedEvent =
    shopCreatedReceipt.events[shopCreatedReceipt.events.length - 1];
  let newShopAddress = shopCreatedEvent.args[0];
  console.log(
    `... shop created in transaction: ${createShopTransaction.hash}.`
  );

  // Approve the newly-created shop as a point spender with all farms.
  for (let i = 0; i < farms.length; i++) {
    let farm = farms[i];
    let farmAddress = farm.farmAddress;
    let farmContract = ethersService.connectToContract(
      farmAddress,
      config.farmABI,
      wallet
    );
    let approveShopTransaction = await farmContract.approvePointSpender(
      newShopAddress,
      true
    );
    await approveShopTransaction.wait();
  }

  // Return the same format that we do for the remote server-created contracts.
  return {
    status: "ok",
    shop: {
      address: newShopAddress
    }
  };
};

// Purchase an item.
const purchase = async function (shop, itemId, assetIndex, amount, price) {
  let wallet = await ethersService.getWallet();

  // Get the shop contract.
  console.log(`Purchasing item ...`);
  let shopContract = ethersService.connectToContract(
    shop,
    config.shopABI,
    wallet
  );
  let purchaseTransaction = await shopContract.purchaseItem(
    itemId,
    amount,
    assetIndex,
    {
      value: price
    }
  );
  await purchaseTransaction.wait();
  console.log(`... item purchased.`);
};

// Purchase an item from a mint shop.
const purchaseMint = async function (shop, itemIds, price) {
  let wallet = await ethersService.getWallet();

  // Get the shop contract.
  console.log(`Purchase-minting item ...`);
  let shopContract = ethersService.connectToContract(
    shop,
    config.mintShopABI,
    wallet
  );
  console.log("a", itemIds);
  let purchaseTransaction = await shopContract.purchaseItems(itemIds, {
    value: price
  });
  console.log("b");
  await purchaseTransaction.wait();
  console.log(`... item purchase-minted.`);
};

// Fetch all shops created by a particular user.
const getAllShops = async function () {
  let wallet = await ethersService.getWallet();
  let walletAddress = await ethersService.getWalletAddress();
  let recordContractAddress =
    config.shopRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(
    recordContractAddress,
    config.shopRecordABI,
    wallet
  );

  let shopCount = await recordContract.getShopCount(walletAddress);
  let shops = [];
  for (let i = 0; i < shopCount; i++) {
    let shop = await recordContract.shopRecords(walletAddress, i);
    let shopContract = ethersService.connectToContract(
      shop,
      config.shopABI,
      wallet
    );

    let shopName = await shopContract.name();

    let shopStakerCount = await shopContract.getStakerCount();
    let shopFarms = [];
    for (let j = 0; j < shopStakerCount; j++) {
      let shopFarm = await shopContract.stakers(j);
      shopFarms.push(shopFarm);
    }

    let shopInventoryCount = await shopContract.getInventoryCount();
    let shopItems = [];
    for (let j = 0; j < shopInventoryCount; j++) {
      let shopItem = await shopContract.inventory(j);
      let pricePairLength = await shopContract.pricePairLengths(j);
      let shopPrices = [];
      for (let k = 0; k < pricePairLength; k++) {
        let priceEntry = await shopContract.prices(j, k);
        let assetType = priceEntry.assetType.toNumber();
        let friendlyAssetName;
        if (assetType === 0) {
          friendlyAssetName = `Farm Points`;
        } else if (assetType === 1) {
          friendlyAssetName = `Ether`;
        } else if (assetType === 2) {
          friendlyAssetName = `ERC-20`;
        }
        shopPrices.push({
          asset: friendlyAssetName,
          price: priceEntry.price
        });
      }
      shopItems.push({
        shop: shop,
        id: shopItem.id,
        address: shopItem.token,
        prices: shopPrices,
        amount: shopItem.amount
      });
    }
    shops.push({
      address: shop,
      name: shopName,
      farms: shopFarms,
      items: shopItems
    });
  }
  return shops;
};

// Fetch all currencies (Staker points) available to a given Shop.
const getAvailableCurrencies = async function (shopAddress) {
  let wallet = await ethersService.getWallet();

  let currencies = [
    {
      name: "Ether",
      assetType: 1,
      address: "0x0000000000000000000000000000000000000000"
    }
  ];
  let shopContract = ethersService.connectToContract(
    shopAddress,
    config.shopABI,
    wallet
  );
  let shopStakerCount = await shopContract.getStakerCount();
  for (let i = 0; i < shopStakerCount; i++) {
    let shopFarm = await shopContract.stakers(i);
    let farmDetails = await farmService.retrieveFullFarmInformation(shopFarm);
    currencies.push({
      name: farmDetails.name,
      assetType: 0,
      address: `0x000000000000000000000000000000000000000${i}`
    });
  }
  return currencies;
};

// Export the shop service functions.
export const shopService = {
  deploy,
  deployLocally,
  purchase,
  purchaseMint,
  getAllShops,
  getAvailableCurrencies
};
