<template>
  <div class='wrapper'>
    <div class='container'>
      <div class='title-wrapper'>
        <h2>Notifications</h2>
      </div>

      <Filters/>
      <div class='list-wrapper'>
        <InfiniteLoading @infinite='fetchNotifications(false)'
                         :isLoading='isLoading'
                         :isShowSpinner='isLoading && notifications.length !== 0'
                         :page='page'>
          <div class='list'>
            <template v-if='notifications.length !== 0 || !isLoading'>
              <NotificationCard v-for='(notification, index) in notifications'
                                :key='index'
                                :data='notification'/>
            </template>
            <template v-else>
              <skeleton v-for='index in 3' is-with-border :key='index'/>
            </template>
          </div>
        </InfiniteLoading>
        <p v-if='notifications.length === 0 && !isLoading'>
          No notifications to display
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  import NotificationCard from '@/components/common/notificationCard'
  import InfiniteLoading from '@/components/utility/InfiniteLoading'
  import Skeleton from '@/components/common/notificationCard/Skeleton'

  import Filters from './components/Filters'

  export default {
    components: {
      Filters, NotificationCard,
      InfiniteLoading, Skeleton
    },
    created() {
      document.title = 'SuperFarm - Notifications'
    },
    methods: {
      ...mapActions('notifications', ['fetchNotifications', 'changeFilter', 'acceptFilters'])
    },
    computed: {
      ...mapState({
        notifications: state => state.notifications.notifications,
        isLoading: state => state.notifications.isLoading,
        page: state => state.notifications.cursor,
        totalPages: state => state.notifications.totalPages,
      })
    },
    mounted() {
      this.fetchNotifications()
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    flex: 1;

    @include media-max-width($smallScreen) {
      padding: 30px 10px;
    }
  }

  .list-wrapper p {
    text-align: center;
  }

  .list {
    max-width: 700px;
    margin: 0 auto;
  }

  .container {
    max-width: 1600px;
    min-height: 530px;
    margin: 0 auto;
  }

  .title-wrapper {
    width: 100%;
    margin-bottom: 20px;

    & > * {
      font-size: 30px;
      font-weight: 600;
    }
  }
</style>
