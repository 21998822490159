<template>
  <div class="recentAnnouncements">
    <h1>Recent Announcements</h1>
    <div class="recentAnnouncements__blogsContainer">
      <InfoCard v-for="(blog, index) in blogs" :key="index" class="card" @click="openWindow(blog.link)">
        <template v-slot>
          <img class="blogImage" :src="blog.image" />

          <div class="blogContent">
            <div class="blogTitle">{{ blog.title }}</div>
            <p class="blogSummary">{{ blog.summary }}</p>
            <div class="blogDate">{{ blog.date }}</div>
            <div class="readMore">Read More</div>
          </div>
        </template>
      </InfoCard>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago';

import InfoCard from './InfoCard';

export default {
  components: {
    InfoCard
  },

  data() {
    return { axios };
  },

  setup() {
    const blogs = ref([]);
    const superFarmMediumApi = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/superfarm';

    const openWindow = url => {
      window.open(url, '_blank');
    };

    onMounted(async () => {
      const timeAgo = new TimeAgo('en-US');
      const response = await axios.get(superFarmMediumApi);
      const _blogs = response.data.items;

      for (let i = 0; i < 4; i++) {
        blogs.value.push({
          image: _blogs[i].thumbnail,
          title: _blogs[i].title,
          author: _blogs[i].author,
          date: timeAgo.format(new Date(_blogs[i].pubDate)),
          summary: _blogs[i].description.replace(/<[^>]+>/g, '').substring(0, 140) + '...',
          link: _blogs[i].link
        });
      }
    });

    return {
      blogs,
      openWindow
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  color: var(--text-color);
  padding: 15px;
}
.recentAnnouncements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;

  h1 {
    margin: 20px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }

  .recentAnnouncements__blogsContainer {
    display: grid;
    max-width: 1280px;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;

    .blogImage {
      width: 100%;
      max-height: 300px;
      margin-bottom: 20px;
      border-radius: 30px 0px;
      object-fit: cover;
    }
    .blogContent {
      text-align: left;

      .blogTitle {
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 1em;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .blogSummary {
        font-size: 18px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .blogDate {
        opacity: 0.7;
        margin-bottom: 10px;
      }

      .readMore:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 990px) {
  .recentAnnouncements .recentAnnouncements__blogsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .recentAnnouncements .recentAnnouncements__blogsContainer {
    grid-template-columns: 1fr;
  }
}
</style>
