<template>
  <div class="container">
    <div class="container">
      <div class="history-item" v-for="action in actions" :key="action.bidder" @click="openTransaction(action.txhash)">
        <!-- <div class="profile-pic">
          <img src="" />
        </div> -->
        <div class="row-data">
          <div class="row-info">
            <!-- Item was listed -->
            <!--
            <div v-if="action.actionType === 'Listed'">
              <p>
                Listed {{ action.numEditions }} editions for
                <span class="row-info-price">{{ action.price }} ETH</span>
              </p>
            </div>
      -->
            <!-- Item was bought -->
            <!--
            <div v-if="action.actionType === 'Bought'">
              <p>
                Bought {{ action.numEditions }} editions for
                <span class="row-info-price">{{ action.price }} ETH</span>
              </p>
            </div>
      -->
            <!-- Item was made an offer for -->
            <!--
            <div v-if="action.actionType === 'Offered'">
              <p>
                Offered
                <span class="row-info-price">{{ action.price }} ETH</span> for
                {{ action.numEditions }}
              </p>
            </div>
          -->
            <div>
              <p>
                <strong>Bid placed</strong> by
                {{ formatAddress(action.bidder) }}
              </p>
              <div class="time-ago">{{ formatDate(action.timestamp) }}</div>
            </div>
            <div class="row-info-price-wrapper">
              <div>
                <div class="row-info-price">{{ action.amount }} ETH</div>
                <div class="row-info-usdPrice">${{ formatNumber(usdPrice * action.amount, 2) }}</div>
              </div>
              <NewWindowIcon class="new-window" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Imports.
import { formatAddress, formatNumber } from '../../../utility';
import store from '/src/store';
import { mapState, mapActions } from 'vuex';
import TimeAgo from 'javascript-time-ago';

// English.
import en from 'javascript-time-ago/locale/en';
TimeAgo.addLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

// Icon Imports
import NewWindowIcon from '/src/components/icons/NewWindowIcon';

export default {
  components: {
    NewWindowIcon
  },
  data() {
    return {
      formatAddress,
      formatNumber,
      actions: []
    };
  },

  async created() {
    await store.dispatch('auction/updateBidHistory', '', { root: true });
    // this.startPollingHistory();
    console.log('history', this.actions);
  },

  computed: {
    ...mapState({
      auction: state => state.auction,
      prices: state => state.tokenPrices
    }),

    usdPrice() {
      return this.prices.ethPrice;
    }
  },

  methods: {
    formatDate(date) {
      return timeAgo.format(date);
    },

    startPollingHistory() {
      const historyUpdate = async () => {
        await store.dispatch('auction/updateBidHistory', '', { root: true });
        console.log('history reload', this.auction.bidHistory);
        this.actions = this.auction.bidHistory;
      };
      this.actions = this.auction.bidHistory;
      store.dispatch('tokenPrices/updateEthPrice');
      this.refreshInterval = setInterval(historyUpdate, 10000);
    },

    openTransaction(txhash) {
      window.open(`https://etherscan.io/tx/${txhash}`, '_blank');
    }
  }
};
</script>

<style scoped>
.history-item {
  display: flex;
  margin-bottom: 10px;
  padding: 20px;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 1px 8px 0 rgba(var(--text-color-rgb), 0.2);
  transition: all 100ms ease-out;
  color: rgba(var(--text-color-rgb), 0.8);
  cursor: pointer;
}

.history-item:hover {
  box-shadow: 0 10px 30px 0 rgba(var(--text-color-rgb), 0.2);
}

.history-item:active {
  box-shadow: 0 1px 5px 0 rgba(var(--text-color-rgb), 0.2);
}

.row-data {
  width: 100%;
}

.profile-pic {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
  background-color: #7112ff;
  background-image: linear-gradient(45deg, #7500ff, #9a00ff);
  margin-right: 10px;
}

.owner {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.row-username {
  font-weight: 600;
  cursor: pointer;
}

.row-info {
  display: flex;
  justify-content: space-between;
}

.row-info-usdPrice {
  opacity: 0.8;
  font-weight: 600;
}

.row-info-price-wrapper {
  text-align: right;
  display: flex;
  align-items: center;
}

.new-window {
  margin-left: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.time-ago {
  opacity: 0.5;
}

.row-info-price {
  font-weight: 600;
}
</style>
