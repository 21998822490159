'use strict';

// Specified actions for mutating the itemModal state.
export default {
  open({ commit }, { isOpen, item, bannerImage, avatarImage, creatorHandle, isDisabled, startBlock, itemType, drop }) {
    commit('open', {
      isOpen,
      item,
      bannerImage,
      avatarImage,
      creatorHandle,
      isDisabled,
      startBlock,
      itemType,
      drop
    });
  },
  updateTab({ commit }, { activeTab }) {
    commit('updateTab', { activeTab });
  },
  close({ commit }, { isOpen, activeTab }) {
    commit('close', { isOpen, activeTab });
  }
};
