<template>
  <div class='side-card'>
    <slot/>
  </div>
</template>

<style scoped lang='scss'>
  .side-card {
    background-color: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
  }
</style>