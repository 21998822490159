<template>
  <div class="deployFarm">
    <div class="deployFarm__form">
      <h1>{{ pageHeader }}</h1>
      <!-- Farm Details -->
      <div class="deployFarm__section">
        <h2>Farm Details</h2>
        <div class="textInput">
          <label for="farmName">Farm Name</label>
          <TextInput placeholder="Enter a name" v-model="farmName" />
        </div>
        <div class="textInput">
          <label for="rewardToken">ERC-20 Reward Token</label>
          <p v-if="isEditPage" class="value">{{ editFarm }}</p>
          <TextInput v-else placeholder="Enter a contract address" v-model="rewardToken" />
        </div>
        <div class="textInput">
          <label for="emoji">Emoji for farm points</label>
          <TextInput placeholder="Enter an emoji" v-model="emoji" />
        </div>
      </div>
      <!-- Emission Rate(s) -->
      <div class="deployFarm__section" v-for="(emissionRate, index) in emissionRates" :key="emissionRate">
        <h2>Emission Rate</h2>

        <div class="textInput">
          <label for="pointsPerBlock">Number of points emitted every block</label>
          <TextInput placeholder="Points per block" v-model="emissionRate.pointsPerBlock" />
        </div>
        <div class="textInput">
          <label for="tokensPerBlock">Number of reward tokens emitted every block</label>
          <TextInput placeholder="Tokens per block" v-model="emissionRate.pointsPerBlock" />
        </div>

        <div class="columns">
          <div class="textInput">
            <label for="startTime">Start Time</label>
            <TextInput placeholder="MM-DD-YYY HH:mm" v-model="emissionRate.startTime" />
          </div>
          <div class="textInput">
            <label for="endTime">End Time</label>
            <TextInput placeholder="MM-DD-YYY HH:mm" v-model="emissionRate.endTime" />
          </div>
        </div>

        <Button v-if="index !== 0" :isSecondary="true" class="button" @click="removeEmissionRate(index)">
          Remove Emission Rate
        </Button>
      </div>
      <div class="deployFarm__section">
        <Button :isSecondary="true" class="button" @click="addEmissionRate">
          + Add Emission Rate
        </Button>
      </div>
      <!-- Pool(s) -->
      <div class="deployFarm__section" v-for="(pool, index) in pools" :key="pool">
        <h2>Pool</h2>
        <div class="textInput">
          <label for="poolName">Pool Name</label>
          <TextInput placeholder="Enter a name" v-model="pool.name" />
        </div>
        <div class="textInput">
          <label for="poolToken">ERC-20 token staked into pool</label>
          <TextInput placeholder="Enter a contract address" v-model="pool.token" />
        </div>
        <div class="textInput">
          <label for="tokenPercent">Percent of tokens emitted that go to this pool</label>
          <TextInput placeholder="Enter a percent" v-model="pool.tokenPercent" />
        </div>
        <div class="textInput">
          <label for="pointPercent">Percent of points emitted that go to this pool</label>
          <TextInput placeholder="Enter a percent" v-model="pool.pointPercent" />
        </div>
        <Button v-if="index !== 0" :isSecondary="true" class="button" @click="removePool(index)">
          Remove Pool
        </Button>
      </div>
      <div class="deployFarm__section">
        <Button :isSecondary="true" class="button" @click="addPool">
          + Add Pool
        </Button>
      </div>
      <div class="deployFarm__submit">
        <Button class="button">
          🚀 Deploy Farm
        </Button>
      </div>
    </div>
    <div class="deployFarm__preview">
      <h1>Preview</h1>
      <div v-if="rewardTokenSymbol.length === 0">Please Supply a Valid Reward Token Contract Address above.</div>

      <FarmDeployerTable v-else :pools="farmPreview" />
    </div>
  </div>
</template>
<script>
'use strict';

// Imports.
import { computed, ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

// Component imports.
import TextInput from '/src/components/ui/TextInput';
import Button from '/src/components/ui/Button';
import FarmDeployerTable from '/src/components/pages/farms-deployer/FarmDeployerTable';
import router from '../router';

export default {
  components: {
    TextInput,
    Button,
    FarmDeployerTable
  },

  setup() {
    const farmName = ref('');
    const rewardToken = ref('');
    const emoji = ref('');
    const emissionRates = ref([]);
    const pools = ref([]);
    const pageHeader = computed(() => {
      return isEditPage.value ? `Edit ${editFarm.value} Farm` : 'New Farm';
    });
    const rewardTokenSymbol = ref('');
    const rewardTokenImgSrc = ref('');
    const route = useRoute();
    const editFarm = ref('');

    const isEditPage = computed(() => {
      return editFarm.value.length > 0;
    });

    watch(rewardToken, async value => {
      if (value.length === 42) {
        try {
          let response = await axios.get(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${value}`);
          rewardTokenSymbol.value = response.data.symbol.toUpperCase();
          rewardTokenImgSrc.value = response.data.image.small;
        } catch (error) {}
      }
    });

    const addPool = () => {
      pools.value.push({
        name: '',
        token: '',
        tokenPercent: '',
        pointPercent: ''
      });
    };

    const removePool = index => {
      pools.value.splice(index, 1);
    };

    const addEmissionRate = () => {
      emissionRates.value.push({
        pointsPerBlock: '',
        tokensPerBlock: '',
        startTime: '',
        endTime: ''
      });
    };

    const removeEmissionRate = index => {
      emissionRates.value.splice(index, 1);
    };

    onMounted(() => {
      addPool();
      addEmissionRate();
      // TODO: When we can retrieve the deployed farm from the store, use the address to fetch farm data.
      if (route.params.farm) {
        editFarm.value = route.params.farm;
        rewardToken.value = route.params.farm;
      }
    });

    const farmPreview = computed(() => {
      let mockPools = [];
      for (let pool of pools.value) {
        mockPools.push({
          token: {
            symbol: rewardTokenSymbol.value
          },
          pair: pool.name,
          pairImgs: [rewardTokenImgSrc.value],
          isLp: false,
          point: emoji.value
        });
      }
      return mockPools;
    });

    return {
      pageHeader,
      farmName,
      rewardToken,
      emoji,
      emissionRates,
      pools,
      addEmissionRate,
      addPool,
      removeEmissionRate,
      removePool,
      rewardTokenSymbol,
      rewardTokenImgSrc,
      farmPreview,
      editFarm,
      isEditPage
    };
  }
};
</script>
<style scoped lang="scss">
.deployFarm {
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .deployFarm__form {
    max-width: 500px;
    width: 100%;

    h1 {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
      font-size: 30px;
      font-weight: 700;
    }

    .deployFarm__section {
      padding-top: 20px;
      border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
      h2 {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;
      }

      .columns {
        display: grid;
        width: 100%;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: 1fr 1fr;
      }

      .textInput {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: 400;
        }

        .value {
          font-weight: 700;
        }
      }

      .button {
        margin-bottom: 20px;
      }
    }

    .deployFarm__submit {
      margin-top: 20px;

      .button {
        height: 60px;
        padding-right: 40px;
        padding-left: 40px;
        border-radius: 30px;
        font-size: 20px;
        width: 100%;
      }
    }
  }

  .deployFarm__preview {
    padding-top: 80px;
    h1 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}
</style>
