<template>
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar" :style="headerStyles">
    <div class="navBar__container">
      <transition name="slide" appear>
        <router-link to="/" class="logo">
          <SuperFarmIcon />
        </router-link>
      </transition>
      <router-link to="/" class="mobileLogo">
        <SuperFarmIcon :noText="true" />
      </router-link>

      <MobileMenu class="navBar__mobile" />

      <div class="navBar__menu">
        <router-link to="/drops" class="item desktopMenu">Drops</router-link>
        <router-link to="/farms" class="item desktopMenu">Farms</router-link>
        <a href="https://superstarter.co/" target="_blank" class="item desktopMenu">SuperStarter</a>
        <div class="toggle_wrapper desktopMenu">
          <ThemeToggle class="toggle" />
        </div>

        <router-link v-if="isHome" to="/farms" style="textDecoration: none" class="desktopMenu">
          <Button>
            Use Farms
          </Button>
        </router-link>

        <button
          v-if="ethereum.address && !isHome"
          class="profile_button"
          :style="showDropdown ? 'background-color: rgba(var(--purple-accent-rgb), 0.2)' : ''"
          @click.stop="toggleDropdown"
        >
          <p>{{ profileAddressLabel }}</p>

          <div class="svg-icon">
            <svg width="10" height="5" viewbox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L5 5L10 0H0Z" fill="currentColor"></path>
            </svg>
          </div>

          <!-- Handle the dropdown for a connected signing address menu. -->
          <!-- Process the link to the 'My Items' page. -->
          <div class="profile-dropdown" v-if="showDropdown">
            <div class="dropdown-item" @click="routeTo(`/wallets/${ethereum.address}`)">
              <div class="html-embed-2 w-embed">
                <svg width="15" height="16" viewbox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5 0.333332H4.5C3.67275 0.333332 3 1.00608 3 1.83333V10.8333C3 11.6606 3.67275 12.3333 4.5 12.3333H13.5C14.3272 12.3333 15 11.6606 15 10.8333V1.83333C15 1.00608 14.3272 0.333332 13.5 0.333332ZM4.5 10.8333V1.83333H13.5L13.5015 10.8333H4.5ZM1.5 4.83333H0V13.8333C0 14.6606 0.67275 15.3333 1.5 15.3333H10.5V13.8333H1.5V4.83333ZM6.75 7.08333L7.5 7.83333L9.75 4.83333L12.75 9.33333H5.25L6.75 7.08333Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <p>My Items</p>
            </div>

            <!-- Process the link to the wallet disconnection page. -->
            <div class="dropdown-item" @click="disconnect">
            <div class="html-embed-2 w-embed">
              <svg
                width="15"
                height="18"
                viewbox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.25375 1.25C8.25375 0.835786 7.91796 0.5 7.50375 0.5C7.08953 0.5 6.75375 0.835786 6.75375 1.25V8.74999C6.75375 9.16421 7.08953 9.49999 7.50375 9.49999C7.91796 9.49999 8.25375 9.16421 8.25375 8.74999V1.25ZM3.25667 5.26024C3.54951 4.9673 3.54944 4.49242 3.25649 4.19958C2.96355 3.90673 2.48868 3.90681 2.19584 4.19975C1.14719 5.24874 0.433125 6.58512 0.143935 8.03991C-0.145256 9.4947 0.00341193 11.0026 0.571141 12.3729C1.13887 13.7432 2.10016 14.9144 3.33348 15.7384C4.56679 16.5624 6.01674 17.0022 7.5 17.0022C8.98325 17.0022 10.4332 16.5624 11.6665 15.7384C12.8998 14.9144 13.8611 13.7432 14.4289 12.3729C14.9966 11.0026 15.1453 9.4947 14.8561 8.03991C14.5669 6.58512 13.8528 5.24874 12.8042 4.19975C12.5113 3.90681 12.0364 3.90673 11.7435 4.19958C11.4506 4.49242 11.4505 4.9673 11.7433 5.26024C12.5822 6.09943 13.1535 7.16853 13.3848 8.33236C13.6162 9.49619 13.4973 10.7025 13.0431 11.7987C12.5889 12.895 11.8199 13.8319 10.8332 14.4911C9.84656 15.1503 8.6866 15.5022 7.5 15.5022C6.31339 15.5022 5.15343 15.1503 4.16678 14.4911C3.18013 13.8319 2.4111 12.895 1.95691 11.7987C1.50273 10.7025 1.38379 9.49619 1.61515 8.33236C1.8465 7.16853 2.41775 6.09943 3.25667 5.26024Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <p>Disconnect</p>
          </div>
          </div>
        </button>

        <!-- The user is not connected to a signing provider. -->
        <Button v-if="!ethereum.address && !isMobile && !isHome" @click="showWalletModalDisplay">
          {{ walletPrompt }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
// Component imports.
import Button from '/src/components/ui/Button';
import WalletModal from '/src/components/common/WalletModal';
import MobileMenu from './MobileMenu';
import { ThemeToggle } from '@/components/utility/theme';

// Icon imports.
import SuperFarmIcon from '/src/components/icons/SuperFarmIcon';
import { useTask } from 'vue-concurrency';
import { ethers } from 'ethers';
import initializeConfig from '@/initialize-config';
import store from '@/store';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    Button,
    SuperFarmIcon,
    WalletModal,
    MobileMenu,
    ThemeToggle
  },
  props: {
    headerStyles: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      ethereum: state => state.ethers
    }),

    walletPrompt() {
      if (!this.ethereum.provider) {
        return '...';
      }
      return 'Connect Wallet';
    },

    profileAddressLabel() {
      let connectedAddress = this.ethereum.address;
      if (connectedAddress) {
        const shortenedAddress = connectedAddress.substring(0, 6) + '...' + connectedAddress.substring(connectedAddress.length - 4);
        return shortenedAddress;

        // If the user has not connected to a signing address, return no label.
      } else {
        return '';
      }
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    isHome() {
      return this.$route.name === 'Home';
    },

    isDarkMode: {
      get() {
        return this.$store.state.theme.isDarkMode;
      },

      set(value) {
        this.$store.dispatch('theme/setDarkMode', { value: value });
      }
    }
  },

  created() {
    let ethereumReference = this.ethereum;
    this.getLaunchpadItemsTask = useTask(function*(signal) {
      this.config = yield initializeConfig();
      if (!ethereumReference.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          // await store.dispatch('items/getLaunchpadItems', '', { root: true });
        });
      } else {
        // yield store.dispatch('items/getLaunchpadItems', '', { root: true });
      }
    });
    this.getLaunchpadItemsTask.perform();
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      showWalletModal: 'alert/showWalletModal',
      hideWalletModal: 'alert/hideWalletModal'
    }),
    ...mapActions('ethers', ['connectWallet', 'disconnectWallet']),
    ...mapActions('auth', ['logout']),

    // TODO
    showWalletModalDisplay() {
      this.showWalletModal();
    },
    hideWalletModalDisplay() {
      this.hideWalletModal();
    },

    // Connect to an Ethereum wallet.
    connect() {
      this.connectWallet();
    },

    // Disconnect from an Ethereum wallet.
    disconnect() {
      this.disconnectWallet(this.logout);
    },

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo(path) {
      this.$router.push(path);
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.navBar {
  position: sticky;
  top: 0;
  z-index: 4;
  display: flex;
  height: 60px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
  color: var(--text-color);
  background-color: var(--background-color);

  .navBar__container {
    position: relative;
    display: flex;
    max-width: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    .logo {
      margin-right: auto;
      margin-left: auto;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: var(--text-color);
      z-index: 2;
    }

    .mobileLogo {
      display: none;
      margin-right: auto;
      margin-left: auto;
      color: var(--text-color);
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 2;
    }

    .mobileLogo:hover {
      transform: scale(1.05);
    }

    .mobileLogo:active {
      transform: scale(0.95);
    }

    .logo:hover {
      transform: scale(1.05);
    }

    .logo:active {
      transform: scale(0.95);
    }

    .navBar__mobile {
      display: none;
    }
  }

  .navBar__menu {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;

    .item {
      display: flex;
      height: 60px;
      margin-right: 0px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      color: var(--text-color);
    }

    .item:hover {
      background-color: rgba(var(--text-color-rgb), 0.1);
    }

    .item:active {
      background-color: rgba(var(--text-color-rgb), 0.2);
    }
    .toggle {
      transition: all 300ms ease;

      &:hover {
        opacity: 0.7;
      }
    }
    .toggle_wrapper {
      padding-top: 4px;
      padding-right: 20px;
      padding-left: 10px;
    }
  }
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.profile-dropdown {
  position: absolute;
  top: 41px;
  right: 0px;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--purple-accent-rgb), 0.2);
  border-radius: 34px;
  background-color: var(--background-color);
  font-size: 14px;
  z-index: 10;
}

.dropdown-item {
  color: var(--text-color);
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.dropdown-item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
 {
  transform: translateY(-100px);
}

// Mobile
@media (max-width: 1260px) {
  .navBar {
    .navBar__container {
      .logo {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 971px) {
  .navBar .navBar__container .navBar__mobile {
    display: initial;
  }

  .navBar .navBar__container .navBar__menu .desktopMenu {
    display: none;
  }
  .navBar .navBar__container .logo {
    display: none;
  }

  .navBar .navBar__container {
    flex-direction: row-reverse;
  }
  .navBar {
    padding-right: 10px;
    padding-left: 10px;
  }

  .navBar .navBar__container .mobileLogo {
    display: initial;
  }
}

@media (max-width: 610px) {
  .toggle {
    display: none;
  }
}

@media (max-width: 570px) {
  .navBar .navBar__menu .item {
    display: none;
  }

  .profile-image {
    display: none;
  }

  .profile_button {
    width: 140px;
  }
}

@media (max-width: 520px) {
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .header > img {
    width: 45px;
    height: 45px;
    margin-bottom: 15px;
  }

  .wallet_button {
    font-size: 0.8rem;
    width: 150px;
    margin-left: 0;
    border: 1px solid black;
    padding: 10px 15px;
  }

  .wallet_button > svg {
    height: 15px;
    width: 15px;
  }
}
</style>
