<template>
  <BaseDropdown>
    <SelectButton icon-position='right'>
      <template #icon><ChevronDownIcon/></template>
      Collections
    </SelectButton>

    <template #dropdown>
      <DropdownContent width='252px' height='272px'>
        <CollectionsList :modelValue='modelValue'
                         @update:modelValue='handleChange'/>
      </DropdownContent>
    </template>
  </BaseDropdown>
</template>

<script>
  import {BaseDropdown, DropdownContent} from '@/components/ui/baseDropdown'
  import {SelectButton} from '@/components/ui/select'
  import {ChevronDownIcon} from '@/components/icons'

  import CollectionsList from './CollectionsList'

  export default {
    components: {
      BaseDropdown, DropdownContent,
      SelectButton, ChevronDownIcon,
      CollectionsList
    },
    props: {
      modelValue: {
        type: Array,
        required: true
      }
    },
    methods: {
      handleChange(value) {
        this.$emit('update:modelValue', value)
      }
    },
    emits: ['update:modelValue']
  }
</script>
