"use strict";

// Imports.
import { tokenService } from "../../services";

// Specify actions that can be performed by this module.
export default {
  // Use the token service to deploy a token.
  async deploy({ dispatch, commit }, { name, ticker, cap, directMints }) {
    commit("deployRequest", { name, ticker });
    try {
      let response = await tokenService.deployLocally(
        name,
        ticker,
        cap,
        directMints
      );
      let tokenData = response.token;
      let tokenAddress = tokenData.address;

      // If the deploy is successful, store token information.
      if (tokenAddress) {
        commit("deploySuccess", tokenData);
        await dispatch(
          "alert/success",
          `Token successfully deployed to ${tokenAddress}.`,
          { root: true }
        );
        await dispatch("getAllTokens", { root: true });
      } else {
        commit("deployFailure", null);
        await dispatch(
          "alert/error",
          `Token '${name}' deployed with no return address!`,
          { root: true }
        );
      }

      // Catch deployment errors.
    } catch (error) {
      commit("deployFailure", error);
      await dispatch("alert/error", error, { root: true });
    }
  },

  // Use the token service to get a list of all a user's tokens.
  async getAllTokens({ dispatch, commit }) {
    try {
      let response = await tokenService.getAllTokens();
      commit("updateTokensList", response);

      // Catch token retrieving errors.
    } catch (error) {
      await dispatch("alert/error", error, { root: true });
    }
  }
};
