<template>
  <section class="common-component search-results">
    <div class="search-results__header">
      <div class="search-results__header-text">Search results for <strong>search term</strong></div>
      <div class="search-results__header-tags">
        <div class="search-results__header-tag">
          <img :src="require('@/images/SuperFarm-icon-black.svg')" class="search-results__header-tag-image" alt="" />
          SUPER
          <close-icon class="search-results__header-tag-cancel" />
        </div>
      </div>
    </div>
    <div class="search-results__tabs-container">
      <VuePerfectScrollbar class="search-results__tabs">
        <router-link
          class="search-results__tab-link"
          v-for="tab in tabs"
          :key="tab"
          :to="{
            name: 'SearchResultsSection',
            params: {
              name: tab.toLowerCase()
            }
          }"
        >
          <BaseTabItem
            :title="tab"
            style="height: 100%"
            :class="{
              selected: activeTab.toLowerCase() === tab.toLowerCase()
            }"
            color="black"
            bgColor="lightBlack"
          />
        </router-link>
      </VuePerfectScrollbar>
    </div>
    <div class="search-results__results-container">
      <div class="search-results__results">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import BaseTabItem from '@/components/ui/BaseTabItem';
import VuePerfectScrollbar from 'vue-perfect-scrollbar/index.vue';
import CloseIcon from '../../components/icons/CloseIcon.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SearchResultsPage',
  components: {
    BaseTabItem,
    VuePerfectScrollbar,
    CloseIcon
  },
  data() {
    return {
      tabs: ['NFTs', 'Users', 'Collections'],
    };
  },
  methods: {
    ...mapActions('searchResults', ['getItems']),
    // changeTab(tab) {
    //   this.activeTab = tab.toLowerCase();
    //   this.$router.push({
    //     name: "SearchResultsSection",
    //     params: {
    //       name: tab.toLowerCase()
    //     }
    //   })
    // }
  },
  computed: {
    activeTab() {
      return this.$route.params.name;
    }
  },
  watch: {
    activeTab(val) {
      this.getItems(val)
    }
  }
};
</script>

<style lang="scss" scoped>
.search-results {
  margin: 0 auto;
  width: 100%;
  &__header {
    display: flex;
    padding: 20px 0px;
    max-width: 1600px;
    margin: 0 auto;
    @include media-max-width(1620px) {
      padding: 20px;
    }
  }
  &__header-text {
    font-size: 30px;
    font-weight: 400;
    margin-right: 20px;
  }
  &__header-tags {
    display: flex;
    margin: -5px;
  }
  &__header-tag {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    background-color: #0000000d;
    border-radius: 20px;
    border: 1px solid $mediumBlack;
    margin: 5px;
    &:hover {
      background-color: $lightBlack;
    }
    &:active {
      background-color: $mediumBlack;
    }
  }
  &__header-tag-cancel {
    width: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
  &__header-tag-image {
    width: 10px;
    margin-right: 10px;
  }
  &__tabs-container {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
  }
  &__tabs {
    position: sticky;
    top: 60px;
    display: flex;
    overflow-x: scroll;
    width: 100%;
    border-bottom: 1px solid $mediumBlack;
    background-color: $white;
    z-index: 3;
  }
  &__tab-link {
    text-decoration: none;
  }
  &__results-container {
    padding: 0 20px;
  }
  &__results {
    max-width: 1600px;
    margin: 0 auto;
  }
}
</style>
