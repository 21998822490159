<template>
  <div class="grid-container">
    <div
      class="metadata-card"
      v-for="attribute in attributes"
      :key="attribute.trait_type"
    >
      <div class="metadata-type-wrapper">
        <div class="metadata-descriptor">{{ attribute.trait_type }}</div>
        <div class="metadata-value">{{ attribute.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['attributes']
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-template-rows: auto;
}

.metadata-card {
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-radius: 30px;
  text-align: center;
}

.metadata-type-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  opacity: 0.8;
}

.metadata-type-wrapper * {
  margin-bottom: 5px;
}

.metadata-descriptor {
  opacity: 0.7;
}

.metadata-value {
  font-weight: 700;
}
</style>
