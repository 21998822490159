<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1935 2.25924C10.7096 1.26619 9.31574 0.453688 7.69435 0.926743C6.91957 1.15054 6.24359 1.63047 5.77685 2.28813C5.31011 1.63047 4.63413 1.15054 3.85935 0.926743C2.23435 0.46091 0.84407 1.26619 0.360181 2.25924C-0.318707 3.64952 -0.0370407 5.21313 1.19796 6.90674C2.16574 8.23202 3.54879 9.57535 5.55657 11.1354C5.62002 11.1848 5.69818 11.2117 5.77865 11.2117C5.85912 11.2117 5.93729 11.1848 6.00074 11.1354C8.0049 9.57897 9.39157 8.24647 10.3593 6.90674C11.5907 5.21313 11.8724 3.64952 11.1935 2.25924Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
