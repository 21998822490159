<template>
  <div
    :class="{
      dropdown: true,
      centered,
      'default-disabled': isDisableHoverActive,
    }"
  >
    <div
      class="dropdown__toggle"
      ref="toggle"
      :class="{ dropdown__toggle_opened: opened && bgShadow }"
      @click.stop="ignoreTriggerClose ? (opened = true) : (opened = !opened)"
    >
      <slot :opened="opened" />
    </div>
    <transition name="fade">
      <div
        class="dropdown__list"
        v-if="opened && showDropdown"
        v-click-outside="{
          handler: click,
          exclude: $refs.toggle,
        }"
      >
        <slot name="dropdown" />
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutside from "@/utility/directives/click-outside";

export default {
  props: {
    bgShadow: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    isDisableHoverActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDropdown: {
      type: Boolean,
      default: true,
    },
    ignoreTriggerClose: {
      type: Boolean,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    click() {
      if (this.opened) {
        this.opened = false;

        this.$emit("onClose");
      }
    },
    hide() {
      this.opened = false;
    },
  },
  emits: ["onClose"],
  directives: {
    clickOutside: clickOutside,
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &__toggle {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:not(.default-disabled) {
    .dropdown__toggle {
      & > :first-child {
        transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:hover {
        & > :first-child {
          background-color: rgba(
            var(--background-color-rgb-reverse),
            0.1
          ) !important;
        }
      }

      &.dropdown__toggle_opened,
      &:active {
        & > :first-child {
          background-color: rgba(
            var(--background-color-rgb-reverse),
            0.2
          ) !important;
        }
      }
    }
  }

  &__list {
    position: absolute;
    right: 0;
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
    border-radius: 30px;
    background-color: var(--background-color);
    top: calc(100% + 3px);
    box-shadow: 0 14px 40px 0 rgb(var(--background-color-rgb-reverse), 0.25);
    overflow: hidden;
    z-index: 10;
  }

  &.centered {
    .dropdown__list {
      right: 50%;
      transform: translateX(50%);
    }
  }
}
</style>
