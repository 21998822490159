<template>
  <BaseDropdown ref='notificationsDropdown'>
    <button class='trigger'>
      <Badge :value='badgeCount'>
        <BellIcon/>
      </Badge>
    </button>

    <template #dropdown>
      <MenuContent :badge-count='badgeCount' :on-clear-badge-count='clearBadgeCount'/>
    </template>
  </BaseDropdown>
</template>

<script>
  import {BaseDropdown} from '@/components/ui/baseDropdown'
  import BellIcon from '@/components/icons/BellIcon'
  import Badge from '@/components/ui/Badge'

  import {socialService} from '@/services'

  import MenuContent from './MenuContent'

  export default {
    components: {
      BellIcon, MenuContent,
      BaseDropdown, Badge
    },
    data: () => ({
      timer: 0,
      badgeCount: 0,
      isLoading: false
    }),
    computed: {
      isDropdownOpen() {
        return this.$refs?.notificationsDropdown?.opened
      }
    },
    methods: {
      async fetchBadgeCount() {
        if (!this.isLoading) {
          this.isLoading = true

          try {
            const res = await socialService.Me.getBadges()

            this.badgeCount = res?.notifications_unread || 0
          } finally {
            this.isLoading = false

            this.timer = window.setTimeout(() => this.fetchBadgeCount(), 5000)
          }
        }
      },
      stopTimer() {
        clearTimeout(this.timer)
      },
      clearBadgeCount() {
        this.badgeCount = 0
      }
    },
    mounted() {
      this.$watch(() => this.isDropdownOpen,
          val => {
            val ? this.stopTimer() : this.fetchBadgeCount()
          }
      )

      this.fetchBadgeCount()
    },
    unmounted() {
      this.stopTimer()
    },
    watch: {
      '$route'() {
        this.$refs.notificationsDropdown.click()
      }
    }
  }
</script>

<style scoped lang='scss'>
  .trigger {
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    cursor: pointer;
  }
</style>
