'use strict';

// Export state mutation functions.
export default {
  // Opening events.
  open(state, { isOpen, item, bannerImage, avatarImage, creatorHandle, isDisabled, startBlock, itemType, drop }) {
    state.isOpen = isOpen;
    state.item = item;
    state.bannerImage = bannerImage;
    state.avatarImage = avatarImage;
    state.creatorHandle = creatorHandle;
    state.isDisabled = isDisabled;
    state.startBlock = startBlock;
    state.itemType = itemType;
    state.drop = drop;
  },
  updateTab(state, { activeTab }) {
    state.activeTab = activeTab;
  },
  close(state, { isOpen, activeTab }) {
    state.isOpen = isOpen;
    state.activeTab = activeTab;
  }
};
