<template>
  <input ref="numberInput" v-if="isNumeric" type="number" v-model="numericValue" :placeholder="initializedPlaceholder" @blur="onBlurNumber" @input="onNumberUpdated" :class="inputClasses" />
  <input v-if="!isNumeric" type="text" v-model="textValue" :placeholder="initializedPlaceholder" @focus="onFocusText" :class="inputClasses" />
  <p v-if="numericValue && showText">
    {{ toWords.convert(parseFloat(numericValue)) }}
  </p>
</template>

<script>
'use strict';

// Imports.
import { toRefs, computed, ref } from 'vue';
import { ToWords } from 'to-words';

// Define this component for export.
export default {
  props: {
    classes: {
      type: Array,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: true
    },
    showText: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Perform component setup.
  setup(props) {
    const { placeholder, modelValue } = toRefs(props);
    const initializedPlaceholder = computed(() => {
      if (placeholder) {
        return placeholder.value;
      } else {
        return 'Enter a number.';
      }
    });
    let delimitedValue = modelValue.value || '0';
    delimitedValue = delimitedValue.toString().split('.');
    delimitedValue[0] = delimitedValue[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
    delimitedValue = delimitedValue.join('.');
    return {
      initializedPlaceholder,
      toWords: new ToWords({
        localeCode: 'en-US',
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: true
        }
      }),
      numericValue: ref(modelValue.value),
      textValue: ref(delimitedValue),
      isNumeric: ref(false)
    };
  },

  updated() {
    if (this.isNumeric) {
      this.$refs.numberInput.focus();
    }
  },

  computed: {
    inputClasses() {
      if (this.classes && this.classes.length > 0) {
        return this.classes.join(' ');
      } else {
        return 'number-input';
      }
    }
  },

  methods: {
    onNumberUpdated(event) {
      this.$emit('update:modelValue', event.target.value);
      this.textValue = this.delimit(this.numericValue);
    },
    onBlurNumber() {
      this.isNumeric = false;
    },
    onFocusText() {
      this.isNumeric = true;
    },
    delimit(value) {
      value = value.toString().split('.');
      value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
      return value.join('.');
    }
  },

  // Watch for a change in the underlying value of this number input.
  // When a change is detected, update the display state.
  watch: {
    modelValue: {
      deep: true,
      handler: function(newValue) {
        this.numericValue = newValue;
        this.textValue = this.delimit(newValue);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.number-input {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgba), 0.2);
  border-radius: 10px;
  background-color: transparent;
  font-size: 15px;
  font-family: 'Work Sans';
  color: rgba(var(--text-color-rgba), 0.8);
  outline: none;
}

.number-input:focus {
  outline: none;
  border-width: 1px;
  border-color: #7112ff;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input[type='number'] {
  -moz-appearance: textfield;
}
</style>
