<template>
  <canvas class="container" ref="container" v-if="threeExtensions.includes(srcExtension)"></canvas>
  <img :src="src" alt="" v-else-if="imageExtensions.includes(srcExtension)">
  <span v-else>Not permitted format</span>
</template>

<script>
import * as THREE from 'three/build/three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VOXLoader, VOXMesh } from 'three/examples/jsm/loaders/VOXLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { debounce } from 'lodash';

export default {
  name: 'Base3dModel',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      threeExtensions: ['gltf', 'glb', 'vox', 'fbx'],
      imageExtensions: ['gif', 'jpg', 'jpeg', 'png'],
      scene: undefined,
      animated: true,
      scaleV3: new THREE.Vector3().setScalar(4),
      camera: "",
      renderer: ""
    };
  },
  computed: {
    srcExtension() {
      return this.src.split('.').pop();
    },
    loader() {
      switch (this.srcExtension) {
        case 'glb':
        case 'gltf':
          return new GLTFLoader();
        case 'vox':
          return new VOXLoader();
        case 'fbx':
          return new FBXLoader();
        default:
          return '';
      }
    }
  },
  methods: {
    init() {
      const loader = new GLTFLoader();
      const scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 20);

      this.loader.load(
        this.src,
        model => {
          if (this.srcExtension == 'gltf' || this.srcExtension == 'glb') {
            this.scaleObject(model.scene);
            scene.add(model.scene);
          } else if (this.srcExtension == 'vox') {
            for (let i = 0; i < model.length; i++) {
              const chunk = model[i];

              // displayPalette( chunk.palette );

              const mesh = new VOXMesh(chunk);
              this.scaleObject(mesh);
              scene.add(mesh);
            }
          } else if (this.srcExtension == 'fbx') {
            model.traverse(function (child) {
              if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = false;
                child.flatshading = true;
              }
            });

            this.scaleObject(model);
            scene.add(model);
          }
        },
        xhr => {
          // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        error => {
          // console.log(error);
        }
      );
      this.renderer = new THREE.WebGLRenderer({ canvas: this.$refs.container, alpha: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      this.camera.position.z = 5;

      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.update();

      let hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 300, 0);
      scene.add(hemiLight);

      let dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(75, 300, -75);
      scene.add(dirLight);

      const animate = () => {
        requestAnimationFrame(animate);
        if (this.animated) {
          scene.rotation.y += 0.003;
        }

        controls.update();

        this.renderer.render(scene, this.camera);
      };

      animate();
    },
    onResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize( window.innerWidth, window.innerHeight );
    },
    scaleObject(object) {
      let box = new THREE.Box3();
      box.setFromObject(object);

      let size = new THREE.Vector3();
      box.getSize(size);

      let center = new THREE.Vector3();
      box.getCenter(center);

      let scaleTemp = new THREE.Vector3().copy(this.scaleV3).divide(size);
      let scale = Math.min(scaleTemp.x, Math.min(scaleTemp.y, scaleTemp.z));
      object.scale.setScalar(scale);

      object.position.sub(center.multiplyScalar(scale));
    }
  },
  mounted() {
    this.init();
    window.addEventListener('resize', debounce(this.onResize, 200));
  },
  unmounted() {
    window.removeEventListener('resize', debounce(this.onResize, 200));
  }
};
</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
}
</style>
