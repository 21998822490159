'use strict';

// Export state mutation functions.
export default {

	updateAuctionData (state, auctionData) {
		state.currentHighBid = auctionData.highBid;
		state.currentHighBidder = auctionData.highBidder;
		state.highBidTime = auctionData.highBidTime;
		state.endTime = auctionData.endTime;
		state.isFinished = auctionData.isFinished;
		state.minimumBid = auctionData.minimumBid;
		state.suggestedBid = auctionData.suggestedBid;
		state.bidCount = auctionData.bidCount;
		state.userIsBeneficiary = auctionData.userIsBeneficiary;
	},
	updateBidHistory (state, bidHistory) {
		state.bidHistory = bidHistory;
	},
	updateUserBidStatus (state, userIsHighBidder) {
		state.userIsHighBidder = userIsHighBidder;
	}
};
