<template>
  <div :class="['condition-component', currentClass]">
    <div class="condition__dot"></div>
    <span class="condition__text">{{ currentTitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    conditionStatus: {
      type: String,
      default: "live"
    }
  },
  computed: {
    currentTitle() {
      switch (this.conditionStatus) {
        case "filled":
          return "Filled";
        case "closed":
          return "Closed";
        default:
          return "LIVE";
      }
    },
    currentClass() {
      switch (this.conditionStatus) {
        case "filled":
          return "condition--filled";
        case "closed":
          return "condition--closed";
        default:
          return "";
      }
    }
  }
}
</script>

<style lang="scss" src="./Condition.scss" scoped></style>