'use strict';

// Imports.
import { createRouter, createWebHistory } from 'vue-router';

import MainLayout from '../layouts/MainLayout';

// Feature flag route guard
import FeatureRouteGuard from '@/components/utility/FeatureRouteGuard';

// Import all routes.
import HomePage from '../pages/HomePage.vue';
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from '../pages/RegisterPage.vue';
import ItemDetailsPage from '../pages/ItemDetailsPage.vue';
import PoolsPage from '../pages/pools-page/PoolsPage.vue';
import PoolDetailsPage from '../pages/pool-details-page/PoolDetailsPage.vue';
// import StorePage from '../pages/StorePage.vue';
import AboutPage from '../pages/AboutPage.vue';
import TokensPage from '../pages/TokensPage.vue';
import FarmsPage from '../pages/FarmsPage.vue';
import ShopsPage from '../pages/ShopsPage.vue';
import ItemDetailsPermalinkPage from '../pages/ItemDetailsPermalinkPage.vue';
import StakingPage from '../pages/StakingPage.vue';
import LaunchpadPage from '../pages/launchpad-page/LaunchpadPage.vue';
import RoadmapPage from '../pages/RoadMapPage.vue';
import TermsAndConditions from '../pages/TermsAndConditions.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import CopyrightPolicy from '../pages/CopyrightPolicy.vue';
import LiquidityProgramPage from '../pages/LiquidityProgramPage.vue';
import SocialActivityPage from '../pages/activity-page';
import ActivityContentSection from '../pages/activity-page/components/content-section/ContentSection.vue';
import DropsPage from '../pages/drops-page';
import DropDetailsPage from '../pages/DropDetailsPage.vue';
import Base3dModel from '@/components/ui/Base3dModel';
import SearchResultsPage from '@/pages/search-results-page';
import SearchResults from '@/pages/search-results-page/components/SearchResults.vue';
// import GemStorePage from '../pages/GemStorePage.vue';
import ClaimPage from '../pages/ClaimPage.vue';
import FarmDeployerPage from '../pages/FarmDeployerPage.vue';
import DeployFarmPage from '../pages/DeployFarmPage.vue';
import PointStorePage from '../pages/PointStorePage.vue';
import AuctionsPage from '../pages/AuctionsPage.vue';
import EditProfilePage from '../pages/edit-profile-page/EditProfilePage.vue';
import Following from '../pages/following';
import Notifications from '../pages/notifications';
import TradePage from '../pages/trade';
import ProfilePage from '../pages/profile-page';
import CollectionPage from '../pages/collection-page';
import WalletPage from '../pages/WalletPage';
import voxPage from '../pages/3dTest/voxPage';
import fbxPage from '../pages/3dTest/fbxPage';
import glbPage from '../pages/3dTest/glbPage';
import HelpCenter from '../pages/help-center';

const flagGuard = (component, requiredFlag, fallbackPath) => {
  return {
    component: FeatureRouteGuard,
    props: {
      component,
      requiredFlag,
      fallbackPath
    }
  };
};

// Define routes to our component pages.

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    children: [
      { path: '/', name: 'Home', component: HomePage },

      { path: '/claim', name: 'Claim', component: ClaimPage },

      {
        path: '/liquidity-program',
        name: 'LiquidityProgram',
        component: LiquidityProgramPage
      },
      { path: '/farms', name: 'Farms', component: FarmsPage },

      { path: '/farm-deployer', name: 'Farm Deployer', component: FarmDeployerPage },

      { path: '/deploy-farm', name: 'Deploy Farm', component: DeployFarmPage },

      { path: '/edit-farm/:farm', name: 'Edit Farm', component: DeployFarmPage },

      { path: '/drops', name: 'Drops', component: DropsPage },

      {
        path: '/drops/:drop',
        name: 'Drop Details',
        component: DropDetailsPage
      },
      { path: '/pools', name: 'Pools', ...flagGuard(PoolsPage, 'pools', { path: '/' }) },
      { path: '/pool/:id', name: 'PoolDetails', ...flagGuard(PoolDetailsPage, 'pools', { path: '/' }) },

      { path: '/store/:id', name: 'Point Store', component: PointStorePage },

      // Pages for displaying particular sets of content.

      {
        path: '/items/:id',
        component: ItemDetailsPermalinkPage,
        name: 'items'
      },

      { path: '/help-center', name: 'Help Center', component: HelpCenter },

      { path: 'wallets/:id', component: WalletPage },

      { path: '/:pathMatch(.*)*', component: HomePage }
    ]
  },
  {
    path: '/',
    name: 'SocialPath',
    ...flagGuard(MainLayout, 'social.enabled', { path: '/' }),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: ProfilePage
      },
      {
        path: 'profile/:id',
        name: 'Public Profile',
        component: ProfilePage
      },
      {
        path: 'edit-profile',
        name: 'EditProfile',
        component: EditProfilePage
      },
      {
        path: 'collections/:id',
        name: 'Collection',
        component: CollectionPage
      },
      {
        path: 'trade',
        name: 'Trade',
        ...flagGuard(TradePage, 'trading.enabled', { path: '/' })
      },
      {
        path: 'following',
        name: 'Following',
        ...flagGuard(Following, 'following', { path: '/' })
      },
      {
        path: 'notifications',
        name: 'Notifications',
        ...flagGuard(Notifications, 'notifications', { path: '/' })
      },
      {
        path: 'search-results',
        name: 'SearchResults',
        ...flagGuard(SearchResultsPage, 'search.enabled', { path: '/' }),
        redirect: { name: 'SearchResultsSection', params: { name: 'nfts' } },
        children: [
          {
            name: 'SearchResultsSection',
            path: ':name',
            component: SearchResults
          }
        ]
      },
      {
        path: 'activity',
        name: 'Activity',
        ...flagGuard(SocialActivityPage, 'activity', { path: '/' }),
        redirect: { name: 'ActivitySection', params: { name: 'global' } },
        children: [
          {
            name: 'ActivitySection',
            path: ':name',
            ...flagGuard(ActivityContentSection, 'activity', { path: '/' })
          }
        ]
      }
    ]
  },
  { path: '/3dvox', name: 'Vox', component: voxPage },
  { path: '/3dfbx', name: 'Fbx', component: fbxPage },
  { path: '/3dglb', name: 'Glb', component: glbPage },
  { path: '/crude', name: 'Crude', component: HomePage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/register', name: 'Register', component: RegisterPage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/tokens', name: 'Tokens', component: TokensPage },
  { path: '/shops', name: 'Shops', component: ShopsPage },
  // { path: "/items", name: "Items", component: ItemsPage },
  { path: '/roadmap', name: 'Roadmap', component: RoadmapPage },
  { path: '/terms', name: 'Terms', component: TermsAndConditions },
  { path: '/privacy', name: 'Privacy Policy', component: PrivacyPolicy },
  { path: '/copyright', name: 'Copyright Policy', component: CopyrightPolicy },
  // { path: '/3dtest', component: Base3dModel },

  // Pages for displaying particular sets of content.

  { path: '/farms/:id', name: 'Staking', component: StakingPage }
];
// Create the router.
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  }
});

// Intercept all routing and force the user off to login if they are not yet logged in.
router.beforeEach((to, from, next) => {
  const privatePages = [];
  const authRequired = privatePages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const activeEthereumAddress = localStorage.getItem('activeEthereumAddress');
  const authenticated = loggedIn || activeEthereumAddress;
  if (authRequired && !authenticated) {
    return next('/login');
  }

  // If the user is already authenticated, skip them over authentication pages.
  const authenticationPages = ['/login', '/register'];
  if (authenticationPages.includes(to.path) && authenticated) {
    return next('/');
  }
  next();
});

// Export the router.
export default router;
