'use strict';

// Imports.
import { drops, featuredDropIds } from '/src/constants/drops-information.js';

// Specify actions that can be performed by this module.
export default {
  async updateDropsData({ commit }) {
    const _drops = drops();
    const _featuredDropIds = featuredDropIds();
    commit('updateDropsData', { _drops, _featuredDropIds });
  }
};
