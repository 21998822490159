<template>
  <!-- Price Info -->
  <div class="price-wrapper">
    <div class="price-container">
      <EthIcon />
      <div class="price-value">{{ price }} ETH</div>
    </div>
    <div class="usd-price">${{ usdPrice }}</div>
  </div>

  <!-- Drop is available -->
  <div class="amount-remaining-wrapper" v-if="isLive">
    <div class="sold-out-tag" v-if="isSoldOut">
      SOLD OUT
    </div>
    <div v-else>
      <Button v-if="!hasSufficientFunds" class="buy-button" :isDisabled="true" @click.stop="">
        Insufficient Funds
      </Button>

      <QuantityInput v-if="hasSufficientFunds" v-model="quantity" class="quantity" />
      <Button v-if="hasSufficientFunds" class="buy-button" :isDisabled="isSoldOut" @click="purchaseItem">
        <template v-slot:default>
          <p>{{ buttonText }}</p>
        </template>
      </Button>

      <div class="amount-remaining">
        <strong>{{ remaining }}</strong
        ><span class="remaining-text">remaining</span>
      </div>
      <ProgressBar :remaining="remaining" :total="total" />
    </div>
  </div>
  <!-- Drop is upcoming -->
  <div v-else class="countdown">
    <div class="countdown-text" v-if="isLive">Available in</div>
    <DropsCountdownCard :startDate="drop.startDate" :endDate="drop.endDate" :hideDate="true" :isLight="true" />
  </div>
</template>

<script>
'use strict';

// Imports.
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { formatNumber } from '/src/utility';
import { ethers } from 'ethers';

// Component Imports
import ProgressBar from '/src/components/ui/ProgressBar';
import DropsCountdownCard from '/src/components/pages/drops/DropsCountdownCard';
import Button from '/src/components/ui/Button';
import QuantityInput from './QuantityInput';

// Icon imports.
import EthIcon from '/src/components/icons/EthIcon';

export default {
  components: {
    ProgressBar,
    DropsCountdownCard,
    Button,
    QuantityInput,
    EthIcon
  },

  data() {
    return {
      formatNumber,
      ethers
    };
  },

  setup() {
    const store = useStore();
    const quantity = ref(1);

    const item = computed(() => {
      return store.state.itemModal.item;
    });

    const drop = computed(() => {
      return store.state.itemModal.drop;
    });

    const isLive = computed(() => {
      const now = new Date();
      return drop.value.startDate < now && drop.value.endDate > now;
    });

    const isSoldOut = computed(() => {
      return item.value.cap.sub(item.value.minted).eq(0);
    });

    const total = computed(() => {
      return item.value.cap.toNumber();
    });

    const remaining = computed(() => {
      return item.value.cap.sub(item.value.minted).toNumber();
    });

    const buttonText = computed(() => {
      return isSoldOut.value ? 'Sold Out' : 'Buy Now';
    });

    const price = computed(() => {
      return formatNumber(ethers.utils.formatEther(item.value.prices[0].price), 2);
    });

    const usdPrice = computed(() => {
      return formatNumber(store.state.tokenPrices.ethPrice * parseFloat(price.value), 2);
    });

    const hasSufficientFunds = computed(() => {
      return !store.state.ethers.balance.lt(item.value.prices[0].price);
    });

    const purchaseItem = async () => {
      // handle this edge case
      // if (!ethereum.canSign) {
      //   this.showWalletModal();
      //   return;
      // }

      let shopAddress = item.value.shop;
      let poolId = 0; // TODO: pool selection
      let groupId = item.value.groupId;
      let assetId = 0; // TODO: assetId selection
      let amount = quantity.value; // TODO: eventual quantity input Imran: we had this somewhere else right?
      let value = item.value.prices[0].price.mul(quantity.value); // pass an optional Ether transaction value
      store.dispatch('items/purchaseLaunchpadItem', { shopAddress, poolId, groupId, assetId, amount, value });
    };

    onMounted(() => {
      store.dispatch('tokenPrices/updateEthPrice');
    });

    return {
      item,
      drop,
      isLive,
      isSoldOut,
      total,
      remaining,
      buttonText,
      purchaseItem,
      price,
      usdPrice,
      hasSufficientFunds,
      quantity
    };
  },

  computed: {
    isAvailable() {
      return false;
    },

    isUpcoming() {
      return true;
    }
  }
};
</script>

<style scoped>
.countdown {
  margin-bottom: 20px;
}
.countdown-text {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.buy-button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
  margin-bottom: 10px;
}

.quantity {
  margin-bottom: 10px;
}
.amount-remaining-wrapper {
  width: 100%;
}

.amount-remaining {
  margin-bottom: 10px;
  text-align: center;
}

.remaining-text {
  opacity: 0.5;
  margin-left: 5px;
}

.sold-out-tag {
  padding: 5px 10px;
  border-radius: 24px;
  background-color: #f12848;
  color: #fff;
  margin: auto;
  width: fit-content;
}

.price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.price-container {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.price-value {
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 700;
  margin-left: 5px;
}

.usd-price {
  text-align: center;
  opacity: 0.5;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 500;
}
</style>
