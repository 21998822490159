<template>
  <div className="progressContainer">
    <div className="progressContainer__full">
      <div
        className="progressContainer__available"
        :style="formatPercentage(availablePercentage)"
      />
      <div
        className="progressContainer__claimed"
        :style="formatPercentage(claimedPercentage)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    availablePercentage: {
      type: Number,
      default: 0
    },

    claimedPercentage: {
      type: Number,
      default: 0
    }
  },

  setup() {
    const formatPercentage = value => {
      const width = value > 0 && value < 5 ? 5 : value;
      return {
        width: `${width}%`
      };
    };

    return {
      formatPercentage
    };
  }
};
</script>
<style scoped lang="scss">
.progressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.progressContainer__full {
  width: 450px;
  height: 54px;
  position: relative;
  border-radius: 50px;
  border: solid 3px rgba(0, 0, 0, 0.2);
  background-color: #f8faff;
  display: flex;
  overflow: hidden;
}

.progressContainer__available {
  left: 0px;
  background-image: linear-gradient(90deg, #7112ff, #005eff);
}

.progressContainer__claimed {
  left: 0px;
  background-image: linear-gradient(90deg, #ff7112, #e6a400);
}
</style>
