<template>
  <PlaceBidModal
    :isOpen="isModalOpen"
    :baseBid="suggestedBid"
    @exit="toggleModal(false)"
  />
  <!-- top bid info  -->
  <!-- top bid amount-->
  <!-- top bid num bids-->
  <!-- top bid num auction-->
  <!-- if you have a bid, then your bid info -->

  <div class="auctionBuyArea">
    <div class="auctionBuyArea__currentBid">
      <div class="label">{{ currentBidLabel }}</div>
      <div class="price">
        <EthIcon /> {{ formatNumber(currentBidPrice, 6) }}
      </div>
      <div class="usdPrice">${{ usdPrice }}</div>
      <div class="bids" @click="changeTab">
        [ {{ currentBidCount }} {{ bidLabel }} ]
      </div>
    </div>
    <div class="verticalDivider" />
    <div class="auctionBuyArea__countdown">
      <div class="title">Auction ending in</div>
      <AuctionCountdownTimer :date="new Date(endTime.toString() * 1000)" :hideDate="true" :isLight="true" />
    </div>
  </div>

  <div class="auctionBuyArea__userBid" v-if="userBidAmount > 0">
    <div class="horizontalDivider"></div>
    <div class="userBidWrapper">
      <div>
        <div class="label">Your Bid</div>
        <div class="price">
          <EthIcon /> {{ formatNumber(userBidAmount, 6) }}
        </div>
        <div class="usdPrice">${{ userBidUsdPrice }}</div>
      </div>

      <div class="bidStatus">
        <div class="emoji">{{ emoji }}</div>
        <div :class="highBidStatus ? 'highest' : 'outbid'">
          {{ userBidStatus }}
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="auctionBuyArea">
    <div
      class="auctionBuyArea__priceWrapper"
      :class="isUserHighestBidder ? 'topBidWrapper' : ''"
    >
      <div class="topBid" v-if="isUserHighestBidder">🥇</div>

      <div class="header">
        {{ headerText }}
      </div>
      <div class="price">
        <EthIcon />
        {{ highBid }}
        ETH
      </div>
      <div class="usdPrice">${{ usdPrice }}</div>
    </div>

    <div class="auctionBuyArea__countdownWrapper">
      <div class="title">Auction ending in</div>
      <DropsCountdownCard
        :date="new Date(endTime.toString() * 1000)"
        :hideDate="true"
        :isLight="true"
      />
    </div>
    {{ currentBidCount }} bids -->

  <div class="ownerActionArea" v-if="userIsBeneficiary && auctionIsFinished">
    <Button class="itemBuyArea__resultButton" @click.stop="acceptResults()">
      Accept
    </Button>
    <Button
      class="itemBuyArea__resultButton decline"
      v-if="userIsBeneficiary && auctionIsFinished"
      @click.stop="declineResults()"
    >
      Decline
    </Button>
  </div>
  <div class="ownerActionArea" v-else>
    <Button
      class="itemBuyArea__button"
      :isDisabled="auctionIsFinished"
      @click.stop="toggleModal(!auctionIsFinished)"
    >
      <template v-slot:default>
        <p>Place a Bid</p>
      </template>
    </Button>
  </div>
</template>

<script>
'use strict';

// Imports.
import axios from 'axios';
import store from '/src/store';
import { mapState, mapActions } from 'vuex';
import { computed } from 'vue';
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component imports.
import Button from '/src/components/ui/Button';
import AuctionCountdownTimer from '/src/components/pages/drops/AuctionCountdownTimer';
import PlaceBidModal from '/src/components/pages/my-items/my-items-modal/PlaceBidModal';

// Icon imports.
import EthIcon from '/src/components/icons/EthIcon';

// Set up the default component.
export default {
  components: {
    Button,
    AuctionCountdownTimer,
    PlaceBidModal,
    // Icons
    EthIcon
  },

  async created() {
    await this.loadAuctionData();

    if (this.ethereum.canSign) {
      await this.loadHighBidStatus();
      await this.startPolling();
    }
    try {
      let priceResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      this.ethPrice = priceResponse.data.ethereum.usd;
    } catch (error) {
      console.error(error);
    }
  },

  data() {
    return {
      bidAmount: '0',
      highBid: 0,
      highBidder: '',
      highBidStatus: false,
      endTime: 0,
      minimumBid: 0,
      isModalOpen: false,
      ethPrice: 0,
      ethers,
      formatNumber,
      userIsBeneficiary: false
    };
  },

  provide() {
    return {
      nft: computed(() => this.itemModal.item)
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      itemModal: state => state.itemModal,
      auction: state => state.auction
    }),

    headerText() {
      return this.highBidStatus ? 'YOU ARE THE TOP BIDDER' : 'Top Bid';
    },

    isUserHighestBidder() {
      return this.highBidStatus;
    },

    currentBidCount() {
      return this.auction.bidCount;
    },

    currentBidPrice() {
      return this.currentBidCount > 0 ? this.auction.currentHighBid : this.auction.minimumBid;
    },

    bidLabel() {
      return this.currentBidCount == 1 ? 'bid' : 'bids';
    },

    usdPrice() {
      return formatNumber(this.ethPrice * this.currentBidPrice, 2);
    },

    auctionIsFinished() {
      return this.auction.isFinished;
    },

    currentBidLabel() {
      return this.currentBidCount > 0 ? 'Top Bid' : 'Minimum Bid';
    },

    emoji() {
      return this.highBidStatus ? '🥇' : '❌';
    },

    userBidStatus() {
      if (this.auction.isFinished) {
        return this.isUserHighestBidder
          ? "You're the winner!"
          : "You've been outbid!";
      } else {
        return this.isUserHighestBidder
          ? "You're the highest bidder!"
          : "You've been outbid!";
      }
      // return this.isUserHighestBidder
      //   ? "You're the highest bidder!"
      //   : "You've been outbid!";
    },

    userBidAmount() {
      let bidAmount = 0;
      for (let bid of this.auction.bidHistory) {
        if (this.ethereum.address === bid.bidder) {
          bidAmount = Math.max(bidAmount, parseFloat(bid.amount));
        }
      }
      return bidAmount;
    },

    userBidUsdPrice() {
      return formatNumber(this.ethPrice * this.userBidAmount, 2);
    },

    suggestedBid() {
      return this.auction.suggestedBid;
    }
  },

  methods: {
    ...mapActions('auction', ['updateAuctionData', 'updateUserBidStatus', 'placeBid']),

    async loadAuctionData() {
      await store.dispatch('auction/updateAuctionData', '', { root: true });
      await store.dispatch('auction/updateBidHistory', '', { root: true });
      this.highBid = this.auction.currentHighBid.toString();
      this.highBidder = this.auction.currentHighBidder;
      this.endTime = this.auction.endTime;
      this.minimumBid = this.auction.minimumBid;
      this.bidAmount = this.minimumBid;
      this.userIsBeneficiary = this.auction.userIsBeneficiary;
    },

    async startPolling() {
      const pageUpdate = async () => {
        await this.loadAuctionData();
        await this.loadHighBidStatus();
      };
      //await pageUpdate();
      await store.dispatch('auction/startBidListener', '', { root: true });
      this.refreshInterval = setInterval(pageUpdate, 12000);
    },

    async stopPolling() {
      clearInterval(this.refreshInterval);
    },

    async loadHighBidStatus() {
      await store.dispatch('auction/updateUserBidStatus', '', { root: true });
      this.highBidStatus = this.auction.userIsHighBidder;
      //this.isUserHighestBidder = this.auction.userIsHighBidder;
    },

    async placeBid() {
      await store.dispatch(
        'auction/placeBid',
        { amount: this.bidAmount },
        { root: true }
      );
    },

    async acceptResults() {
      await store.dispatch('auction/acceptResults', '', { root: true });
    },

    async declineResults() {
      await store.dispatch('auction/declineResults', '', { root: true });
    },

    async purchaseFarmedItem() {
      let groupId = this.itemModal.item.groupId;

      await store.dispatch(
        'items/purchaseLaunchpadItem',
        {
          poolId: 0,
          groupId: groupId,
          assetId: 0,
          amount: 1
        },
        {
          root: true
        }
      );
    },

    toggleModal(value) {
      console.log('modaltoggle', value, this.auction.suggestedBid);

      this.isModalOpen = value;
    },

    changeTab() {
      store.dispatch('itemModal/updateTab', { activeTab: 'History' });
    }
  }
};
</script>

<style scoped lang="scss">
.auctionBuyArea {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: row;

  .auctionBuyArea__currentBid {
    margin-right: 30px;
    .label {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }

    .price {
      font-size: 30px;
      line-height: 1.3em;
      font-weight: 700;
    }

    .usdPrice {
      opacity: 0.5;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
  .bids {
    cursor: pointer;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }

  .verticalDivider {
    width: 1px;
    height: 100%;
    margin-right: 10px;
    margin-left: 10px;
    background-color: rgba(var(--text-color-rgb), 0.2);
  }

  .auctionBuyArea__countdown {
    margin: 0 auto;
    .title {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.auctionBuyArea__userBid {
  width: 100%;
  .horizontalDivider {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: rgba(var(--text-color-rgb), 0.2);
  }

  .userBidWrapper {
    display: flex;
    justify-content: space-between;
    .label {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }

    .price {
      font-size: 30px;
      line-height: 1.3em;
      font-weight: 700;
    }

    .usdPrice {
      opacity: 0.5;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .bidStatus {
      margin: auto 0px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      .emoji {
        font-size: 30px;
        margin-right: 5px;
      }
      .highest {
        color: #28cc1c;
      }

      .outbid {
        color: #f12848;
      }
    }
  }
}

// .auctionBuyArea {
//   width: 100%;
//   text-align: center;

//   .topBidWrapper {
//     width: 100%;
//     margin-bottom: 20px;
//     padding: 10px;
//     border-style: solid;
//     border-width: 1px;
//     border-color: #28cc1c;
//     border-radius: 20px;
//     background-color: rgba(40, 204, 28, 0.2);
//   }

//   .topBid {
//     margin-bottom: 5px;
//     font-size: 30px;
//   }

//   .header {
//     margin-bottom: 5px;
//     font-size: 16px;
//     font-weight: 600;
//   }

//   .price {
//     font-size: 30px;
//     line-height: 1.3em;
//     font-weight: 700;
//   }

//   .usdPrice {
//     opacity: 0.5;
//     font-size: 18px;
//     line-height: 1.3em;
//     font-weight: 500;
//     margin-bottom: 10px;
//   }

//   .auctionBuyArea__countdownWrapper {
//     width: fit-content;
//     margin: auto;
//     margin-bottom: 20px;

//     .title {
//       margin-bottom: 5px;
//       font-size: 16px;
//       font-weight: 600;
//     }
//   }
// }

.itemBuyArea__button {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
}
.itemBuyArea__resultButton {
  width: 50%;
  height: 60px;
  border-radius: 30px;
  font-size: 16px;
}
.itemBuyArea__bid {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}
.itemBuyArea__message {
  margin-bottom: 20px;
  opacity: 0.5;
}

.itemBuyArea__countdown {
  margin-bottom: 20px;
}
.itemBuyArea__countdownText {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.decline {
  background: #911;
}

.ownerActionArea {
  width: 100%;
}
</style>
