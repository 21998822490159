'use strict';

// Export state mutation functions.
export default {
  updateDropsData(state, { _drops, _featuredDropIds }) {
    console.log(_drops);
    state.drops = _drops;
    state.featuredDropIds = _featuredDropIds;
  }
};
