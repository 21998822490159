<template>
  <section class='section' v-if='pinnedItems && pinnedItems.length > 0'>
    <div class='container'>
      <TitledSection title='Pinned NFTs'>
        <div class='cards-list'>
          <div v-for='(item, index) in pinnedItems' :key='item?.id || index'>
            <BaseNFTCard :item='item' @onPin='setPin' :is-show-dropdown-toggle='editable'>
              <template #info>
                <BaseNFTCardInfo :item='item'/>
              </template>
              <template #price>
                <BaseNFTCardPrice state='onAuction'/>
              </template>
            </BaseNFTCard>
          </div>
        </div>
      </TitledSection>
    </div>
  </section>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import BaseNFTCard from '@/components/pages/my-items/nft-card/BaseNFTCard'
  import BaseNFTCardInfo from '@/components/pages/my-items/nft-card/BaseNFTCardInfo'
  import BaseNFTCardPrice from '@/components/pages/my-items/nft-card/BaseNFTCardPrice'
  import {TitledSection} from '@/layouts/profile-layout'

  export default {
    name: 'PinnedItems',
    props: {
      editable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapGetters('profile', ['pinnedItems'])
    },
    methods: {
      ...mapActions('profile', ['setPin'])
    },
    components: {
      BaseNFTCard,
      BaseNFTCardInfo,
      BaseNFTCardPrice,
      TitledSection
    }
  }
</script>

<style scoped lang='scss'>
  .cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;

    @include media-max-width($smallScreen) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  .section {
    padding: 30px 20px;

    @include media-max-width($smallScreen) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
</style>