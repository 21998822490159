<template>
  <div class="allocations">
    <div class="allocations__container">
      <h2 class="allocations__header">
        Your Allocations
      </h2>
      <Skeletor v-if="isLoading" style="width: 100%; height: 120px; border-radius: 24px" />
      <div class="allocations__table table" v-else>
        <div class="table__header">
          <div class="table__header-field">#</div>
          <div class="table__header-field">Action</div>
          <div class="table__header-field">Token Allocation</div>
          <div class="table__header-field">ETH Amount</div>
          <div class="table__header-field">Date</div>
        </div>
        <div class="table__row">
          <div class="table__row-field">1</div>
          <div class="table__row-field"></div>
          <div class="table__row-field"></div>
          <div class="table__row-field"></div>
          <div class="table__row-field"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  components: {
    Skeletor
  },
  props: {
    isLoading: Boolean,
    pool: Object
  }
}
</script>

<style src="./YourAllocations.scss" lang="scss" scoped>
</style>