<template>
  <div class='wrapper'>
    <div class='row' v-if='state === "transferred"'>
      <div class='start-icon'>
        <TransferredIcon/>
      </div>
      <div>
        <strong>usernameA → </strong>
        <strong>usernameB</strong>
      </div>
    </div>

    <div class='row' v-if='state === "offered"'>
      <div class='start-icon'>
        <HandIcon/>
      </div>
      <div>
        <strong>username</strong> offered <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "on_auction"'>
      <div class='start-icon'>
        <HammerIcon/>
      </div>
      <div>
        <strong>username</strong> put on auction for <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "on_sale"'>
      <div class='start-icon'>
        <TagIcon/>
      </div>
      <div>
        <strong>username</strong> put on sale for <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "purchased"'>
      <div class='start-icon eth-icon'>
        <EthIcon/>
      </div>
      <div>
        purchased by <strong>usernameA</strong> for <strong>0.1 ETH</strong> from <strong>usernameB</strong>
      </div>
    </div>

    <div class='row' v-if='state === "sold"'>
      <div class='start-icon'>
        <CoinsIcon/>
      </div>
      <div>
        <strong>username</strong> sold for <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "bid"'>
      <div class='start-icon'>
        <HammerIcon/>
      </div>
      <div>
        <strong>username</strong> bid <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "accepted"'>
      <div class='start-icon hand-reflect'>
        <HandIcon/>
      </div>
      <div>
        <strong>usernameA</strong> accepted offer from <strong>usernameB</strong> for <strong>0.1 ETH</strong>
      </div>
    </div>

    <div class='row' v-if='state === "collection"'>
      <div class='start-icon'>
        <ThumbsIcon/>
      </div>
      <div>
        added to <strong>Collection Name</strong>
      </div>
    </div>
  </div>
</template>

<script>
  import TransferredIcon from '@/components/icons/TransferredIcon'
  import HandIcon from '@/components/icons/HandIcon'
  import HammerIcon from '@/components/icons/HammerIcon'
  import TagIcon from '@/components/icons/TagIcon'
  import EthIcon from '@/components/icons/EthIcon'
  import CoinsIcon from '@/components/icons/CoinsIcon'
  import ThumbsIcon from '@/components/icons/ThumbsIcon'

  export default {
    props: {
      state: {
        type: String,
        required: true
      }
    },
    components: {
      TransferredIcon,
      HandIcon,
      HammerIcon,
      TagIcon,
      EthIcon,
      CoinsIcon,
      ThumbsIcon
    }
  }
</script>

<style scoped lang='scss'>
  .wrapper {
    .row {
      display: flex;
      align-items: center;
    }

    .start-icon {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &.hand-reflect {
        transform: scale(-1, 1);
      }

      svg {
        fill: currentColor;
      }

      &.eth-icon {
        svg {
          width: 8px;
          height: 13px;
        }
      }
    }
  }
</style>