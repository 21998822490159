<template>
  <CardModal/>
  <div class='list-container'>
    <template v-if='!isLoading'>
      <div v-for='(item, index) in items' :key='index'>
        <BaseNFTCard class='following-nft'
                     :item='item'
                     @click='openModal(item)'
                     :is-with-dropdown-toggle='false'
                     is-large>
          <template #header>
            <NftHeader :item='item' :openDetails='openModal'/>
          </template>
          <template #info>
            <BaseNFTCardInfo :item='item'>
              <div class='event'>
                <BaseNFTCardEvent :state='item.metadata.state'/>
              </div>
            </BaseNFTCardInfo>
          </template>
          <template v-slot:price>
            <BaseNFTCardPrice state='onAuction' :prices='item.prices' :item='item' />
          </template>
        </BaseNFTCard>
      </div>
    </template>
    <template v-else>
      <nft-card-skeleton v-for='index in 3' :key='index'/>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

  import BaseNFTCard from '@/components/pages/my-items/nft-card/BaseNFTCard'
  import BaseNFTCardEvent from '@/components/pages/my-items/nft-card/BaseNFTCardEvent'
  import BaseNFTCardInfo from '@/components/pages/my-items/nft-card/BaseNFTCardInfo'
  import BaseNFTCardPrice from '@/components/pages/my-items/nft-card/BaseNFTCardPrice'
  import NftCardSkeleton from '@/components/nft/card/NftCardSkeleton'
  import CardModal from '@/components/nft-detail-modal/CardModal'

  import NftHeader from './NftHeader'

  export default {
    components: {
      BaseNFTCard, BaseNFTCardEvent,
      BaseNFTCardInfo, BaseNFTCardPrice,
      NftCardSkeleton, NftHeader,
      CardModal
    },
    computed: {
      ...mapGetters('following', ['items', 'isLoading'])
    },
    methods: {
      ...mapActions('itemModal', ['open']),
      openModal(item) {
        this.open({
          isOpen: true,
          item: item,
          bannerImage: null,
          avatarImage: null,
          creatorHandle: null,
          isDisabled: false,
          startBlock: null,
          isDropItem: false,
          itemType: 'onAuction',
          drop: null
        });
      },
    }
  }
</script>

<style scoped>
  .following-nft {
    max-width: none;
  }

  .list-container {
    display: grid;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 60px 20px;
  }

  .event {
    margin-bottom: 10px;
  }
</style>
