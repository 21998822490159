<template>
  <div class="toolsSection">
    <div class="container">
      <h2>A host of tools for the NFT world.</h2>
      <div class="subtitle">
        We build state of the art apps to access the SuperFarm protocol and contribute to the world of NFTs and DeFi.
      </div>
      <div class="toolsGrid">
        <InfoCard
          v-for="tool in tools"
          :key="tool"
          :isActive="tool.active"
          @click="navigateToUrl(tool.route)"
          :style="tool.active ? { cursor: 'pointer' } : {}"
          class="card"
        >
          <template v-slot>
            <img :src="require(`@/images/${tool.img}.svg`)" alt="" class="toolIcon" />
            <div class="toolTitle">{{ tool.title }}</div>
            <div v-if="tool.comingSoon" class="comingSoon">(coming soon)</div>
            <div class="toolSubtitle">
              {{ tool.subtitle }}
            </div>
          </template>
        </InfoCard>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import router from '/src/router';
// Component Imports.
import InfoCard from '/src/components/pages/launchpad/InfoCard';

export default {
  components: {
    InfoCard
  },

  setup() {
    const tools = [
      {
        img: 'launch',
        title: 'NFT Launchpad',
        subtitle: 'Launch limited edition NFTs on our Drops platform.',
        active: true,
        route: '/drops'
      },
      {
        img: 'farms',
        title: 'NFT Farming',
        subtitle: 'Earn NFTs by staking coins on SuperFarm.',
        active: true,
        route: '/farms'
      },
      {
        img: 'generation',
        title: 'NFT Generation',
        subtitle: 'Tools for creating your next digital masterpiece.',
        active: false,
        comingSoon: true
      },
      {
        img: 'market',
        title: 'NFT Marketplace',
        subtitle: 'Browse, buy, sell and auction your NFTs.',
        active: false,
        comingSoon: true
      },
      {
        img: 'trade',
        title: 'NFT Trading',
        subtitle: 'Easily swap NFTs with other collectors.',
        active: false,
        comingSoon: true
      }
    ];

    const navigateToUrl = route => {
      if (route === '/drops' || route === '/farms') router.push(route);
    };

    return {
      tools,
      navigateToUrl
    };
  }
};
</script>
<style scoped lang="scss">
.toolsSection {
  padding: 140px 20px;
  flex: 1;

  .container {
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-top: 0px;
      margin-bottom: 40px;
      line-height: 1.1;
      text-align: center;
      font-size: 32px;
    }

    .subtitle {
      max-width: 60ch;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 24px;
      text-align: center;
    }

    .toolsGrid {
      display: grid;
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      .toolIcon {
        display: flex;
        width: 72px;
        height: 72px;
        margin-bottom: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        vertical-align: middle;
      }

      .toolTitle {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
      }

      .toolSubtitle {
        font-size: 16px;
      }

      .comingSoon {
        font-size: 14px;
        margin-bottom: 10px;
        font-style: italic;
      }
    }
  }
}

@media (max-width: 990px) {
  .toolsSection .container .toolsGrid {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .toolsSection .container .toolsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 497px) {
  .toolsSection .container .toolsGrid {
    grid-template-columns: 1fr;
  }
}
</style>
