<template>
  <div class="dropdown-wrapper" @click="toggleDropdown">
    <div v-if="true" class="dropdown-button">
      <img src="../../../../images/dots.svg"/>
    </div>
    <div class="dropdown-menu" v-if="showDropdown">
      <!-- on sale -->
      <div v-if="state === 'onSale'" class="dropdown-menu-item">
        <CloseIcon/>
        <div class="dropdown-menu-text">Cancel Sale</div>
      </div>
      <!-- on auction -->
      <div v-if="state === 'onAuction'" class="dropdown-menu-item">
        <CloseIcon/>
        <div class="dropdown-menu-text">Cancel Auction</div>
      </div>
      <!-- not listed -->
      <div
          v-if="state === 'notListed'"
          class="dropdown-menu-item"
          @click.stop="triggerModal('listItem')"
      >
        <TagIcon/>
        <div class="dropdown-menu-text">Put on Sale</div>
      </div>
      <div v-if="state === 'notListed'" class="dropdown-menu-item">
        <HammerIcon/>
        <div class="dropdown-menu-text">Put on Auction</div>
      </div>
      <!-- always show transfer -->
      <div class="dropdown-menu-item" @click.stop="triggerModal('transfer')">
        <TransferredIcon/>
        <div class="dropdown-menu-text">Transfer</div>
      </div>
      <feature-inline-guard requiredFlag='profile.pinneditems'>
        <div class="dropdown-menu-item" @click.stop="onPin(isPinned ? 'unpin' : 'pin')">
          <PinIcon/>
          <div class="dropdown-menu-text">{{isPinned ? 'Unpin' : 'Pin'}} NFT</div>
        </div>
      </feature-inline-guard>
      <div class="dropdown-menu-item">
        <NotAcceptOffers />
        <div class="dropdown-menu-text">Not accepting offers</div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon';
import TagIcon from '@/components/icons/TagIcon';
import HammerIcon from '@/components/icons/HammerIcon';
import TransferredIcon from '@/components/icons/TransferredIcon';
import PinIcon from '@/components/icons/PinIcon';
import NotAcceptOffers from '@/components/icons/NotAcceptOffers';
import FeatureInlineGuard from '@/components/utility/FeatureInlineGuard.vue';

export default {
  components: {
    CloseIcon,
    TagIcon,
    HammerIcon,
    TransferredIcon,
    PinIcon,
    NotAcceptOffers,
    FeatureInlineGuard
  },
  props: {
    state: {
      type: String
    },
    isPinned: {
      type: Boolean,
      required: false
    }
  },
  emits: ['triggerModal', 'onPin'],
  data() {
    return {
      showDropdown: false
    };
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    onPin(type) {
      this.showDropdown = false

      this.$emit('onPin', type)
    },
    triggerModal(type) {
      this.showDropdown = false;
      this.$emit('triggerModal', {type});
    }
  }
};
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
  z-index: 2;
}

.dropdown-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 12%);
  transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
}

.dropdown-button:hover {
  box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.25);
}

.dropdown-button:active {
  transform: scale(0.95);
}

.dropdown-menu {
  top: 43px;
  right: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
}

.dropdown-menu-item {
  display: flex;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  transition: all 200ms ease;
  white-space: nowrap;
  border-radius: 20px;
}

.dropdown-menu-item svg {
  color: rgba(0, 0, 0, 0.8);
}

.dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu-item:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-menu-text {
  margin-left: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
</style>
