<template>
  <div class="section roadmap">
    <div class="container">
      <div class="roadmap-title-wrapper">
        <a href="#" class="superfarm-logo-roadmap-page w-inline-block"
          ><img
            src="../images/superfarm-logo-white.svg"
            loading="lazy"
            alt=""
            class="superfarm-logo-white"
        /></a>
        <div class="roadmap-title">Roadmap</div>
        <div class="text-block-10">v0.0.23 Codename: Alpha</div>
      </div>

      <div
        data-delay="0"
        data-w-id="f90f3447-cc93-2d71-9e37-caed91160123"
        class="accordion-wrapper w-dropdown"
      >
        <div class="accordion-toggle w-dropdown-toggle">
          <div class="phase-name-wrapper">
            <div class="phase-number">01</div>
            <div class="div-block-29">
              <div class="phase-title">Phase One</div>
              <div class="phase-subtitle">Alpha</div>
            </div>
          </div>
          <div
            data-w-id="f90f3447-cc93-2d71-9e37-caed9116012d"
            data-animation-type="lottie"
            data-src="https://uploads-ssl.webflow.com/6059841890ac8c7278bf761c/6059841890ac8c3561bf7660_plus-to-x-white.json"
            data-loop="0"
            data-direction="1"
            data-autoplay="0"
            data-is-ix2-target="1"
            data-renderer="svg"
            data-default-duration="1"
            data-duration="0"
            data-ix2-initial-state="0"
            class="accordion-open-icon"
          ></div>
        </div>
        <nav class="faq-body w-dropdown-list">
          <div class="accordion-body">
            <div class="phase-items-wrapper _2-columns">
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/drop-silhouette.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Drops</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/shuttle.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Launchpad</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/shopping-cart.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Marketplace</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/wheat.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Farming [Beta]</div>
                </div>
              </div>
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/portal.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Claim Portal</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/auction.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Auctions</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/collaboration.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Partner &amp; Celeb Drops + Farms</div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div
        data-delay="0"
        data-w-id="f90f3447-cc93-2d71-9e37-caed9116014f"
        class="accordion-wrapper w-dropdown"
      >
        <div class="accordion-toggle w-dropdown-toggle">
          <div class="phase-name-wrapper">
            <div class="phase-number">02</div>
            <div>
              <div class="phase-title">Phase Two</div>
              <div class="phase-subtitle">Beta</div>
            </div>
          </div>
          <div
            data-w-id="f90f3447-cc93-2d71-9e37-caed91160159"
            data-animation-type="lottie"
            data-src="https://uploads-ssl.webflow.com/6059841890ac8c7278bf761c/6059841890ac8c3561bf7660_plus-to-x-white.json"
            data-loop="0"
            data-direction="1"
            data-autoplay="0"
            data-is-ix2-target="1"
            data-renderer="svg"
            data-default-duration="1"
            data-duration="0"
            data-ix2-initial-state="0"
            class="accordion-open-icon"
          ></div>
        </div>

        <nav class="faq-body w-dropdown-list">
          <div class="accordion-body">
            <div class="phase-items-wrapper _2-columns">
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/heart.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Social Features</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/magic-wand.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Enhanced NFT &amp; Marketplace Features</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/treasure.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Chest &amp; Pack Openings</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/create-new-pencil-button.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Generation [Beta]</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/diagram.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Indexes</div>
                </div>
              </div>
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/shuttle.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Project Launchpad</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/gamepad.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Video Game Integration (Impostors)</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/treasure.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Impostors Chest Drops/Collabs</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/3D.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>3D NFTs</div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        data-delay="0"
        data-w-id="f90f3447-cc93-2d71-9e37-caed91160183"
        class="accordion-wrapper w-dropdown"
      >
        <div class="accordion-toggle w-dropdown-toggle">
          <div class="phase-name-wrapper">
            <div class="phase-number">03</div>
            <div>
              <div class="phase-title">Phase Three</div>
              <div class="phase-subtitle">Full Release</div>
            </div>
          </div>
          <div
            data-w-id="f90f3447-cc93-2d71-9e37-caed9116018d"
            data-animation-type="lottie"
            data-src="https://uploads-ssl.webflow.com/6059841890ac8c7278bf761c/6059841890ac8c3561bf7660_plus-to-x-white.json"
            data-loop="0"
            data-direction="1"
            data-autoplay="0"
            data-is-ix2-target="1"
            data-renderer="svg"
            data-default-duration="1"
            data-duration="0"
            data-ix2-initial-state="0"
            class="accordion-open-icon"
          ></div>
        </div>
        <nav class="faq-body w-dropdown-list">
          <div class="accordion-body">
            <div class="phase-items-wrapper _2-columns">
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/vote.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Voting</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/swap-horizontal-orientation-arrows.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Trading</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/new-release.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Generation Release</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/seeding.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Farm [Release]</div>
                </div>
              </div>
              <div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/gamepad.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Gamification</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/rent.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Rental</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/pawn-shop.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>NFT Loans</div>
                </div>
                <div class="phase-item">
                  <img
                    src="../images/roadmap/cards-with-dollar-sign.svg"
                    loading="lazy"
                    alt=""
                    class="phase-item-icon"
                  />
                  <div>Fiat Implementation</div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
* {
  text-align: left;
}
body {
  font-family: 'Work Sans', sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.section {
  padding: 60px 30px;
}

.section.roadmap {
  min-height: 100vh;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(113, 18, 255, 0.8)),
      to(rgba(113, 18, 255, 0.8))
    ),
    url('../images/roadmap/andromeda.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(113, 18, 255, 0.8),
      rgba(113, 18, 255, 0.8)
    ),
    url('../images/roadmap/andromeda.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;
}

.superfarm-logo-roadmap-page {
  width: 100%;
  max-width: 200px;
  margin-bottom: 20px;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.superfarm-logo-roadmap-page:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.superfarm-logo-white {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.accordion-body {
  padding: 40px;
}

.accordion-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  width: 100%;
  min-height: 140px;
  padding: 33px 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  white-space: break-spaces;
}

.accordion-toggle.w--open {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.phase-items-wrapper._2-columns {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  justify-items: start;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-block-10 {
  opacity: 0.5;
  font-style: italic;
}

.faq-body {
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
}

.faq-body.w--open {
  background-color: transparent;
}

.roadmap-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.phase-title {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0px, 0px, 20px);
  transform: translate3d(0px, 0px, 20px);
  color: #fff;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.accordion-wrapper {
  overflow: visible;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #9c9c9c;
  border-radius: 24px;
  background-color: #fff;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.accordion-wrapper:hover {
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.35);
}

.accordion-open-icon {
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.phase-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  color: #333;
}

.phase-item-icon {
  width: 20px;
  height: 20px;
  margin-right: 13px;
  -o-object-fit: contain;
  object-fit: contain;
}

.roadmap-title {
  margin-top: 0px;
  margin-right: -27px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 50px;
  line-height: 1em;
  font-weight: 200;
  text-align: center;
  letter-spacing: 27px;
  text-transform: uppercase;
}

.phase-subtitle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0px, 0px, 20px);
  transform: translate3d(0px, 0px, 20px);
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 600;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.phase-number {
  margin-right: 40px;
  -webkit-transform: translate3d(0px, 0px, 20px);
  transform: translate3d(0px, 0px, 20px);
  color: #fff;
  font-size: 80px;
  line-height: 74px;
  font-weight: 700;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.phase-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .phase-items-wrapper._2-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .phase-number {
    margin-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding: 30px 15px;
  }

  .accordion-body {
    padding: 20px;
  }

  .accordion-toggle {
    padding-right: 20px;
    padding-left: 20px;
  }

  .roadmap-title-wrapper {
    margin-bottom: 30px;
  }

  .phase-title {
    font-size: 18px;
  }

  .roadmap-title {
    font-size: 20px;
    font-weight: 300;
  }

  .phase-subtitle {
    font-size: 25px;
  }

  .phase-number {
    margin-right: 20px;
    font-size: 40px;
  }
}
</style>
