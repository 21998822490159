"use strict";

// Export state mutation functions.
export default {
  // Deployment events.
  deployRequest(state, { name }) {
    state.status = { deploying: true };
  },
  deploySuccess(state, token) {
    state.status = {};
  },
  deployFailure(state, error) {
    state.status = {};
  },

  // Update the list of items being tracked for display purposes.
  updateItemsList(state, itemsList) {
    state.itemList = itemsList;
  },

  // Update the list of presale items being tracked for display purposes.
  updatePresaleList(state, itemsList) {
    state.presaleItemList = itemsList;
  },

  // Update the list of presale items being tracked for display purposes.
  updateOwnedItems(state, itemsList) {
    state.ownedItems = itemsList;
  },

  // Update the list of launchpad items being tracked for display purposes.
  updateLaunchpadItemsList(state, itemsList) {
    state.launchpadItemList = itemsList;
  }
};
