<template>
  <div class="container" :class="isActive ? 'active' : ''">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  padding: 20px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px 0px;
  background-color: var(--background-accent);
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: var(--text-color-secondary);
  text-align: center;
  text-decoration: none;

  &:hover {
    box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.25);
    transform: translate(10px, -10px);
  }

  &:active {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    transform: translate(0px, 0px);
  }
}

.active {
  background-color: #7112ff;
  color: #fff;
  cursor: pointer;
}
</style>
