<template>
  <div>
    <h2>{{ header }}</h2>
    <h4 v-if="data.length === 0">
      {{ emptyMessage }}
    </h4>

    <!-- Display a running list of any elements that have been added. -->
    <table v-if="data.length > 0">
      <thead>
        <tr>
          <th
            v-for="(name, index) in columns.map(column => column.name)"
            :key="index"
            colspan="1"
          >
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td v-for="(column, columnIndex) in columns" :key="columnIndex">
            <span v-if="column.type === 'primary'">
              {{ item.primary }}
            </span>
            <span v-else>
              <NumberInput
                v-if="column.type === 'number'"
                v-model="item[column.id]"
              />
              <input v-else :type="column.type" v-model="item[column.id]" />
            </span>
          </td>
          <button type="button" class="btn btn-danger" @click="remove(item.id)">
            <i class="fa fa-trash" />
          </button>
        </tr>
      </tbody>
    </table>

    <!-- If custom input is not specified, display our single select box. -->
    <div class="multiselection-container form-group">
      <div v-if="!customSelection && !customOnly" class="input-group">
        <input
          name="selectedValue"
          type="text"
          class="multiselection-input form-control"
          v-model="pendingAddition"
          :placeholder="initializedSelectPlaceholder"
        />
        <div class="input-group-append">
          <button type="button" class="btn btn-success" @click="add">
            <i class="fa fa-plus" />
          </button>
        </div>
      </div>

      <!-- If custom input is specified, display an additional input box. -->
      <div v-if="customSelection || customOnly">
        <div class="input-group">
          <input
            name="selectedValue"
            type="text"
            class="form-control"
            v-model="pendingAddition"
            :placeholder="initializedCustomPlaceholder"
          />
          <div class="input-group-append">
            <button type="button" class="btn btn-success" @click="add">
              <i class="fa fa-plus" />
            </button>
            <button type="button" class="btn btn-danger" @click="clear">
              <i class="fa fa-trash" />
            </button>
          </div>
        </div>
      </div>

      <!-- Populate the selection dropdown. -->
      <select
        v-if="!customSelection && !customOnly"
        class="multiselection"
        @change="selection"
        ref="options"
      >
        <option
          v-for="(element, index) in elements"
          :key="index"
          :value="element[primary]"
        >
          {{ format(element) }}
        </option>
        <option selected="selected" disabled>──────────</option>
        <option value="">{{ initializedCustomText }}</option>
      </select>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { toRefs, computed, ref } from 'vue';

// Component imports.
import NumberInput from './ui/NumberInput';

// Define this component for export.
export default {
  components: {
    NumberInput
  },
  props: {
    header: {
      type: String,
      required: false
    },
    emptyMessage: {
      type: String,
      required: false
    },
    customOnly: {
      type: Boolean,
      required: false
    },
    selectPlaceholder: {
      type: String,
      required: false
    },
    customPlaceholder: {
      type: String,
      required: false
    },
    customInputText: {
      type: String,
      required: false
    },
    columns: {
      type: Array,
      required: true
    },
    primary: {
      type: String,
      required: true
    },
    elements: {
      type: Array,
      required: false
    },
    template: {
      type: String,
      required: false
    },
    defaultFields: {
      type: Object,
      required: false
    },
    modelValue: {
      type: Array,
      required: false
    }
  },

  // Perform component setup.
  setup(props) {
    const { selectPlaceholder, customPlaceholder, customInputText } = toRefs(
      props
    );
    const initializedSelectPlaceholder = computed(() => {
      return selectPlaceholder?.value || 'Select an item from the list.';
    });
    const initializedCustomPlaceholder = computed(() => {
      return customPlaceholder?.value || 'Enter a custom value.';
    });
    const initializedCustomText = computed(() => {
      return customInputText?.value || 'Custom Input';
    });
    return {
      initializedSelectPlaceholder,
      initializedCustomPlaceholder,
      initializedCustomText,

      customSelection: ref(false),
      pendingId: ref(0),
      pendingAddition: ref(''),
      pendingIndex: ref(-1),
      data: ref([])
    };
  },
  methods: {
    format(item) {
      let result = this.template.replace(/\${(.*?)}/g, function(a, b) {
        return item[b];
      });
      return result;
    },

    selection() {
      const selectedValue = event.target.value;
      if (selectedValue === '') {
        this.pendingIndex = -1;
        this.pendingAddition = '';
        this.customSelection = true;
      } else {
        this.customSelection = false;
        this.pendingAddition = selectedValue;
        this.pendingIndex = event.target.selectedIndex;
      }
    },

    clear() {
      this.customSelection = false;
      this.pendingAddition = '';
    },

    add() {
      if (this.pendingAddition) {
        let id = this.pendingId;
        this.pendingId += 1;
        let next = { id, primary: this.pendingAddition };
        if (this.defaultFields) {
          next = { ...next, ...this.defaultFields };
        }
        let inputElement = this.elements[this.pendingIndex];
        if (this.pendingIndex !== -1) {
          next = { ...next, ...inputElement };
        }
        for (let i = 0; i < this.columns.length; i++) {
          let column = this.columns[i];
          if (column.type !== 'primary') {
            next[column.id] = !inputElement
              ? column.value
              : inputElement[column.id] || column.value;
          } else {
            next[column.id] = this.pendingAddition;
          }
        }
        this.data.push(next);
        this.$emit('update:modelValue', this.data);
      }
    },

    remove(id) {
      this.data = this.data.filter(item => item.id !== id);
      this.$emit('update:modelValue', this.data);
    }
  }
};
</script>

<style lang="css" scoped>
.multiselection-container {
  position: relative;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}

.multiselection {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  line-height: 32px;
  opacity: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.multiselection-input {
  position: relative;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

table {
  width: 75%;
  margin: auto;
}

td {
  padding: 0 15px;
}
</style>
