<template>
  <section class="common-section-component edit-profile__section">
    <div class="common-container edit-profile__container">
      <div class="edit-profile__title-wrap">
        <h2 class="section__title edit-profile__title">Edit Profile</h2>
      </div>
      <Form
        :validation-schema="simpleSchema"
        class="edit-profile__form"
        @submit="sendChanges"
      >
        <upload/>
        <upload subtitle="Banner" typeImg="banner"/>
        <user-info />
        <social-links />
        <div class="edit-profile__control">
          <Button isSecondary @click="cancel"> Cancel </Button>
          <Button type="submit">Save Changes</Button>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import {Form} from 'vee-validate'
import * as yup from 'yup'

import Button from '@/components/ui/Button'
import {validationRules} from '@/utility'

import Upload from './upload-img/UploadImg'
import UserInfo from './user-info/UserInfo'
import SocialLinks from './social-links/SocialLinks'

export default {
  components: {
    Button,
    Upload,
    UserInfo,
    Form,
    SocialLinks
  },
  data() {
    return {
      validationRules: validationRules
    }
  },
  computed: {
    simpleSchema() {
      return yup.object({
        email: this.validationRules["emailRules"],
        name: this.validationRules["nameRules"],
        description: this.validationRules["descRules"],
        website: this.validationRules["urlRules"],
        socials: yup.array().of(yup.object({
          link: yup.string().when('type.value', value =>
              this.validationRules[value === 'email' ? 'emailRules' : 'urlRules'])
        }))
      })
    }
  },
  methods: {
    cancel() {
      console.log("cancel")
    },
    sendChanges(data) {
      console.log("send changes", data)
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-profile__section {
  padding: 30px 20px;

  @include media-max-width($smallScreen) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.edit-profile__container {
  max-width: 500px;
  width: 100%;
}

.edit-profile__title-wrap {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 20%);
}

.edit-profile__title {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
}

.edit-profile__control {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @include media-max-width($smallScreen) {
    grid-template-columns: 1fr;
  }
}
</style>