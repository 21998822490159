<template>
  <div class='filters-wrapper'>
    <div class='search-wrapper'>
      <SearchField placeholder='Search 249 items'
                   :modelValue='filters.search'
                   @update:modelValue='setExactFilter({value: $event.target.value, type: "search"})'/>
    </div>
    <div class='filters-sorting'>
      <FilterContainer :activeCount='filtersCount'>
        <BaseFilterForm :initialValues='{...filters}'
                        :minMaxValues='fromToMinMax'
                        :onAccept='setAllFilters'/>
      </FilterContainer>
      <SortBy v-model='filters.sort_by'
              :options='sortingOptions'
              class='sort-dropdown'/>
    </div>
  </div>
</template>

<script>
  import {inject, computed} from 'vue'

  import {SearchField} from '@/components/ui/text-fields'

  import {SortBy, BaseFilterForm, getFiltersCount, getExactSortOption} from '@/components/common/filters'
  import {FilterContainer} from '@/components/common/modal'

  export default {
    components: {
      SearchField, SortBy,
      FilterContainer, BaseFilterForm
    },
    data() {
      return {
        fromToMinMax: {
          min: .1,
          max: 6
        },
        sortingOptions: [
          getExactSortOption('most_recent'),
          {value: 'most_relevant', label: 'Most Relevant'},
          getExactSortOption('price_to_low'),
          getExactSortOption('price_to_high')
        ]
      }
    },
    setup() {
      const {filters, setExactFilter, setAllFilters} = inject('all-nfts-section', {
        filters: {},
        setExactFilter: () => {},
        setAllFilters: () => {}
      })

      const filtersCount = computed(() => getFiltersCount(filters, ['sort_by', 'search']))

      return {
        filters,
        filtersCount,
        setExactFilter,
        setAllFilters
      }
    }
  }
</script>

<style scoped lang='scss'>
  .filters-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include media-max-width($mediumScreen) {
      flex-direction: column;
      align-items: start;

      .search-wrapper {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
      }
    }

    @include media-max-width($smallScreen) {
      .filters-sorting {
        width: 100%;
        flex-direction: column;

        :deep .select-button {
          width: 100%;
        }

        & > :first-child {
          margin-bottom: 10px;
        }

        & > * {
          width: 100%;
          margin-right: 0 !important;
        }
      }

      .sort-dropdown {
        :deep .dropdown__list {
          width: 100%;
        }
      }
    }
  }

  .search-wrapper {
    margin-right: 10px;
    flex: 1;
  }

  .filters-sorting {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }

  .sort-dropdown {
    :deep .select-button {
      width: 139px
    }
  }
</style>
