<template>
  <div class="claimCardProgressBar">
    <div class="header">
      {{ title }}: <strong>{{ formatNumber(current, 2) }} SUPER</strong>
    </div>
    <div class="progressWrapper">
      <div class="progressFill" :style="width"></div>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed } from 'vue';
import { formatNumber } from '/src/utility/format';

export default {
  props: {
    title: {
      type: String
    },
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },

  data() {
    return { formatNumber };
  },

  setup(props) {
    const width = computed(() => {
      const calcWidth =
        props.total === 0 ? 0 : Math.floor((props.current / total) * 100);

      return {
        width: `${calcWidth}%`,
        backgroundImage:
          props.title === 'Available'
            ? 'linear-gradient(90deg, #7112ff, #005eff)'
            : 'linear-gradient(90deg, #ff7112, #e6a400)'
      };
    });

    return { width };
  }
};
</script>
<style scoped lang="scss">
.claimCardProgressBar {
  .header {
    margin-bottom: 5px;
  }

  .progressWrapper {
    height: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #f8faff;
  }

  .progressFill {
    display: flex;
    height: 100%;
    min-width: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #fff;
  }
}
</style>
