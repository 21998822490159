<template>
  <div class="helpCenter">
    <h1>Help Center</h1>
    <h2>Frequently Asked Questions</h2>
    <div class="dropdownWrapper">
      <DropdownItem class="faq" v-for="faq in faqs" :key="faq" :title="faq.title" :description="faq.description" />
    </div>
    <h2>How to Farm</h2>
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab">
        <BaseTabItem :title="tab" :isSelected="currentTab === tab" @click="setTab(tab)" />
      </div>
    </div>
    <div class="tabPane" v-if="currentTab === 'Computer'">
      <h2>How to farm on a laptop or desktop computer</h2>
      <p>(Ethereum network only)</p>
      <ol class="list">
        <li class="item">Go to <a href="https://superfarm.com/farms">https://superfarm.com/farms</a> and connect your <a href="https://metamask.io/">MetaMask</a> wallet.</li>
        <li class="item">Ensure your MetaMask wallet is on the Ethereum Mainnet network and that your SUPER tokens are ERC20.</li>
      </ol>
      <h3>For the first farm, SUPER:</h3>
      <figure class="exampleImage">
        <div><img src="https://superfarm-assets.s3.amazonaws.com/help-center/farm-example.jpeg" loading="lazy" alt="Farm Image" /></div>
      </figure>
      <p>This farm is for staking SUPER to earn GEMs. The amount of gems you earn can be seen under the <strong>Estimated Yield per $1000</strong> column. This yield will fluctuate based on the price movement of SUPER (as yield is calculated per $1000 USD) and pool size.</p>
      <h4>How to stake SUPER</h4>
      <ol class="list">
        <li class="item">Click the <strong>Details</strong> button for the farm to show the Deposit and Withdraw fields.</li>
        <li class="item">Enter the amount you want to stake in the <strong>Deposit</strong> field and click <strong>Approve</strong>.</li>
        <li class="item">MetaMask will ask for approval. <strong>Approve</strong> the transaction.</li>
        <li class="item">After successful approval, MetaMask will allow you to deposit. Click <strong>Deposit</strong> and confirm the transaction.</li>
        <li class="item">Once your SUPER is deposited, they will appear in the <strong>Withdraw</strong> section. You are now earning GEMs!</li>
      </ol>
      <h3>The second farm, ETH-SUPER:</h3>
      <figure class="exampleImage">
        <div><img src="https://superfarm-assets.s3.amazonaws.com/help-center/lp-farm-example.jpeg" loading="lazy" alt="" /></div>
      </figure>
      <p>This farm provides liquidity for the ETH-SUPER pair on the Uniswap V2 Pool to earn GEMs and SUPER rewards. The liquidity is equally proportioned 50/50. For example, $500 worth of SUPER and $500 worth of ETH. The amount of GEMs and SUPER earned can be seen under <strong>Estimated Yield per $1000</strong> (shown in USD value). The yield fluctuates according to the price movement of SUPER and&nbsp;ETH (as yield is calculated per $1000 USD) and Pool size.</p>
      <p><strong>In order to stake:</strong>‍</p>
      <ol class="list">
        <li class="item">Click the <strong>Details</strong> button for the farm to show the Deposit and Withdraw fields.</li>
        <li class="item">On the left side of the details pane, click <strong>GET ETH-SUPER</strong> which will take you to <a href="https://v2.info.uniswap.org/pair/0x25647e01bd0967c1b9599fa3521939871d1d0888">https://v2.info.uniswap.org/pair/0x25647e01bd0967c1b9599fa3521939871d1d0888</a></li>
      </ol>
      <figure class="exampleImage half">
        <div><img src="https://superfarm-assets.s3.amazonaws.com/help-center/uniswap-example.jpeg" loading="lazy" alt="" /></div>
      </figure>
      <ol start="3" class="list">
        <li class="item">Click <strong>+ Add Liquidity</strong> which will take you to the pool to enter the amounts of proportional ETH-SUPER&nbsp;liquidity. When you add liquidity, you will receive liquidity pool (LP) tokens representing your position. These will be labeled <strong>UNI-V2</strong> and these are what you use to farm on the ETH-SUPER farming pool.</li>
        <li class="item">Once you have the LP tokens return to <a href="https://superfarm.com/farms">https://superfarm.com/farms</a> where now at the bottom of the <strong>Deposit</strong> section you will see the available LP tokens.</li>
        <li class="item">Select the amount you want to stake in the provided space or click <strong>MAX</strong> to use all the LP tokens you have available.</li>
        <li class="item">Click <strong>Approve</strong></li>
        <li class="item">MetaMask will pop up asking for approval. After successful approval it will then allow you to <strong>Deposit</strong>.</li>
        <li class="item">Click <strong>Deposit</strong> which will bring up MetaMask again asking for confirmation.</li>
        <li class="item">Once it is deposited it will reflect in the <strong>Withdraw</strong> section.</li>
        <li class="item">A third section appear in the details pane with the option to <strong>Harvest</strong> the SUPER you have earned from the farm. You can harvest whenever you want. You are now earning SUPER and GEMs!<br /></li>
      </ol>
    </div>

    <div class="tabPane" v-if="currentTab === 'Mobile'">
      <h2>How to farm on mobile</h2>
      <p>(Not recommended)</p>
      <p><strong>You need to go through the MetaMask browser.</strong></p>
      <ol class="list">
        <li class="item">Open the MetaMask app on your mobile device.</li>
        <li class="item">Make sure you are on <strong>Ethereum Mainnet.</strong></li>
        <li class="item">Press the <strong>☰</strong> menu button at the top left and select <strong>Browser</strong></li>
      </ol>
      <figure class="exampleImage half">
        <div><img src="https://superfarm-assets.s3.amazonaws.com/help-center/metamask.jpeg" loading="lazy" alt="" /></div>
      </figure>
      <ol start="4" class="list">
        <li class="item">Paste in the URL <a href="https://superfarm.com/farms">https://superfarm.com/farms</a></li>
        <li class="item">Follow the same procedures as described in the <strong>Computer</strong> tab above.<br /></li>
      </ol>
    </div>

    <div class="helpCenter">
      <SocialsSection />
      <RecentAnnouncements />
    </div>
  </div>
</template>
<script>
// Imports.
import { ref } from 'vue';

import DropdownItem from './components/DropdownItem.vue';
import BaseTabItem from '/src/components/ui/BaseTabItem';
import SocialsSection from '/src/components/pages/launchpad/SocialsSection.vue';
import RecentAnnouncements from '/src/components/pages/launchpad/RecentAnnouncements.vue';

export default {
  components: {
    DropdownItem,
    BaseTabItem,
    SocialsSection,
    RecentAnnouncements
  },

  setup() {
    const faqs = [
      {
        title: 'Can I Farm on another network besides Ethereum like the BSC chain?',
        description: ['No, for the moment you need to be on Ethereum network and require ERC20 tokens to gain access to the farms, we are working on integrating other chains into the platform but for the time being it is Ethereum network.']
      },
      {
        title: 'I have an ET or Genesis NFT. Can I use it’s farming boost?',
        description: ['No, NFT boosts will only be implemented in V2 Farming so for the moment they can not be used.']
      },
      {
        title: 'I don’t have a MetaMask wallet, can I use something else?',
        description: ['No, for the time being you can only use MetaMask to connect to the site.']
      },
      {
        title: 'I have my SUPER on BSC (BEP20), how do I get them onto Ethereum (ERC20)?',
        description: ['If you have a Binance account you can deposit your SUPER into your wallet and withdraw your SUPER on ERC20 back into your MetaMask wallet or you can use the Anyswap Bridge to swap your BEP20 SUPER to ERC20 SUPER.']
      },
      {
        title: 'Do staked SUPER count towards SuperStarter IDO’s?',
        description: ['In addition to amazing benefits, it will now be required to stake SUPER tokens into either SUPER or SUPER/ETH LP farms to be eligible for future SuperStarter launches. More information to come.']
      },
      {
        title: "What happens to the GEMs I don't use?",
        description: ['Unredeemed points will have additional utility in the future, so don’t worry if you can’t make use of all of them right away.']
      },
      {
        title: 'What can I use GEMs for?',
        description: ['GEMs can be used to redeem items on https://superfarm.com/gem-store for SuperFarm Genesis Series NFTs.']
      },
      {
        title: 'Are GEMs transferable between wallets?',
        description: ['No, GEMs are not transferable to different wallets.']
      },
      {
        title: 'I added more LP tokens to the ETH-SUPER farm and when I deposited the second amount, the SUPER I had earned disappeared. What happened?',
        description: ['Fear not! When you made your second deposit your earned SUPER was automatically harvested and transferred to your wallet in the same transaction, saving you gas. You can see the SUPER owed in your wallet and on your EtherScan for when you completed the deposit.']
      },
      {
        title: 'Do the Genesis NFT redeemed from the GEM store have aidrops attached?',
        description: ['Yes, they have the airdrops attached according to rarity outlined in the Genesis medium seen here medium.com/superfarm/announcing-the-superfarm-genesis-series-nft-drops-929cd7455dd7']
      },
      {
        title: 'Can I withdraw my SUPER from the farms at any time?',
        description: ['Yes, you are free to withdraw your SUPER whenever you wish!']
      }
    ];

    const tabs = ['Computer', 'Mobile'];
    const currentTab = ref('Computer');

    const setTab = (tab) => {
      currentTab.value = tab;
    };

    return {
      faqs,
      tabs,
      currentTab,
      setTab
    };
  }
};
</script>
<style scoped lang="scss">
a {
  color: var(--text-color-primary);
}
.helpCenter {
  padding: 90px 20px;
  flex: 1;
  text-align: center;

  h1 {
    margin-top: 0px;
    margin-bottom: 60px;
    font-size: 60px;
    font-weight: 600;
  }

  h2 {
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 40px;
    line-height: 1.1;
  }

  .dropdownWrapper {
    margin-bottom: 60px;
  }

  .tabs {
    position: sticky;
    top: 60px;
    display: flex;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
  }

  .tabPane {
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    text-align: left;
    margin-top: 20px;

    p {
      margin-bottom: 20px;
      font-size: 16px;
    }

    .list {
      list-style-type: numbered;
      padding-left: 30px;
      .item {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }

    .exampleImage {
      width: 100%;
      max-width: 100%;
      text-align: center;
      clear: both;
      display: block;
      margin-right: auto;
      margin-left: auto;

      img {
        width: 100%;
      }
    }

    .half {
      max-width: 60%;
    }
  }
}

@media (max-width: 600px) {
  .helpCenter .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>
