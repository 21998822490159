<template>
  <div class="details-page">
    <div class="details-scrollable-content">
      <div class="banner-container">
        <!-- <div class="banner-image-container">
          <img class="banner-image" src="superfarm-banner-genesis.png" />
        </div> -->
        <div class="creator-info-container">
          <div class="creator-info-wrapper">
            <!-- <div class="avatar-wrapper">
								<img class="avatar-image" :src="itemModal.avatarImage" >
							</div>
							<div class="creator-handle-wrapper">
								<div class="creator-handle">
									{{ itemModal.creatorHandle }}
								</div>
							</div> -->
          </div>
        </div>
        <div class="title-container">
          <h1 class="nft-title">
            {{ title }}
          </h1>
          <div class="limited-edition-container">
            <div class="limited-edition-badge">
              Limited Edition
            </div>
            <div v-if="itemType === 'onDrops'" class="limited-edition-total-wrapper">
              <div v-if="item && item.cap && item.minted" class="limited-edition-total">{{ item.cap.toNumber() * 1 }} Total</div>
            </div>
            <div v-else class="limited-edition-total-wrapper">
              <div v-if="item && item.cap && item.minted" class="limited-edition-total">{{ item.cap.sub(item.minted).toNumber() * 1 }} Remaining</div>
              <div v-if="item && item.cap && !item.minted" class="limited-edition-total">{{ item.cap.toNumber() * 1 }} Total</div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-container">
        <div class="tab-menu">
          <BaseTabItem v-for="(tab, index) in tabs" :key="index" :title="tab" :isSelected="activeTab === tab" @click="setActiveTab(tab)" class="modal-tab-item" />

          <!-- <a
            type="button"
            id="details-tab"
            class="modal-tab-btn modal-tab-link-active"
            @click="setActiveTab('details')"
            >Details</a
          >
          <a
            type="button"
            id="metadata-tab"
            class="modal-tab-btn modal-tab-link-inactive"
            @click="setActiveTab('metadata')"
            >Metadata</a
          > -->
          <!-- <a
            type="button"
            id="owner-tab"
            class="modal-tab-btn modal-tab-link-inactive"
            @click="setActiveTab('owner')"
            >Owners</a
          >-->
          <!-- <a
            type="button"
            id="history-tab"
            class="modal-tab-btn modal-tab-link-inactive"
            @click="setActiveTab('history')"
            >History</a
          > -->
        </div>
        <div class="tab-content">
          <transition name="fade" mode="out-in">
            <div v-if="item && activeTab === 'Details'" className="tab-pane" id="shop-nav-metadata">
              <CardModalDetails :metadata="item.metadata" />
            </div>
            <div v-else-if="activeTab === 'Metadata'" className="tab-pane" id="shop-nav-metadata">
              <CardModalMetadata :attributes="item.metadata.attributes" />
            </div>
            <div v-else-if="activeTab === 'Owner'" className="tab-pane" id="shop-nav-metadata">
              <CardModalOwners />
            </div>
            <div v-else-if="activeTab === 'History'" className="tab-pane" id="shop-nav-metadata">
              <CardModalHistory />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <CardModalBuyArea v-if="item && !item.id" :config="itemType" :item="item" />

    <div class="banner-container__mobile">
      <div class="banner-image-container__mobile"></div>
      <div class="title-container__mobile">
        <h1 class="nft-title">
          {{ title }}
        </h1>
        <div class="limited-edition-container">
          <div class="limited-edition-badge">
            Limited Edition
          </div>
          <div class="limited-edition-total-wrapper">
            <div v-if="item && item.cap && item.minted" class="limited-edition-total">{{ item.cap.sub(item.minted).toNumber() * 1 }} Remaining</div>
            <div v-if="item && item.cap && !item.minted" class="limited-edition-total">{{ item.cap.toNumber() * 1 }} Total</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports
import { mapActions, mapState } from 'vuex';
import { formatNumber } from '../../utility';
import { ethers } from 'ethers';
import store from '/src/store';

// Component Imports
import CardModalDetails from './card-modal-tabs/CardModalDetails';
import CardModalMetadata from './card-modal-tabs/CardModalMetadata';
import CardModalOwners from './card-modal-tabs/CardModalOwners';
import CardModalHistory from './card-modal-tabs/CardModalHistory';
import CardModalBuyArea from './card-modal-buy-area/CardModalBuyArea';
import CardModalItemButtons from './CardModalItemButtons';
import BaseTabItem from '/src/components/ui/BaseTabItem';

export default {
  components: {
    CardModalDetails,
    CardModalMetadata,
    CardModalOwners,
    CardModalHistory,
    CardModalBuyArea,
    BaseTabItem
    // CardModalItemButtons
  },

  props: {
    item: {
      Object
    },
    itemType: {
      type: String
    }
  },

  data() {
    return {
      formatNumber,
      ethers,
      tabs: ['Details', 'Metadata']
    };
  },

  methods: {
    ...mapActions({
      showWalletModal: 'alert/showWalletModal'
    }),
    ...mapActions('items', ['purchaseLaunchpadItem']),
    ...mapActions('itemModal', ['updateTab']),

    setActiveTab: function(tab) {
      store.dispatch('itemModal/updateTab', { activeTab: tab });
    },

    async purchaseItem() {
      if (!this.ethereum.canSign) {
        this.showWalletModal();
        return;
      }

      let poolId = 0; // TODO: pool selection
      let groupId = this.itemModal.item.groupId;
      let assetId = 0; // TODO: assetId selection
      let amount = 1; // TODO: eventual quantity input
      await this.purchaseLaunchpadItem({ poolId, groupId, assetId, amount });
    }
  },

  computed: {
    ...mapState({
      itemModal: (state) => Object.assign({}, state.itemModal)
    }),

    activeTab() {
      return this.itemModal.activeTab;
    },

    title() {
      // TODO: items must include a "group name" in their metadata for this purpose
      if (this.item) {
        let name = this.item.metadata.name;
        if (name.indexOf('#') > 0) {
          return name.substring(0, name.indexOf('#'));
        } else {
          return name;
        }
      } else {
        return null;
      }
    },

    cost() {
      if (this.item && this.item.prices) {
        return `${formatNumber(ethers.utils.formatEther(this.item.prices[0].price), 0)} SUPER`;
      } else {
        return null;
      }
    },

    buttonText() {
      let isInStock = this.amountRemaining > 0;
      let canPurchase = true;
      if (isInStock) {
        if (canPurchase) {
          return 'Buy Now';
        } else {
          return 'Allocated';
        }
      } else {
        return 'Sold Out';
      }
    },

    amountRemaining() {
      if (this.item) {
        return this.item.cap.sub(this.item.minted).toNumber();
      } else {
        return 0;
      }
    },

    usdCost() {
      if (this.itemModal.item) {
        let superCost = ethers.utils.formatEther(this.itemModal.item.prices[0].price);
        return `$${(this.ethPrice * superCost).toFixed(2)}`;
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.details-page {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 0 0 500px;
  background-color: var(--background-color);
  border-left: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.details-scrollable-content {
  overflow-y: scroll;
  flex: 1;
}

.banner-container__mobile {
  display: none;
}

.banner-image-container {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: #0b0224;
}

.banner-image-container__mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b0224;
}

.banner-image {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 90px;
  object-fit: contain;
}

.banner-image__mobile {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.creator-info-container {
  padding: 15px 15px 0px;
}

.creator-info-wrapper {
  display: flex;
  grid-auto-columns: 1fr;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  border-radius: 20px;
  transition: background-color 200ms ease;
  text-decoration: none;
}

.avatar-wrapper {
  width: 50px;
  height: 50px;
  margin-top: -32px;
  border-radius: 60px;
  box-shadow: 0 0 0 3px #fff;
  object-fit: cover;
}

.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 60px;
  box-shadow: 0 0 0 3px #fff;
  object-fit: cover;
}

.creator-handle-wrapper {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
}

.creator-handle {
  color: rgba(var(--text-color-rgb), 0.8);
  font-size: 16px;
  text-decoration: none;
}

.title-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
}

.title-container__mobile {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nft-title {
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 700;
}

.limited-edition-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.limited-edition-badge {
  padding: 8px 10px;
  background-color: var(--text-color);
  color: var(--background-color);
  font-weight: 500;
}

.limited-edition-total-wrapper {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--text-color);
  color: var(--text-color);
  font-weight: 500;
}

.tab-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.tab-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.modal-tab-btn {
  flex: 1;
  padding: 15px 0px;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
}

.modal-tab-item {
  flex: 1;
}

.modal-tab-link-active {
  cursor: pointer;
  border-bottom: 3px solid var(--text-color);
  background-color: transparent;
  color: var(--text-color);
  font-weight: 600;
  text-align: center;
}

.modal-tab-link-inactive {
  cursor: pointer;
  padding: 15px 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  color: rgba(var(--text-color-rgb), 0.5);
  text-align: center;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
}

.modal-tab-link-inactive:hover {
  background-color: rgba(var(--text-color-rgb), 0.1);
  color: var(--text-color);
}

.modal-tab-link-inactive:active {
  background-color: rgba(var(--text-color-rgb), 0.2);
}

.tab-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  display: block;
  overflow: hidden;
  border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.tab-pane {
  padding: 20px;
}

@media (max-width: 990px) {
  .details-page {
    overflow: inherit;
  }

  .details-scrollable-content {
    overflow-y: auto;
  }

  .details-page {
    flex-direction: column-reverse;
  }

  .banner-container {
    display: none;
  }

  .banner-container__mobile {
    display: initial;
  }
}
</style>
