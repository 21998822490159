<template>
  <div class="item__header">
    <div class="item__picture">
      <img
        class="item__img"
        :src="require(`../../../../assets/images/pools/${pool.avatar}`)"
        :alt="pool.name"
        :title="pool.name"
      />
    </div>
    <condition
      v-if="pool.condition"
      :conditionStatus="pool.condition"
    />
    <time v-else class="item__time">{{ pool.time }}</time>
  </div>
  <div class="item__info">
    <h3 class="item__title">
      {{ pool.name }}
      <span v-if="pool.token" class="item__token">
        pooltoken / {{ pool.token }}
      </span>
    </h3>
    <p v-if="pool.progress_percent" class="item__ratio">
      Ratio per {{ pool.ratio_per }} <span>eth</span>
    </p>
    <div v-if="pool.ratio_per" class="item__progress">
      <p class="item__progress-title">Progress</p>
      <div class="item__progress-bar">
        <div
          class="item__progress-level"
          :style="{ width: `${pool.progress_percent}%` }"
        ></div>
      </div>
      <p class="item__progress-inner">
        <span>{{ pool.progress_percent }}%</span>
        <span>(Min {{ pool.progress_min }}%)</span>
      </p>
    </div>
    <p class="item__total-raise">
      <span>Total Raise</span>
      <span>&#36;{{ pool.total_raise.toUpperCase() }}</span>
    </p>
  </div>
  <div class="item__footer">
    <slot name="footer-metadata"></slot>
    <p class="item__metadata">
      <span class="item__metadata-title">Access</span>
      <span class="item__metadata-value">{{ pool.access }}</span>
    </p>
  </div>
</template>

<script>
import Condition from "./condition/Condition";

export default {
  props: {
    pool: Object
  },
  components: {
    Condition
  }
};
</script>

<style src="./Card.scss" lang="scss" scoped></style>
