<template>
  <div class='actions'>
    <Button class='button' @click='onSubmit'>Save</Button>
    <Button class='button' is-text @click='onClear'>Clear</Button>
  </div>
</template>

<script>
  import Button from '@/components/ui/Button'

  export default {
    props: {
      onSubmit: {
        type: Function,
        required: true
      },
      onClear: {
        type: Function,
        required: true
      }
    },
    components: {Button}
  }
</script>

<style scoped lang='scss'>
  .actions {
    & > :first-child {
      z-index: 11;
    }

    .button {
      width: 100%;
    }
  }
</style>