<template>
  <div class='right-side'>
    <div class='trade-column'>
      <TradeBlock groupName='your'/>
      <TradeBlock groupName='their'/>

      <div class='message'>
        <label class='message-title' for='trade-offer-message'>
          Include a message with your offer:
        </label>
        <Textarea id='trade-offer-message'
                  :modelValue='message.value.value'
                  :error-message='message.errorMessage.value'
                  @update:model-value='message.handleChange'/>
      </div>

      <Button type='submit' @click='onSubmit'>Make Offer</Button>
    </div>
  </div>
</template>

<script>
  import {provide} from 'vue'
  import {useForm, useField} from 'vee-validate'

  import {currencyOptions} from '@/components/common/filters/data'
  import {Textarea} from '@/components/ui/text-fields'
  import Button from '@/components/ui/Button'
  import {object, maxString, decimal, requiredOption} from '@/utility/validation-rules'

  import TradeBlock from './TradeBlock'

  export default {
    components: {
      TradeBlock, Textarea,
      Button
    },
    setup() {
      const form = useForm({
        initialValues: {
          your: {
            amount: '',
            currency: {...currencyOptions[0]},
          },
          their: {
            amount: '',
            currency: {...currencyOptions[0]},
          },
          message: ''
        },
        validationSchema: object({
          message: maxString(5000),
          your: object({
            amount: decimal.concat(maxString(30)),
            currency: requiredOption()
          }),
          their: object({
            amount: decimal.concat(maxString(30)),
            currency: requiredOption()
          })
        })
      })

      const onSubmit = form.handleSubmit(values => {
        // TODO some logic.

        console.log(values)
      })

      const message = useField('message')

      provide('trade-form', {form})

      return {form, onSubmit, message}
    }
  }
</script>

<style scoped lang='scss'>
  .right-side {
    .side-card {
      padding: 20px;
    }

    .trade-column {
      display: grid;
      grid-auto-columns: 1fr;
      gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .message {
      &-title {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
    }
  }
</style>