import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters"

const state = {
  activities: [],
  filters: {
    global: [],
    following: [],
    myactivity: [],
    watchlist: []
  },
  isLoading: false
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
