<template>
  <div class="container">
    <p class="description">{{ metadata.description }}</p>
    <!-- TODO: uncomment this and fill in with dynamic data to populate -->
    <!-- <div class="metadata-items">
      <div class="metadata-item" v-for="item in metadataItems" :key="item.descriptor">
        <MetadataItem :descriptor="item.descriptor" :value="item.value" :hint="item.hint" />
      </div>
    </div> -->

    <div class="creator-owner-collection">
      <div class="item-creator-wrapper">
        <!-- <div class="item-creator-image">
          <img
            src="../../../images/profile-avatar-placeholder.gif"
            class="avatar-image"
          />
        </div> -->
        <!-- <div>
          <div class="creator-tag">Creator</div>
          <div class="item-creator-username">username</div>
        </div> -->
      </div>
      <div class="item-creator-wrapper">
        <div class="item-collection-image">
          <img
            src="https://assets.website-files.com/606b8a262f3b7db261ad7f08/606ca6c63caf306e7093fbed_unnamed.png"
            class="avatar-image"
          />
        </div>
        <div>
          <div class="creator-tag">Collection</div>
          <div class="item-creator-username">SuperFarm Genesis Series</div>
        </div>
      </div>
    </div>

    <!-- <div class="metadata">
      <p
        class="metadata-item"
        v-for="item in metadataItems"
        :key="item.descriptor"
      >
        <span>{{ item.value }}</span> {{ item.descriptor }}
      </p>
    </div> -->
    <!-- <ModalAccordian title="Chain Info" />
    <ModalAccordian title="Proof of Authenticity" /> -->
  </div>
</template>

<script>
// import MetadataItem from '../MetadataItem';
import ModalAccordian from '../ModalAccordian';

export default {
  components: {
    // MetadataItem,
    // ModalAccordian
  },

  props: ['metadata'],

  data() {
    return {
      metadataItems: [
        {
          descriptor: 'Access',
          value: 'SUPER',
          hint: 'sample info'
        },
        {
          descriptor: 'Issue Type',
          value: 'NFT',
          hint: 'sample info'
        },
        // {
        //   descriptor: 'Max Allocation',
        //   value: '1',
        //   hint: 'sample info'
        // },
        // {
        //   descriptor: 'Max Issue #',
        //   value: '250',
        //   hint: 'sample info'
        // }
      ]
    };
  }
};
</script>

<style scoped>
.description {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
}

.metadata-items {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.metadata {
  justify-content: center;
  flex-wrap: wrap;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-columns: auto;
  grid-template-rows: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.metadata-item {
  padding: 5px 10px;
  color: rgba(var(--text-color-rgb), 0.5);
}

.metadata-item span {
  color: rgba(var(--text-color-rgb), 1);
  font-weight: 700;
}

.accordion-header {
  padding: 15px;
  transition: 0.2s ease all;
}

.accordion-header > p {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3em;
}

.chain-info {
  border: 1px solid rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  background-clip: padding-box;
  margin-bottom: 15px;
}

.footer-item {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(113, 18, 255, 0.2);
  font-weight: 600;
  font-size: 14px;
}

.footer-item:hover {
  cursor: pointer;
}

.footer-item > img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}

.footer-section {
  margin-right: 6px;
  opacity: 0.8;
}

.footer-value {
  color: #7112ff;
}

.creator-owner-collection {
  display: grid;
  margin-bottom: 20px;
  flex-direction: column;
  justify-items: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.item-creator-wrapper {
  display: flex;
  align-items: center;
}

.item-creator-image {
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.avatar-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.creator-tag {
  opacity: 0.5;
}

.item-creator-username {
  font-weight: 600;
}

.item-collection-image {
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #fff;
}
</style>
