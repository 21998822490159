<template>
  <div class='socials-wrapper'>
    <a v-for='(link, index) in links'
       :href='link.name === "email" ? `mailto:${link.href}` : link.href'
       target='_blank'
       :class='{"social-button": true, [link.name]: true}'
       :key='index'>
      <IconButton>
        <component :is='getSocialIconByKey(link.name)'/>
      </IconButton>
    </a>
  </div>
</template>

<script>
  import IconButton from '@/components/IconButton'
  import {getSocialIconByKey} from '@/components/icons'

  export default {
    props: {
      values: {
        type: Object,
        required: true
      }
    },
    components: {IconButton},
    data() {
      return {
        links: this.convertToArray(),
        getSocialIconByKey
      }
    },
    methods: {
      convertToArray() {
        if (this.values) {
          return Object.keys(this.values).map(key => ({
            name: key,
            href: this.values[key]
          }))
        }
        return []
      }
    }
  }
</script>

<style scoped lang='scss'>
  .socials-wrapper {
    position: relative;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-button {
    display: inline-block;
    color: unset;

    .icon-button {
      color: rgba(var(--text-color-rgb), .5);
    }

    &:hover .icon-button {
      color: rgb(var(--text-color-rgb));
    }

    &.twitter:hover .icon-button {
      color: $twitterColor;
    }

    &.instagram:hover .icon-button {
      color: #e1306c;
    }

    &.facebook:hover .icon-button {
      color: $facebookColor;
    }

    &.youtube:hover .icon-button {
      color: red;
    }

    &.linkedin:hover .icon-button {
      color: #0e65c2;
    }

    &.email:hover .icon-button {
      color: $emailColor;
    }

    &.twitch:hover .icon-button {
      color: #9047ff;
    }

    &.telegram:hover .icon-button {
      color: $telegramColor;
    }

    &.discord:hover .icon-button {
      color: #7289da;
    }
  }
</style>