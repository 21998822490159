<template>
  <Ethereum :callback="initialize" />
  <CardModal />
  <div class="auctions">
    <div class="gemStore__resultsContainer">
      <div class="gemStore__itemsGrid">
        <WalletItems :items="getItems()" cardState="onAuction" />
      </div>
    </div>
  </div>
</template>

<script>
// Imports
import { mapState } from 'vuex';
import store from '../store';
import { ethers } from 'ethers';

// Component Imports
import CardModal from '/src/components/nft-detail-modal/CardModal';
import Ethereum from '/src/components/common/Ethereum';
import WalletItems from '/src/components/pages/my-items/WalletItems';

export default {
  components: {
    Ethereum,
    CardModal,
    WalletItems
  },

  data() {
    return {
      ethers,
      refreshInterval: null
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      items: state => state.items,
      farms: state => state.farms
    })
  },

  methods: {
    async initialize() {
      let userAddress = null;
      if (this.ethereum.canSign) {
        userAddress = this.ethereum.address;
      }
      await store.dispatch('farms/retrieveFullFarmInformation', userAddress, {
        root: true
      });
      await store.dispatch('items/getLaunchpadItems', { root: true });
    },

    getItems() {
      if (
        this.items.launchpadItemList.pools &&
        this.items.launchpadItemList.pools.length < 0
      ) {
        this.getItems();
      }

      let items = ['loading'];

      if (
        this.items.launchpadItemList.pools !== undefined &&
        this.items.launchpadItemList.pools.length > 0
      ) {
        items = [];
        for (let pool of this.items.launchpadItemList.pools) {
          for (let item of pool.items) {
            items.push(item);
          }
        }
      }
      return items;
    }
  }
};
</script>
<style scoped lang="scss"></style>
