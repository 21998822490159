"use strict";

// Imports.
import * as yup from "yup";

export const requiredMessage = 'required'
export const wrongFormatMessage = 'wrong format'

export const numberSchema = yup.number().transform((currentValue, originalValue) => {
    return originalValue === '' ? undefined : currentValue
}).typeError('Must be a number')

export const object = fields => yup.object().shape(fields)

// Export some predefined validation rules from yup.
export const emailRules = yup.string().max(140).email('must be a valid email');
export const passwordRules = yup.string().min(8);
export const nameRules = yup.string().max(15);
export const descRules = yup.string().max(140);
export const urlRules = yup.string().max(140).url('must be a valid URL');

const minStringMessage = min => `min ${min} characters`
const maxStringMessage = max => `max ${max} characters`

export const minNumberMessage = min => `no less than ${min || 'min'}`
export const maxNumberMessage = max => `no more than ${max || 'max'}`

export const minNumber = min => numberSchema.min(min, params => minNumberMessage(params.min))
export const maxNumber = max => numberSchema.max(max, params => maxNumberMessage(params.max))
export const minMaxNumber = (min, max) => minNumber(min).concat(maxNumber(max))

export const minString = min => yup.string().min(min, params => minStringMessage(params.min))
export const maxString = max => yup.string().max(max, params => maxStringMessage(params.max))
export const minMaxString = (min, max) => minString(min).concat(maxString(max))

export const requiredNumber = numberSchema.required(requiredMessage)

export const yupRef = key => yup.ref(key)

export const decimal = yup.string().matches(/^[0-9]\d*(\.(\d{1,18}))?$/, {message: wrongFormatMessage, excludeEmptyString: true})

export const requiredOption = (message = requiredMessage) => yup.mixed().test({name: 'optionTest', message, test: (value) => value?.value})