<template>
  <section class="tabs">
    <div class="tabs__tabs-container">
      <template v-for="tab in tabs" :key="tab">
        <BaseTabItem
          @click="currentTab = tab"
          :title="tab"
          :isSelected="currentTab === tab"
          class="tabs__tab"
          :class="{tabs__tab_selected: currentTab === tab}"
          bgColor="lightViolet"
        />
      </template>
    </div>
    <transition-group name="fade" @enter="enter">
        <div
          class="tabs__tab-content"
          v-show="currentTab === tab"
          v-for="(tab, i) in tabs"
          :key="i"
        >
          <slot :name="`tab-${i + 1}`"></slot>
        </div>
    </transition-group>
  </section>
</template>

<script>
import BaseTabItem from "@/components/ui/BaseTabItem";

export default {
  props: {
    tabs: Array
  },
  components: {
    BaseTabItem
  },
  data() {
    return {
      currentTab: this.tabs[0]
    };
  },
  methods: {
    enter(el) {
      el.style.display = "none"
      setTimeout(() => {
        el.style.display = "block"
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.fade {
  &-enter-to {
    opacity: 0;
  }
}

.tabs {
  &__tabs-container {
    display: flex;
    border-bottom: 1px solid $lightViolet;
    position: relative;
  }
  &__tab {
    padding: 22px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: -3px;
    color: #00000080;
    height: 100%;
    white-space: initial;
    text-align: left;

    &_selected {
      color: #000000cc;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
  &__tab-content {
    transition: opacity 200ms;
  }
}
</style>
