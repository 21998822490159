<template>
  <FilterHeader :onClear='onClear'/>

  <ModalBody>
    <FiltersList v-model='values'/>
  </ModalBody>

  <ModalFooter>
    <Button class='accept-action' @click='onSubmit'>View 15 Items</Button>
  </ModalFooter>
</template>

<script>
  import {inject} from 'vue'
  import {mapActions} from 'vuex'

  import {FilterHeader, ModalBody, ModalFooter} from '@/components/common/modal'
  import Button from '@/components/ui/Button'

  import FiltersList from './FiltersList'

  export default {
    components: {
      FilterHeader,
      ModalBody, ModalFooter,
      Button, FiltersList
    },
    props: {
      initialValues: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        values: this.initialValues
      }
    },
    methods: {
      ...mapActions('notifications', ['changeFilter']),
      onSubmit() {
        this.close()
        this.changeFilter(this.values)
      },
      onClear() {
        this.values = []
      }
    },
    setup() {
      const {close} = inject('filterContainer', {
        close: () => {}
      })
      return {close}
    }
  }
</script>

<style scoped lang='scss'>
  :deep .list {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150fr));
    grid-template-rows: auto;

    @include media-max-width($mediumScreen) {
      padding: 10px;
    }
  }
</style>
