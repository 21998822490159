<template>
  <div class='profile-stats'>
    <div class='stat-wrapper'>
      <div class='stat'>{{formattedValue(data?.totalNFTItems)}}</div>
      <div class='stat-type'>items</div>
    </div>
    <div class='stat-wrapper'>
      <div class='stat'>{{formattedValue(data?.totalFollowers)}}</div>
      <div class='stat-type'>followers</div>
    </div>
    <div v-if='isHasOwners'
         class='stat-wrapper'>
      <div class='stat'>{{formattedValue(data?.totalOwners)}}</div>
      <div class='stat-type'>owners</div>
    </div>
    <div class='stat-wrapper'>
      <div class='stat'>{{formattedValue(data?.avgSaleAmount)}} ETH</div>
      <div class='stat-type'>avg. sale</div>
    </div>
    <div class='stat-wrapper'>
      <div class='stat'>{{formattedValue(data?.totalSaleAmount)}} ETH</div>
      <div class='stat-type'>traded</div>
    </div>
  </div>
</template>

<script>
  import {abbreviateNumber} from '@/utility/format'

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      isHasOwners() {
        return this.data?.totalOwners !== undefined
      }
    },
    methods: {
      formattedValue(value) {
        return abbreviateNumber(value || 0)
      }
    }
  }
</script>

<style scoped lang='scss'>
  .profile-stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .stat-wrapper {
    display: flex;
    padding: 5px 10px;

    .stat {
      margin-right: 5px;
      font-weight: 700;
    }

    .stat-type {
      opacity: .5;
    }
  }
</style>