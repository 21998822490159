"use strict";

// Import our module information.
import actions from "./actions";
import mutations from "./mutations";

// Specify state for this module.
const state = {
  status: {},
  itemList: [],
  presaleItemList: [],

  ownedItems: [],

  launchpadItemList: []
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
