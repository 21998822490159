<template>
  <div :class='["field-wrapper", !!errorMessage ? "error" : undefined, variant]'>
    <div :class='{"field-inner": true, "with-icon": hasIcon}'>
      <div v-if='hasIcon' class='icon'>
        <slot name='inputIcon'/>
      </div>
      <input class='field'
             :class='{"is-dark-mode": isDarkMode}'
             :type='type'
             :placeholder='placeholder'
             :value='value'
             @input='onChange'
             @keypress='onKeyPress'>
    </div>
    <ErrorMessage v-if='isErrorMessageVisible && !!errorMessage'>{{errorMessage}}</ErrorMessage>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  import ErrorMessage from './ErrorMessage'

  export default {
    components: {ErrorMessage},
    props: {
      value: {
        type: String,
        required: false
      },
      onChange: {
        type: Function,
        required: false
      },
      onKeyPress: {
        type: Function,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      type: {
        type: String,
        default: 'text',
        required: false
      },
      errorMessage: {
        type: String,
        required: false
      },
      isErrorMessageVisible: {
        type: Boolean,
        required: false,
        default: true
      },
      variant: {
        type: String,
        required: false,
        default: 'default'
      }
    },
    computed: {
      ...mapGetters('theme', ['isDarkMode']),
      hasIcon() {
        return !!this.$slots.inputIcon
      }
    }
  }
</script>

<style scoped lang='scss'>
  .field-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    &.error {
      .field {
        border-color: $errorRed;
      }
    }

    &.default {
      .field {
        &:hover {
          border-color: rgba(var(--text-color-rgb), 0.5);
        }

        &:focus {
          border-width: 2px;
          border-color: var(--text-color);
        }
      }
    }

    &.native {
      .field {
        border-radius: 0;

        &:focus {
          border-color: $blue;
        }
      }
    }
  }

  .field-inner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.with-icon {
      .field {
        padding-left: 40px;
        padding-right: 20px;
      }

      .icon {
        position: absolute;
        left: 10px;
        display: flex;
      }
    }
  }

  .field {
    outline: none;
    padding: 8px 12px;
    border: 1px solid rgba(var(--text-color-rgb), 0.2);
    font-size: 14px;
    line-height: 1.42;
    height: 40px;
    border-radius: 24px;
    background-color: $white;
    color: var(--text-color);
    transition: 200ms cubic-bezier(.215, .61, .355, 1);
    width: 100%;

    &.is-dark-mode {
      background-color: transparent;
    }

    &::placeholder {
      opacity: 1;
      color: rgba(var(--text-color-rgb), 0.5);
    }

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
</style>