<template>
  <SplashPage />
  <DropsContainer />
  <CardModal />
  <!-- <FeaturedDropsPage /> -->
  <!-- <DropAnnouncements
    :title="featuredDropsMockData.title"
    :drops="featuredDropsMockData.drops"
    :fullPage="featuredDropsMockData.fullPage"
  /> -->
  <!-- <RecentAnnouncements
    :header="articlesMockData.title"
    :articles="articlesMockData.articles"
  /> -->
  <!-- TODO: click-away from modal -->
  <AboutUs />
  <!-- <NewsletterForm /> -->
</template>

<script>
// Component imports.
import SplashPage from '../SplashPage';
// import RecentAnnouncements from '../../components/pages/launchpad/RecentAnnouncements';

import DropsContainer from '../../components/pages/launchpad/DropsContainer';
import AboutUs from '../../components/pages/launchpad/AboutUs';
import CardModal from '../../components/nft-detail-modal/CardModal';

// Set up the component.
export default {
  components: {
    SplashPage,
    DropsContainer,
    CardModal,
    // FeaturedDropsPage,
    // DropAnnouncements,
    // RecentAnnouncements,
    // NewsletterForm,
    AboutUs
  },

  mounted() {
    document.title =
      'SuperFarm - NFT Platform where you can Buy, Create and Sell Crypto NFTs';
  },

  data() {
    return {
      featuredDropsMockData: {
        title: '🔥 FEATURED UPCOMING DROPS',
        // to update drops just change info here
        drops: [
          {
            date: new Date(2021, 2, 31),
            title: 'SuperFarm Genesis Series: Day 1 Hype Lord',
            author: '@SuperFarm',
            img: 'hype_lord.jpg'
          },
          {
            date: new Date(2021, 3, 1),
            title: 'SuperFarm Genesis Series: Day 2 Yield Master',
            author: '@SuperFarm',
            img: 'yield_master.jpg'
          },
          {
            date: new Date(2021, 3, 2),
            title: 'SuperFarm Genesis Series: Day 3 Pool Shark',
            author: '@SuperFarm',
            img: 'pool_shark.jpg'
          },
          {
            date: new Date(2021, 3, 3),
            title: 'SuperFarm Genesis Series: Day 4 Lunar Prophet',
            author: '@SuperFarm',
            img: 'lunar_prophet.jpg'
          },
          {
            date: new Date(2021, 3, 4),
            title: 'SuperFarm Genesis Series: Day 5 Cyber Chef',
            author: '@SuperFarm',
            img: 'cyber_chef.jpg'
          }
        ],
        fullPage: {
          label: 'View all upcoming drops →',
          link: ''
        }
      },
      articlesMockData: {
        title: '📢 RECENT ANNOUNCEMENTS',
        // to update articles just change info here
        articles: [
          {
            title: 'Announcing the SuperFarm Genesis Series NFT Drops',
            preview:
              'The first NFT Drops on the SuperFarm Launchpad are aptly named the Genesis Series. These NFTs are packed with utility across the SUPERVERSE, and will entitle their holders to unique privileges and unlock new experiences.',
            img:
              'https://miro.medium.com/max/3200/1*6VOEQevI8i8HrJh2TcvCXA.jpeg',
            url:
              'https://medium.com/superfarm/announcing-the-superfarm-genesis-series-nft-drops-929cd7455dd7'
          },
          {
            title: 'SuperFarm Platform Launch — Phase 1',
            preview:
              'It all begins March 31 with the first-ever NFT drop on the SuperFarm protocol! This is the first glimpse that the public will have of the next-generation SuperFarm platform.',
            img:
              'https://miro.medium.com/max/3840/1*YRGVhDLunjk8s0HgsV5-tQ.png',
            url:
              'https://medium.com/superfarm/superfarm-platform-launch-phase-1-2aca70dcfb25'
          },
          {
            title: 'EllioTrades CryptoPunk Collection SUPER Airdrop details',
            preview:
              'The snapshot of NFT holder addresses for this first drop will take place at block 12,087,850 on March 22, 2021. The airdrop will consist of 10% of the total coins allotted to that NFT, as detailed below.',
            img:
              'https://miro.medium.com/max/2000/1*ufo0nYcFoniAAtl5rQxhZA.png',
            url:
              'https://medium.com/superfarm/elliotrades-nft-collection-airdrop-details-236ebb394f7a'
          }
        ]
      }
    };
  }
};
</script>
