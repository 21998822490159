<template>
  <div class="drop-section">
    <!-- TODO: video here -->
    <div class="backdrop"></div>
    <video autoplay loop muted playsinline="" data-object-fit="cover" class="video-container">
      <source :src="require(`@/images/dropday/${drop.bannerImage}`)" />
    </video>

    <div class="drop-info-wrapper">
      <div class="drop-banner-creator">{{ drop.username }}</div>
      <div class="drop-banner-title">{{ drop.title }}</div>

      <div v-if="isLive">
        <LiveNowTimer :date="drop.endDate" />
      </div>
      <div v-else class="drop-countdown-wrapper">
        <DropsCountdownCard :startDate="drop.startDate" :endDate="drop.endDate" />
      </div>

      <div class="view-drop" @click="navigateToDetailsPage(drop.id)">View Drop</div>
    </div>
  </div>
</template>

<script>
import DropsCountdownCard from './DropsCountdownCard';
import LiveNowTimer from './LiveNowTimer';
import router from '../../../router';

export default {
  components: {
    DropsCountdownCard,
    LiveNowTimer
  },

  props: {
    drop: {
      type: Object
    }
  },

  computed: {
    isLive() {
      const now = new Date();
      return this.drop.startDate < now;
    }
  },

  methods: {
    navigateToDetailsPage(id) {
      router.push({
        name: 'Drop Details',
        params: { drop: id }
      });
    }
  }
};
</script>

<style scoped>
.drop-section {
  display: flex;
  height: auto;
  min-height: 500px;
  margin-bottom: 1px;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  color: #fff;
  text-align: center;
  position: relative;
}

.drop-info-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.drop-banner-creator {
  margin-bottom: 10px;
  opacity: 0.5;
  font-size: 30px;
  line-height: 1em;
}

.drop-banner-title {
  margin-bottom: 20px;
  font-size: 70px;
  line-height: 1em;
  font-weight: 500;
}

.view-drop {
  border: 2px solid #fff;
  background-color: transparent;
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1), background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
}

.view-drop:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.drop-countdown-wrapper {
  margin-bottom: 20px;
}

.video-container {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  object-position: 0px 15%;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
</style>
