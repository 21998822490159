<template>
  <div class="container">
    <div class="tabs">
      <SegmentedTabs :items="tabs" @tabUpdated="setTab" />
    </div>
    <div class="tabs-pane">
      <transition name="fade" appear="" mode="out-in">
        <div v-if="currentTab === 'Offers Received'">
          <div v-for="offer in mockOffersReceived()" :key="offer">
            <WatchlistItem :nft="offer" />
          </div>
        </div>
        <div v-else>
          <div v-for="offer in mockOffersMade()" :key="offer">
            <ActivityItem :nft="offer" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// Component Imports
import ActivityItem from '../activity/ActivityItem';
import WatchlistItem from '../watchlist/WatchlistItem';
import SegmentedTabs from '/src/components/ui/SegmentedTabs';

export default {
  components: {
    ActivityItem,
    WatchlistItem,
    SegmentedTabs
  },

  data() {
    return {
      tabs: ['Offers Received', 'Offers Made'],
      currentTab: 'Offers Received'
    };
  },

  methods: {
    setTab(tab) {
      this.currentTab = tab;
    },

    mockOffersReceived() {
      return [
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'received offer',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'received offer',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'received offer',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        }
      ];
    },

    mockOffersMade() {
      return [
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'offered',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'offered',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'offered',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date()
          }
        }
      ];
    }
  }
};
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 20px auto;
}

.tab {
  min-width: 40vw;
}
</style>
