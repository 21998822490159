<template>
  <div class="cardModalDetail__container">
    <div class="cardModalDetail__headerWrapper">
      <div class="cardModalDetail__header">
        <button class="cardModalDetail__backButton" @click="close">
          <BackArrowIcon :color="'#aaa'" />
        </button>
        <p class="cardModalDetail__headerText">{{ item.metadata.name }}</p>
      </div>
      <div class="cardModalDetail__zoomContainer">
        <button class="cardModalDetail__zoomIncrement end" @click="zoomOut">
          -
        </button>
        <input
          class="cardModalDetail__rangeSlider"
          type="range"
          min="1"
          max="100"
          v-model="zoomLevel"
        />
        <button class="cardModalDetail__zoomIncrement" @click="zoomIn">
          +
        </button>
      </div>
    </div>

    <v-zoomer
      class="cardModalDetail__mediaContainer"
      :mouseWheelToZoom="false"
      :doubleClickToZoom="false"
      :maxScale="6"
      ref="zoomer"
    >
      <img
        v-if="mediaType === 'png' || mediaType === 'jpg'"
        :src="item.metadata.image"
        class="video"
      />
      <video
        class="video"
        v-if="mediaType === 'mp4'"
        autoplay
        loop
        muted
        :key="item.metadata.video"
      >
        <!-- TODO support animation_url as fallback to video. -->
        <!-- TODO: no video on card preview -->
        <source :src="item.metadata.video" :type="`video/${mediaType}`" />
      </video>
    </v-zoomer>
  </div>
</template>

<script>
import BackArrowIcon from '/src/components/icons/BackArrowIcon';

export default {
  components: { BackArrowIcon },
  emits: ['closeDetails'],

  props: ['item'],

  data() {
    return {
      zoomLevel: 1
    };
  },

  watch: {
    zoomLevel(newZoomLevel, oldZoomLevel) {
      this.$refs.zoomer.reset();
      const scale = 5 * (newZoomLevel / 100) + 1;
      if (newZoomLevel > oldZoomLevel) {
        this.$refs.zoomer.zoomIn(scale);
      } else {
        this.$refs.zoomer.zoomOut(scale);
      }
    }
  },

  computed: {
    mediaType() {
      let video = this.item.metadata.video;
      let animationUrl = this.item.metadata['animation_url'];
      let image = this.item.metadata.image;
      if (video) {
        let videoPath = this.item.metadata.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.item.metadata['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.item.metadata.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    }
  },

  methods: {
    close() {
      this.$emit('closeDetails');
    },

    zoomIn() {
      if (this.zoomLevel < 80) {
        this.zoomLevel = parseInt(this.zoomLevel) + 20;
      } else {
        this.zoomLevel = 100;
      }
    },

    zoomOut() {
      if (this.zoomLevel > 20) {
        this.zoomLevel = parseInt(this.zoomLevel) - 20;
      } else {
        this.zoomLevel = 1;
      }
    }
  }
};
</script>

<style scoped>
.cardModalDetail__container {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  z-index: 9999;
  position: fixed;
  overflow: hidden;
}
.cardModalDetail__backButton {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 200ms ease;
}

.cardModalDetail__backButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.cardModalDetail__backButton:active {
  transform: scale(0.95);
}

.cardModalDetail__headerWrapper {
  position: sticky;
  z-index: 1;
  top: 0px;
  display: grid;
  height: 60px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #333;
  width: 100%;
}

.cardModalDetail__header {
  display: flex;
  align-items: center;
}

.cardModalDetail__headerText {
  color: rgba(255, 255, 255, 0.9);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
}

.cardModalDetail__mediaContainer {
  height: calc(100vh - 60px);
  margin-top: 60px;
  width: 100%;
  background-color: #333 !important;
  position: inherit;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  scrollbar-width: none; /* Firefox 64 */
}

.video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: auto;
  cursor: grab;
  /* transform: scale(2, 2); */
  /* object-fit: cover; */
}

.video:active {
  cursor: grabbing;
}

.cardModalDetail__zoomContainer {
  display: flex;
  align-items: center;
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  color: lightgray;
}

.cardModalDetail__zoomIncrement {
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease;
}

.cardModalDetail__zoomIncrement:hover {
  color: white;
}

.cardModalDetail__zoomIncrement:active {
  transform: scale(0.9);
}

.cardModalDetail__zoomBar {
  position: relative;
  display: flex;
  width: 100px;
  height: 4px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
}

.cardModalDetail__zoomHandle {
  position: absolute;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: grab;
}

.cardModalDetail__rangeSlider {
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
  max-width: 150px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  margin-left: 10px;
  outline: none;
}

.cardModalDetail__rangeSlider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: white;
  transition: all 0.2s ease;
}

.cardModalDetail__rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s ease;
}

.cardModalDetail__rangeSlider::-webkit-slider-thumb:hover {
  box-shadow: 0px 0px 20px 4px black;
}

.cardModalDetail__rangeSlider::-moz-range-thumb:hover {
  box-shadow: 0px 0px 20px 4px black;
}

.cardModalDetail__rangeSlider::-webkit-slider-thumb:active {
  transform: scale(0.9);
}

.cardModalDetail__rangeSlider::-moz-range-thumb:active {
  transform: scale(0.9);
}
</style>
