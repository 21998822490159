<template>
  <svg
    :style="iconStyle"
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.40474H2.47619V0.595215H0V7.40474ZM11.7619 4.3095H7.42857L6.13476 3.8576L6.33905 3.27569L7.42857 3.69045H9.17429C9.57667 3.69045 9.90476 3.36236 9.90476 2.95998C9.90476 2.65664 9.71286 2.38426 9.42809 2.27283L4.93381 0.595215H3.71429V6.17902L8.04762 7.40474L13 5.5476C12.9938 4.86664 12.449 4.3095 11.7619 4.3095Z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    isFlipped: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    iconStyle() {
      return this.isFlipped
        ? {
            transform: 'scaleX(-1)'
          }
        : {};
    }
  }
};
</script>
