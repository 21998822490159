<template>
  <div v-if="!hideDate" class="drop-banner-date" :style="smallFont ? { fontSize: '16px' } : {}">
    {{ fullDateLabel }}
  </div>
  <div v-if="!showSeconds && !isLive && !isEnded">
    <Skeletor class="countdown" width="250" height="50" pill />
  </div>
  <div v-if="isUpcoming && showSeconds" class="countdown" :style="borderTheme">
    <div class="countdown-grid" :style="gridColumns">
      <div v-if="showDays" class="time-block">
        <div class="time-value">{{ days }}</div>
        <div class="time-division">{{ daysLabel }}</div>
      </div>
      <div v-if="showHours" class="time-block">
        <div class="time-value">{{ hours }}</div>
        <div class="time-division">{{ hoursLabel }}</div>
      </div>
      <div v-if="showMinutes" class="time-block">
        <div class="time-value">{{ minutes }}</div>
        <div class="time-division">{{ minutesLabel }}</div>
      </div>
      <div v-if="showSeconds" class="time-block">
        <div class="time-value">{{ seconds }}</div>
        <div class="time-division">{{ secondsLabel }}</div>
      </div>
    </div>
  </div>
  <div class="ended live" v-else-if="isLive">
    Live Now
  </div>
  <div class="ended" v-else-if="isEnded">
    Ended
  </div>
</template>

<script>
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';
export default {
  components: {
    Skeletor
  },

  props: {
    startDate: {
      type: Date
    },

    endDate: {
      type: Date
    },

    hideDate: {
      type: Boolean,
      default: false,
      required: false
    },

    isLight: {
      type: Boolean,
      default: false,
      required: false
    },

    smallFont: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      now: new Date(),
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      distance: ''
    };
  },

  mounted() {
    let x = setInterval(() => {
      this.now = new Date();

      // Find the distance between now and the count down date
      if (this.startDate) {
        this.distance = this.startDate.getTime() - new Date().getTime();

        // Time calculations for days, hours, minutes and seconds
        this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

        // If the count down is finished
        if (this.distance < 0) {
          clearInterval(x);
        }
      }
    }, 1000);
  },

  computed: {
    daysLabel() {
      return this.days !== 1 ? 'days' : 'day';
    },

    hoursLabel() {
      return this.hours !== 1 ? 'hours' : 'hour';
    },

    minutesLabel() {
      return this.minutes !== 1 ? 'minutes' : 'minute';
    },

    secondsLabel() {
      return this.seconds !== 1 ? 'seconds' : 'second';
    },

    showDays() {
      return this.distance > 1000 * 60 * 60 * 24;
    },

    showHours() {
      return this.distance > 1000 * 60 * 60;
    },

    showMinutes() {
      return this.distance > 1000 * 60;
    },

    showSeconds() {
      return this.distance > 1000;
    },

    gridColumns() {
      if (this.distance > 1000 * 60 * 60 * 24) {
        return { gridTemplateColumns: 'repeat(4, auto)' };
      } else if (this.distance > 1000 * 60 * 60) {
        return { gridTemplateColumns: 'repeat(3, auto)' };
      } else if (this.distance > 1000 * 60) {
        return { gridTemplateColumns: 'repeat(2, auto)' };
      } else {
        return { gridTemplateColumns: 'repeat(1, auto)' };
      }
    },

    fullDateLabel() {
      const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        timeZoneName: 'short'
      };

      // options.timeZone = 'PST';
      // options.timeZoneName = 'short';
      return this.startDate ? this.startDate.toLocaleDateString(undefined, options) : '';
    },

    borderTheme() {
      if (this.isLight) {
        return {
          backgroundColor: 'transparent',
          borderColor: 'rgba(var(--text-color-rgb), 0.2)'
        };
      } else {
        return {};
      }
    },

    isLive() {
      return this.now > this.startDate && this.endDate > this.now;
    },

    isEnded() {
      const now = new Date();
      return this.endDate < now;
    },

    isUpcoming() {
      const now = new Date();
      return now < this.startDate;
    }
  }
};
</script>

<style scoped>
.countdown {
  border-color: rgba(var(--text-color-rgb), 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  padding: 10px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  text-align: left;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.countdown-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
}

.time-value {
  font-size: 20px;
  font-weight: 600;
}

.time-block {
  text-align: center;
}

.drop-banner-date {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 1em;
  text-align: center;
}

.ended {
  padding: 8px 12px;
  background-color: white;
  color: black;
  margin-bottom: 20px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.live {
  background-color: #f12848;
  color: white;
}
</style>
