<template>
  <Skeletor v-if="isLoading" style="borderRadius: 24px; height: 100%" />
  <div class="info-card" v-else>
    <div class="info-card__header">
      <div class="info-card__header-title">Swap Amount</div>
      <div class="info-card__header-course">{{ pool.common_info.swap_amount }}</div>
    </div>
    <div class="info-card__token-amount-wrapper">
      <div class="info-card__token-amount">{{ pool.common_info.token }}.00 TOKEN</div>
      <div class="info-card__eth-amount">{{ pool.common_info.eth }} ETH</div>
    </div>
    <div class="info-card__closes">Closes in</div>
    <div class="info-card__endtime">Ended</div>
    <div class="info-card__progress-wrapper progress">
      <div class="progress__header">Swap Progress</div>
      <div class="progress__progress-bar">
        <div class="progress__progress-bar_fill" :style="{width: `${pool.common_info.progress_percent}%`}"></div>
      </div>
      <div class="progress__detail-wrapper">
        <div class="progress__percent-wrapper">
          <div class="progress__percent">{{pool.common_info.progress_percent}}%</div>
          <div class="progress__min-percent">(Min {{pool.common_info.progress_min}}%)</div>
        </div>
        <div class="progress__total-pool">{{ pool.common_info.current_value }}/{{ pool.common_info.total_value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  components: {
    Skeletor
  },
  props: {
    isLoading: Boolean,
    pool: Object
  }
};
</script>

<style src="./SwapCard.scss" lang="scss" scoped></style>
