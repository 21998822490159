import {socialService} from '@/services'

export default {
     getCollection: async ({state, commit}, id) => {
         if (!!state.collection) {
             commit('setCollection', null)
         }

         commit('setLoading', true)

         try {
             const response = await socialService.Collections.getDetails(id)

             if (response) {
                 commit('setCollection', response)
             }
         } finally {
             commit('setLoading', false)
         }
    },
    toggleFollowing: async ({state, commit}, id) => {
         const {Collections} = socialService

         if (state.collection) {
             commit('setFollowLoading', true)

             try {
                 state.collection.isFollowed ? await Collections.unFollow(id) : await Collections.follow(id)

                 commit('setFollowState', !state.collection.isFollowed)
             } finally {
                 commit('setFollowLoading', false)
             }
         }
    }
}