<template>
  <div class="metadata-container">
    <div class="metadata-item">
      <div class="metadata-descriptor">{{ descriptor }}</div>
      <div class="metadata-value">{{ value }}</div>
      <div v-if="hint" class="hint">
        <div>{{ hint }}</div>
      </div>
    </div>
  </div>
</template>

<script>
"use strict";

// Set up the default component.
export default {
  props: {
    descriptor: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
.metadata-container {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.metadata-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 20px;
}

.metadata-descriptor {
  margin-right: 6px;
  font-weight: 600;
}

.metadata-value {
  color: #7112ff;
  font-weight: 600;
  font-size: 14px;
}

.hint {
  position: absolute;
  left: 0px;
  bottom: 43px;
  visibility: hidden;
  width: 240px;
  max-width: 90vw;
  height: fit-content;
  padding: 15px;
  border-radius: 24px;
  background-color: rgba(41, 41, 41, 0.9);
  backdrop-filter: blur(15px);
  color: #fff;
  z-index: 99;
}

.metadata-item:hover .hint {
  visibility: visible;
}

.metadata-item:hover {
  cursor: default;
}

@media (max-width: 650px) {
  .metadata-descriptor,
  .metadata-value {
    font-size: 2vw;
  }

  .metadata-descriptor {
    margin-right: 1vw;
  }

  .metadata-item {
    padding: 1vw 1.5vw;
  }
}
</style>
