<template>
  <div>
    <FromToFields :names='{from: "from", to: "to"}' :is-show-errors='isShowErrors'>
      <FromToActions :on-submit='onSubmit' :on-clear='handleClear'/>
    </FromToFields>
  </div>
</template>

<script>
  import {useForm} from 'vee-validate'

  import FromToFields from './FromToFields'
  import FromToActions from './FromToActions'
  import {fromToValidationSchema} from './validation'

  export default {
    components: {FromToFields, FromToActions},
    props: {
      values: Object,
      onSave: Function,
      onClear: Function,
      minMaxValues: {
        type: Object,
        required: true
      },
      isShowErrors: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      handleClear() {
        this.form.resetForm({
          values: {to: '', from: ''}
        })
        this.onClear?.()
      }
    },
    setup(props) {
      const form = useForm({
        initialValues: {
          from: props.values.from,
          to: props.values.to
        },
        validationSchema: fromToValidationSchema(props.minMaxValues.min, props.minMaxValues.max)
      })

      const onSubmit = form.handleSubmit(props.onSave)

      return {form, onSubmit}
    }
  }
</script>