<template>
  <div class="about">
    <h1>About SuperFarm</h1>
    <p>
      SuperFarm is bringing the benefits of nonfungible item farming to the
      masses. For gamers and collectors, SuperFarm provides a new way to farm
      and obtain unique items. For artists, influencers, and content creators,
      SuperFarm offers new avenues for monetizing communities. All of this
      driven by a platform where anybody, no matter their level of crypto
      comfort, can create their own farms today.
    </p>
  </div>
</template>
