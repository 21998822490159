"use strict";

// Imports.
import axios from "axios";
import initializeConfig from "../initialize-config";
import { authHeader } from "../utility";
import { ethersService } from "./index";

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Issue the request to deploy a new token to Ethereum.
const deploy = function (name, ticker) {
  return axios.post(
    `${config.apiUrl}/token`,
    { name: name, ticker: ticker },
    { headers: authHeader }
  );
};

// Deploy the token using the local Ethers provider.
const deployLocally = async function (name, ticker, cap, directMints) {
  let wallet = await ethersService.getWallet();
  let recordContractAddress =
    config.tokenRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(
    recordContractAddress,
    config.tokenRecordABI,
    wallet
  );

  // Create a new Token via the farm records contract.
  console.log(`Creating token in: ${recordContractAddress} ...`);
  let createTokenTransaction = await recordContract.createToken(
    name,
    ticker,
    ethersService.parseEther("1").mul(cap),
    directMints.map(o => o.address),
    directMints.map(o => ethersService.parseEther("1").mul(o.tokens))
  );
  let tokenCreatedReceipt = await createTokenTransaction.wait();
  let tokenCreatedEvent =
    tokenCreatedReceipt.events[tokenCreatedReceipt.events.length - 1];
  let newTokenAddress = tokenCreatedEvent.args[0];
  console.log(
    `... token created in transaction: ${createTokenTransaction.hash}.`
  );

  // Return the same format that we do for the remote server-created contracts.
  return {
    status: "ok",
    token: {
      address: newTokenAddress
    }
  };
};

// Fetch all tokens created by a particular user.
const getAllTokens = async function () {
  let wallet = await ethersService.getWallet();
  let walletAddress = await ethersService.getWalletAddress();
  let recordContractAddress =
    config.tokenRecordContractAddress[ethersService.getNetworkId()];
  let recordContract = ethersService.connectToContract(
    recordContractAddress,
    config.tokenRecordABI,
    wallet
  );

  let tokenCount = await recordContract.getTokenCount(walletAddress);
  let tokens = [];
  for (let i = 0; i < tokenCount; i++) {
    let token = await recordContract.tokenRecords(walletAddress, i);
    let tokenContract = ethersService.connectToContract(
      token,
      config.tokenABI,
      wallet
    );
    let tokenName = await tokenContract.name();
    let tokenSymbol = await tokenContract.symbol();
    tokens.push({
      address: token,
      name: tokenName,
      symbol: tokenSymbol
    });
  }
  return tokens;
};

// Return whether or not the contract at the specified address is a Token.
const isValidToken = async function (token) {
  let wallet = await ethersService.getWallet();
  let tokenContract = ethersService.connectToContract(
    token,
    config.tokenABI,
    wallet
  );
  try {
    await tokenContract.name();
    await tokenContract.symbol();
    await tokenContract.decimals();
    return true;
  } catch (error) {
    return false;
  }
};

// Return details about a particular token.
const getTokenDetails = async function (token) {
  let wallet = await ethersService.getWallet();
  let tokenContract = ethersService.connectToContract(
    token,
    config.tokenABI,
    wallet
  );
  let tokenCap;
  try {
    tokenCap = await tokenContract.cap();
  } catch (error) {
    tokenCap = null;
  }
  return {
    name: await tokenContract.name(),
    symbol: await tokenContract.symbol(),
    decimals: await tokenContract.decimals(),
    totalSupply: await tokenContract.totalSupply(),
    cap: tokenCap,
    owner: await tokenContract.owner()
  };
};

// Return the balance of a particular token for a given user.
const getBalance = async function (token, user) {
  let wallet = await ethersService.getWallet();
  let tokenContract = await ethersService.connectToContract(
    token,
    config.tokenABI,
    wallet
  );
  let userBalance = await tokenContract.balanceOf(user);
  return userBalance;
};

// Export the token service functions.
export const tokenService = {
  deploy,
  deployLocally,
  getAllTokens,

  isValidToken,
  getTokenDetails,
  getBalance
};
