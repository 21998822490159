<template>
  <Tabs :modelValue='activeTab'
        sticky-offset='60px'
        @update:modelValue='changeActiveTab'>
    <Tab v-for='(item, index) in items'
         :title='item'
         :key='index'/>
  </Tabs>
  <div class='tabs-content'>
    <slot :activeTab='activeTab'/>
  </div>
</template>

<script>
  import {Tabs, Tab} from './'

  export default {
    components: {Tabs, Tab},
    props: {
      items: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data() {
      return {
        activeTab: 0
      }
    },
    methods: {
      changeActiveTab(value) {
        if (value !== this.activeTab) {
          this.$router.push({
            path: this.$route.path,
            query: {...this.$route.query, tab: value}
          })
        }
      },
      setActiveTab() {
        const foundTabValue = () => {
          const tabValue = Number(this.$route.query?.tab)
          if (!tabValue || tabValue > (this.items.length - 1)) {
            return 0
          }
          return tabValue
        }
        this.activeTab = foundTabValue()
      }
    },
    watch: {
      $route() {
        this.setActiveTab()
      }
    },
    created() {
      this.setActiveTab()
    }
  }
</script>

<style scoped lang='scss'>
  .tabs-content {
    padding-top: 20px;
  }
</style>
