<template>
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17604 0.666504H1.59714C0.902403 0.666504 0.333984 1.23492 0.333984 1.92966V10.7718H1.59714V1.92966H9.17604V0.666504ZM11.0708 3.19281H4.12344C3.42871 3.19281 2.86029 3.76123 2.86029 4.45596V13.298C2.86029 13.9928 3.42871 14.5612 4.12344 14.5612H11.0708C11.7656 14.5612 12.334 13.9928 12.334 13.298V4.45596C12.334 3.76123 11.7656 3.19281 11.0708 3.19281ZM11.0708 13.298H4.12344V4.45596H11.0708V13.298Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
