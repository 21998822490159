'use strict';

// Imports.

// Specify actions that can be performed by this module.
export default {
  async setDarkMode({ commit }, { value }) {
    commit('setDarkMode', value);
  }
};
