<template>
  <div class='items-wrapper'>
    <Filters/>

    <DropContainer class='items-list'
                   :group-name='groupName'
                   name='items'
                   @onDrop='applyDrop'>
      <template v-if='tradeItems && tradeItems.length !== 0'>
        <DragItem v-for='item in tradeItems'
                  :key='item.id'
                  :item='item'>
          <ItemCard :item='item'/>
        </DragItem>
      </template>
      <div v-else>No items to display</div>
    </DropContainer>
    <div class='items-pagination'>
      <div class='items-pagination-text'>
        <p>Displaying tradable items only.</p>
      </div>
      <NexPrevButtons :value='paginationState.page'
                      :count='paginationState.totalPages'
                      @onChange='changePage'/>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import NexPrevButtons from '@/components/NexPrevButtons'

  import Filters from './Filters'
  import ItemCard from '../../components/ItemCard'
  import DropContainer from '../../components/DropContainer'
  import DragItem from '../../components/DragItem'

  export default {
    components: {
      Filters, NexPrevButtons,
      ItemCard, DropContainer,
      DragItem
    },
    props: {
      groupName: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('trade', ['tradeItems', 'paginationState'])
    },
    methods: {
      ...mapActions('trade', ['requestItems', 'changePage', 'applyDrop'])
    },
    async created() {
      await this.requestItems()
    }
  }
</script>

<style scoped lang='scss'>
  .items-list {
    min-height: 213px;
    display: grid;
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-template-rows: auto;

    :deep {
      .card {
        width: 100%;

        .img-container {
          img {
            max-height: none;
            height: 160px;
          }
        }

        .card-content {
          padding: 12px;
        }

        .info-container {
          margin-bottom: 0;
        }

        .collection-name, .item-name {
          font-size: 12px;
        }
      }
    }
  }

  .items-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    @include media-max-width($largeScreen) {
      flex-direction: column;
      align-items: center;

      .items-pagination-text {
        margin-bottom: 20px;
      }
    }
  }
</style>
