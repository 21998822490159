"use strict";

// Imports.
import { shopService } from "../../services";

// Specify actions that can be performed by this module.
export default {
  // Use the shop service to deploy a shop.
  async deploy({ dispatch, commit }, { name, farmsToAttach }) {
    commit("deployRequest", { name, farmsToAttach });
    try {
      let response = await shopService.deployLocally(name, farmsToAttach);
      let shopData = response.shop;
      let shopAddress = shopData.address;

      // If the deploy is successful, store shop information.
      if (shopAddress) {
        commit("deploySuccess", shopData);
        await dispatch(
          "alert/success",
          `Shop successfully deployed to ${shopAddress}.`,
          { root: true }
        );
        await dispatch("getAllShops", { root: true });
      } else {
        commit("deployFailure", null);
        await dispatch(
          "alert/error",
          `Shop '${name}' deployed with no return address!`,
          { root: true }
        );
      }

      // Catch deployment errors.
    } catch (error) {
      commit("deployFailure", error);
      await dispatch("alert/error", error, { root: true });
    }
  },

  // Purchase an item from a shop.
  async purchase({ dispatch, commit }, { shop, itemId, assetIndex, price }) {
    try {
      await shopService.purchase(shop, itemId, assetIndex, 1, price);

      // Catch deployment errors.
    } catch (error) {
      commit("deployFailure", error);
      await dispatch("alert/error", error, { root: true });
    }
  },

  // Purchase an item from a mint shop.
  async purchaseMint({ dispatch, commit }, { shop, itemIds, price }) {
    try {
      await shopService.purchaseMint(shop, itemIds, price);

      // Catch deployment errors.
    } catch (error) {
      commit("deployFailure", error);
      await dispatch("alert/error", error, { root: true });
    }
  },

  // Use the token service to get a list of all a user's tokens.
  async getAllShops({ dispatch, commit }) {
    try {
      let response = await shopService.getAllShops();
      commit("updateShopsList", response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch("alert/error", error, { root: true });
    }
  }
};
