<template>
  <div v-if="item.value" className="top-page">
    <div class="item-display">
      <div class="item">
        <div v-if="item.value.video" className="display-video-mask">
          <video
            autoplay
            loop
            className="display-video"
            :key="item.value.video"
          >
            <source :src="item.value.video" :type="item.value.type" />
          </video>
          <Skeletor className="skeleton-display-video" />
        </div>
        <img v-else :src="item.value.image" className="display-image" />
      </div>
      <Skeletor className="skeleton-display-video" />
      <vue-final-modal v-model="show" name="fullscreen">
        <video
          v-if="item.value.video"
          autoplay
          loop
          className="display-video-modal"
          :key="item.value.video"
        >
          <source :src="item.value.video" :type="item.value.type" />
        </video>
        <img v-else :src="item.value.image" className="display-image-modal" />
      </vue-final-modal>
      <button className="fullscreen-btn" @click="show = true">
        <img src="../images/fullscreen-icon.png" />
      </button>
    </div>
    <div class="shop">
      <div className="shop-top">
        <div className="shop-name-and-buttons">
          <div className="title">
            {{ item.value.name.replace(/^(.{50}[^\s]*).*/, "$1") }}
          </div>
          <div className="item-buttons">
            <div className="favorite-button">
              <img
                src="../images/heart-icon.png"
                className="item-details-heart-icon"
              />
            </div>
            <div className="dots-button">
              <img
                src="../images/dots.png"
                className="item-details-dots-icon"
              />
            </div>
            <div className="share-button">
              <img
                src="../images/share-icon.png"
                className="item-details-share-icon"
              />
            </div>
          </div>
          <div>
            <img src="../images/artwork-tag.png" className="filters" />
          </div>
        </div>
        <div className="collectible-container">
          <img
            src="../images/collectible-outline.png"
            className="collectible-outline"
          />
          <text className="collectible-text">{{
            "#" + item.value.issueNumber + "/" + item.value.metadata.groupSize
          }}</text>
        </div>
      </div>
      <div className="shop-mid">
        <div class="btn-group-shop-nav" role="group">
          <button
            type="button"
            class="btn btn-shop-nav"
            @click="setActiveTab('info')"
          >
            Info
          </button>
          <button
            type="button"
            class="btn btn-shop-nav"
            @click="setActiveTab('owner')"
          >
            Owner
          </button>
          <button
            type="button"
            class="btn btn-shop-nav"
            @click="setActiveTab('history')"
          >
            History
          </button>
          <button
            type="button"
            class="btn btn-shop-nav"
            @click="setActiveTab('bids')"
          >
            Bids
          </button>
          <button
            type="button"
            class="btn btn-shop-nav"
            @click="setActiveTab('metadata')"
          >
            Metadata
          </button>
        </div>
        <div v-if="activeTab === 'info'" id="shop-nav-info">
          <div className="collection-container">
            <div className="profile-picture">
              <img
                src="../images/elliotrades-profile-picture.png"
                className="profile-picture"
              />
              <img
                src="../images/verified-stamp.png"
                className="verified-stamp"
              />
            </div>
            <div className="collection">
              <text className="collection-text">Collection</text>
              <router-link to="/collections/elliotrades">
                <text className="collection-username">EllioTrades</text>
              </router-link>
            </div>
            <div className="owner">
              <img src="../images/e-profile.png" className="owner-profile" />
              <text className="owner-text">
                Owner
                <router-link to="/collections/elliotrades">
                  <div className="owner-text-owner">EllioTrades</div>
                </router-link>
              </text>
              <img src="../images/e-profile.png" className="creator-profile" />
              <text className="creator-text"
                >Creator
                <router-link to="/collections/elliotrades">
                  <div className="creator-text-creator">EllioTrades</div>
                </router-link>
              </text>
            </div>
          </div>
          <div className="info">
            <ReadMore>
              <p>
                {{ item.value.description }}
              </p>
            </ReadMore>
          </div>
        </div>
        <div
          v-if="activeTab === 'metadata'"
          className="info"
          id="shop-nav-metadata"
        >
          <pre>
						{{ JSON.stringify(item.value.metadata, null, 2) }}
					</pre
          >
        </div>
      </div>
      <div className="shop-bot">
        <text className="current-price">Current Price</text>
        <text className="eth-price">{{
          "ETH " + formatNumber(ethers.utils.formatEther(item.value.price), 3)
        }}</text>
        <text className="eth-price-subtext"
          >({{
            `$${(ethPrice * ethers.utils.formatEther(item.value.price)).toFixed(
              2
            )}`
          }})</text
        >
        <button className="btn-bid">BID</button>
        <button
          v-if="ethereum.connected"
          className="btn-buy"
          @click="purchaseItem"
        >
          BUY NOW
        </button>
        <text className="service-fees"
          >Service fee 2.5%.
          {{
            `${formatNumber(
              ethers.utils.formatEther(item.value.price.div(40)),
              6
            )} ETH  $${(
              ethPrice *
              formatNumber(
                ethers.utils.formatEther(item.value.price.div(40)),
                6
              )
            ).toFixed(2)}`
          }}</text
        >
        <img
          src="../images/ten-percent-banner.png"
          className="ten-percent-banner"
        />
      </div>
    </div>
  </div>
  <div v-if="!item.value" className="skeleton-top-page">
    <div className="skeleton-item-display">
      <Skeletor width="500px" height="650px" />
    </div>
    <div className="skeleton-shop">
      <div className="skeleton-shop-top">
        <Skeletor height="90px" pill />
      </div>
      <div className="skeleton-shop-mid">
        <div className="skeleton-shop-mid-div-1">
          <div className="skeleton-shop-mid-div-1-1">
            <Skeletor width="100%" height="100%" pill />
          </div>
          <div className="skeleton-shop-mid-div-1-2">
            <Skeletor width="100%" height="100%" pill />
          </div>
        </div>
        <div className="skeleton-shop-mid-div-2">
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
          <Skeletor height="10%" pill />
        </div>
      </div>
      <div className="skeleton-shop-bottom">
        <div className="skeleton-shop-bot-div-1">
          <div className="skeleton-shop-bot-div-1-1">
            <Skeletor width="50%" height="100%" pill />
          </div>
          <div className="skeleton-shop-bot-div-1-2">
            <Skeletor width="50%" height="100%" pill />
            <Skeletor width="50%" height="100%" pill />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="item-carousel">
    <div className="item-carousel-text">Other EllioTrades Items</div>
    <div className="skeleton-carousel" v-if="!item.value">
      <Skeletor width="100%" height="500px" />
      <Skeletor width="100%" height="500px" />
      <Skeletor width="100%" height="500px" />
    </div>
    <carousel
      v-if="items.presaleItemList.length > 3 && item.value"
      :settings="settings"
    >
      <slide v-for="(item, index) in items.presaleItemList" :key="index">
        <Item :item="item" :collection="true" />
      </slide>
      <template #addons>
        <navigation />
      </template>
    </carousel>
    <div
      v-if="items.presaleItemList.length <= 3 && item.value"
      className="item-display-without-carousel"
    >
      <div
        v-for="(item, index) in items.presaleItemList"
        :key="index"
        className="carouseless-item"
      >
        <Item :item="item" :collection="true" />
      </div>
    </div>
  </div>
</template>

<script>
"use strict";

// Imports.
import { mapState, mapActions } from "vuex";
import store from "../store";
import { formatNumber } from "../utility";
import axios from "axios";
import { ethers } from "ethers";
import initializeConfig from "../initialize-config";
import { useTask } from "vue-concurrency";
import { ref } from "vue";

// Component imports.
import "vue3-carousel/dist/carousel.css";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Item from "../components/Item";
import ReadMore from "../components/ReadMore";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

// Set up the component.
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    Item,
    ReadMore,
    Skeletor
  },
  data() {
    return {
      ethers,
      formatNumber,

      item: ref({}),
      show: false,
      readMoreActivated: false,
      ethPrice: null,
      activeTab: "info",
      getPresaleItemsTask: null,
      isLoading: true,

      // Carousel settings.
      settings: {
        itemsToShow: 5,
        snapAlign: "center",
        wrapAround: true
      }
    };
  },
  async created() {
    this.config = await initializeConfig();

    let ethereumReference = this.ethereum;
    let routeReference = this.$route;
    let itemsReference = this.items;
    this.getPresaleItemsTask = useTask(function* (signal, item) {
      // yield sleep(100000);
      if (!ethereumReference.initialized) {
        yield store.dispatch("ethers/initialize", async () => {
          await store.dispatch("items/getPresaleItems", "", { root: true });
          let id = routeReference.params.id;
          for (let i = 0; i < itemsReference.presaleItemList.length; i++) {
            if (id === itemsReference.presaleItemList[i].id) {
              item.value = itemsReference.presaleItemList[i];
            }
          }
          axios
            .get(
              "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
            )
            .then(response => (this.ethPrice = response.data.ethereum.usd));
        });
      } else {
        yield store.dispatch("items/getPresaleItems", "", { root: true });
        let id = routeReference.params.id;
        for (let i = 0; i < itemsReference.presaleItemList.length; i++) {
          if (id === itemsReference.presaleItemList[i].id) {
            item.value = itemsReference.presaleItemList[i];
          }
        }
        axios
          .get(
            "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
          )
          .then(response => (this.ethPrice = response.data.ethereum.usd));
      }
    });
    this.getPresaleItemsTask.perform(this.item);
  },
  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      account: state => state.account,
      items: state => state.items
    })
  },
  methods: {
    ...mapActions("account", ["logout"]),
    ...mapActions("shops", ["purchaseMint"]),
    handleLogout(e) {
      this.account.status.loggingOut = true;
      this.logout();
    },
    setActiveTab(tab) {
      console.log(tab);
      this.activeTab = tab;
    },
    purchaseItem() {
      console.log("Purchasing an item.");
      let shopAddress = this.config.presaleShopAddress[this.ethereum.networkId];
      let id = this.$route.params.id;
      let purchasedId = ethers.BigNumber.from(id).add(1);
      console.log(shopAddress, purchasedId, this.item.price);
      this.purchaseMint({
        shop: shopAddress,
        itemIds: [purchasedId],
        price: this.item.price
      });
    }
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      for (let i = 0; i < this.items.presaleItemList.length; i++) {
        if (id === this.items.presaleItemList[i].id) {
          this.item.value = this.items.presaleItemList[i];
        }
      }
      axios
        .get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        )
        .then(response => (this.ethPrice = response.data.ethereum.usd));
      console.log(this.item.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.top-page {
  display: flex;
  width: 1400px;
  margin: auto;
  margin-bottom: 48px;
  z-index: 999;
  box-shadow: 0px 6px 40px -15px rgba(96, 124, 172, 0.24891);
  font-family: Work Sans;
}

.item-display {
  width: 60%;
  min-width: 900px;
  min-height: 762px;
  top: 100%;
  display: flex;
  background: linear-gradient(147.6deg, #ffffff 21.2%, #fafcff 85.67%);
}

.display-image {
  position: absolute;
  margin-top: -100px;
  margin-left: 35px;
  border-radius: 0px;
  width: 500px;
  height: 650px;
  background: transparent url(../images/spinner.gif) no-repeat scroll center
    center;
}

.display-video {
  width: 500px;
  height: 100%;
  background: transparent url(../images/spinner.gif) no-repeat scroll center
    center;
}

.display-video-mask {
  position: absolute;
  margin-top: -100px;
  margin-left: 35px;
  border-radius: 0px;
  width: 500px;
  height: 650px;
  overflow: hidden;
}

.display-image-modal {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0px;
  width: 800px;
  height: 1040px;
}

.display-video-modal {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0px;
  width: 100;
  height: 1040px;
}

.shop {
  width: 100%;
  min-width: 500px;
  height: 100%;
  min-height: 762px;
  margin: 0px;
  background: #ffffff;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #dee5ef;
}

.shop-top {
  height: 100%;
  width: 100%;
}

.shop-name-and-buttons {
  display: flex;
}

.title {
  position: relative;
  min-width: 233px;
  height: 20%;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #424b5f;
  margin-left: 40px;
  margin-top: 25px;
  margin-right: 23px;
  font-family: Hammersmith One;
}

.item-buttons {
  position: relative;
  display: flex;
  margin-left: 10px;
  margin-right: 40px;
  margin-top: 27px;
}

.favorite-button {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #e6ebf2;
  box-sizing: border-box;
  border-radius: 10px;
}

.item-details-heart-icon {
  margin-left: 14px;
  margin-top: 15px;
}

.share-button {
  width: 48px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e6ebf2;
  box-sizing: border-box;
  border-radius: 10px;
}

.item-details-share-icon {
  margin-left: 10px;
  margin-top: 12px;
}

.dots-button {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #e6ebf2;
  box-sizing: border-box;
  border-radius: 10px;
}

.item-details-dots-icon {
  margin-left: 12px;
  margin-top: 18px;
}

.filters {
  position: absolute;
  margin-top: 95px;
  margin-left: -140px;
  width: 84px;
  height: 20px;
}

.collectible-container {
  display: flex;
  height: 20%;
  left: 0;
  padding-left: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.collectible-outline {
  position: relative;
  width: 60px;
  height: 25px;
}

.collectible-text {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #424b5f;
  margin-top: 6px;
  margin-left: -52px;
}

.item {
  border-radius: 30px;
  width: 575px;
  height: 435px;
  margin-top: 164px;
  margin-left: 183px;
}

.fullscreen-btn {
  margin-top: 50px;
  margin-left: 7px;
  margin-right: 29px;
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #e6ebf2;
  box-sizing: border-box;
  box-shadow: 0px 2px 34px rgba(255, 255, 255, 0.712495);
  border-radius: 10px;
  transition: box-shadow 0.2s;
}

.fullscreen-btn:hover {
  box-shadow: 0px 0px 10px rgba(10, 10, 10, 0.5);
}

.fullscreen-image {
  width: 116px;
  height: 116px;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}

.shop-mid {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dee5ef;
  position: relative;
  width: 100%;
  min-height: 300px;
  height: 100%;
}

.item-details-nav {
  display: flex;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dee5ef;
  min-height: 40px;
  width: 100%;
}

/* selected btn css */
.btn-group-shop-nav .btn:not(:disabled):not(.disabled).active,
.btn-group-shop-toggle .btn:not(:disabled):not(.disabled):active,
.show > .btn.dropdown-toggle {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
}

/* non selected btn css */
.btn-group-shop-nav .btn {
  color: #ffffff;
  background-color: #ffffff;
  width: 20%;
  border-radius: 0px;
  border-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dee5ef;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
}

.btn-group-shop-nav .btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0);
}

.btn-group-shop-nav .btn:hover {
  background-color: #dee5ef;
}

.tab {
  position: absolute;
  margin-left: 40px;
  margin-top: 36px;
}

.tab-text {
  position: relative;
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  width: 40px;
  height: 16px;
  color: #67758d;
}

.collection-container {
  position: relative;
  width: 207px;
  height: 48px;
  margin-left: 40px;
  margin-top: 30px;
  margin-right: 0px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #dee5ef;
}

.collection {
  position: absolute;
  margin-top: -50px;
  margin-left: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #67758d;
}

.collection-text {
  position: absolute;
}

.collection-username {
  position: absolute;
  margin-top: 25px;
  font-weight: bold;
  color: #67758d;
  outline: none;
}

.profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 18px;
  margin-right: 16px;
}

.verified-stamp {
  position: absolute;
  opacity: 1;
  margin-top: -15px;
  margin-left: 33px;
  width: 17px;
  height: 17px;
}

.owner {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 212px;
  height: 45px;
  margin-right: -294px;
  margin-top: 0px;
}

.owner-profile {
  position: absolute;
  margin-top: 0px;
}

.creator-profile {
  position: absolute;
  margin-top: 30px;
}

.owner-text {
  position: absolute;
  margin-left: 25px;
  margin-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #67758d;
  display: flex;
}

.owner-text-owner {
  padding-left: 2px;
  font-weight: bold;
  color: #67758d;
  outline: none;
}

.creator-text {
  position: absolute;
  margin-left: 25px;
  margin-top: 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #67758d;
  display: flex;
}

.creator-text-creator {
  padding-left: 2px;
  font-weight: bold;
  color: #67758d;
  outline: none;
}

.info {
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #67758d;
  width: 420px;
  height: 100;
  margin-left: 40px;
}

.read-more {
}

.shop-bot {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dee5ef;
  position: relative;
  width: 100%;
  height: 275px;
}

.current-price {
  position: absolute;
  width: 90px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #67758d;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-left: 40px;
  margin-top: 30px;
}

.eth-price {
  position: absolute;
  width: 300px;
  height: 46px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #424b5f;
  margin-left: 40px;
  margin-top: 53px;
  font-family: Hammersmith One;
}

.eth-price-subtext {
  position: absolute;
  width: 80px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #67758d;
  mix-blend-mode: normal;
  opacity: 0.8;
  right: 0%;
  margin-right: 150px;
  margin-top: 70px;
}

.btn-bid {
  position: absolute;
  margin-left: 40px;
  margin-top: 120px;
  background: #ffffff;
  border: 2px solid #f03648;
  box-sizing: border-box;
  border-radius: 18px;
  width: 200px;
  height: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #f13748;
  transition: box-shadow 0.5s;
}

.btn-bid:hover {
  box-shadow: 0px 10px 15px -10px rgba(240, 43, 66, 1);
}

.btn-buy {
  position: absolute;
  margin-left: 260px;
  margin-top: 120px;
  background: linear-gradient(193.59deg, #f23c47 -25.04%, #e50e55 124.96%);
  border-radius: 18px;
  border: 0px;
  width: 200px;
  height: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  transition: box-shadow 0.5s;
}

.btn-buy:hover {
  background: linear-gradient(193.59deg, #f23c47 -25.04%, #a30338 124.96%);
  box-shadow: 0px 0px 10px 5px rgba(255, 116, 124, 0.5);
}

.service-fees {
  position: absolute;
  width: 420px;
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #67758d;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-left: 40px;
  margin-top: 192px;
}

.ten-percent-banner {
  position: absolute;
  margin-left: 155px;
  margin-top: 218px;
}

.item-carousel {
  height: 775px;
  border-radius: 20px 20px 0px 0px;
  background: #f1f6fd;
}

.item-carousel-text {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #424b5f;
  padding-top: 70px;
  margin-left: 100px;
}

.carousel {
}

.carousel__slide {
  height: 700px;
}

.item-display-without-carousel {
  padding-top: 87.5px;
  display: flex;
}

.carouseless-item {
  width: 33.3%;
  height: 600px;
  padding-top: 50px;
}

.skeleton-top-page {
  display: flex;
  width: 100%;
  height: 785 - 48px;
  margin: auto;
  margin-bottom: 48px;
  z-index: 999;
  box-shadow: 0px 6px 40px -15px rgba(96, 124, 172, 0.24891);
}

.skeleton-item-display {
  width: 60%;
  min-width: 940px;
  min-height: 762px;
  padding-left: 200px;
  padding-top: 50px;
  padding: auto;
  display: flex;
  background: linear-gradient(147.6deg, #ffffff 21.2%, #fafcff 85.67%);
}

.skeleton-shop {
  width: 40%;
  min-height: 762px;
  margin: 0px;
  background: #ffffff;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #dee5ef;
}

.skeleton-shop-top {
  padding: 50px;
  height: 185px;
  width: 100%;
}

.skeleton-shop-mid {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dee5ef;
  position: relative;
  width: 100%;
  height: 300px;
}

.skeleton-shop-mid-div-1 {
  display: flex;
  width: 100%;
  height: 25%;
  padding: 5px;
}

.skeleton-shop-mid-div-1-1 {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.skeleton-shop-mid-div-1-2 {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.skeleton-shop-mid-div-2 {
  padding-top: 20px;
  width: 100%;
  height: 75%;
  padding: 10px;
}

.skeleton-shop-mid-div-2 * {
  margin-bottom: 5px;
}

.skeleton-shop-mid-div-2-1 {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.skeleton-shop-mid-div-2-2 {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.skeleton-shop-bottom {
  display: flex;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dee5ef;
  position: relative;
  width: 100%;
  height: 275px;
}

.skeleton-shop-bot-div-1 {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.skeleton-shop-bot-div-1-1 {
  display: flex;
  width: 100%;
  height: 25%;
  padding: 5px;
  margin-top: 50px;
}

.skeleton-shop-bot-div-1-2 {
  display: flex;
  width: 100%;
  height: 25%;
  padding: 5px;
}

.skeleton-shop-bot-div-1-2 * {
  margin-right: 5px;
}

.skeleton-carousel {
  display: flex;
  margin-top: 75px;
  height: 500px;
}

.skeleton-carousel * {
  margin-left: 80px;
  margin-right: 80px;
}
</style>
