<template>
  <div class="activity-container">
    <div v-for="nft in nfts" :key="nft">
      <ActivityItem :nft="nft" />
    </div>
  </div>
</template>

<script>
import ActivityItem from './ActivityItem';

export default {
  components: { ActivityItem },

  data() {
    return {
      nfts: [
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'listed',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'auctioned',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'offered',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'sold',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'transferred',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'liked',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'bid',
            price: '0.1 ETH',
            user: 'user1',
            date: new Date(),
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
.activity-container {
  margin-top: 30px;
}
</style>
