'use strict';

// Specified state transitions per particular actions.
export default {
  success(state, { message, duration, metadata }) {
    state.message = message;
    state.type = 'success';
    state.duration = duration;
    state.metadata = metadata;
  },

  info(state, { message, duration, metadata }) {
    state.message = message;
    state.type = 'info';
    state.duration = duration;
    state.metadata = metadata;
  },

  warning(state, { message, duration, metadata }) {
    state.message = message;
    state.type = 'warning';
    state.duration = duration;
    state.metadata = metadata;
  },

  error(state, { message, duration, metadata }) {
    state.message = message;
    state.type = 'error';
    state.duration = duration;
    state.metadata = metadata;
  },

  default(state, { message, duration, metadata }) {
    state.message = message;
    state.type = 'default';
    state.duration = duration;
    state.metadata = metadata;
  },

  updateDeployedToastIds(state, { deployedToastId }) {
    state.deployedToastIds.add(deployedToastId);
  },

  // Tell the app to clear all alerts.
  clear(state) {
    for (let toast of state.deployedToastIds) {
      this.$app.$toasts.remove(toast);
    }
    state.message = null;
    state.type = null;
    state.deployedToastIds = new Set();
  },

  // TODO
  showWalletModal(state) {
    state.showWalletModal = true;
  },
  hideWalletModal(state) {
    state.showWalletModal = false;
  }
};
