'use strict';

// Export state mutation functions.
export default {
  // Deployment events.
  deployRequest(state, { name, ticker }) {
    state.status = { deploying: true };
  },
  deploySuccess(state, token) {
    state.status = {};
  },
  deployFailure(state, error) {
    state.status = {};
  },

  // Update the token approval for a particular farm token.
  updateTokenApproval(state, { tokenAddress, amount }) {
    state.farm.poolTokens[tokenAddress].userAllowance = amount;
  },

  // Update the list of farms being tracked for display purposes.
  updateFarmsList(state, farmsList) {
    state.farmList = farmsList;
  },

  // Provides a deeper update to all farms in the staking page.
  updateFarmsInformation(state, farms) {
    state.farms = farms;
  }
};
