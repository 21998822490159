<template>
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.80627V5.81884C0 6.0509 0.0921901 6.27346 0.256279 6.43755L4.63128 10.8126C4.97299 11.1542 5.52701 11.1542 5.86872 10.8126L9.88129 6.8C10.223 6.45828 10.223 5.90427 9.88129 5.56255L5.50628 1.18755C5.34219 1.02346 5.11963 0.931274 4.88757 0.931274H0.875C0.391755 0.931274 0 1.32303 0 1.80627ZM2.40625 3.99377C2.76868 3.99377 3.0625 3.69996 3.0625 3.33752C3.0625 2.97509 2.76868 2.68127 2.40625 2.68127C2.04382 2.68127 1.75 2.97509 1.75 3.33752C1.75 3.69996 2.04382 3.99377 2.40625 3.99377Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
