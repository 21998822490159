<template>
  <div class="info-profile">
    <Skeletor v-if="isLoading" class="info-profile__avatar" />
    <img
      v-else
      :src="require(`@/images/social/avatars/username.gif`)"
      class="info-profile__avatar"
      alt=""
    />
    <Skeletor v-if="isLoading" class="info-profile__name" />
    <h2 v-else class="info-profile__name">{{ pool.pool_name }}</h2>
    <Skeletor v-if="isLoading" class="info-profile__hash" />
    <div v-else class="info-profile__hash">
      {{ pool.pool_hash }}
    </div>
    <div v-if="isLoading" class="info-profile__button-wrapper">
      <Skeletor v-for="i in 2" :key="i" class="info-profile__button" style="margin-right: 15px" width="115" />
    </div>
    <div v-else class="info-profile__button-wrapper">
      <template v-if="pool.status === 'live'">
        <a class="info-profile__button info-profile__button_violet">
          + Join Pool
        </a>
        <a class="info-profile__button info-profile__button_white">
          View on Etherscan
        </a>
      </template>
      <template v-else-if="pool.status === 'upcoming'">
        <a class="info-profile__button info-profile__button_violet">
          + Join Whitelist
        </a>
      </template>
      <template v-else-if="pool.status === 'filled'">
        <a class="info-profile__button info-profile__button_white">
          View on Etherscan
        </a>
      </template>
    </div>
    <div v-if="isLoading" class="info-profile__status-wrapper">
      <Skeletor v-for="i in 2" :key="i" class="info-profile__status-badge" style="margin-right: 15px; padding: 5px 0px;" width="70" />
    </div>
    <div v-else class="info-profile__status-wrapper">
      <template v-if="pool.status === 'filled'">
        <div class="info-profile__status-badge badge badge_filled">
          <div class="badge__dot"></div>
          <div class="badge__text">Filled</div>
        </div>
        <div class="info-profile__status-time">Published {{ pool.published }} hours ago</div>
      </template>
      <template v-else-if="pool.status === 'live'">
        <div class="info-profile__status-badge badge badge_live">
          <div class="badge__dot"></div>
          <div class="badge__text">LIVE</div>
        </div>
        <div class="info-profile__status-time">Published {{ pool.published }} hours ago</div>
      </template>
      <template v-else-if="pool.status === 'upcoming'">
        <div class="info-profile__status-badge badge badge_upcoming">
          <div class="badge__text">in 17 days</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";

export default {
  components: {
    Skeletor
  },
  props: {
    isLoading: Boolean,
    pool: Object
  }
};
</script>

<style src="./PoolInfo.scss" lang="scss" scoped>
</style>
