<template>
  <div class="container">
    <div class="header">
      Farms
    </div>
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab">
        <BaseTabItem :title="tab" :isSelected="currentTab === tab" @click="setTab(tab)" />
      </div>
    </div>
    <div class="tab-pane">
      <FarmsTable />
    </div>

    <div class="helpSection">
      <div class="header">Need help?</div>
      <div class="subtitle">We're here for you. Head on over to our help center and we'll get you sorted.</div>
      <router-link :to="'/help-center'">
        <Button @click="routeTo('/help-center')"> <QuestionMarkIcon class="icon" /> Help Center </Button>
      </router-link>
    </div>
  </div>
</template>

<script>
// Component imports.
import BaseTabItem from '../components/ui/BaseTabItem';
import FarmsTable from '../components/pages/farms/FarmsTable';
import Button from '/src/components/ui/Button.vue';

// Icon imports.
import QuestionMarkIcon from '/src/components/icons/QuestionMarkIcon.vue';

export default {
  components: {
    BaseTabItem,
    FarmsTable,
    Button,
    QuestionMarkIcon
  },
  data() {
    return {
      tabs: ['All'],
      // tabs: [ 'All', 'Live', 'Finished', 'Staked' ],
      currentTab: 'All'
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    setTab(tab) {
      this.currentTab = tab;
    }
  }
};
</script>

<style scoped>
.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0px 20px;
  max-width: 1600px;
  width: 100%;
}
.header {
  padding: 20px 0px;
  font-size: 30px;
  font-weight: 600;
  margin-top: 60px;
}

.tabs {
  position: sticky;
  top: 60px;
  display: flex;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.tab-pane {
  margin: 20px 0px;
}

.helpSection {
  padding: 90px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  margin-bottom: 20px;
}

.icon {
  height: 17px;
  widows: 17px;
  padding-right: 10px;
}
</style>
