import {getStateByTab} from './'

import {findIndex} from 'lodash'

export default {
    setItems(state, items) {
        switch (state.activeTab) {
            case 0:
                state.your.items = items

                break
            case 1:
                state.their.items = items

                break
            default:
                break
        }
    },
    setTab(state, value) {
        state.activeTab = value
    },
    setExactFilter(state, data) {
        const stateByTab = getStateByTab(state)

        if (stateByTab) {
            stateByTab.filters[data.type] = data.value
        }
    },
    setFilters(state, values) {
        const stateByTab = getStateByTab(state)

        if (stateByTab) {
            stateByTab.filters = {
                ...stateByTab.filters,
                ...values
            }
        }
    },
    setPage(state, page) {
        const stateByTab = getStateByTab(state)

        if (stateByTab) {
            stateByTab.page = page
        }
    },
    setDroppedData(state, data) {
        const stateByTab = getStateByTab(state)

        if (stateByTab) {
            if (data.from === 'items' && data?.droppedItem) {
                stateByTab.selected.push(data.droppedItem)
            } else if (data.from === 'selected' && data?.droppedItem) {
                const foundIndex = findIndex(stateByTab.selected, item => item?.id === data.droppedItem?.id)

                if (foundIndex !== -1) {
                    stateByTab.selected.splice(foundIndex, 1)
                }
            }
        }
    }
}