<template>
  <div class="ecosystem">
    <img src="../../../images/nft-collage.svg" loading="lazy" alt="" class="nft-bg" ref="ecosystemImage" />

    <div class="container">
      <!-- mobile top -->
      <img src="../../../images/nft-ecosystem-mobile-top.svg" loading="lazy" alt="" class="ecosystem-mobile-top" />

      <h2 class="heading">A growing NFT ecosystem.</h2>
      <div class="subtitle">
        SuperFarm empowers NFT creators, collectors and traders to participate in an NFT marketplace that is open and accessible to all.
      </div>

      <div class="launchpad-icon">
        <LaunchpadIcon />
      </div>
      <div class="farm-icon">
        <FarmIcon />
      </div>
      <div class="collections-icon">
        <CollectionIcon />
      </div>
      <div class="trade-icon">
        <TradeIcon />
      </div>
      <div class="generation-icon">
        <GenerationIcon />
      </div>
      <div class="market-icon">
        <MarketIcon />
      </div>
      <img src="../../../images/nft-ecosystem-mobile-bottom.svg" loading="lazy" alt="" class="ecosystem-mobile-bottom" />
    </div>
  </div>
</template>
<script>
import FarmIcon from '/src/components/icons/FarmIcon';
import LaunchpadIcon from '/src/components/icons/LaunchpadIcon';
import CollectionIcon from '/src/components/icons/CollectionIcon';
import TradeIcon from '/src/components/icons/TradeIcon';
import GenerationIcon from '/src/components/icons/GenerationIcon';
import MarketIcon from '/src/components/icons/MarketIcon';

export default {
  components: {
    CollectionIcon,
    TradeIcon,
    FarmIcon,
    GenerationIcon,
    LaunchpadIcon,
    MarketIcon
  },

  mounted() {
    const zoomElement = this.$refs.ecosystemImage;

    document.addEventListener('scroll', function(e) {
      // once we have reached 1/3 down the page, set the scale and keep increasing the scale until we have reached full page
      let scaleFactor = window.scrollY / window.innerHeight + 0.37;
      const scrollTranslation = (scaleFactor - 0.37) * 50;
      if (scaleFactor > 1) scaleFactor = 1;

      zoomElement.style.transform = `scale(${scaleFactor})`;

      if (scrollTranslation < 1000) {
        zoomElement.style.transform = `scale(${scaleFactor}) translateY(-${scrollTranslation}px)`;
      }
    });
  }
};
</script>
<style scoped lang="scss">
.ecosystem {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 20px;
  flex: 1;

  .nft-bg {
    position: absolute;
    max-height: 90vh;
    max-width: 80vw;
    transform: scale(0.7);
    transition: transform 300ms ease-out;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;

    .heading {
      margin-top: 0px;
      margin-bottom: 40px;
      line-height: 1.1;
      text-align: center;
      font-size: 32px;
    }

    .subtitle {
      width: 60ch;
      max-width: 45vw;
      margin-bottom: 0px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      font-size: 24px;
      text-align: center;
      opacity: 0.8;
    }

    .launchpad-icon {
      position: absolute;
      left: 11%;
      top: -80%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .farm-icon {
      position: absolute;
      left: 38%;
      top: -120%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .collections-icon {
      position: absolute;
      left: 77%;
      top: -95%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .trade-icon {
      position: absolute;
      left: 77%;
      top: 180%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .generation-icon {
      position: absolute;
      left: 38%;
      top: 220%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .market-icon {
      position: absolute;
      left: 12%;
      top: 200%;
      display: flex;
      width: 96px;
      justify-content: center;
      align-items: center;
    }

    .ecosystem-mobile-bottom,
    .ecosystem-mobile-top {
      display: none;
    }

    .ecosystem-mobile-bottom {
      max-width: 68%;
      margin-top: 30px;
    }

    .ecosystem-mobile-top {
      max-width: 68%;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 990px) {
  .ecosystem .container .ecosystem-mobile-top,
  .ecosystem .container .ecosystem-mobile-bottom {
    display: initial;
  }

  .ecosystem .nft-bg {
    display: none;
  }

  .ecosystem .container {
    .subtitle {
      max-width: 100%;
    }
    .launchpad-icon {
      left: 36%;
      top: 29%;
      width: 80px;
    }

    .farm-icon {
      left: 44%;
      top: 9%;
      width: 80px;
    }

    .collections-icon {
      left: 52%;
      top: 20%;
      width: 80px;
    }

    .market-icon {
      left: 34%;
      top: 62%;
      width: 80px;
    }
    .trade-icon {
      left: 53%;
      top: 69%;
      width: 80px;
    }
    .generation-icon {
      left: 39%;
      top: 79%;
      width: 80px;
    }
  }
}

@media (max-width: 700px) {
  .ecosystem .container {
    .heading {
      font-size: 26px;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 20px;
      max-width: 100%;
    }

    .launchpad-icon,
    .farm-icon,
    .collections-icon,
    .market-icon,
    .trade-icon,
    .generation-icon {
      width: 60px;
    }
  }
}

@media (max-width: 479px) {
  .ecosystem .container {
    .launchpad-icon,
    .farm-icon,
    .collections-icon,
    .market-icon,
    .trade-icon,
    .generation-icon {
      width: 40px;
    }
  }
}
</style>
