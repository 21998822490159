<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewbox="0 0 14 16"
    fill="none"
  >
    <path
      d="M2.9 0L0 2.9V13.1H3.4V16L6.3 13.1H8.6L13.7 8V0H2.9ZM10.3 9.7L12.6 7.4V1.1H3.4V9.7H5.7V12L8 9.7H10.3ZM8 3.4H6.9V6.9H8V3.4ZM10.9 6.9V3.4H9.7V6.9H10.9Z"
      style="clip-rule:evenodd;fill-rule:evenodd;fill:currentColor"
    ></path>
  </svg>
</template>
