<template>
  <div>
    <h1>Tokens</h1>
    <p>Now that you're logged in, you can deploy tokens on this page.</p>
    <Form @submit="handleSubmit">
      <div class="form-group">
        <label for="name">Token Name</label>
        <Field
          name="name"
          as="input"
          v-model="name"
          class="form-control"
          :rules="validationRules.tokenName"
        />
        <ErrorMessage name="name" />
      </div>
      <div class="form-group">
        <label for="ticker">Token Ticker</label>
        <Field
          name="ticker"
          as="input"
          v-model="ticker"
          class="form-control"
          :rules="validationRules.tokenTicker"
        />
        <ErrorMessage name="ticker" />
      </div>
      <div class="form-group">
        <label for="cap">Token Supply Cap</label>
        <NumberInput v-model="cap" />
      </div>

      <label for="directMint">Mint Tokens on Creation?</label>
      <input
        name="directMint"
        type="checkbox"
        v-model="shouldDirectMint"
        class="form-control"
      />
      <br />
      <div v-if="shouldDirectMint" class="form-group">
        <MultiSelectionList
          header="Addresses to mint to:"
          emptyMessage="You have not specified any addresses."
          :customOnly="true"
          customPlaceholder="Enter an address to mint tokens to."
          :columns="[
            {
              name: 'Address',
              id: 'address',
              type: 'primary'
            },
            {
              name: 'Tokens to Mint',
              id: 'tokens',
              type: 'number',
              value: '0'
            }
          ]"
          primary="address"
          v-model="directMints"
        />
      </div>

      <div class="form-group">
        <button class="btn btn-primary" :disabled="tokens.status.deploying">
          Deploy
        </button>
        <img
          v-show="tokens.status.deploying"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </div>
    </Form>
    <br />
    <h1>Your Tokens</h1>
    <ul v-if="tokens.tokenList.length > 0">
      <li v-for="token in tokens.tokenList" :key="token">
        {{ token.name }} ({{ token.symbol }}) at {{ token.address }}
      </li>
    </ul>
    <h3 v-if="tokens.tokenList.length === 0">
      You have not created any tokens yet.
    </h3>
    <br />
    <p>
      When your token has deployed, move on to the
      <router-link to="/farms">Farms</router-link> tab to set up a farm.
    </p>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import { validationRules } from '../utility';
import store from '../store';

// Component imports.
import { Form, Field, ErrorMessage } from 'vee-validate';
import NumberInput from '../components/ui/NumberInput.vue';
import MultiSelectionList from '../components/MultiSelectionList.vue';

// Set up the component.
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    NumberInput,
    MultiSelectionList
  },
  data() {
    return {
      name: '',
      ticker: '',
      cap: '1000000000',
      shouldDirectMint: false,
      directMints: [],

      // Include our standard validation rules.
      validationRules: validationRules
    };
  },
  async created() {
    console.log(`Initializing data for tokens page ...`);
    await store.dispatch('tokens/getAllTokens', '', { root: true });
  },
  computed: {
    ...mapState({
      tokens: state => state.tokens
    })
  },
  methods: {
    ...mapActions('tokens', ['deploy']),
    handleSubmit(e) {
      this.tokens.status.deploying = true;
      const { name, ticker, cap, directMints } = this;
      if (name && ticker) {
        this.deploy({ name, ticker, cap, directMints });
      }
    }
  }
};
</script>
