<template>
  <DropsBanner v-for="(drop, index) in featuredDrops" :key="index" :drop="drop" />
  <Ethereum :callback="initializePage" />
  <div class="tabs">
    <div v-for="(tab, index) in tabs" :key="index">
      <BaseTabItem :title="tab" :isSelected="index === currentTabIndex" @click="setTab(index)" />
    </div>
  </div>
  <div class="tab-pane-container">
    <div class="tab-pane">
      <div class="drops-grid" v-if="currentTab === 'Live'">
        <DropCard v-for="drop in liveDrops" :key="drop" :drop="drop" @click="navigateToDropDetails(drop.id)" />
      </div>
      <div class="drops-grid" v-else-if="currentTab === 'Upcoming'">
        <DropCard v-for="drop in upcomingDrops" :key="drop" :drop="drop" @click="navigateToDropDetails(drop.id)" />
      </div>
      <div class="drops-grid" v-else-if="currentTab === 'Ended'">
        <DropCard v-for="drop in endedDrops" :key="drop" :drop="drop" @click="navigateToDropDetails(drop.id)" />
      </div>
    </div>
  </div>
  <div class="newsletter">
    <NewsletterForm />
  </div>
</template>

<script>
'use strict';

// Component imports
import Ethereum from '/src/components/common/Ethereum';
import DropsBanner from '../../components/pages/drops/DropBanner';
import DropCard from '../../components/pages/drops/DropCard';
import NewsletterForm from '../../components/pages/launchpad/NewsletterForm';
import BaseTabItem from '../../components/ui/BaseTabItem';

// Imports
import router from '../../router';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    DropsBanner,
    DropCard,
    Ethereum,
    NewsletterForm,
    BaseTabItem
  },

  setup() {
    const store = useStore();
    // const currentTab = ref('Live');
    const currentTabIndex = ref(0);
    // Methods.
    const initializePage = async () => {
      await store.dispatch('drops/updateDropsData');
      console.log(store.state.drops);
    };

    const setTab = index => {
      currentTabIndex.value = index;
    };

    const navigateToDropDetails = id => {
      if (id === 'open-edition' || id === 'genesis') return;
      router.push({
        name: 'Drop Details',
        params: { drop: id }
      });
    };

    const featuredDrops = computed(() => {
      const featured = [];
      for (const drop of store.state.drops.drops) {
        if (store.state.drops.featuredDropIds.has(drop.id)) {
          featured.push(drop);
        }
      }
      console.log(store.state.drops.featuredDropIds);
      return featured;
    });

    const liveDrops = computed(() => {
      const live = [];
      for (const drop of store.state.drops.drops) {
        // If the drop window is now.
        if (drop.startDate < new Date() && drop.endDate > new Date()) {
          live.push(drop);
        }
      }
      return live;
    });

    const endedDrops = computed(() => {
      const ended = [];
      for (const drop of store.state.drops.drops) {
        // If the drop window is now.
        if (drop.endDate < new Date()) {
          ended.push(drop);
        }
      }
      return ended;
    });

    const upcomingDrops = computed(() => {
      const upcoming = [];
      for (const drop of store.state.drops.drops) {
        // If the drop window is now.
        if (drop.startDate > new Date()) {
          upcoming.push(drop);
        }
      }
      return upcoming;
    });

    const tabs = computed(() => {
      let tabs = [];
      if (liveDrops.value.length > 0) tabs.push('Live');
      if (upcomingDrops.value.length > 0) tabs.push('Upcoming');
      if (endedDrops.value.length > 0) tabs.push('Ended');
      return tabs;
    });

    const currentTab = computed(() => {
      return tabs.value[currentTabIndex.value];
    });

    return {
      tabs,
      currentTab,
      initializePage,
      setTab,
      navigateToDropDetails,
      featuredDrops,
      liveDrops,
      endedDrops,
      upcomingDrops,
      currentTabIndex
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.tabs {
  position: sticky;
  top: 60px;
  display: flex;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tab-pane-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.tab-pane {
  margin: 20px;
  width: 100%;
  max-width: 1600px;
}

.drops-grid {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: auto;
}

@media (max-width: 450px) {
  .drops-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
</style>
