<template>
  <div class='nft-drag-container'>
    <BaseNFTCard class='nft-card' :item='item'>
      <template #info>
        <BaseNFTCardInfo :item='item'/>
      </template>
    </BaseNFTCard>
  </div>
</template>

<script>
  import BaseNFTCard from '@/components/pages/my-items/nft-card/BaseNFTCard'
  import BaseNFTCardInfo from '@/components/pages/my-items/nft-card/BaseNFTCardInfo'

  export default {
    name: 'ItemCard',
    components: {BaseNFTCard, BaseNFTCardInfo},
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped lang='scss'>
  .nft-drag-container {
    cursor: grab;
    transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 0 0 30px 30px;

    &:hover {
      box-shadow: 0 14px 40px 0 rgba(var(--text-color-rgb), 0.25);
    }

    &:active {
      box-shadow: none;
    }
  }

  :deep {
    .nft-card {
      width: 100%;
      pointer-events: none;

      .img-container {
        height: auto;

        img {
          max-height: none;
          height: 160px;
        }
      }

      .card-content {
        padding: 12px;
      }

      .info-container {
        margin-bottom: 0;
      }

      .collection-name, .item-name {
        font-size: 12px;
      }
    }
  }
</style>