<template>
  <transition name="fade" appear>
    <div class="modal-background" @click="exitModal" v-if="isOpen"></div>
  </transition>
  <transition name="slide" appear>
    <div class="modal-wrapper" v-if="isOpen">
      <div class="modal-container">
        <div class="modal-header-wrapper">
          <div class="modal-header">Add Pool</div>
          <div class="close-icon" @click="exitModal">
            <CloseIcon />
          </div>
        </div>
        <div class="modal-content-wrapper">
          <div class="textInput">
            <label for="poolName">Pool Name</label>
            <TextInput placeholder="Enter a name" v-model="name" />
          </div>
          <div class="textInput">
            <label for="poolToken">ERC-20 token staked into pool</label>
            <p v-if="modalData.address" class="tokenAddress">{{ modalData.address }}</p>
            <TextInput v-else placeholder="Enter a contract address" v-model="token" />
          </div>
          <div class="textInput">
            <label for="tokenPercent">Percent of tokens emitted that go to this pool</label>
            <TextInput placeholder="Enter a percent" v-model="tokenPercent" />
          </div>
          <div class="textInput">
            <label for="pointPercent">Percent of points emitted that go to this pool</label>
            <TextInput placeholder="Enter a percent" v-model="pointPercent" />
          </div>
        </div>
        <div class="modal-footer-wrapper">
          <div class="footer-buttons">
            <Button :isSecondary="true" @click="exitModal">
              Cancel
            </Button>
            <Button>
              Add Pool
            </Button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Imports.
import { ref, onMounted } from 'vue';

// Component Imports.
import TextInput from '/src/components/ui/TextInput';
import Button from '/src/components/ui/Button';
// Icon imports.
import CloseIcon from '/src/components/icons/CloseIcon';

export default {
  components: {
    TextInput,
    Button,
    // Icons
    CloseIcon
  },

  props: {
    isOpen: Boolean,
    modalData: Object
  },

  emits: ['exit'],

  data() {
    return {};
  },

  methods: {
    exitModal() {
      this.$emit('exit');
    }
  },

  setup(props) {
    const name = ref('');
    const token = ref('');
    const tokenPercent = ref('');
    const pointPercent = ref('');

    onMounted(() => {
      if (props.modalData.address) {
        token.value = props.modalData.address;
      }
    });

    return {
      name,
      token,
      tokenPercent,
      pointPercent
    };
  }
};
</script>

<style scoped>
.modal-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  z-index: 999;
  cursor: default;
}

.modal-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
  z-index: 998;
}

.modal-container {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  min-width: 400px;
  width: fit-content;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  background-color: var(--background-color-nft);
}

.modal-header-wrapper {
  position: relative;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.modal-footer-wrapper {
  position: relative;
  display: flex;
  padding: 20px;
  align-items: center;
  border-top: 1px solid rgba(var(--text-color-rgb), 0.2);
}

.footer-buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.modal-header {
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 700;
}

.close-icon {
  position: absolute;
  right: 13px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.close-icon:hover {
  cursor: pointer;
  background-color: rgba(var(--text-color-rgb), 0.1);
}

.close-icon:active {
  background-color: rgba(var(--text-color-rgb), 0.2);
}

.modal-content-wrapper {
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
}

.textInput {
  margin-bottom: 20px;
}

.textInput > label {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
}

.textInput > .value {
  font-weight: 700;
}

.tokenAddress {
  font-weight: 700;
}

/* Animations */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(40px);
}
</style>
