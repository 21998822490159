<template>
  <div v-for="item in mockWatchlistData()" :key="item">
    <WatchlistItem :nft="item" />
  </div>
</template>

<script>
import WatchlistItem from './WatchlistItem';

export default {
  components: {
    WatchlistItem
  },

  methods: {
    mockWatchlistData() {
      return [
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'auctioned',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'offered',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'listed',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'sold',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        },
        {
          name: 'nft name',
          collection: 'nft collection',
          info: {
            action: 'transferred',
            price: '0.1 ETH',
            sender: 'user1',
            receiver: 'user2',
            date: new Date()
          }
        }
      ];
    }
  }
};
</script>

<style scoped></style>
