'use strict';

import initializeConfig from '../initialize-config';
import { ethers } from 'ethers';
import { ethersService } from './index';

let config;
(async () => {
  config = await initializeConfig();
})();

const getClaimContract = async () => {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();

  const claimPortalContractAddress =
    config.claimPortalContractAddress[provider.provider.chainId];
  const claimPortalContractAbi = config.claimPortalABI;

  const claimContract = new ethers.Contract(
    claimPortalContractAddress,
    claimPortalContractAbi,
    provider
  );

  return claimContract;
};

const getUserAddress = async () => {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();

  return address;
};

const getClaim = async () => {
  const claimContract = await getClaimContract();
  const address = await getUserAddress();
  let claim = await claimContract.getClaim(address);
  const claimableAmount = await claimContract.claimableAmount(address);

  claim = {
    claimableAmount: claimableAmount,
    ...claim
  };

  return claim;
};

const claimSuper = async () => {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();

  const claimPortalContractAddress =
    config.claimPortalContractAddress[provider.provider.chainId];
  const claimPortalContractAbi = config.claimPortalABI;

  const claimContract = new ethers.Contract(
    claimPortalContractAddress,
    claimPortalContractAbi,
    signer
  );

  const claimTx = await claimContract.claim(address);
  await claimTx.wait();
};

export const claimService = {
  getClaim,
  claimSuper
};
