<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="filter-modal"
    content-class="filter-modal__window"
    name="filterModal"
    @before-close="beforeClose"
  >
    <div class="filter-modal__content">
      <div class="filter-modal__header">
        <span
          class="filter-modal__header-button"
          @click="clearActivitiesFilters({ tab: activeTab })"
          >Clear</span
        >
        <div class="filter-modal__header-text">Filter</div>
        <img
          src="@/images/cross.svg"
          alt=""
          class="filter-modal__header-cross"
          @click="$vfm.hide('filterModal')"
        />
      </div>
      <VuePerfectScrollbar style="height: 100%">
        <div class="filter-modal__filters">
          <!-- <BaseAccordion class="filter-modal__accordion">
            <template #toggle> Price (0.1 - 6 ETH) </template>
          </BaseAccordion> -->
          <template class="" v-if="filterTypesNumber === 1">
            <div class="filter-modal__accordion-body">
              <slot :name="`body-1`"></slot>
            </div>
          </template>
          <template v-else>
            <BaseAccordion
              class="filter-modal__accordion"
              v-for="filterNumber in filterTypesNumber"
              :key="filterNumber"
              :ref="`filter-accordion-${filterNumber}`"
            >
              <template #toggle>
                <div class="filter-modal__accordion-header">
                  <slot :name="`header-${filterNumber}`"></slot>
                </div>
              </template>
              <template #body>
                <div class="filter-modal__accordion-body">
                  <slot :name="`body-${filterNumber}`"></slot>
                </div>
              </template>
            </BaseAccordion>
          </template>
        </div>
      </VuePerfectScrollbar>
      <div class="filter-modal__footer">
        <Button class="filter-modal__footer-button" @click="viewFilteredItems">View 15 Items </Button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar/index.vue";
import BaseAccordion from "@/components/ui/BaseAccordion";
import Button from "@/components/ui/Button";
import { mapActions } from "vuex";

export default {
  name: "filterModal",
  props: {
    filterTypesNumber: Number,
  },
  computed: {
    filterModal() {
      return this.$vfm.get("filterModal");
    },
    activeTab() {
      return this.$route.params.name;
    },
  },
  inheritAttrs: false,
  components: {
    VuePerfectScrollbar,
    BaseAccordion,
    Button,
  },
  methods: {
    ...mapActions("activity", ["clearActivitiesFilters"]),
    beforeClose() {
      if (this.filterTypesNumber > 1) {
        for (let i = 1; i <= this.filterTypesNumber; i++) {
          this.$refs[`filter-accordion-${i}`].expanded = false;
        }
      }
    },
    viewFilteredItems() {
      if (this.filterTypesNumber > 1) {
        for (let i = 1; i <= this.filterTypesNumber; i++) {
          this.$refs[`filter-accordion-${i}`].expanded = false;
        }
      }
      this.$vfm.hide('filterModal')
    }
  },
};
</script>

<style lang="scss" scoped>
:deep .filter-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  &__window {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: flex;
    width: 400px;
    height: 500px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    background-color: $white;
    @include media-max-width($mediumScreen) {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
  }
  &__header {
    display: flex;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $mediumBlack;
  }
  &__header-cross {
    position: absolute;
    right: 34px;
  }
  &__header-button {
    position: absolute;
    left: 20px;
    display: flex;
    height: 40px;
    padding: 0 15px;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    font-weight: 500;
    text-decoration: none;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    &:hover {
      background-color: $lightBlack;
    }
  }
  &__header-text {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 700;
  }
  &__footer {
    padding: 20px 15px;
    border-top: 1px solid $mediumBlack;
  }
  &__footer-button {
    height: 50px;
    border-radius: 40px;
    width: 100%;
  }
  &__filters {
    padding: 10px;
    flex: 1;
    height: 100%;
    @include media-max-width($mediumScreen) {
      padding: 0;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &__accordion-body {
    padding: 10px;
  }
  &__accordion {
    border-radius: 30px;
    margin-bottom: 10px;
    @include media-max-width($mediumScreen) {
      border-radius: 0px;
      margin-bottom: 0;
    }
  }
}
:deep .vfm--overlay {
  background-color: #00000080 !important;
}
</style>
