<template>
  <!-- on sale -->
  <div v-if="state === 'onSale'" class="price-wrapper">
    <div>
      <div class="price-label">
        Price
      </div>
      <div class="price-value">{{ priceSymbol }} {{ item.price || formatNumber(ethers.utils.formatEther(prices[0].price), 0) }}</div>
    </div>
    <!-- <div v-if="hasLastPrice">
      <div class="price-label">
        Last
      </div>
      <div class="last-price-value">
        0.05 ETH
      </div>
    </div> -->
  </div>

  <!--  on auction -->
  <div v-if="state === 'onAuction'" class="price-wrapper">
    <div>
      <div class="price-label">
        Top Bid
      </div>
      <div class="price-value">
        {{ highestBid }}
      </div>
    </div>
    <div class="time-left" :class="underOneHour ? 'under-one-hour' : ''">
      <div>{{ endTime }}</div>
    </div>
  </div>

  <BaseNFTCardPriceDrops v-if="state === 'onDrops'" :item="item" />

  <!--  on auction -->
  <div v-if="state === 'notListed'" class="price-wrapper">
    <!-- <div>
      <div class="price-label">
        For Sale
      </div>
      <div class="price-value">
        15 GEMS
      </div>
    </div> -->
    <!-- <div class="time-left" :class="underOneHour ? 'under-one-hour' : ''">
      <div>35m left</div>
    </div> -->
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import { formatNumber } from '/src/utility';
import { ethers } from 'ethers';
import store from '/src/store';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// Component Imports.
import BaseNFTCardPriceDrops from './BaseNFTCardPriceDrops.vue';

TimeAgo.addLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

export default {
  components: {
    BaseNFTCardPriceDrops
  },

  props: {
    state: {
      type: String
    },
    prices: {
      type: Object
    },

    item: {
      type: Object
    }
  },

  async created() {
    // await store.dispatch('auction/updateAuctionData', '', { root: true });
  },

  computed: {
    ...mapState({
      auction: state => state.auction
    }),

    highestBid() {
      if (this.auction.currentHighBid) {
        return formatNumber(this.auction.currentHighBid, 4);
      } else {
        return '0';
      }
    },

    endTime() {
      const date = new Date(parseInt(this.auction.endTime) * 1000);
      // If the drop has not ended yet, display the time remaining.
      if (date > new Date()) {
        const formattedDate = timeAgo.format(date);
        return formattedDate;
      } else {
        return 'Ended';
      }
    },

    underOneHour() {
      const date = new Date(parseInt(this.auction.endTime) * 1000);
      const oneHourFromNow = new Date();
      oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);
      return date < oneHourFromNow && date > new Date();
    }
  },

  data() {
    // TODO: move to databse config
    let POINT_DATA = {
      '0xf35A92585CeEE7251388e14F268D9065F5206207': { symbol: '💎' },
      '0x8e586D927acE36a3ef7bDDF9f899d2E385d5Fc9b': { symbol: '🚀' },
      '0xb3EA98747440aDDC6A262735E71B5A5cB29edd80': { symbol: '⚙️' }
    };
    let priceSymbol;
    if (this.prices && this.prices[0]?.assetType === 0 && this.prices[0]?.asset) {
      priceSymbol = POINT_DATA[this.prices[0]?.asset].symbol;
    } else if (this.prices && this.prices[0]?.staker) {
      priceSymbol = POINT_DATA[this.prices[0]?.staker].symbol;
    }

    return {
      ethers,
      formatNumber,
      hasLastPrice: true,
      priceSymbol
    };
  }
};
</script>

<style scoped>
.price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-label {
  text-align: left;
  opacity: 0.5;
  font-size: 12px;
}

.price-value {
  font-weight: 700;
  margin-top: 5px;
}

.time-left {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: fit-content;
}

.under-one-hour {
  background-color: #f12848;
}
</style>
