// Mixin for component to access feature flags and their updates through variable this.flags
export default () => {
  return {
    created() {
      this.setFlags();
      this.$flags.on('change', () => {
        this.setFlags();
      });
    },
    data() {
      return {
        flags: {},
        exactFlag: ''
      }
    },
    methods: {
      setFlags() {
        this.flags = this.$flags.allFlags();
        this.exactFlag = this.$flags.variation(this.requiredFlag, false);
      }
    },
  }
};
