<template>
  <p class='description'>
    {{text}}
    <span class='read-more' v-if='isHasMore && !isActive' @click='activate'>read more</span>
  </p>
</template>

<script>

  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      maxLength: {
        type: Number,
        required: false,
        default: 280
      }
    },
    data() {
      return {
        isActive: false
      }
    },
    computed: {
      text() {
        if (this.value) {
          return this.isHasMore
              ? this.isActive ? this.value : `${this.value.slice(0, this.maxLength)}...`
              : this.value
        }

        return ''
      },
      isHasMore() {
        return this.value && this.value.length > this.maxLength
      }
    },
    methods: {
      activate() {
        this.isActive = true
      }
    }
  }
</script>

<style scoped lang='scss'>
  .description {
    max-width: 60ch;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--text-color);
    display: block;

    .read-more {
      cursor: pointer;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>