<template>
  <CardModalDetailView
    v-if="showDetails"
    @closeDetails="toggleDetails"
    :item="itemModal.item"
  />
  <vue-final-modal
    name="card-modal"
    class="vfm__content"
    :lock-scroll="true"
    :click-to-close="true"
    :esc-to-close="true"
    :zIndex="100"
    @before-open="openedModal()"
    @closed="closeModal()"
    v-model="itemModal.isOpen"
  >
    <div class="modal-container">
      <div class="container" @click.self="closeModal">
        <div class="close-button" @click="closeModal">
          <img src="../../images/close-icon-black.svg" />
        </div>
        <div class="media-wrapper" @click.self="closeModal">
          <!-- TODO: genericize this -->

          <img
            v-if="mediaType === 'png' || mediaType === 'jpg'"
            :src="itemModal.item.metadata.image"
            class="video detail-hover"
            @click="toggleDetails"
          />
          <video
            class="video detail-hover"
            v-if="mediaType === 'mp4'"
            autoplay
            loop
            muted
            :key="itemModal.item.metadata.video"
            @click="toggleDetails"
          >
            <!-- TODO support animation_url as fallback to video. -->
            <!-- TODO: no video on card preview -->
            <source
              :src="itemModal.item.metadata.video"
              :type="`video/${mediaType}`"
            />
          </video>
        </div>
      </div>
      <CardModalsidePane
        :item="itemModal.item"
        ref="sidePane"
        :itemType="itemModal.itemType"
      />
    </div>
  </vue-final-modal>
</template>

<script>
'use strict';

// Imports.
import axios from 'axios';
import { ethers } from 'ethers';
import { formatAddress, formatNumber } from '../../utility';
import { mapState, mapActions } from 'vuex';

// Component imports.
import CardModalsidePane from './CardModalSidePane';
import CardModalDetailView from './CardModalDetailView';

export default {
  async created() {
    axios
      .get(
        'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      )
      .then(response => (this.ethPrice = response.data.ethereum.usd));
  },
  data() {
    return {
      ethers,
      formatNumber,
      ethPrice: null,
      currentBrowserLocation: '',
      showDetails: false
    };
  },
  computed: {
    ...mapState({
      ethereum: state => state.ethers,
      itemModal: state => state.itemModal
    }),

    mediaType() {
      if (!this.itemModal.item) {
        return null;
      }
      let video = this.itemModal.item.metadata.video;
      let animationUrl = this.itemModal.item.metadata['animation_url'];
      let image = this.itemModal.item.metadata.image;
      if (video) {
        let videoPath = this.itemModal.item.metadata.video
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (animationUrl) {
        let videoPath = this.itemModal.item.metadata['animation_url']
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return videoPath;
      }
      if (image) {
        let imagePath = this.itemModal.item.metadata.image
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim();
        return imagePath;
      }
      return null;
    }
  },
  components: {
    CardModalsidePane,
    CardModalDetailView
  },
  methods: {
    ...mapActions('itemModal', ['close', 'updateTab']),
    ...mapActions({
      showWalletModal: 'alert/showWalletModal'
    }),

    openedModal: function() {
      this.setBrowserUrl();
    },
    closeModal: function() {
      this.restoreBrowserUrl();
      this.$refs.sidePane.setActiveTab('Details');
      this.close({ isOpen: false, activeTab: 'Details' });
    },

    setBrowserUrl() {
      this.currentBrowserLocation = window.location.href;
      const name = this.itemModal.item.metadata.name;

      let formattedName = name.toLowerCase().split(' ');
      formattedName[formattedName.length - 1] = formattedName[formattedName.length - 1].replace('#', '');
      formattedName = formattedName.join('-');
      // This will create a new entry in the browser's history, without reloading
      history.replaceState({}, null, 'items/' + formattedName);
    },

    restoreBrowserUrl() {
      history.replaceState({}, null, this.currentBrowserLocation);
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
    }
  }
};
</script>

<style scoped>
.video {
  max-height: 61.8%;
  width: auto;
  max-width: 100%;
}

.detail-hover {
  cursor: zoom-in;
}

.modal-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--background-color-rgb), 0.9);
  position: relative;
  z-index: 100;
}

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  flex: 1;
}

.media-wrapper {
  height: calc(100vh - 64px);
  position: relative;
  display: flex;
  width: 85%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.close-button {
  position: absolute;
  left: 20px;
  top: 10px;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #e4e6ea;
  height: 40px;
  width: 40px;
  z-index: 99;
}

.close-button:hover {
  opacity: 1;
  transform: scale(1.05);
}

@supports (
  (-webkit-backdrop-filter: blur(35px)) or (backdrop-filter: blur(35px))
) {
  .modal-container {
    backdrop-filter: blur(35px);
    background-color: rgba(var(--background-color-rgb), 0.2);
    -webkit-backdrop-filter: blur(35px);
  }
}

@media (max-width: 990px) {
  .modal-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    overflow: initial;
    height: 60vh;
  }

  .image-container {
    height: 95%;
  }

  .vfm__content {
    overflow: scroll;
  }
}
</style>
