<template>
  <div class='prev-next-wrapper'>
    <Button class='button' @click='handleChange("decrement")'>&lt;</Button>
    <span class='values'>{{val}} of {{count}}</span>
    <Button class='button' @click='handleChange("increment")'>&gt;</Button>
  </div>
</template>

<script>
  import Button from '@/components/ui/Button'

  export default {
    components: {Button},
    props: {
      value: {
        type: Number,
        required: true
      },
      count: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        val: this.value
      }
    },
    methods: {
      handleChange(type) {
        let value = this.val

        if (type === 'increment' && value < this.count) {
          value++
        } else if (type === 'decrement' && value > 1) {
          value--
        }

        if (this.val !== value) {
          this.val = value

          this.$emit('onChange', this.val)
        }
      }
    },
    emits: ['onChange']
  }
</script>

<style scoped lang='scss'>
  .prev-next-wrapper {
    display: flex;
    align-items: center;

    .values {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      width: 106.59px;
    }

    .button {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
</style>
